import { FunctionComponent, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CircleIcon from "../../chatbot/Icon";
import secureLocalStorage from "react-secure-storage";
import { getFundbyId } from "../../investor-fund-subscription/services/services";
import { toast } from "react-toastify";
import ServicingAccordians from "./ServicingAccordians";

const ServicingTab = () => {
  const navigate = useNavigate();
  const [fundDetails, setFundDetails] = useState(null)

  const fundData = async () => {
    const data = await getFundbyId(secureLocalStorage.getItem('FundId'))
    if (data.responseCode === 200) {
      setFundDetails(data.responseData)
    } else {
      toast.warning(data.responseData,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }
  useEffect(() => {
    fundData()
  }, [])


  return (
    <div className="services-accord">
      <h4> Services </h4>
      <>
        {
          fundDetails ? <div>
            <ServicingAccordians fundDetails={fundDetails} />
            <CircleIcon />
          </div> : 'Loading...'
        }
      </>

    </div>
  );
};

export default ServicingTab;
