export const investorRelationJsonData = {
    "InvestorRelationsColumns": [
        {
    
            "id": 1,
    
            "field": "TYPE",
    
            "headerName": "TYPE",
    
            "width": 250
    
        },
        {
    
            "id": 2,
    
            "field": "STATUS",
    
            "headerName": "STATUS",
    
            "width": 200
    
        },
        {
    
            "id": 3,
    
            "field": "DATE",
    
            "headerName": "DATE",
    
            "width": 90
    
        }
    ],
    
    "InvestorRelationsRows": [
        {
    
            "id": 1,
    
            "TYPE": "Subsequent Closes",
    
            "STATUS": "Scheduled",
    
            "DATE": "03/31/23"
        },
        {
    
            "id": 2,
    
            "TYPE": "Final Close",
    
            "STATUS": "Delivered",
    
            "DATE": "03/15/23"
        },
        {
    
            "id": 3,
    
            "TYPE": "Fund Extension",
    
            "STATUS": "Scheduled",
    
            "DATE": "03/21/23"
        }
    ],
    "UpcomingInvestorColumns": [
        {
    
            "id": 1,
    
            "field": "TYPE",
    
            "headerName": "TYPE",
    
            "width": 175
    
        },
        {
    
            "id": 2,
    
            "field": "FUND",
    
            "headerName": "FUND",
    
            "width": 150
    
        },
        {
    
            "id": 3,
    
            "field": "LOCATION",
    
            "headerName": "LOCATION",
    
            "width": 150
    
        },
        {
    
            "id": 4,
    
            "field": "DATE",
    
            "headerName": "DATE",
    
            "width": 100
    
        }
    ],
    "UpcomingInvestorRows": [
        {
            "id": 1,
    
            "TYPE": "Annual Investor Meeting",
    
            "FUND": "Nexus Health Equ…",
    
            "LOCATION": "New York, NY",

            "DATE":  "03/31/23"
        },
        {
            "id": 2,
    
            "TYPE": "Portfolio Update Webinar",
    
            "FUND": "Crestmont Capital…",
    
            "LOCATION": "Online Webinar",

            "DATE":  "03/15/23"
        },
        {
            "id": 3,
    
            "TYPE": "ESG Workshop",
    
            "FUND": "Pinecrest Fund I",
    
            "LOCATION": "San Francisco, CA",

            "DATE":  "03/21/23"
        },
        {
            "id": 4,
    
            "TYPE": "Market Outlook Presentation",
    
            "FUND": "Cascade Infrastruct…",
    
            "LOCATION": "London, UK",

            "DATE":  "03/21/23"
        },
        {
            "id": 5,
    
            "TYPE": "Investor Networking Event",
    
            "FUND": "Summit Ridge Credi…",
    
            "LOCATION": "Online Webinar",

            "DATE":  "03/21/23"
        },
        {
            "id": 6,
    
            "TYPE": "Fund Launch Event",
    
            "FUND": "Redwood Natural….",
    
            "LOCATION": "Boston, MA",

            "DATE":  "03/21/23"
        },
        {
            "id": 7,
    
            "TYPE": "Risk Mnagement Workshop",
    
            "FUND": "Summit Ridge Infr…",
    
            "LOCATION": "Chicago, IL",

            "DATE":  "03/21/23"
        },
        {
            "id": 8,
    
            "TYPE": "Year-End Review",
    
            "FUND": "Arrowhead Equity",
    
            "LOCATION": "Online Webinar",

            "DATE":  "03/21/23"
        },
        {
            "id": 9,
    
            "TYPE": "Annual Investor Meeting",
    
            "FUND": "Atlas Peak Capital",
    
            "LOCATION": "Online Webinar",

            "DATE":  "03/21/23"
        },
        {
            "id": 10,
    
            "TYPE": "ESG Workshop",
    
            "FUND": "Crestmont Capital…",
    
            "LOCATION": "New York, NY",

            "DATE":  "03/21/23"
        },
        {
            "id": 11,
    
            "TYPE": "Portfolio Update Webinar",
    
            "FUND": "Pinecrest Fund I",
    
            "LOCATION": "New York, NY",

            "DATE":  "03/21/23"
        }
    ],
    "LPCommunicationColumns": [
        {
    
            "id": 1,
    
            "field": "TYPE",
    
            "headerName": "TYPE",
    
            "width": 270
    
        },
        {
    
            "id": 2,
    
            "field": "STATUS",
    
            "headerName": "STATUS",
    
            "width": 200
    
        },
        {
    
            "id": 3,
    
            "field": "DATE",
    
            "headerName": "DATE",
    
            "width": 90
    
        }
    ],
    "LPCommunicationRows": [
        {
    
            "id": 1,
    
            "TYPE": "Tax Reporting",
    
            "STATUS": "Scheduled",
    
            "DATE": "03/31/23"
    
        },
        {
    
            "id": 2,
    
            "TYPE": "Distribution Notice",
    
            "STATUS": "Delivered",
    
            "DATE": "03/15/23"
    
        },
        {
    
            "id": 3,
    
            "TYPE": "ESG Report",
    
            "STATUS": "Scheduled",
    
            "DATE": "03/21/23"
    
    
        }
    ],

        "RelColumns": [
            {
        
                "id": 1,
        
                "field": "INVESTORS",
        
                "headerName": "INVESTORS",
        
                "width": 170
        
            },
            {
        
                "id": 2,
        
                "field": "STATUS",
        
                "headerName": "STATUS",
        
                "width": 90
        
            },
            {
        
                "id": 3,
        
                "field": "TYPE",
        
                "headerName": "TYPE",
        
                "width": 110
        
            },
    {
        
                "id": 4,
        
                "field": "SUBSCRIBED",
        
                "headerName": "SUBSCRIBED",
        
                "width": 110
        
            },
    {
        
                "id": 5,
        
                "field": "CONTRIBUTED",
        
                "headerName": "CONTRIBUTED",
        
                "width": 120
        
            },
    {
        
                "id": 6,
        
                "field": "UNFUNDED",
        
                "headerName": "UNFUNDED",
        
                "width": 100
        
            },
    {
        
                "id": 7,
        
                "field": "NAV",
        
                "headerName": "NAV",
        
                "width": 90
        
            },
    {
        
                "id": 8,
        
                "field": "DISTRIBUTIONS",
        
                "headerName": "DISTRIBUTIONS",
        
                "width": 110
        
            },
    {
        
                "id": 9,
        
                "field": "OWNERSHIP",
        
                "headerName": "OWNERSHIP",
        
                "width": 90
        
            },
    {
        
                "id": 10,
        
                "field": "RECEIVED",
        
                "headerName": "RECEIVED",
        
                "width": 90
        
            },
    {
        
                "id": 11,
        
                "field": "FEE",
        
                "headerName": "FEE",
        
                "width": 70
        
            },
            {
        
                "id": 12,
        
                "field": "CARRY",
        
                "headerName": "CARRY",
        
                "width": 90
        
            },
    {
        
                "id": 13,
        
                "field": "UPDATE",
        
                "headerName": "UPDATE",
        
                "width": 110
        
            }
    
    
        ],
        "RelRows": [
            {
        
                "id": 1,
                "INVESTORS": "Thomas Smith",
                "STATUS":"Signed",
                "TYPE": "Family Office",
                "SUBSCRIBED": "$215,000.00",
                "CONTRIBUTED": "$192,474.00",
                "UNFUNDED": "$0.00",
                "NAV": "$0.00",
                "DISTRIBUTIONS": "$0.00",
                "OWNERSHIP": "2.75%",
                "RECEIVED": "$22,575",
                "FEE": "2%",
                "CARRY": "20%",
                "UPDATE": "MAR 1, 2023"
    
            },
            {
        
                "id": 2,
                "INVESTORS": "Brandon Kim",
                "STATUS":"Signed",
                "TYPE": "Family Office",
                "SUBSCRIBED": "$215,000.00",
                "CONTRIBUTED": "$192,474.00",
                "UNFUNDED": "$0.00",
                "NAV": "$0.00",
                "DISTRIBUTIONS": "$0.00",
                "OWNERSHIP": "2.75%",
                "RECEIVED": "$22,575",
                "FEE": "2%",
                "CARRY": "20%",
                "UPDATE": "MAR 1, 2023"
    
            },
    {
        
                "id": 3,
                "INVESTORS": "Samantha Hernandez",
                "STATUS":"Signed",
                "TYPE": "Family Office",
                "SUBSCRIBED": "$215,000.00",
                "CONTRIBUTED": "$192,474.00",
                "UNFUNDED": "$0.00",
                "NAV": "$0.00",
                "DISTRIBUTIONS": "$0.00",
                "OWNERSHIP": "2.75%",
                "RECEIVED": "$22,575",
                "FEE": "2%",
                "CARRY": "20%",
                "UPDATE": "MAR 1, 2023"
    
            },
    {
        
                "id": 4,
                "INVESTORS": "William A. St. John",
                "STATUS":"Signed",
                "TYPE": "Family Office",
                "SUBSCRIBED": "$215,000.00",
                "CONTRIBUTED": "$192,474.00",
                "UNFUNDED": "$0.00",
                "NAV": "$0.00",
                "DISTRIBUTIONS": "$0.00",
                "OWNERSHIP": "2.75%",
                "RECEIVED": "$22,575",
                "FEE": "2%",
                "CARRY": "20%",
                "UPDATE": "MAR 1, 2023"
    
            },
    {
        
                "id": 5,
                "INVESTORS": "Tyler Wong",
                "STATUS":"Signed",
                "TYPE": "Family Office",
                "SUBSCRIBED": "$215,000.00",
                "CONTRIBUTED": "$192,474.00",
                "UNFUNDED": "$0.00",
                "NAV": "$0.00",
                "DISTRIBUTIONS": "$0.00",
                "OWNERSHIP": "2.75%",
                "RECEIVED": "$22,575",
                "FEE": "2%",
                "CARRY": "20%",
                "UPDATE": "MAR 1, 2023"
    
            },
    {
        
                "id": 6,
                "INVESTORS": "Olivia Brown",
                "STATUS":"Signed",
                "TYPE": "Family Office",
                "SUBSCRIBED": "$215,000.00",
                "CONTRIBUTED": "$192,474.00",
                "UNFUNDED": "$0.00",
                "NAV": "$0.00",
                "DISTRIBUTIONS": "$0.00",
                "OWNERSHIP": "2.75%",
                "RECEIVED": "$22,575",
                "FEE": "2%",
                "CARRY": "20%",
                "UPDATE": "MAR 1, 2023"
    
            },
    {
        
                "id": 7,
                "INVESTORS": "Tyler Wong",
                "STATUS":"Signed",
                "TYPE": "Family Office",
                "SUBSCRIBED": "$215,000.00",
                "CONTRIBUTED": "$192,474.00",
                "UNFUNDED": "$0.00",
                "NAV": "$0.00",
                "DISTRIBUTIONS": "$0.00",
                "OWNERSHIP": "2.75%",
                "RECEIVED": "$22,575",
                "FEE": "2%",
                "CARRY": "20%",
                "UPDATE": "MAR 1, 2023"
    
            },
    {
        
                "id": 8,
                "INVESTORS": "Justin Patel",
                "STATUS":"Signed",
                "TYPE": "Family Office",
                "SUBSCRIBED": "$215,000.00",
                "CONTRIBUTED": "$192,474.00",
                "UNFUNDED": "$0.00",
                "NAV": "$0.00",
                "DISTRIBUTIONS": "$0.00",
                "OWNERSHIP": "2.75%",
                "RECEIVED": "$22,575",
                "FEE": "2%",
                "CARRY": "20%",
                "UPDATE": "MAR 1, 2023"
    
            },
    {
        
                "id": 9,
                "INVESTORS": "Chloe Davis",
                "STATUS":"Signed",
                "TYPE": "Family Office",
                "SUBSCRIBED": "$215,000.00",
                "CONTRIBUTED": "$192,474.00",
                "UNFUNDED": "$0.00",
                "NAV": "$0.00",
                "DISTRIBUTIONS": "$0.00",
                "OWNERSHIP": "2.75%",
                "RECEIVED": "$22,575",
                "FEE": "2%",
                "CARRY": "20%",
                "UPDATE": "MAR 1, 2023"
    
            },
    {
        
                "id": 10,
                "INVESTORS": "Andrew Nguyen",
                "STATUS":"Signed",
                "TYPE": "Family Office",
                "SUBSCRIBED": "$215,000.00",
                "CONTRIBUTED": "$192,474.00",
                "UNFUNDED": "$0.00",
                "NAV": "$0.00",
                "DISTRIBUTIONS": "$0.00",
                "OWNERSHIP": "2.75%",
                "RECEIVED": "$22,575",
                "FEE": "2%",
                "CARRY": "20%",
                "UPDATE": "MAR 1, 2023"
    
            },
    {
        
                "id": 11,
                "INVESTORS": "Madison Thomas",
                "STATUS":"Signed",
                "TYPE": "Family Office",
                "SUBSCRIBED": "$215,000.00",
                "CONTRIBUTED": "$192,474.00",
                "UNFUNDED": "$0.00",
                "NAV": "$0.00",
                "DISTRIBUTIONS": "$0.00",
                "OWNERSHIP": "2.75%",
                "RECEIVED": "$22,575",
                "FEE": "2%",
                "CARRY": "20%",
                "UPDATE": "MAR 1, 2023"
    
            },
    {
        
                "id": 12,
                "INVESTORS": "Caleb Johnson",
                "STATUS":"Signed",
                "TYPE": "Family Office",
                "SUBSCRIBED": "$215,000.00",
                "CONTRIBUTED": "$192,474.00",
                "UNFUNDED": "$0.00",
                "NAV": "$0.00",
                "DISTRIBUTIONS": "$0.00",
                "OWNERSHIP": "2.75%",
                "RECEIVED": "$22,575",
                "FEE": "2%",
                "CARRY": "20%",
                "UPDATE": "MAR 1, 2023"
    
    }
    ]

}

export const ComplianceTabJsonData ={
    "ComplianceAlertColumns": [
        {
    
            "id": 1,
    
            "field": "TYPE",
    
            "headerName": "TYPE",
    
            "width": 190
    
        },
        {
    
            "id": 2,
    
            "field": "FUND",
    
            "headerName": "FUND",
    
            "width": 150
    
        },
        {
    
            "id": 3,
    
            "field": "STATUS",
    
            "headerName": "STATUS",
    
            "width": 130
    
        },
        {
    
            "id": 4,
    
            "field": "DATE",
    
            "headerName": "DATE",
    
            "width": 90
    
        }
    ],
    "ComplianceAlertRows": [
        {
    
            "id": 1,
    
            "TYPE": "Form ADV",
    
            "FUND": "Nexus Health Equ…",
    
            "STATUS": "Completed",
            
            "DATE": "03/31/23"
        },
        {
    
            "id": 2,
    
            "TYPE": "Form PF",
    
            "FUND": "NCrestmont Capital…",
    
            "STATUS": "Completed",
            
            "DATE": "03/15/23"
        },
        {
    
            "id": 3,
    
            "TYPE": "AML/KYC Documentation",
    
            "FUND": "Pinecrest Fund I",
    
            "STATUS": "Completed",
            
            "DATE": "03/21/23"
        },
        {
    
            "id": 4,
    
            "TYPE": "FATCA Reporting",
    
            "FUND": "Cascade Infrastruct…",
    
            "STATUS": "Completed",
            
            "DATE": "03/21/23"
        },
        {
    
            "id": 5,
    
            "TYPE": "CRS Reporting",
    
            "FUND": "Summit Ridge Credi….",
    
            "STATUS": "In Progress",
            
            "DATE": "03/21/23"
        },
        {
    
            "id": 6,
    
            "TYPE": "AIFMD Reporting",
    
            "FUND": "Redwood Natural…",
    
            "STATUS": "In Progress",
            
            "DATE": "03/21/23"
        },
        {
    
            "id": 7,
    
            "TYPE": "Form D",
    
            "FUND": "Summit Ridge Infr…",
    
            "STATUS": "Completed",
            
            "DATE": "03/21/23"
        },
        {
    
            "id": 8,
    
            "TYPE": "ERISA Compliance",
    
            "FUND": "Arrowhead Equity",
    
            "STATUS": "Resolved",
            
            "DATE": "03/21/23"
        },
        {
    
            "id": 9,
    
            "TYPE": "GDPR Compliance",
    
            "FUND": "Atlas Peak Capital",
    
            "STATUS": "Resolved",
            
            "DATE": "03/21/23"
        },
        {
    
            "id": 10,
    
            "TYPE": "ESG Workshop",
    
            "FUND": "Crestmont Capital…",
    
            "STATUS": "Completed",
            
            "DATE": "03/21/23"
        },
        {
    
            "id": 11,
    
            "TYPE": "Trade Compliance Alert",
    
            "FUND": "Pinecrest Fund I",
    
            "STATUS": "Completed",
            
            "DATE": "03/21/23"
        }
    ],
    "KYC/AMLDocumentationColumns": [
        {
    
            "id": 1,
    
            "field": "TYPE",
    
            "headerName": "TYPE",
    
            "width": 250
    
        },
        {
    
            "id": 2,
    
            "field": "FUND",
    
            "headerName": "FUND",
    
            "width": 250
    
        },
        {
    
            "id": 3,
    
            "field": "STATUS",
    
            "headerName": "STATUS",
    
            "width": 250
    
        },
        {
    
            "id": 4,
    
            "field": "DATE",
    
            "headerName": "DATE",
    
            "width": 250
    
        }
    ],
    "KYC/AMLDocumentationRows": [
        {
    
            "id": 1,
    
            "TYPE": "Form ADV",
    
            "FUND": "Nexus Health Equ…",
    
            "STATUS": "Completed",
            
            "DATE": "03/31/23"
        },
        {
    
            "id": 2,

            "FUND": "NCrestmont Capital…",
    
            "STATUS": "Completed",
            
            "DATE": "03/15/23"
        },
        {
    
            "id": 3,
    
            "TYPE": "AML/KYC Documentation",
    
            "FUND": "Pinecrest Fund I",
    
            "STATUS": "Completed",
            
            "DATE": "03/21/23"
        },
        {
    
            "id": 4,
    
            "TYPE": "FATCA Reporting",
    
            "FUND": "Cascade Infrastruct…",
    
            "STATUS": "Completed",
            
            "DATE": "03/21/23"
        },
        {
    
            "id": 5,
    
            "TYPE": "CRS Reporting",
    
            "FUND": "Summit Ridge Credi….",
    
            "STATUS": "In Progress",
            
            "DATE": "03/21/23"
        },
        {
    
            "id": 6,
    
            "TYPE": "AIFMD Reporting",
    
            "FUND": "Redwood Natural…",
    
            "STATUS": "In Progress",
            
            "DATE": "03/21/23"
        },
        {
    
            "id": 7,
    
            "TYPE": "Form D",
    
            "FUND": "Summit Ridge Infr…",
    
            "STATUS": "Completed",
            
            "DATE": "03/21/23"
        },
        {
    
            "id": 8,
    
            "TYPE": "ERISA Compliance",
    
            "FUND": "Arrowhead Equity",
    
            "STATUS": "Resolved",
            
            "DATE": "03/21/23"
        },
        {
    
            "id": 9,
    
            "TYPE": "GDPR Compliance",
    
            "FUND": "Atlas Peak Capital",
    
            "STATUS": "Resolved",
            
            "DATE": "03/21/23"
        },
        {
    
            "id": 10,
    
            "TYPE": "ESG Workshop",
    
            "FUND": "Crestmont Capital…",
    
            "STATUS": "Completed",
            
            "DATE": "03/21/23"
        },
        {
    
            "id": 11,
    
            "TYPE": "Trade Compliance Alert",
    
            "FUND": "Pinecrest Fund I",
    
            "STATUS": "Completed",
            
            "DATE": "03/21/23"
        }
    ]
}