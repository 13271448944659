import React from 'react'
import { getAllFunds, getInvestorsTypes } from '../../../InvestorRelations/Services/IRServices';
import SubscriptionQuestionsMain from './SubscriptionQuestionsMain';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState,useEffect } from 'react';
import { LinearProgress } from '@mui/material';
import { FieldVariants } from '../../../common/TextInput/appInputenum';
import { getKeyValuePairs } from '../../../portfolio-companies/services/services';
import SelectField from '../../../common/input-fields/SelectField';
import { InvestorTypesEnum } from '../../../user-profiles/services/apiDataTemplate';
import { getMarketPlaceFunds } from '../../../marketplace-dashboard/services/mpservices';

const SubscriptionQuestionsModel = () => {

  //#region variables
  const [investorTypes,setInvestorTypes] = useState(null);
  const [expanded, setExpanded] = useState({});
  const [selectedFundType,setSelectedFundType] = useState();
  const [selectedFundTypeName,setSelectedFundTypeName] = useState();
  const [fundTypes,setFundTypes] = useState([]);
  const [allFundDeatils,setAllFundDetails] = useState([]);

  //#region change events
  const handleFilterChange = (name, value) => {
    setSelectedFundType(value);
    setExpanded({});
  }

  //#region click events
  const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded({ ...expanded, [panel]: isExpanded });
  };

  //#region api get calls
  const getFundTypes = async() => {
    const data = await getKeyValuePairs("FundType");
    if(data.responseCode === 200){
        const dropdownValues = data.responseData
                                ?.filter(option => option.listItemID !== -1)
                                ?.map(option => ({ label: option.listItemValue, value: option.listItemID })) || []
        setSelectedFundType(dropdownValues[0]?.value)
        setFundTypes(dropdownValues)
    }
    else{
        setFundTypes([])
    }
  }

  const getInvestorsType = async () => {
    const data = await getInvestorsTypes('InvestorType');
    if (data?.responseCode === 200) {
      setInvestorTypes(data?.responseData);
    } else {
      // Handle error if needed
      /* console.log("Error in getting investor types"); */
    }
  }

  const getFunds = async() => {
    const data = await getMarketPlaceFunds();
    if(data.responseCode === 200){
       const filteredFunds = data.responseData
                             ?.filter((fund) => fund.fundTypeID === selectedFundType)
                             ?.map((fund) => ({"listItemValue": fund.fundName, "listItemID": fund.fundID}))
       setAllFundDetails(filteredFunds);
    }
    else{
       setAllFundDetails([]);
    }
   }

  //#region useeffect
  useEffect(() => {
    getInvestorsType();
    getFundTypes();
  },[]);  

  useEffect(()=>{
    getFunds();
  },[selectedFundType])

  useEffect(()=>{
    const selectedOption = fundTypes?.find((option) => option?.value === selectedFundType)
    setSelectedFundTypeName(selectedOption?.label)
  },[selectedFundType,fundTypes])

  //#region return
  return (
    <div className='margin-top-20'>
      <h4>Fund Subscription Questions</h4>
      <div className='dispaly-column white-card margin-top-10'>
         <div className='space-between'>
            <div></div>
            <div className='width-180'>
                <SelectField
                    name='fundType'
                    label='Fund Type'
                    variant={FieldVariants.OUTLINED}
                    value={selectedFundType}
                    options={fundTypes}
                    onChange={(name, value) => handleFilterChange(name, value)} />
                </div>
         </div>
          {
            investorTypes ? investorTypes.map((item,index) => {
              return (
                <div className='margin-top-10'>
                    <Accordion expanded={expanded[item.listItemID] || false}
                                onChange={handleAccordionChange(item.listItemID)}
                                style={{border:'1px lightgray solid'}}>
                        <AccordionSummary
                                aria-controls="panel1bh-content"
                                expandIcon={<ExpandMoreIcon />}
                                id="panel1bh-header">
                                  {item.listItemValue}
                        </AccordionSummary>
                        <AccordionDetails>
                          {
                            expanded[item.listItemID] && 
                            <SubscriptionQuestionsMain 
                                  selectedFundType={selectedFundType} 
                                  selectedFundTypeName={selectedFundTypeName} 
                                  investorTypeId={item.listItemID} 
                                  investorTypeName={item.listItemValue}
                                  allFundDeatils={allFundDeatils} />
                          }
                        </AccordionDetails>
                    </Accordion>
                </div>
              )
            }) : <LinearProgress />
          }
      </div>
    </div>
  )
}

export default SubscriptionQuestionsModel