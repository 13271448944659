import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import { useState } from 'react';
import ActionButton from '../../../common/ActionButton/ActionButton';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { updateSubscriptionQuestionOrder } from '../services/services';
import { ToastContainer, toast } from 'react-toastify';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

/**
 * ReOrderingPopup component
 * 
 * This component is used to reorder the questions inside a step
 * 
 * @param {Object} props
 * @param {boolean} props.open - whether the popup is open or not
 * @param {Object} props.selectedStepDetails - the selected step details
 * @param {Function} props.onClose - the function to call when the popup is closed
 */


const ReOrderingPopup = (props) => {

    //#region props
    const { open, selectedStepDetails, onClose } = props;

    //#region variables
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState(selectedStepDetails?.children);
    const columns = [
        {
            field: 'questionNo', headerName: 'Question Order', width: 200,
            renderCell: (params) => (params.row.questionBank.questionNo / 100),
        },
        {
            field: 'branchTitle', headerName: 'Question Name', width: 200,
            renderCell: (params) => (
                params.row.branchType === "Question" ? params.row.branchTitle : ""
            ),
        },
    ]

    //#region change events
    const handleRowOrderChange = async (params) => {
        const newRows = await updateRowPosition(
            params.oldIndex,
            params.targetIndex,
            rows,
        );

        setRows(newRows);

    };

    //#region click events
    const onSubmit = () => {
        updateQuestionOrder();
    }

    //#region api update calls
    const updateQuestionOrder = async () => {
        setLoading(true);
        const requestedBody = rows?.flatMap((item, parentIndex) => {
            let result = [{
                "questionBankID": item?.questionBank?.questionBankID,
                "questionNo": (parentIndex + 1) * 100
            }];
            if (Array.isArray(item?.children)) {
                const children = item?.children.map((childQuestion, childIndex) => ({
                    "questionBankID": childQuestion?.questionBank?.questionBankID,
                    "questionNo": ((parentIndex + 1) * 100) + (childIndex + 1)
                }));
                result = result.concat(children);
            }

            return result;
        });

        const data = await updateSubscriptionQuestionOrder(requestedBody);
        if (data.responseCode === 200) {
            toast.success("The question order is updated successfully",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            onClose();
            setLoading(false);
        } else {
            setLoading(false);
            toast.error("unable to update question order",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>
                Step Questions
            </DialogTitle>
            <DialogContent>
                <DataGridPro
                    columns={columns}
                    rows={
                        rows?.length > 0
                            ? rows.map((question, index) => ({
                                id: question?.questionBank?.questionNo,
                                ...question
                            }))
                            : []
                    }
                    onRowOrderChange={handleRowOrderChange}
                    rowReordering
                    hideFooter />
            </DialogContent>
            <DialogActions>
                <div className='space-between'>
                    <div>
                        <ActionButton variant='outlined' icon={<CloseIcon />} disabled={loading} onClick={onClose} label='Cancel' />
                    </div>
                    <div className='margin-left-10'>
                        <ActionButton
                            label='SAVE'
                            loading={loading}
                            icon={<SaveIcon />}
                            startIconName=''
                            styleProps={{
                                padding: '8px 36px'
                            }}
                            onClick={onSubmit} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}

function updateRowPosition(initialIndex, newIndex, rows) {
    return new Promise((resolve) => {
        setTimeout(() => {
            const rowsClone = [...rows];
            const row = rowsClone.splice(initialIndex, 1)[0];
            rowsClone.splice(newIndex, 0, row);
            resolve(rowsClone);
        }, Math.random() * 500 + 100); // simulate network latency
    });
}

export default ReOrderingPopup;