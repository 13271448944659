export function validateDate(value) {

    let dateObj = "";

    if(typeof value === 'string' || value instanceof String){
        dateObj = new Date(value);
    }
    else{
        dateObj = value;
    }

    // Ensure dateObj is a valid Date instance and not 'Invalid Date'
    if (!(dateObj instanceof Date) || isNaN(dateObj)) {
      return false;
    }
  
    // Extract year, month, and day from the Date object
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1; // Months are 0-indexed, so add 1
    const day = dateObj.getDate();
  
    // Ensure all components are present and within valid ranges
    const isValidYear = year > 0; // Customize this range as needed
    const isValidMonth = month >= 1 && month <= 12;
    const isValidDay = day >= 1 && day <= 31;
  
    return isValidYear && isValidMonth && isValidDay;
  }