import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function RowRadioButtonsField(props) {

  const {label,options,value,onChange,disabled} = props; 

  const handleChange = (ev) =>{
    const {name,value} = ev.target; 
    onChange(value);
  };
  return (
    <FormControl disabled={disabled}>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name={label}
        value={value}
        onChange={(event)=>handleChange(event)}>
        {
            options?.map((item)=>{
                return (
                    <FormControlLabel value={item.value} control={<Radio />} label= {item.label}/>
                )
            })
        }
      </RadioGroup>
    </FormControl>
  );
}
