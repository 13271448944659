import React, { useEffect } from 'react'
import CheckBoxRenderingDemo from '../../../administration/InvestorOnBoardingQues/Components/CheckBoxRenderingDemo';
import TextInput from '../../../common/TextInput/TextInput';
import Box from '@mui/system/Box';
import Card from '@mui/material/Card';
import './styles.css';
import { useState } from 'react';
import { convertArrayToString } from '../../../common/Functions/ConvertStringtoArray';
import { convertStringToArray } from '../../../common/Functions/ConvertStringtoArray';
import { NewObqoptionsTemplate } from './APIDataTemplate';
import secureLocalStorage from 'react-secure-storage';

const ObqOptions = (props) => {

    const {data,dataAnswers,setApiansweritem,setAnswers,Texquestionformat,optionsformat} = props;

    //data -> Active Question (all onboarding questions)
    //dataAnswers -> Answerss filled but by user till now
    //setApiansweritem -> the data sent to api after going to next step
    //setAnswers -> this is used to store the answers...

    /* const Texquestionformat = 5;
    const optionsformat = 4; */

    //Create new options incase it is not there in data answers

    const createNewObqoptionAnswer = (additionalData) => {
      const UpdatedOption = { ...NewObqoptionsTemplate };
      UpdatedOption["questionBankID"] = additionalData.questionBankID;
      UpdatedOption["userID"] = secureLocalStorage.getItem("userId");
    
      // Check if the option already exists in dataAnswers
      const existingOption = dataAnswers?.find(
        (item) => item.questionBankID === additionalData.questionBankID
      );
    
      // If the option exists, use the existing one; otherwise, add the new option
      if (existingOption) {
        return existingOption;
      } else {
        setAnswers((prevItems) => {
          if (!prevItems || prevItems.length === 0) {
             
            return [UpdatedOption];
          }
          return [...prevItems, UpdatedOption];
        });
        
        return { ...UpdatedOption };
      }
    };
    
    let currentQuestionAnswer = dataAnswers && dataAnswers.length > 0
                                  ? (dataAnswers.find((item) => item.questionBankID === data?.questionBankID) || {}).questionAnswerID || createNewObqoptionAnswer(data)
                                  : createNewObqoptionAnswer(data);

    

    //To Set the Question Bank Items with data answers 

   /*  const currentQuestionAnswer = dataAnswers && data
                                  ? (dataAnswers.find((item) => item.questionBankID === data.questionBankID) || {}).questionAnswerID || createNewObqoptionAnswer(data)
                                  : null; */
                                    
  
   const textquestionAnswer =  data.onboardingQuestionPattern === "5"
                                  ? dataAnswers && data
                                    ? dataAnswers?.find((item) => item.questionBankID === data.questionBankID) || null
                                    : null
                                  : null;
    const [textquestion,settextquestion] = useState(textquestionAnswer);
    const [currQuestionID,setcurrQuestionID] = useState();

    useEffect(()=>{
       
           const tempanswer = data.onboardingQuestionPattern === "5"
                              ? dataAnswers && data
                                ? dataAnswers?.find((item) => item.questionBankID === data.questionBankID) || null
                                : null
                              : null;
                          /* debugger */
            settextquestion(tempanswer);
            setcurrQuestionID(data.questionBankID);
    },[textquestionAnswer,data])

   /*  const textquestion =  data.onboardingQuestionPattern === 5
                          ? dataAnswers && data
                            ? dataAnswers?.find((item) => item.questionBankID === data.questionBankID) || null
                            : null
                          : null; */
    useEffect(()=>{
      const MultiChoiceAnwers = dataAnswers && data
              ? dataAnswers?.find((item) => item.questionBankID === data.questionBankID) || null
              : "";

        const MultianswerArray = convertStringToArray(MultiChoiceAnwers?.answer);

        const MCOptions = data?.questionAnswer?.map(item => ({
            ...item,
            ischecked: MultianswerArray.includes(item.questionAnswerID)
          }));
      setMCCheckedFields(MCOptions);
      },[textquestionAnswer,data])

    const MultiChoiceAnwers = dataAnswers && data
                                ? dataAnswers?.find((item) => item.questionBankID === data.questionBankID) || null
                                : "";

    const MultianswerArray = convertStringToArray(MultiChoiceAnwers?.answer);

    const MCOptions = data?.questionAnswer?.map(item => ({
                              ...item,
                              ischecked: MultianswerArray.includes(item.questionAnswerID)
                            }));

    const [MCCheckedFields,setMCCheckedFields] = useState(MCOptions);
                                
    const [selectedanswerItem,setSelectedItem] = useState();

    useEffect(() => {
      if (currentQuestionAnswer !== selectedanswerItem) {
        setSelectedItem(currentQuestionAnswer);
      }
    }, [currentQuestionAnswer, selectedanswerItem]);

    //To render normal and multi select options
  
    const renderNormalOptions = (item) =>{
      /*   */
      return(
           <div className={selectedanswerItem === item.questionAnswerID ? 'obq-optionselector-holder ' : 'obq-option-holder'}
             onClick={() => handlechangesingleoption('questionAnswerID',item.questionAnswerID,item)}>
                <div className='optionanswer'>
                  {item.answer}
                </div>
                <div className='optiondescription'>
                   {item.description}
                </div>
           </div>
      );
    }

    const renderMultiSelectOptions = (item) => {
      return (
        <div key={item.id}>
        <Card variant="outlined" sx={{ borderColor: 'black', minHeight: '50px' , width:'100%',marginBottom: '10px'  }}>
              <Box display="flex" alignItems="center" p={1} height="100%">
                  <CheckBoxRenderingDemo
                      name='ischecked'
                      label={item.answer}
                      value={item.ischecked}
                      handleChange={handleOptionChange}
                      params={item} 
                      description={item.description}
                  />
              </Box>
        </Card>
        </div>
      );
    }

     //To handle three dieffernt types of questions
  
    const handlechangesingleoption = (field,value,answervalue) =>{
      setSelectedItem(value);
      currentQuestionAnswer = value
      const updatedoptionitem = dataAnswers?.find((item)=>item.questionBankID === data.questionBankID);
      if (updatedoptionitem) {
        /*   */;
        updatedoptionitem[field] = value;
        handleChange(field,value);
        setApiansweritem(updatedoptionitem);
      } else {
        // Handle the case where the item is not found
        console.warn("Item not found for questionBankID:", data.questionBankID);
      }
      
    }

    const handleOptionChange = (optionItem, field, value) => {
      /*   */
      const updatedMCOptions = MCCheckedFields.map(item =>
        item.questionAnswerID === optionItem.questionAnswerID
          ? { ...item, [field]: value }
          : item
      );
      //setting the answer in main data
      const CheckedIds = updatedMCOptions
              .filter((item) => item.ischecked)
              .map((item) => item.questionAnswerID);
      
      setMCCheckedFields(updatedMCOptions);
      const convertelementstostring = convertArrayToString(CheckedIds);

      const updatedoptionitem = dataAnswers?.find((item) => item.questionBankID === data.questionBankID);

      if (updatedoptionitem) {
        updatedoptionitem["answer"] = convertelementstostring;
        /*   */;
        setApiansweritem(updatedoptionitem);
         
        handleChange("answer", convertelementstostring);
      } else {
        // Handle the case where the item is not found
        console.warn("Item not found for questionBankID:", data.questionBankID);
      }

     };

     const [errors, setErrors] = useState({});

    const handleChangetext = (field,value) => {
      // debugger
      /*   */
        /*   */
       /*  if(data.setValidations){
            const validationResult = validateField(field, value);
            if (!validationResult.isValid) {
                setErrors(prevErrors => ({ ...prevErrors, [field]: validationResult.error }));
            } else {
              textquestion[field]=value;
              const updatedoptionitem = dataAnswers?.find((item)=>item.questionBankID === data.questionBankID);
              if (updatedoptionitem) {
                updatedoptionitem[field] = value;
                handleChange(field,value);
                setApiansweritem(updatedoptionitem);
              } else {
                // Handle the case where the item is not found
                console.warn("Item not found for questionBankID:", data.questionBankID);
              }
            }
        }
        else{ */
          textquestion[field]=value;
          const updatedoptionitem = dataAnswers?.find((item)=>item.questionBankID === data.questionBankID);
          if (updatedoptionitem) {
            updatedoptionitem[field] = value;
            handleChange(field,value);
            setApiansweritem(updatedoptionitem);
          } else {
            // Handle the case where the item is not found
            console.warn("Item not found for questionBankID:", data.questionBankID);
          }
             
    };

   /*  const validateField = (field, value) => {
      const validationRules = {
           answer: (value, currState) => {
            const isValid = value.length <= data.characterLength;
        
            return {
              isValid,
              error: isValid ? null : `Please enter a value with a maximum length of ${data.characterLength} characters`,
            };
          },
        };      
      return validationRules[field] ? validationRules[field](value) : { isValid: true, error: null };
  }; */

    const handleChange = (field,value) =>{
      setAnswers((prevItems) => {
        return prevItems.map((item) => {
          if (item.questionBankID === data.questionBankID) {
            return { ...item, [field]: value }; 
          }
          return item; 
        });
      });
    }

    let textInputType = 'a';

    if(data.setValidations)
    {
      if (data.allowAlphabets && !data.allowNumbers) {
        /*   */
        textInputType = 'textwithcharlimit';
      } else if (data.allowNumbers && !data.allowAlphabets ) {
        /*   */
        textInputType = 'numberwithcharlimit';
      } else if (data.allowNumbers && data.allowAlphabets === true) {
        /*   */
        textInputType = 'onlycharlimit';
      }
      else{
        textInputType = 'onlycharlimit';
      }
    }
    else{
      textInputType = '';
    }

  return (
    <div>
         {data?.onboardingQuestionPattern == optionsformat && data?.multipleAnswers ? (
                <div>
                    {MCCheckedFields[0]?.questionBankID === data.questionBankID && (
                      <div className='marginm-top-10'>
                        {MCCheckedFields?.map((item) => renderMultiSelectOptions(item))}
                      </div>
                    )}
                  </div>
                ) : data?.onboardingQuestionPattern == Texquestionformat ? (
                    <div className='backgroundwhite'>
                      {
                        currQuestionID === data.questionBankID && ( 
                         <TextInput
                            multiline
                            required={true}
                            name='answer'
                            type={textInputType}
                            charactersMaxLength={data.characterLength}
                            defaultValue={textquestion?.answer}
                            placeholder="Please Write Your Answer"
                            onChange={(name, value) => handleChangetext(name, value)} 
                            /* onChange={(name,value) =>  handleChangetext(name,value) } */
                            /* errormessage={errors.answer || undefined} */
                            errormessage={errors.answer || undefined}
                         />
                        )
                      }
                    </div>
                ) : data?.onboardingQuestionPattern == optionsformat ? (
                        <div> {(
                            <div className='obq-option-container'>
                                {data?.questionAnswer?.map((item) => renderNormalOptions(item))}
                            </div>)}
                         </div>
                ) : (
                    <div>
                        
                    </div>
                )} 

    </div>
  )
}

export default ObqOptions