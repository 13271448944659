import React, { forwardRef, useImperativeHandle } from 'react';
import { validationsConstants } from '../../../../utils/AppConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import SelectField from '../../../common/input-fields/SelectField';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import ActionButton from '../../../common/ActionButton/ActionButton';
import AddIcon from '@mui/icons-material/Add';
import CrudDataGrid from '../../../investor-fund-subscription/components/CrudDataGrid';
import { MenuItem, Radio, Select } from '@mui/material';

const ManagementTeam = forwardRef((props, ref) => {

  //#region props
  const { fundDetails, setFundDetails, managementStaffDetails } = props;

  //#region intialData
  const initialData = {
    ...fundDetails,
  };

  const optionsGridColumns = [
    {
      field: 'answer', headerName: 'Name', editable: true, flex: 1, type: 'singleSelect',
      valueOptions: managementStaffDetails,
      renderEditCell: (params) => (
        <Select
          value={params.value || ''}
          onChange={(event) => params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value })}
          fullWidth>
          {managementStaffDetails.map((option) => (
            <MenuItem key={option.value} value={option.value}
              disabled={(Array.isArray(fundDetails?.fundStaff) && fundDetails?.fundStaff?.find((item) => item?.answer === option?.value)) ||
                (Array.isArray(managementTeamDetails?.values?.managementDetails) && managementTeamDetails?.values?.managementDetails?.find((item) => item?.answer === option?.value))}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      field: 'Title', headerName: 'Title', editable: false, flex: 1,
      renderCell: (params) => {
        const title = managementStaffDetails?.find((item) => item?.value === params.row?.answer)?.title;
        return <div>{title}</div>;
      }
    },
    {
      field: 'description', headerName: 'Description', editable: false, flex: 1,
      renderCell: (params) => {
        const description = managementStaffDetails?.find((item) => item?.value === params.row?.answer)?.briefDescription;
        return <div>{description}</div>;
      }
    },
  ];

  if (fundDetails?.isFirmAsGP) {
    optionsGridColumns.push({
      field: 'AuthorizedSignatory',
      headerName: 'Authorized Signatory',
      editable: true,
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Radio checked={params.row.answer === managementTeamDetails?.values?.authorizedSignatory} onChange={(e) => authorizedSignatoryChange(params.row.answer)} />
        </div>
      )
    });
  }


  //#region functions
  const fetchGridInitialData = (value) => {
    if (value !== "" & !(Array.isArray(value))) {
      return JSON.parse(value !== "" ? value : "") || []
    }
    else if (Array.isArray(value)) {
      return value
    }
    else {
      return []
    }
  }

  //#region change events
  const authorizedSignatoryChange = (value) => {
    managementTeamDetails.setFieldValue('authorizedSignatory', value);
  }
  const handleChange = (field, value) => {
    managementTeamDetails.setFieldValue(field, value);
  }

  //#region formik validations
  const validationSchema = yup.object().shape({
    fundTypeID: yup.number()
      .min(1, validationsConstants.REQUIRED)
      .required(validationsConstants.REQUIRED),
    fundName: yup
      .string()
      .required(validationsConstants.REQUIRED),
  });

  const managementTeamDetails = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return true;
    },
  });

  //#region imperative handle
  useImperativeHandle(ref, () => ({
    validateFields: async () => {
      const errors = await managementTeamDetails.validateForm();
      if (Object.keys(errors).length === 0) {
        //need to update main set of values
        setFundDetails((prevValues) => {
          return { ...prevValues, ...managementTeamDetails?.values };
        });
        return true;
      }
      else {
        managementTeamDetails?.handleSubmit()
      }
    },
    updateFields: async () => {
      setFundDetails((prevValues) => {
        return { ...prevValues, ...managementTeamDetails?.values };
      });
    }
  }));

  //#region return
  return (
    <div className='display-row-items-flex'>
      <div className='width-98'>
        <CrudDataGrid
          name="managementDetails"
          addLabel="ADD TEAM MEMBER"
          initialColumns={optionsGridColumns}
          initialRows={fetchGridInitialData(managementTeamDetails.values?.managementDetails)}
          onRowChange={(name, value) => handleChange(name, value)}
          height={200} />
      </div>
    </div>
  );
});

export default ManagementTeam;