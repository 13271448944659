import React from 'react'
import {
    DialogTitle,
    DialogContent,
    IconButton,
    Grid
} from '@mui/material';
import { Close } from '@mui/icons-material';
import Text from '../../common/Text/Text';

function BulkUploadInfo(props) {
    //#region props
    const { onInfoCancelIconClick, infoType } = props;

    //#region json for both natural & non natural fields
    const mandatoryFields = {
        natural: [
            { label: 'First Name', description: 'Enter the first name of the investor.' },
            { label: 'Last Name', description: 'Enter the last name of the investor.' },
            { label: 'Email Address', description: "Enter the investor's valid email address. This will be used for login and communication." },
        ],
        nonNatural: [
            { label: 'Legal Entity Name', description: 'Enter the name of the entity.' },
            {label:'Investor type' , description: 'Select the investor type.'},
            { label: 'Legal entity Type', description: 'Select the entity type.' },
            { label: 'Email Address', description: "Enter the investor's valid email address. This will be used for login and communication." },
        ],
    };

    const optionalFields = {
        natural: [{ label: 'Phone Number', description: "Enter the investor's phone number." },
        { label: 'Address Line 1 & 2', description: "Enter the investor's complete address." },
        { label: 'Country', description: "Select the investor's country from the dropdown menu." },
        { label: 'State', description: "Select the investor's state from the dropdown menu." },
        { label: 'City', description: "Enter the investor's City." },
        { label: 'Zip Code', description: 'Enter the investor\'s zip code in the format "xxxxxx" or "xxxxxxxxx".' },
        { label: 'Annual Pre-tax Income', description: "Enter the investor's annual pre-tax income in USD." },
        { label: 'Number of Dependents', description: "Select the number of dependents from the dropdown menu (1, 2, or 3)." },
        { label: 'Employment Status', description: "Select the investor's employment status from the dropdown menu (Employed, Retired, Student, or Self-Employed)." },
        { label: 'Employer', description: 'Select "Yes" if the investor has an employer, "No" otherwise.' },
        { label: 'Occupation', description: "Enter the investor's occupation." },
        { label: 'Spouse Annual Pre-Tax Income', description: "Enter the spouse's annual pre-tax income in USD if applicable." },
        { label: 'Spouse has IIA Account', description: 'Select "Yes" if the spouse has an Individual Investment Account (IIA), "No" otherwise.' },
        { label: 'Federal Tax Bracket', description: "Enter the investor's federal tax bracket as a percentage." },
        { label: 'Household Investable Assets', description: "Enter the household's total investable assets in USD." },],
        nonNatural: [
            { label: 'Phone Number', description: "Enter the investor's phone number." },
            { label: 'Address Line 1 & 2', description: "Enter the investor's complete address." },
            { label: 'Country', description: "Select the investor's country from the dropdown menu." },
            { label: 'State', description: "Select the investor's state from the dropdown menu." },
            { label: 'City', description: "Enter the investor's City." },
            { label: 'Zip Code', description: 'Enter the investor\'s zip code in the format "xxxxxx" or "xxxxxxxxx".' },
            { label: 'Annual Pre-tax Income', description: "Enter the investor's annual pre-tax income in USD." },
            { label: 'Federal Tax Bracket', description: "Enter the investor's federal tax bracket as a percentage." },
            { label: 'Household Investable Assets', description: "Enter the household's total investable assets in USD." },
            { label: 'Website', description: 'Enter the Website name if have any' },
            { label: 'Tax ID', description: "Enter the Tax ID" },
            { label: 'TaxFilingStatus', description: "Select from dropdown menu" },
            // { label: 'Languages spoken', description: 'Enter languages spoken by the investor' },
            { label: 'Advisory Services Required', description: "Select from dropdown menu" },
            { label: 'Referral Source', description: "Enter the referral source of how they heard about your firm or platform." }
        ]

    };

    //#region rendering fields
    const renderFields = (fields) => (
        fields.map((field, index) => (
            <Grid key={index}>
                • <b>{field.label}</b> : {field.description}
            </Grid>
        ))
    );

    return (
        <div className='InfoBgColor'>
            <DialogTitle>
                <Grid>
                    <IconButton onClick={onInfoCancelIconClick}>
                        <Close htmlColor='white' />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent style={{ color: 'white', fontFamily: 'cresta' }}>
                <Text label='Guide to Bulk Upload' />
                <Grid>
                    Welcome to the Bulk Investor Upload Guide!
                </Grid>
                <Grid className='margin-top-15'>
                    This guide provides detailed instructions on filling out the template for uploading investor information to the platform. Please follow these instructions carefully to ensure a smooth and successful upload.
                </Grid>
                <Grid className='margin-top-15'>
                    Template Structure:
                </Grid>
                <Grid className='margin-top-15'>
                    <Grid>
                        • The template to be downloaded is for {infoType === 'natural' ? "Natural" : "Non-Natural"} Investor type
                    </Grid>
                    <Grid>
                        • The template is a single excel sheet with a header row containing field names.
                    </Grid>
                    <Grid>
                        • Do not edit the column headers.
                    </Grid>
                    <Grid>
                        • Fill data only in the designated grid area.
                    </Grid>
                    <Grid>
                        • Do not include any additional information in other cells or sheets.
                    </Grid>
                </Grid>
                <Grid className='margin-top-15'>
                    <b>Mandatory Fields : </b>
                </Grid>
                <Grid className='margin-top-15'>
                    {renderFields(infoType === 'natural' ? mandatoryFields.natural : mandatoryFields.nonNatural)}
                </Grid>
                <Grid className='margin-top-15'>
                    <b>Optional Fields : </b>
                </Grid>
                <Grid className='margin-top-15'>
                    {renderFields(infoType === 'natural' ? optionalFields.natural : optionalFields.nonNatural)}
                </Grid>
                <Grid className='margin-top-15'>
                    <b>Important Notes : </b> Leave blank any optional fields you do not wish to populate . Please ensure all the data is accurate and in the correct format.
                </Grid>
            </DialogContent>
        </div>
    )
}

export default BulkUploadInfo