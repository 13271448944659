import React, { useEffect, useState } from 'react'
import { fundTypesEnum } from '../../marketplace-dashboard/jsonData';
import FundGridView from '../../common/StackView/FundGridView';
import { getKeyValuePairs } from '../../portfolio-companies/services/services';
import secureLocalStorage from 'react-secure-storage';
import SelectField from '../../common/input-fields/SelectField';
import { FieldVariants } from '../../common/TextInput/appInputenum';
import { fundColumns, myInvestmentsprivateCreditColumns, myInvestmentsprivateEquityColumns } from '../../investor-dashboard/fundTypeValues';
import { getInvestorSubscribedFunds } from '../../investor-dashboard/services/services';
import { AppDashboards } from '../../../utils/enum';
import { useNavigate } from 'react-router-dom';
import { fundSubscriptionStatues } from '../../CODashboard/Services/apiDataTempate';
import { subscriberStatusDropdown, subscriberStatusEnum } from '../../FundManagerDashboard/fundTypeValues';
import AwaitingPopup from '../popups/AwaitingPopup';
import { subscriptionStatusEnum } from '../../investor-fund-subscription/services/apiDataTemplate';

const InvestorInvestmentsGrid = (props) => {

  //#region variables
  const { investorID } = props;

  //#region initialdata
  const initialData = {
      'subscriptionStatus': subscriptionStatusEnum.ALL,
      'fundType': fundTypesEnum.ALL
  }  
  
   //#region variables
  const navigate = useNavigate();
  const isInvestorLogin = secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD 
  const [filters,setFilters] = useState(initialData); 
  const [investments,setInvestments] = useState([]);
  const [filteredInvestments,setFilteredInvestments] = useState([]);
  const [investmentColumns,setInvestmentColumns] = useState(fundColumns); 
  const [fundTypes,setFundTypes] = useState([]);
  const [awaitingPopup,setAwaitingPopup] = useState(false);
  const [currFundName,setCurrFundName] = useState("");

  //#region functions
  const currFundType = () => {
    return fundTypes?.find((item)=>item.value === filters.fundType)?.label;
  }

  const currFundStatus = () => {
        /* return fundStatus?.find((item)=>item.value === filters.fundStatus)?.label; */
        return filters.fundStatus
  }
  
  //#region change events
  const handleFilterChange = (name, value) => {
      setFilteredInvestments([]);
      setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: value,
      }));
  };

  //#region click events
  const onRowClick = (params) => {
    if(params?.row?.subscriptionStatus === "Awaiting Online Processing"
       && secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD
    ){
      setCurrFundName(params?.row?.fundName);
      onOpenAwaitingPopup();
      return;
    }
      if(isInvestorLogin){
        onInvestorFundClick(params);
      }
      else{
        onFirmFundClick(params);
      }
  }

  const onOpenAwaitingPopup = () => {
    setAwaitingPopup(true);
  }

  const onCloseAwaitingPopup = () => {
    setAwaitingPopup(false);
  }

  const onInvestorFundClick = (params) => {
    secureLocalStorage.setItem("FundId", params.row.id);
    if (params.row.subscriptionStatus === 'Approved') {
      navigate('/Investor-Overview-Page', {
        state: {
          FundId: params.row.id,
          subscriptionId: params.row.subscriptionID
        }
      });
      window.scrollTo(0,0);
    }
    else {
      secureLocalStorage.setItem("FundId", params.row.id);
      navigate('/subscription-details', {
        state: {
          FundId: params.row.id,
          subscriptionId: params.row.subscriptionID,
          selectedRow: params.row,
        }
      });
      window.scrollTo(0,0);
    }
  }

  const onFirmFundClick = (params) => {
    secureLocalStorage.setItem("FundId", params?.row?.id);
    if (params.row.subscriptionStatus === fundSubscriptionStatues.APPROVED) {
       secureLocalStorage.setItem("investorId", investorID);
      navigate("/Investor-Overview-Page", 
        { state: { userId: { itemId: investorID , fullName: params?.row?.investor} } });
    } else {
      const details = {
        "subscriptionsID" : params.row.subscriptionID,
        "investorID" : investorID,
        "isInvestorVerified" : params.row.subscriptionStatus,
      }
      secureLocalStorage.setItem('InvestorAndFMNotificationsRow', details)
      navigate("/subscription-details");
    }
  }
  
  //#region api get calls
  const getFundDetails = async () => {
    const data = await getInvestorSubscribedFunds(investorID);
    if (data.responseCode === 200) {
    setInvestments(data.responseData.filter(u=>u.subscriptionStatus !== "Investor Sign Pending"));
    }
    else {
        setInvestments([])
    }
  }
  
  const getFundTypes = async() => {
      const data = await getKeyValuePairs("FundType");
      if(data.responseCode === 200){
          const dropdownValues = data.responseData?.map(option => ({ label: option.listItemValue, value: option.listItemID })) || []
          setFundTypes(dropdownValues)
      }
      else{
          setFundTypes([])
      }
  }
  
  //#region useeffect
  useEffect(()=>{
      getFundDetails();
      getFundTypes();
  },[])
    
  useEffect(()=>{
    
      const selectedFundType = currFundType();
  
      //sorting
      const sortedFunds = investments?.sort((a, b) => {
        let dateA = new Date(a.updatedDate);
        let dateB = new Date(b.updatedDate);
  
        return dateB - dateA; 
      });
  
      //variables
      let fundTypes = []
      let subscriptionStatus = []
     
      if(filters.fundType !== ""){
          if(filters.fundType !== fundTypesEnum.ALL){
              const selectedSubscribers = sortedFunds.filter((fund)=> fund.fundTypeName === selectedFundType);
              fundTypes = selectedSubscribers;
              if(selectedFundType === fundTypesEnum.PRIVATE_CREDIT){
                setInvestmentColumns(myInvestmentsprivateCreditColumns);
              }
              else{
                setInvestmentColumns(myInvestmentsprivateEquityColumns);
              }
          }
          else{
              setInvestmentColumns(fundColumns);
              fundTypes = sortedFunds;
          }
      }
  
      if(filters.subscriptionStatus !== ""){
        //for investor dashboard
        if(filters.subscriptionStatus !== fundTypesEnum.ALL){debugger
          if(secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD && 
            (filters.subscriptionStatus === subscriptionStatusEnum.FM_REVIEW 
            || filters.subscriptionStatus === subscriptionStatusEnum.COMPLIANCE_REVIEW)){
              const selectedSubscriptionStatus = sortedFunds.filter((fund)=> fund.subscriptionStatus.includes("Review"));
              subscriptionStatus = selectedSubscriptionStatus;
          }
          else{
            const selectedSubscriptionStatus = sortedFunds.filter((fund)=> fund.subscriptionStatus === filters.subscriptionStatus);
            subscriptionStatus = selectedSubscriptionStatus;
          }
        }
        else{
          subscriptionStatus = sortedFunds
        }
      }
      
      let allFilteredSubscribers = [];
      allFilteredSubscribers = sortedFunds?.filter((item) => {
          const typeMatch = subscriptionStatus?.length > 0 && subscriptionStatus.includes(item);
          const statusMatch = fundTypes?.length > 0 && fundTypes.includes(item);
          if (subscriptionStatus?.length > 0 && fundTypes?.length > 0) {
              return typeMatch && statusMatch;
          } else {
              return typeMatch && statusMatch;
          }
      });

      let subscriptionStatusToBeShown = "Under Review"
      
      const updatedSubscribers = allFilteredSubscribers.map(subscriber => {
        if (subscriber.subscriptionStatus.includes("Review")) {
          return { ...subscriber, subscriptionStatus: subscriptionStatusToBeShown };
        }
        return subscriber;
      });

      setFilteredInvestments(isInvestorLogin ? updatedSubscribers : allFilteredSubscribers );
  
  },[investments, filters])  

  //#region return
  return (
    <div className='child-margin-5 white-card width-percent-100'>
          <div className='space-between'>
             <h6>{isInvestorLogin ? "My Investments" : "Fund Invested In"}</h6>
             <div className='child-row-margin-5'>
                <div className='min-width-150'>
                    <SelectField 
                        name="subscriptionStatus"
                        label="Subscription Status"
                        variant={FieldVariants.OUTLINED}
                        value={filters.subscriptionStatus}
                        options={secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD ? 
                                 subscriberStatusDropdown?.filter((u)=> u.value !== subscriptionStatusEnum.FM_REVIEW) : subscriberStatusDropdown}
                        onChange={(name,value)=> {handleFilterChange(name,value)}}/>
                </div>
                <div className='min-width-150'>
                    <SelectField 
                        name="fundType"
                        label="Fund Type"
                        variant={FieldVariants.OUTLINED}
                        value={filters.fundType}
                        options={fundTypes}
                        onChange={(name,value)=>{handleFilterChange(name,value)}}/>
                </div>
            </div>
          </div>
          <div className='height-450'>
            <FundGridView 
               columns={investmentColumns}
               rows={filteredInvestments.length > 0 ? 
                    filteredInvestments?.map((fund) => ({
                        id: fund.fundID,
                        ...fund
                    })) : []
               }
               isHeight={false}
               handleRowClick={onRowClick}
               pagination={true} />
          </div>
          {
            awaitingPopup &&
            <AwaitingPopup open={awaitingPopup} fundName={currFundName} onClose={onCloseAwaitingPopup}/>
          }
    </div>
  )
}

export default InvestorInvestmentsGrid