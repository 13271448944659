import React from 'react'
import { TopNavBar } from '../../../common/navigation-bar/TopNavBar'
import { useState,useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { getUserDetailsbyEmail } from '../../../common/navigation-bar/navServices';

const TopBarObq = (props) => {

    const navigate = useNavigate();

    const [fullName, setFullName] = useState('');
  
    const [ProfileInformation,setProfileInformation] = useState(null);
    const emailId = secureLocalStorage.getItem("userEmail");

    const getUserData = async () => {
      const data = await getUserDetailsbyEmail(emailId);
      if(data.responseCode === 200){
        setProfileInformation(data.responseData);
       const firstName = (data.responseData?.personalInformation?.firstName ?? "").charAt(0).toUpperCase() + (data.responseData?.personalInformation?.firstName ?? "").slice(1);
       const lastName = (data.responseData?.personalInformation?.lastName ?? "").charAt(0).toUpperCase() + (data.responseData?.personalInformation?.lastName ?? "").slice(1);
       setFullName(firstName + " " + lastName);
      }
      else{
 
      }
   }
  
    useEffect(() => {
      if(!secureLocalStorage.getItem("userLoggedIn")){
        navigate("/", { replace: true });
        return;
      }
      getUserData();
    }, []);

  return (
    <div>
      <TopNavBar fullName={fullName} ProfileInformation={ProfileInformation} hideMenuButton={props.hideMenuButton} {...props}/>
    </div>
  )
}

export default TopBarObq