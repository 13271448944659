import React, { forwardRef, useImperativeHandle } from 'react';
import { validationsConstants } from '../../../../utils/AppConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import SelectField from '../../../common/input-fields/SelectField';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import { percentageDropdownValues } from '../../services/apiDataDemplate';
import { textFormatTypeEnum } from '../../../common/TextInput/appInputenum';

const FeesandExpenses = forwardRef((props, ref) => {

  //#region props
  const { fundDetails, setFundDetails, fundTypes } = props;

  //#region intialData
  const initialData = {
    ...fundDetails?.feesExpenses[0]
  };


  const handleChange = (field, value) => {
    feesExpenses.setFieldValue(field, value);
  }

  //#region formik validations
  const validationSchema = yup.object().shape({
    managementFees: yup
      .string()
      .required(validationsConstants.REQUIRED),
    performanceFeesOrCarriedInterest: yup
      .string()
      .required(validationsConstants.REQUIRED),
    additionalFeesAndExpenses: yup
      .string()
      .required(validationsConstants.REQUIRED),
  });

  const feesExpenses = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return true;
    },
  });

  //#region imperative handle
  useImperativeHandle(ref, () => ({
    validateFields: async () => {
      const errors = await feesExpenses.validateForm();
      if (Object.keys(errors).length === 0) {
        //need to update main set of values
        const details = fundDetails;
        details["feesExpenses"] = [feesExpenses?.values];
        setFundDetails(details);
        return true;
      }
      else {
        feesExpenses?.handleSubmit()
      }
    },
    updateFields: async () => {
      //need to update main set of values
      const details = fundDetails;
      details["feesExpenses"] = [feesExpenses?.values];
      debugger
      setFundDetails(details);
    }
  }));

  //#region return
  return (
    <div className='display-row-items-flex'>
      <div className='width-48'>
        <ALTTextField
          textFormatType={textFormatTypeEnum.PERCENTAGE}
          name="managementFees"
          label="Management Fees"
          value={feesExpenses?.values?.managementFees}
          onChange={(name, value) => handleChange(name, value)}
          required={true}
          onBlur={feesExpenses.handleBlur}
          error={feesExpenses.touched.managementFees && Boolean(feesExpenses.errors.managementFees)}
          errorMessage={feesExpenses.touched.managementFees && feesExpenses.errors.managementFees} />
      </div>
      <div className='width-48'>
        <SelectField
          name="performanceFeesOrCarriedInterest"
          label="Performance Fees / Carried Interest"
          value={feesExpenses?.values?.performanceFeesOrCarriedInterest}
          onChange={(name, value) => handleChange(name, value)}
          required={true}
          options={percentageDropdownValues}
          onBlur={feesExpenses.handleBlur}
          error={feesExpenses.touched.performanceFeesOrCarriedInterest && Boolean(feesExpenses.errors.performanceFeesOrCarriedInterest)}
          errorMessage={feesExpenses.touched.performanceFeesOrCarriedInterest && feesExpenses.errors.performanceFeesOrCarriedInterest} />
      </div>
      <div className='width-48'>
        <ALTTextField
          textFormatType={textFormatTypeEnum.PERCENTAGE}
          name="additionalFeesAndExpenses"
          label="Additional Fees and Expenses"
          value={feesExpenses?.values?.additionalFeesAndExpenses}
          onChange={(name, value) => handleChange(name, value)}
          required={true}
          onBlur={feesExpenses.handleBlur}
          error={feesExpenses.touched.additionalFeesAndExpenses && Boolean(feesExpenses.errors.additionalFeesAndExpenses)}
          errorMessage={feesExpenses.touched.additionalFeesAndExpenses && feesExpenses.errors.additionalFeesAndExpenses} />
      </div>
    </div>
  );
});

export default FeesandExpenses;