import React, { useEffect, useState } from 'react'
import { HeaderTemplates, PageHeader } from '../../common/PageHeader/PageHeader';
import secureLocalStorage from 'react-secure-storage';
import FundDetailPerformance from '../../dashboards/FundDetailDashboards/FundDetailPerformance';
import ServicingAccordion from '../../Servicing/FundServicing';
import { getFundByInvestorId } from '../../dashboards/services/DashboardService';
import TabsComponment from '../../common/Tabs/TabsComponment';
import InvestorOverview from './subscriptions/InvestorOverview';
import { Grid, LinearProgress } from '@mui/material';
import { AppDashboards } from '../../../utils/enum';
import Redemption from './subscriptions/Redemption';
import { useLocation } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';
const ApprovedInvestorMain = () => {

  //#region URL Params
  const queryParams = new URLSearchParams(window.location.search);
  const selectTab = queryParams.get('goto');

  //#region variables
  const [fundDetails, setFundDetails] = useState();
  const [selectedTab, setSelectedTab] = useState(selectTab == null ? 0 : parseInt(selectTab));
  const fundId = secureLocalStorage.getItem("FundId");
  const location = useLocation();
  const invId = location?.state?.userId?.itemId;
  const invName = location?.state?.userId?.fullName;

  const investorName = secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD ?
    secureLocalStorage.getItem("fullName") : (secureLocalStorage.getItem("InvestorName") == null ? invName : secureLocalStorage.getItem("InvestorName"));
  const investorId = secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD ?
    secureLocalStorage.getItem("userId") : (secureLocalStorage.getItem("investorId") == null ? invId : secureLocalStorage.getItem("investorId"));

  //#region api get calls
  const getFundDetails = async () => {
    const data = await getFundByInvestorId(investorId, fundId);
    if (data.responseCode === 200) {
      const fundDetails = data.responseData?.find(fund => fund?.fundID === fundId);
      setFundDetails(fundDetails);
    }
    else {
      console.error(data.error);
    }
  }

  //#region useeffect
  useEffect(() => {
    // setSelectedTab((secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD && invId == undefined ) ? 2 : 0);
    getFundDetails();
  }, [])

  //#region tabs
  const tabs= [
    {
      key: 0,
      icon: <InfoOutlinedIcon/>,
      label: "Overview",
      isBackgroundColor: false,
      component: <InvestorOverview investorId={investorId} fundId={fundId} />
    }
    , {
      key: 1,
      icon: <EqualizerOutlinedIcon/>,
      label: "Performance",
      //isBackgroundColor: true,
      component: <FundDetailPerformance fundDetails={fundDetails} />
    },
    {
      key: 2,
      icon: <DesignServicesOutlinedIcon/>,
      label: "Servicing",
      isBackgroundColor: false,
      component: <ServicingAccordion approvedinvestorsoverview={true} />
    },
    {
      key: 3,
      icon: <RedeemOutlinedIcon/>,
      label: "REDEMPTION",
      isBackgroundColor: false,
      component: <Redemption />
    }
  ];

  //#region return
  return (
    <div>
      <PageHeader
        template={HeaderTemplates.CLASSIC}
        primaryTitle={fundDetails?.fundName}
        primaryImage={fundDetails?.iconImage}
        secondaryTitle={investorName}
        secondaryImage={fundDetails?.userProfileIcon
          ? fundDetails?.userProfileIcon
          : "/icons/defaultpropic.jpg"}
        customBackgroundImage={fundDetails?.bgImage ? fundDetails?.bgImage : '/DefaultBackgroundImage.png'} />
      <div className='wrapper'>
        {
          fundDetails ?
            <TabsComponment tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} /> :
            <Grid xs={12}>
              <LinearProgress />
            </Grid>
        }
      </div>
    </div>
  )
}

export default ApprovedInvestorMain