import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AIPDataGrid from "../../common/DataGrid/AIPDataGrid";
import { useState } from "react";
import { checkFeaturePermissions } from "../../../utils/common";
import { FeatureAccess, RolesAndPermissions } from "../../../utils/enum";
import { Grid } from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import { getKycAmlDetailsbyFundID } from "../../KYCVerification/services/services";
import { ComplianceTabJsonData } from "../jsonData";
import SelectField from "../../common/input-fields/SelectField";
import { FieldVariants } from "../../common/TextInput/appInputenum";
import { yearsDropDownValues } from "../../common/FundPerformance/apiDataTemplate";

const ComplianceTab = () => {

  //#region varibales
  const navigate = useNavigate(); 
  const [investorsList,setInvestorsList] = useState(null);
  const [filtersData,setFiltersData] = useState({
    "complianceAlerts": 2024,
    "kycamlYear": 2024,
  })
  
  const Columns = [
    { field: 'fullName', headerName: 'INVESTOR NAME', width: 300, },
    { field: 'type', headerName: 'INVESTOR TYPE', width: 200,
      renderCell: (params) => {
         return "Individual Investor"
      } 
    },
    { field: 'kycStatusName', headerName: 'KYC', width: 200 },
    { field: 'amlStatusName', headerName: 'AML', width: 200 },
  ]

  //#region chaneg events
  const handleChange = (name,value) => {
    setFiltersData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
  }

  //#region click events
  const onInvestorClick = (params) => {
    secureLocalStorage.setItem("KYCInvestorUserId", params.row.id);
    secureLocalStorage.setItem("KYCInvestorEmailAddress", params.row.useremail);
    secureLocalStorage.setItem("KYCInvestorDetails", params.row);
    /* navigate("/kyc-aml-verification"); */
    navigate(`/kyc-aml-verification/details?investorId=${params.row.id}&readonly=${true}`);
    window.scrollTo(0, 0);
  }

  //#region api get calls
  const getInvestors = async () => {
    const fundID = secureLocalStorage.getItem("FundId")
    const data = await getKycAmlDetailsbyFundID(fundID);
    if (data?.responseCode === 200) {
      const reversedData = data.responseData;
      setInvestorsList(reversedData);
    } else { 
      // Handle error if needed
    }
  }

  //#region useeffect
  useEffect(()=>{
    getInvestors();
  },[])
  
  //#region return
  return (
    <div>
      <h4>Compliance </h4>
      <Grid container xs='12' spacing={2} marginTop={1} style={{ margin: '0px auto' }}>
        {
          checkFeaturePermissions(RolesAndPermissions.COMPLIANCE_ALERTS, FeatureAccess.READ) &&
          <Grid item xs='12' md='6' style={{ paddingLeft: '0px' }} >
            <div className="child-margin-5 white-card">
              <div className="space-between">
                <h6>Compliance Alerts</h6>
                <div className="width-130">
                    <SelectField
                        name='complianceAlerts'
                        label="Year"
                        value={filtersData?.complianceAlerts}
                        size='small'
                        fullWidth
                        variant={FieldVariants?.OUTLINED}
                        options={yearsDropDownValues}
                        onChange={(name, value) => handleChange(name, value)} />
                </div>
              </div>
              <div className="height-450">
                <AIPDataGrid
                  columns={ComplianceTabJsonData.ComplianceAlertColumns}
                  rows={ComplianceTabJsonData.ComplianceAlertRows}
                  onRowsSelectionHandler={() => { }} />
              </div>
            </div>
          </Grid>
        }
        {
          checkFeaturePermissions(RolesAndPermissions.KYC_AML_DOCUMENTATION, FeatureAccess.READ) &&
          <Grid item xs='12' md='6' className="res-grid">
            <div className="child-margin-5 white-card" >
              <div className="space-between">
                <h6>KYC/AML Documentation Status for LPs</h6>
                <div className="width-130">
                    <SelectField
                        name='kycamlYear'
                        label="Year"
                        value={filtersData?.kycamlYear}
                        size='small'
                        fullWidth
                        variant={FieldVariants?.OUTLINED}
                        options={yearsDropDownValues}
                        onChange={(name, value) => handleChange(name, value)} />
                </div>
              </div>
              <div className="height-450">
                  <AIPDataGrid
                        columns={Columns}
                        rows={investorsList ? investorsList?.map((user)=>{
                          return{
                            id: user.userId,
                            ...user
                          }
                        }) : []}
                        handleRowClick={onInvestorClick}
                        onRowsSelectionHandler={() => { }}
                        sortByLatestFirst = {false}
                      />
              </div>

              {/* <button
                className='seemore mt5'
                onClick={() => navigate("/marketplace/PerformancePage")}>
                See More <ArrowForwardOutlinedIcon />
              </button> */}
            </div>
          </Grid>
        }
      </Grid>
    </div>
  );
};

export default ComplianceTab;
