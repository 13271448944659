import { useEffect, useState, useRef } from "react";
import ActionButton from "../../../common/ActionButton/ActionButton";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FundDetails from "./FundDetails";
import ManagementTeam from "./ManagementTeam";
import InvestmentStrategy from "./InvestmentStrategy";
import LegalCompliance from "./LegalCompliance";
import FeesExpenses from "./FeesExpenses";
import FundTitle from "./FundTitle";
import './styles.css';
import { InitialFundDataTemplate } from './InitialFundData';
import StepModal from "../../../common/Modal/StepModal";
import secureLocalStorage from "react-secure-storage";
import 'react-toastify/dist/ReactToastify.css';
import { getFundFilterData } from "../../services/MarketplaceService";
import { Button } from "@mui/material";
import { getFolders } from "./Services/DropDownData";
import { getRoles } from "../../../FundManagerDashboard/Services/FundNewsService";
import { createFund } from "../../services/MarketplaceService";
import { AppDashboards } from "../../../../utils/enum";
import { getMarketPlaceFunds } from "../../../marketplace-dashboard/services/mpservices";
import { addFavourite, updateAllFunds } from "../../../../state/slices/MarketPlaceSlice";
import { useDispatch, useSelector } from 'react-redux';
import CreateManagementTeams from "./CreateManagementTeams";
import FundType from "./FundType";
import { getFirmDetails } from "../../../user-profile/FirmUserProfile/services/firmdetaailsservices";
import { ToastContainer, toast } from 'react-toastify';

const CreateFundModal = ({ showCreateFund, setShowCreateFund, handleCancelModel,fetchFundDetails }) => {

    //#region variables
    const [step, setStep] = useState(1);
    const livePage = useRef(step);
    livePage.current = step;
    const [disabledFMoptions, setFMDisabledFMOptions] = useState({})
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [issubmittbtn, setSubmittbtn] = useState(false);
    const InitialFundData = {
        //  FundTypeDetails : {},
        FundDetails: {},
        FundManagementTeams: {},
        InvestmentStrategy: {
        },
        FeesExpenses: {},
        LegalCompliance: {}
    }
    const [fundData, setFundData] = useState(InitialFundData);
    //TODO : For Fees and expenses as it is last step data is not saving properly using set method usestate
    const [feesExpenses, setFeesExpenses] = useState({
        id: 0,
        fundID: 0,
        managementFees: '',
        performanceFeesOrCarriedInterest: '0',
        additionalFeesAndExpenses: 0
    });
    const [tenantUser, setTenantUser] = useState();
    const loggedInUserId = secureLocalStorage.getItem("userId");
    const [fundstaffdropdown, setFundStaffDropdown] = useState(null);
    const [managementstaffdropdown, setManagementStaffDropdown] = useState(null);
    const [currStep, setCurrStep] = useState(livePage.current);
    const [FolderNames, setFolderNames] = useState(null);
    const [fundFilterData, setFundFilterData] = useState();
    const [FundDetailerrors, setFundDetailErrors] = useState(false);
    const [firmDetails, setFirmDetails] = useState(null);

    //#region click events
    const handleModalNavigation = (isPrev) => {
        let currFundData = InitialFundDataTemplate;
        currFundData = {
            ...currFundData,
            ...fundData.FundDetails,
            ...fundData.InvestmentStrategy,
            ...fundData.LegalCompliance,
        };
        if (isPrev) {
            setFundDetailErrors(false); // Clear any previous errors
            setStep(currStep => (!(isPrev && (currStep === 1))) ? (currStep - 1) : currStep);
        } else {
            setFundDetailErrors(true);
            setTimeout(() => {
                setFundDetailErrors(false);
            }, 10);
            /* below lines is written in component to enable validations
            props.setFundDetailErrors(false);
            props.setStep(currStep => currStep + 1);  */

            //For testing purpose
            /* setStep(currStep => currStep + 1); */
        }
    };

    //#region api get calls
    const getFunds = async () => {
        const data = await getMarketPlaceFunds();
        if (data.responseCode === 200) {
            dispatch(updateAllFunds({ value: data.responseData }));
            const filterFunds = data.responseData.filter(item => item.favourite === true);
            filterFunds.forEach(favFund => {
                dispatch(addFavourite({ fund: favFund }));
            });
        } else {
            console.log('Unable to load funds');
        }
    }

    const getAllFolderNames = async () => {
        const data = await getFolders();
        if (data.responseCode === 200) {
            console.clear()
            console.log(data.responseData);
            const NamesAndIds = data?.responseData?.filter(d => d.parentType != "Investor Reporting" && d.title != "Investor Reporting")?.map(item => ({
                folderName: item.title,
                folderID: item.documentTypeID,
                parentfolderid: item.defaultFolderID
            }));
            console.log(NamesAndIds);
            setFolderNames(NamesAndIds);
        } else {

        }
    }

    const getTenantDetails = async () => {
        const data = await getFirmDetails();
        if (data?.responseCode == 200) {
            setFirmDetails(data?.responseData);
        } else {
            setFirmDetails({});
        }
    }

    //#region api post calls
    const handleSaveData = async () => {
        setLoading(true);
        let currFundData = InitialFundDataTemplate;
        currFundData = {
            ...currFundData,
            ...fundData?.FundDetails,
            ...fundData?.InvestmentStrategy
        };
        currFundData.fundManagementTeams = fundData?.FundManagementTeams;
        currFundData.feesExpenses = [{ ...currFundData.feesExpenses[0], ...feesExpenses }];
        currFundData.investmentCycle = fundData.FundDetails?.liquidity;

        //FundStafff
        currFundData.fundStaff = !(fundData.FundDetails?.isFirmAsGP) && Array.isArray(fundData.FundDetails?.fundStaff)
            ? fundData.FundDetails?.fundStaff.join(",")
            : "";



        currFundData.userID = currFundData.userID ?? secureLocalStorage.getItem("userId");
        currFundData.iconImage = fundData.FundDetails?.iconImage?.name;
        currFundData.bgImage = fundData.FundDetails?.bgImage?.name;
        currFundData.fundManager = secureLocalStorage.getItem("fullName");
        const formDataBody = new FormData();
        formDataBody?.append("fundDetails", JSON.stringify(currFundData));
        /* console.log(JSON.stringify(currFundData)); */

        /* Documents */
        const { strategyDocument } = fundData.InvestmentStrategy;
        const { offeringDocumentsfile } = fundData.LegalCompliance;
        const { subscriptionDocumentsfile } = fundData.LegalCompliance;
        const { complianceDocumentsfile } = fundData.LegalCompliance;

        const { offeringDocuments, subscriptionDocuments, complianceDocuments, FolderPaths } = fundData.LegalCompliance;
        if (fundData?.FundDetails?.iconImage) {
            formDataBody.append('iconImage', fundData.FundDetails?.iconImage, fundData.FundDetails.iconImage?.name);
        }
        if (fundData?.FundDetails?.bgImage) {
            formDataBody.append('bgImage', fundData.FundDetails.bgImage, fundData.FundDetails.bgImage?.name);
        }
        if (strategyDocument) {
            formDataBody.append('strategyDocument', strategyDocument, fundData.InvestmentStrategy.startegyfilepath + "/" + strategyDocument?.name);
        }
        if (offeringDocumentsfile) {
            formDataBody.append('offeringDocuments', offeringDocumentsfile, fundData.LegalCompliance.offeringDocumentsfilepath + "/" + offeringDocumentsfile?.name);
        }
        if (subscriptionDocumentsfile) {
            formDataBody.append('subscriptionDocuments', subscriptionDocumentsfile, fundData.LegalCompliance.subscriptionDocumentsfilepath + "/" + subscriptionDocumentsfile?.name);
        }
        if (complianceDocumentsfile) {
            formDataBody.append('complianceDocuments', complianceDocumentsfile, fundData.LegalCompliance.compliancefilepath + "/" + complianceDocumentsfile?.name);
        }


        console.clear()
        console.log(formDataBody.offeringDocuments);

        console.log('Fund Data:', JSON.stringify(currFundData));

        const response = await createFund(formDataBody);
        if (response?.responseCode === 200) {
            /* console.log("Success", formDataBody); */
            /*   */
            fetchFundDetails();
            setShowCreateFund(false);
            handleCancelModel(true);
            setLoading(false);
            /*   getFunds(); */
            toast.success("Fund Created Successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: "colored",
            });
        } else {
            toast.error("Unable to create the fund,please try again after some time", {
                           position: toast.POSITION.BOTTOM_RIGHT,
                           theme: "colored",
                     });
            setLoading(false);
        }
    }

    //#region useeffect
    useEffect(() => {
        // Use the ref to set the state
        setCurrStep(livePage.current);
        // Your other logic...
    }, [step, livePage]);

    useEffect(() => {
        getTenantDetails();
        getAllFolderNames();
    }, [])

    useEffect(() => {
        const userDetails = async () => {
            ;
            const loggedInUserId = secureLocalStorage.getItem("userId");
            const data = await getRoles("Fund Manager");
            if (data?.responseCode === 200) {
                const fundStaffOptions = data?.responseData
                    ?.map(user => ({
                        label: user.userFullName,
                        value: user.userId,
                        disabled: false,
                        title: user?.userRoleName,
                        briefDescription: user.briefDescription
                    }));

                setFundStaffDropdown(fundStaffOptions);
            } else {
                console.log("Error fetching user details");
            }
        };
        userDetails();
    }, []);

    useEffect(() => {
        const managementStaffDetails = async () => {
            ;
            const data = await getRoles();
            if (data.responseCode === 200) {
                ;
                const managementStaffOptions = data.responseData?.filter(a => a.userRoleName !== AppDashboards.INV_DASHBOARD)?.map(user => ({
                    label: user.userFullName,
                    value: user.userId,
                    isdisabled: false,
                    title: user.userRoleName,
                    description: user.briefDescription,
                }));
                setManagementStaffDropdown(managementStaffOptions);
            } else {
                console.log("Error fetching user details");
            }
        };
        managementStaffDetails();
    }, []);

    useEffect(() => {
        console.log('mounted');
        const tenantUser = async () => {
            const data = await getRoles();
            if (data.responseCode === 200) {
                console.log(data.responseData);
                setTenantUser(data.responseData);
            } else {
                console.log("data.responseData");
            }
        }
        tenantUser();


        const getFundFilters = async () => {
            const data = await getFundFilterData();
            if (data.responseCode === 200) {
                setFundFilterData(data.responseData)
            }
            else {
            }
        }
        getFundFilters();
        return () => console.log('unmounted')
    }, []);

    //#region render functions
    const renderFundFormByStep = () => {
        switch (step) {
            case 1:
                return <FundType
                    data={fundData.FundDetails}
                    FundDetailerrors={FundDetailerrors}
                    setStep={setStep}
                    fundFilterData={fundFilterData}
                    setFundDetailErrors={setFundDetailErrors}
                    fundData={fundData}
                    updateData={data => setFundData({ ...fundData, FundDetails: data })}
                />
            case 2:
                return <FundDetails
                    data={fundData?.FundDetails}
                    fundData={fundData}
                    updateData={data => setFundData({ ...fundData, FundDetails: data })}
                    fundFilterData={fundFilterData}
                    FundDetailerrors={FundDetailerrors}
                    fundStaffData={fundstaffdropdown}
                    setFundStaffDropdown={setFundStaffDropdown}
                    setStep={setStep}
                    setFMDisabledFMOptions={setFMDisabledFMOptions}
                    disabledFMoptions={disabledFMoptions}
                    setFundDetailErrors={setFundDetailErrors}
                    firmDetails={firmDetails}
                />;
            case 3:
                return <CreateManagementTeams
                    data={fundData?.FundManagementTeams}
                    fundData={fundData}
                    isFirmAsGP={fundData.FundDetails?.isFirmAsGP}
                    updateData={data => {
                        setFundData(currFund => ({ ...currFund, FundManagementTeams: [...data] }))
                    }}
                    FundDetailerrors={FundDetailerrors}
                    setStep={setStep}
                    fundstaffdropdown={fundstaffdropdown}
                    setFundStaffDropdown={setFundStaffDropdown}
                    setFMDisabledFMOptions={setFMDisabledFMOptions}
                    disabledFMoptions={disabledFMoptions}
                    editable={true}
                    setFundDetailErrors={setFundDetailErrors}
                />;
            case 4:
                return <InvestmentStrategy
                    fundData={fundData}
                    data={fundData?.InvestmentStrategy}
                    updateData={data => {
                        setFundData(currFund => ({ ...currFund, InvestmentStrategy: { ...currFund.InvestmentStrategy, ...data } }))
                    }}
                    showdocuments={true}
                    FundDetailerrors={FundDetailerrors}
                    setStep={setStep}
                    setFundDetailErrors={setFundDetailErrors}
                />;
            // case 5:
            //     return <LegalCompliance
            //         data={fundData?.LegalCompliance}
            //         updateData={data => {
            //             setFundData(currFund => ({ ...currFund, LegalCompliance: { ...currFund.LegalCompliance, ...data } }))
            //         }}
            //         FundDetailerrors={FundDetailerrors}
            //         FolderNamesDropdown={FolderNames ? FolderNames : []}
            //         setStep={setStep}
            //         setFundDetailErrors={setFundDetailErrors}
            //     />;
            case 5:
                return <FeesExpenses
                    data={fundData?.FeesExpenses}
                    fundData={fundData}
                    updateData={data => setFundData({ ...fundData, FeesExpenses: data })}
                    FundDetailerrors={FundDetailerrors} FeesExpenses={feesExpenses}
                    setStep={setStep}
                    setFundDetailErrors={setFundDetailErrors}
                    setSubmittbtn={setSubmittbtn}
                    loading={loading}
                    handleSaveData={handleSaveData}
                />;
            default:
                return;
        }
    }

    return (
        <div>
            <ToastContainer />
            <StepModal
                open={showCreateFund}
                onPrevClick={() => handleModalNavigation(true)}
                onNextClick={() => handleModalNavigation(false)}
                currentStep={currStep}
                firstStep={1}
                lastStep={5}>
                <DialogTitle >
                    <FundTitle step={step} onClose={() => {
                        setShowCreateFund(false);
                        handleCancelModel(false);
                    }} />
                </DialogTitle>
                <DialogContent >
                    {renderFundFormByStep()}
                </DialogContent>
                <DialogActions>

                    {/* Save Button is Available in FeesExpenses.jsx(Last Step)  
              {step === 5 && <div className="fundActionButtonWrapper">
                    <ActionButton
                        label="SAVE"
                        loading={loading}
                        startIconName="SaveOutlined"
                        onClick={() => handleSaveData()}
                        styleProps={{
                            padding: '8px 56px'
                        }} />
                </div>} */}
                </DialogActions>
            </StepModal>
        </div>
    )
}

export default CreateFundModal;