import React, { useEffect } from 'react'
import { useParams,useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Tabs } from '../../common/Tabs/Tabs';
import { checkFeaturePermissions } from '../../../utils/common';
import { FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupIcon from '@mui/icons-material/Group';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import FeedIcon from '@mui/icons-material/Feed';

const IRRelationsTabTemplate = {
  INVESTORS: "investors",
  CUSTOM_EMAIL: "custom-email",
  INVESTOR_COMMUNICATION: "investor-communication",
  OFFLINE_SUBSCRIPTION: "manual-subscription",
  INSTANT_MESSENGER : "instant-messenger",
  EVENT_MANAGEMENT : "event-response-messenger"
} 

const IRHeader = (props) => {

  const navigate = useNavigate();
  const params = useParams();
  
  const handleTabSelection = (selectedTabValue) => {
    navigate(`/investor-relations/${selectedTabValue}`);
  };
  const [tabs, setTabs] = React.useState([
    {
      icon: <GroupIcon />,
      label: 'Investors',
      key: IRRelationsTabTemplate.INVESTORS
    },
   /*  {
      icon: '',
      label: 'Send Email',
      key: IRRelationsTabTemplate.CUSTOM_EMAIL
     }, */
    {
      icon: <ContactMailIcon />,
      label: 'Investor Communication',
      key: IRRelationsTabTemplate.INVESTOR_COMMUNICATION
    },
    {
      icon: <FeedIcon />,
      label: 'Offline Subscriptions',
      key: IRRelationsTabTemplate.OFFLINE_SUBSCRIPTION
    },
    {
      icon: <CalendarMonthIcon />,
      label: 'Event Response Management',
      key: IRRelationsTabTemplate.EVENT_MANAGEMENT
    },
  
  ])

  useEffect(()=>{
    if(!checkFeaturePermissions(RolesAndPermissions.INVITE_INVESTORS, FeatureAccess.READ)){
      setTabs(tabs?.filter(a=> a.label !== "Investors"));
    }
  },[]);


  return (
      <Tabs tabSelected={tabs?.findIndex(tab=>tab.key==params.detail)} tabs={tabs} onSelectTab={handleTabSelection} />
  )
}

export default IRHeader