import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import ActionButton from '../../common/ActionButton/ActionButton';
import AppTextInput from '../../common/TextInput/AppTextField';
import { InputTypes, DatePickerInputTypes, validationMessages, validationsTypeEnum, FieldVariants } from '../../common/TextInput/appInputenum';
import { toast } from 'react-toastify';
import FirstComponent from '../../common/DatePicker/DatePicker';
import DatePickerField from '../../common/DatePicker/DatePickerField';
import MultiSelectDropdown from '../../common/Multi-Select-Dropdown/MultiSelectDropdown';
import { addEventTemplate } from '../services/CalendarDataTemplate';
import { addCalendarEvent, getCalendarEventbyID, updateCalendarEvent } from '../services/calendarservices';
import secureLocalStorage from 'react-secure-storage';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import ALTTextField from '../../common/input-fields/ALTTextField';
import DateField from '../../common/input-fields/DateField';
import MultiSelectField from '../../common/input-fields/MultiSelectField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { validationsConstants } from '../../../utils/AppConstants';

const EventPopup = (props) => {

    //#region props
    const {
        openPopup,
        onClose,
        selectedEventDetails,
        usersDropdown,
        handleConfirm,
        events,
        setEvents,
        getEvents } = props;

    //#region initialData
    const initialData = {
        event_id: null,
        title: "",
        start: new Date(),
        end: new Date(),
        attendees: null
    }

    //#region variables
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(openPopup ? openPopup : true);
    const [eventDetails, setEventDetails] = useState(selectedEventDetails || initialData);

    //#region changeHandlers
    const handleChange = (name, value) => {
        eventPopupValues.setFieldValue(name, value)
    }

    //#region validations
    const [noofValidFields, setNoofValidFields] = useState({ validfields: 0 });
    const [checkIfValid, setcheckIfValid] = useState(false);
    let count = 0;   //to Show Toast Message only once

    const checknoofValidFields = (isValid, name, defaultValue) => {
        const TotalFiledsToValidate = 4;
        count = ++count;
        noofValidFields['validfields'] = isValid ? noofValidFields['validfields'] + 1 : noofValidFields['validfields'];
        if (noofValidFields['validfields'] === TotalFiledsToValidate) {
            noofValidFields['validfields'] = 0;
            count = 0;
            //Post API
            handleSubmit();
        } else {
            if (count === TotalFiledsToValidate) {
                toast.warning("Please verify the input fields",
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored", style: { zIndex: 9999 } });
                count = 0;
            }
        }
    };

    //#region Click Events
    const handleClose = () => {
        setOpen(false);
        onClose();
    }
    const handleSave = () => {
        setcheckIfValid(true);
        noofValidFields['validfields'] = 0;
    }

    const handleSubmit = async () => {
        setLoading(true);

        /* API DATA TEMPLATE FOR ADD NEW EVENT */
        const requestData = { ...addEventTemplate }
        requestData.calenderEventID = eventPopupValues.values?.event_id === '' || eventPopupValues.values.event_id === null ? 0 : eventPopupValues.values.event_id;
        requestData.eventID = eventPopupValues.values.event_id === '' || eventPopupValues.values.event_id === null ? 0 : eventPopupValues.values.event_id;
        requestData.title = eventPopupValues.values.title;
        requestData.startDate = eventPopupValues.values.start;
        requestData.endDate = eventPopupValues.values.end;
        requestData.listOfInvestors = eventPopupValues.values.attendees;
        requestData.actionBy = secureLocalStorage.getItem("userId");
        //console.log(requestData);

        if (requestData.endDate >= requestData.startDate) {
            let data = '';
            if (requestData.calenderEventID === 0) {
                data = await addCalendarEvent(requestData);
            }
            else {
                //update API
                data = await updateCalendarEvent(requestData);
            }
            if (data?.responseCode == 200) {
                setLoading(false);
                getEvents();
                toast.success(`Event ${requestData.calenderEventID === 0 ? 'added' : 'updated'} successfully`,
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            } else {
                setLoading(false);
                toast.error(`Failed to ${requestData.calenderEventID === 0 ? 'add' : 'update'} event`,
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
            handleClose();
        }
        else {
            toast.warning(`toast.warning("Please ensure that the End Date comes after the Start Date.`,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            setLoading(false);
        }
    }

    //#region api get calls
    const getEventDetails = async () => {
        if (eventDetails.event_id) {
            const data = await getCalendarEventbyID(eventDetails.event_id);
            if (data?.responseCode == 200) {
                const eventData = data?.responseData;
                eventPopupValues.setValues({
                   ...eventDetails,
                   attendees:eventData?.listOfInvestors || []
                })
            } else {
                toast.warning("Failed to get event details",
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
        }
    }

    //#region useEffect
    useEffect(() => {
        getEventDetails();
    }, []);

    //#region formik validations
    const eventPopupSchema = yup.object().shape({
        title: yup
            .string()
            .required(validationsConstants.REQUIRED)
            .min(1, validationsConstants.REQUIRED)
            .max(100, validationsConstants.REQUIRED)
            .matches(/^[\s\S]*$/, validationsConstants.REQUIRED),  // Accepts all characters

        attendees: yup
            .array()
            .required(validationsConstants.REQUIRED)
            .min(1, validationsConstants.REQUIRED),

        start: yup
            .date()
            .required(validationsConstants.DateError)
            .test(
                "is-future-date",
                validationsConstants.DateError,
                (value) => {
                    if (!value) return false; // Required rule will handle missing value
                    const now = new Date();
                    return value >= now; // Ensures start date/time is after the current date/time
                }
            ),

        end: yup
            .date()
            .required(validationsConstants.DateError)
            .when("start", (start, schema) =>
                start
                    ? schema.min(start, validationsConstants.DateError)
                    : schema
            )
    });

    const eventPopupValues = useFormik({
        initialValues: eventDetails,
        validationSchema: eventPopupSchema,
        onSubmit: (values) => {
            handleSubmit()
        },
    });



    return (
        <Dialog open={open} handleClose={handleClose} fullWidth>
            <DialogTitle>
                {eventDetails.event_id && eventDetails.event_id !== 0 ? 'Edit Event' : 'Add Event'}
            </DialogTitle>
            <DialogContent>
                <div className='margin-top-15'>
                    <ALTTextField
                        name='title'
                        label="Event Title"
                        value={eventPopupValues?.values?.title}
                        onChange={(name, value) => handleChange(name, value)}
                        required={true}
                        onBlur={eventPopupValues.handleBlur}
                        error={eventPopupValues.touched.title && Boolean(eventPopupValues.errors.title)}
                        errorMessage={eventPopupValues.touched.title && eventPopupValues.errors.title}
                    />
                </div>
                <div className='margin-top-15 display-row'>
                    <div className='field-width-50'>
                        <DateField
                            type={DatePickerInputTypes.DATE_AND_TIME}
                            name='start'
                            label="Start Date"
                            variant={FieldVariants.STANDARD}
                            value={eventPopupValues?.values?.start}
                            onChange={(name, value) => handleChange(name, value)}
                            required={true}
                            onBlur={eventPopupValues.handleBlur}
                            error={eventPopupValues.touched.start && Boolean(eventPopupValues.errors.start)}
                            errorMessage={eventPopupValues.touched.start && eventPopupValues.errors.start}
                        />
                    </div>
                    <div className='field-width-50 margin-left-15'>
                        <DateField
                            type={DatePickerInputTypes.DATE_AND_TIME}
                            name='end'
                            label="End Date"
                            variant={FieldVariants.STANDARD}
                            value={eventPopupValues?.values?.end}
                            onChange={(name, value) => handleChange(name, value)}
                            required={true}
                            onBlur={eventPopupValues.handleBlur}
                            error={eventPopupValues.touched.end && Boolean(eventPopupValues.errors.end)}
                            errorMessage={eventPopupValues.touched.end && eventPopupValues.errors.end}
                        />
                    </div>
                </div>
                <div className='margin-top-15'>
                    <MultiSelectField
                        name='attendees'
                        label="Attendees"
                        editable={true}
                        required={true}
                        value={eventPopupValues?.values?.attendees || []}
                        onChange={(name, value) => handleChange(name, value)}
                        onBlur={eventPopupValues.handleBlur}
                        error={eventPopupValues.touched.attendees && Boolean(eventPopupValues.errors.attendees)}
                        errorMessage={eventPopupValues.touched.attendees && eventPopupValues.errors.attendees}
                        options={usersDropdown?.map(user => ({ value: user.userId, label: `${user.userFullName} (${user.userEmailAddress})` })) || []}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <div className='space-between'>
                    <div>
                        <ActionButton variant='outlined' onClick={handleClose}
                            icon={<CancelIcon />}
                            label='CANCEL' />
                    </div>
                    <div className='margin-left-10'>
                        <ActionButton
                            icon={eventDetails.event_id === '' || eventDetails.event_id === null ? <AddIcon /> : <SaveIcon />}
                            label={eventDetails.event_id === '' || eventDetails.event_id === null ? 'ADD' : 'SAVE'}
                            loading={loading}
                            startIconName=''
                            onClick={eventPopupValues.handleSubmit} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}
export default EventPopup;