
import { getAPIResponse } from "../../utils/common";
import axiosApiCall from "../Services/axiosApiCall";

export const FieldType = {
    SELECT: 'select',
    NUMBER: 'number',
    FILE: 'file',
    COLOR: 'color',
    FONTSIZE: 'fontsize',
    SelectFontSize: 'selectFontSize',
    HeadingsfontSize: 'HeadingsfontSize',
    SubHeadingsfontSize: 'SubHeadingsfontSize',
    resetButton: 'reset',
    applyButton: 'apply'
};

export const DefaultLabelingConfig = {
    mainNavigation: {
        primaryColor: '#0a1a27',
        secondaryColor: '#d2dae0',
        iconColor: '#858d93',
        selectionIndicatorColor: '#38b5e8',
        activeIcon: '#0a1a27'
    },
    topNavigation: {
        logoUrl: '/bluehublab-logo.png',
        backgroundColor: '#fefefe',
        iconColor: '#828a91'
    },
    header: {
        gradientPrimaryColor: '#0a1a27',
        gradientSecondaryColor: '#5f6b76',
        color: '#ffffff',
    },
    rootBackground: {
        color: '#eaf0f7',
        size: 1,
        fontFamily: 'Cresta',
    }
}

export const fontOptions = [
    { label: 'Gill Sans MT', value: 'Gill Sans MT' },
    { label: 'Calibri', value: 'Calibri' },
    { label: 'Trebuchet MS', value: 'Trebuchet MS' },
    { label: 'Courier New', value: 'Courier New' },
    { label: 'Courier', value: 'Courier' },
    { label: 'monospace', value: 'monospace' },
    { label: 'Franklin Gothic Medium', value: 'Franklin Gothic Medium' },
    { label: 'Arial Narrow', value: 'Arial Narrow' },
    { label: 'Lucida Sans', value: 'Lucida Sans' },
    { label: 'Geneva', value: 'Geneva' },
    { label: 'Verdana', value: 'Verdana' },
    { label: 'Tahoma', value: 'Tahoma' },
    { label: 'Times New Roman', value: 'Times New Roman' },
    { label: 'Georgia', value: 'Georgia' },
    { label: 'Cambria', value: 'Cambria' },
    { label: 'Impact', value: 'Impact' },
    { label: 'Cresta', value: 'Cresta' },
]
export const fontSize = [
    { label: 'Small', value: 1 },
    { label: 'Medium', value: 2 },
    { label: 'Large', value: 3 },
]
export const bodyfontSize = [
    { label: '12', value: 12 },
    { label: '14 (Recommended)', value: 14 },
    { label: '16', value: 16 },
]

export const HeadingsfontSize = [
    { label: '34', value: 12 },
    { label: '36 (Recommended)', value: 14 },
    { label: '38', value: 16 },
]
export const SubHeadingsfontSize = [
    { label: '16', value: 12 },
    { label: '18 (Recommended)', value: 14 },
    { label: '20', value: 16 },
]

export const LabelingConfig = [
    {
        title: "Left Navigation",
        name: 'mainNavigation',
        subText: "Custom configuration for the left navigation can be done from here",
        fields: [
            {
                type: FieldType.COLOR,
                label: "Primary",
                name: 'primaryColor'
            },
            {
                type: FieldType.COLOR,
                label: "Secondary",
                name: 'secondaryColor'
            },
            {
                type: FieldType.COLOR,
                label: "Icon",
                name: 'iconColor'
            },
            {
                type: FieldType.COLOR,
                label: "Active Indicator",
                name: 'selectionIndicatorColor'
            }, {
                type: FieldType.COLOR,
                label: "Active Icon",
                name: 'activeIconColor'
            }
        ],
        buttons: [
            {
                type: FieldType.resetButton,
                name:"MainNavigation"
            },
            {
                type: FieldType.applyButton
            }
        ]
    },
    {
        title: "Application Header",
        name: 'topNavigation',
        subText: "Set your branding logo from here.",
        fields: [
            {
                type: FieldType.FILE,
                label: "Logo",
                name: 'logoUrl'
            },
            {
                type: FieldType.COLOR,
                label: "Background",
                name: 'backgroundColor'
            },
            {
                type: FieldType.COLOR,
                label: "Icon",
                name: 'iconColor'
            },
        ],
        buttons: [
            {
                type: FieldType.resetButton,
                key: 1,
                name:'TopNavigation'
            },
            {
                type: FieldType.applyButton,
                key:2,
            }
        ]
    },
    {
        title: "Page Header",
        name: 'header',
        subText: "Set your header gradient color from here.",
        fields: [
            {
                type: FieldType.COLOR,
                label: "Gradient (Primary)",
                name: 'gradientPrimaryColor'
            },
            {
                type: FieldType.COLOR,
                label: "Gradient (Secondary)",
                name: 'gradientSecondaryColor'
            },
            {
                type: FieldType.COLOR,
                label: "Text Color",
                name: 'color'
            },
        ],
        buttons: [
            {
                type: FieldType.resetButton,
                name:'Header'
            },
            {
                type: FieldType.applyButton
            }
        ]
    },
    {
        title: "Application Background Color & Fonts",
        name: 'rootBackground',
        subText: "Choose light background color to give it more immersive experience",
        fields: [
            {
                type: FieldType.COLOR,
                label: "Color",
                name: 'color'
            },
            {
                type: FieldType.SELECT,
                label: 'Font Name',
                name: 'fontFamily'
            }, {
                type: FieldType.FONTSIZE,
                label: "Font Size",
                name: 'size'
            },
            // {
            //     type: FieldType.HeadingsfontSize,
            //     label: "Headings Font Size",
            //     name: 'fontSize'
            // },
            // {
            //     type: FieldType.SubHeadingsfontSize,
            //     label: "Sub-Headings Font Size",
            //     name: 'fontSize'
            // },
        ],
        buttons: [
            {
                type: FieldType.resetButton,
                name:'RootBackground'
            },
             {
                type: FieldType.applyButton
            }
        ]
    }
];

export async function ResetWhiteLabelingConfig(GUID,section) {
    return await axiosApiCall.get(`/v1/UserRole/ResetTenantCustomCSS?tenantGUID=${GUID}&sectionToReset=${section}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}
