export const subscriptionStatusEnum = {
  ALL : -1,
  APPROVED : "Approved",
  REJECTED : "Rejected",
  COMPLIANCE_REVIEW : "Under Compliance Review",
  FM_REVIEW : "Under Fund Manager Review",
  INVESTOR_SIGN_PENDING : "Investor Sign Pending",
  AWAITING_ONLINE_PROCESSING : 'Awaiting Online Processing',
  ON_HOLD : "On Hold",
}



export const subscriptionMode = {
  ONLINE_SUBSCRIPTION: 'online Subscription',
  OFFLINE_SUBSCRIPTION: 'offline subscriptio'
}

export const questionPatterns = {
  TEXT_BOX: 'Open-ended question',
  DROP_DOWN: 'Dropdown',
  MULTI_CHOICE_QUESTION: 'Multiple choice question (MCQ)',
  DATE_TIME: 'Datetime',
  RAIDO: 'Radio Button',
  RAIDO_HORIZONTAL: 'RadioHorizontal',
  FILE_UPLOAD: 'FileUpload',
  GRID: 'Grid',
  RADIO_WITH_MCQ: 'RadioWithMCB',
  RADIO_WITH_RADIO: 'RadioWithRadio',
  HEADER: 'Header'
}

export const stepsToShowWhile = {
  SUBSCRIBING: 'invetsor-subscription',
  USER_PROFILE_SAVE: 'investoer-user-profile',
  REVIEW: 'review',
  OFFLINE_SUBSCRIPTION: 'offline-subscription'
}

export const subscriptionValidateFields = {
  COMMITED_CAPITAL: 'Capital Committed',
  SUBSCRIPTION_AMOUNT: 'Subscription Amount'
}

export const subscriptionQuestionData = {
  "UserProfileID": 0,
  "QuestionBankID": "",
  "UserID": "",
  "SubscriptionID": "",
  "QuestionPatternTypeID": "",
  "QuestionPatternType": "",
  "QuestionAnswerID": "",
  "UserProvidedAnswerinText": "",
  "multiChoiceQuestionAnswerIDs": "",
  "UploadedFile": "",
}


export const subscriptiondummyData = {
  "userQuestionAndAnswers": [
    {
      "questionBankID": 613,
      "questionName": "Name of the Investor",
      "answers": "",
      "multipleAnswers": false,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 1,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T07:42:44.9",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T07:42:44.9",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": true,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": true,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": false,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 615,
      "questionName": "Capital Committed",
      "answers": "",
      "multipleAnswers": false,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 1,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T07:44:28.187",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T07:44:28.187",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": true,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": true,
      "characterLength": 0,
      "askMultipleTimes": false,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 2,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 616,
      "questionName": "Type of Investor",
      "answers": "",
      "multipleAnswers": true,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 1,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T07:46:47.88",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T07:46:47.88",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": false,
      "bookmarks": null,
      "onboardingQuestionPattern": 6,
      "questionPatternTypeID": 6,
      "questionPatternType": "Dropdown",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": false,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 3,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": [
        {
          "questionAnswerID": 55977,
          "questionBankID": 616,
          "answer": "Individual",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:26:47.22",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:26:47.22",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55978,
          "questionBankID": 616,
          "answer": "Joint tenant",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:27:20.533",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:27:20.533",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55979,
          "questionBankID": 616,
          "answer": "Tenants in common ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:27:53.83",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:27:53.83",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55980,
          "questionBankID": 616,
          "answer": "Tenants by entireties ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:28:32.33",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:28:32.33",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55981,
          "questionBankID": 616,
          "answer": "Individual Retirement Accounts for ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:29:03.583",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:29:03.583",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55982,
          "questionBankID": 616,
          "answer": "Self-Directed retirement plan for ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:29:33.51",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:29:33.51",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55983,
          "questionBankID": 616,
          "answer": "Nominee – Acting on behalf of an Individual",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:30:17.38",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:30:17.38",
          "isActive": true,
          "softDelete": false
        }
      ]
    },
    {
      "questionBankID": 617,
      "questionName": "First Name",
      "answers": "",
      "multipleAnswers": false,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 1,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T07:50:41.153",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T07:50:41.153",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": true,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": true,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 4,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 618,
      "questionName": "Last Name",
      "answers": "",
      "multipleAnswers": false,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 1,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T07:51:18.817",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T07:51:18.817",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": true,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": true,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 5,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 619,
      "questionName": "SSN",
      "answers": "",
      "multipleAnswers": false,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 1,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T07:53:59.69",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T07:53:59.69",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": true,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": true,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 6,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 620,
      "questionName": "State of Residence",
      "answers": "",
      "multipleAnswers": false,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 1,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T07:58:09.227",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T07:58:09.227",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": true,
      "bookmarks": null,
      "onboardingQuestionPattern": 6,
      "questionPatternTypeID": 6,
      "questionPatternType": "Dropdown",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": false,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 7,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": [
        {
          "questionAnswerID": 55984,
          "questionBankID": 620,
          "answer": "California",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:32:54.847",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:32:54.847",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55985,
          "questionBankID": 620,
          "answer": "Texas",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:33:20.033",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:33:20.033",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55986,
          "questionBankID": 620,
          "answer": "Florida",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:33:42.55",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:33:42.55",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55987,
          "questionBankID": 620,
          "answer": "New York",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:33:56.643",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:33:56.643",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55988,
          "questionBankID": 620,
          "answer": "Pennsylvania",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:34:10.81",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:34:10.81",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55989,
          "questionBankID": 620,
          "answer": "Illinois",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:34:19.277",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:34:19.277",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55990,
          "questionBankID": 620,
          "answer": "Ohio",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:34:27.05",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:34:27.05",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55991,
          "questionBankID": 620,
          "answer": "Georgia",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:34:38.893",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:34:38.893",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55992,
          "questionBankID": 620,
          "answer": "North Carolina",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:34:49.617",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:34:49.617",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55993,
          "questionBankID": 620,
          "answer": "Michigan",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:34:59.4",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:34:59.4",
          "isActive": true,
          "softDelete": false
        }
      ]
    },
    {
      "questionBankID": 621,
      "questionName": "Date of Birth",
      "answers": "",
      "multipleAnswers": false,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 1,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T08:07:00.307",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T08:07:00.307",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": true,
      "bookmarks": null,
      "onboardingQuestionPattern": 7,
      "questionPatternTypeID": 7,
      "questionPatternType": "Datetime",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": false,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 8,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 626,
      "questionName": "Bank Name",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 2,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T09:54:57.307",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T09:54:57.307",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 627,
      "questionName": "Bank City",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 2,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T09:58:28.247",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T09:58:28.247",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 629,
      "questionName": "Bank State",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 2,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:00:33.37",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:00:33.37",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 6,
      "questionPatternTypeID": 6,
      "questionPatternType": "Dropdown",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": [
        {
          "questionAnswerID": 55994,
          "questionBankID": 629,
          "answer": "California",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:35:26.33",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:35:26.33",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55995,
          "questionBankID": 629,
          "answer": "Texas",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:37:25.787",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:37:25.787",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55996,
          "questionBankID": 629,
          "answer": "Florida",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:37:25.827",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:37:25.827",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55997,
          "questionBankID": 629,
          "answer": "New York",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:37:25.833",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:37:25.833",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55998,
          "questionBankID": 629,
          "answer": "Pennsylvania",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:37:25.84",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:37:25.84",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 55999,
          "questionBankID": 629,
          "answer": "Illinois",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:37:25.847",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:37:25.847",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56000,
          "questionBankID": 629,
          "answer": "Ohio",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:37:25.85",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:37:25.85",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56001,
          "questionBankID": 629,
          "answer": "Georgia",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:37:25.857",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:37:25.857",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56002,
          "questionBankID": 629,
          "answer": "North Carolina",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:37:25.863",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:37:25.863",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56003,
          "questionBankID": 629,
          "answer": "Michigan",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:37:25.87",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:37:25.87",
          "isActive": true,
          "softDelete": false
        }
      ]
    },
    {
      "questionBankID": 624,
      "questionName": "Name of the Investor",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 2,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T09:41:59.16",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T09:41:59.16",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 638,
      "questionName": "ABA# ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 2,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:29:14.1",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:29:14.1",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 640,
      "questionName": "Account Name ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 2,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:30:15.02",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:30:15.02",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 643,
      "questionName": "Account Number",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 2,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:32:24.443",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:32:24.443",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 646,
      "questionName": "For further credit to",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 2,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:34:09.32",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:34:09.32",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 648,
      "questionName": "For further credit to Account Number",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 2,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:35:16.35",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:35:16.35",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 650,
      "questionName": "Accredited Investor Status",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 3,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:36:21.243",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:36:21.243",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 4,
      "questionPatternTypeID": 4,
      "questionPatternType": "Multiple choice question (MCQ)",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": true,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": [
        {
          "questionAnswerID": 56004,
          "questionBankID": 650,
          "answer": "Net worth over $1 million, not including primary residence (individually or jointly with spouse or partner)",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:42:46",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:42:46",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56005,
          "questionBankID": 650,
          "answer": "Income over $200,000 (individually) or $300,000 (joint income with spouse or spousal equivalent) for at least the past two years, including the current year. ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:42:46.043",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:42:46.043",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56006,
          "questionBankID": 650,
          "answer": "Financial professionals who hold in good standing a securities representative license (Series 7), an investment adviser representative license (Series 65), or private securities offerings representative license (Series 82) ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:42:46.05",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:42:46.05",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56007,
          "questionBankID": 650,
          "answer": "For investments in private funds, “knowledgeable employees” of the fund count as accredited investors in that fund. ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:42:46.057",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:42:46.057",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56008,
          "questionBankID": 650,
          "answer": "Investor cannot make any representation set forth above and is not an accredited investor",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:42:46.063",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:42:46.063",
          "isActive": true,
          "softDelete": false
        }
      ]
    },
    {
      "questionBankID": 651,
      "questionName": "Qualified Purchaser Status ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 4,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:37:21.9",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:37:21.9",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": true,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": [
        {
          "questionAnswerID": 56009,
          "questionBankID": 651,
          "answer": "The Investor (or, if the Investor is an Individual Retirement Account, Keogh Plan or other Self Directed Defined Contribution Plan in which a participant may exercise control over the investment of assets credited to his or her account, the investing participant) is a qualified purchaser because he/she (alone, or together with his/her spouse, if investing jointly) owns not less than $5,000,000 in investments. ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:45:54.163",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:45:54.163",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56010,
          "questionBankID": 651,
          "answer": "The Investor cannot make the representation set forth in Paragraph above and is not a qualified purchaser",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:45:54.2",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:45:54.2",
          "isActive": true,
          "softDelete": false
        }
      ]
    },
    {
      "questionBankID": 656,
      "questionName": "Qualified Client Status",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 5,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:43:08.883",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:43:08.883",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 4,
      "questionPatternTypeID": 4,
      "questionPatternType": "Multiple choice question (MCQ)",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": true,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": [
        {
          "questionAnswerID": 56011,
          "questionBankID": 656,
          "answer": "The Investor is a \"qualified purchaser\" under the Investment Company Act.  ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:51:27.247",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:51:27.247",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56012,
          "questionBankID": 656,
          "answer": "The Investor has an individual net worth, or joint net worth with his or her spouse, in excess of $2,100,000 immediately before entering into an advisory contract, not including the value of their primary residence. ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:51:27.297",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:51:27.297",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56013,
          "questionBankID": 656,
          "answer": "An employee of the advisor who is involved in the investment activities and has been so for at least one year. ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:51:27.3",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:51:27.3",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56014,
          "questionBankID": 656,
          "answer": "Immediately after being admitted to the Partnership, the Investor will have at least $1,000,000 under the management of the General Partner and its affiliates, whether under the Partnership Agreement or otherwise. ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:51:27.307",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:51:27.307",
          "isActive": true,
          "softDelete": false
        }
      ]
    },
    {
      "questionBankID": 659,
      "questionName": "Foreign Status ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 6,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:50:39.693",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:50:39.693",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": true,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": [
        {
          "questionAnswerID": 56015,
          "questionBankID": 659,
          "answer": "is neither of the above and is therefore a foreign person for United States federal income tax purposes (“Foreign Person”). ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:54:26.197",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:54:26.197",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56016,
          "questionBankID": 659,
          "answer": " is not a United States citizen but is a resident of the United States for federal income tax purposes. ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:54:26.237",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:54:26.237",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56017,
          "questionBankID": 659,
          "answer": "is a United States citizen. ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:54:26.243",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:54:26.243",
          "isActive": true,
          "softDelete": false
        }
      ]
    },
    {
      "questionBankID": 661,
      "questionName": "Partner",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 6,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:51:32.59",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:51:32.59",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 662,
      "questionName": "Name",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 6,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:52:16.717",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:52:16.717",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 663,
      "questionName": "Address",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 6,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:53:02.547",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:53:02.547",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 665,
      "questionName": "Telephone number",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 6,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:53:50.75",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:53:50.75",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 666,
      "questionName": "Fax number",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 6,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:55:34.403",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:55:34.403",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 668,
      "questionName": "Email address",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 6,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:56:13.45",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:56:13.45",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 672,
      "questionName": "The Investor acknowledges and agrees that the appointment made above is irrevocable during the term of the Fund.",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 6,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:00:18.47",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:00:18.47",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 11,
      "questionPatternTypeID": 11,
      "questionPatternType": "RadioHorizontal",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": [
        {
          "questionAnswerID": 56018,
          "questionBankID": 672,
          "answer": "Yes",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:56:10.853",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:56:10.853",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56019,
          "questionBankID": 672,
          "answer": "No",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:56:10.89",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:56:10.89",
          "isActive": true,
          "softDelete": false
        }
      ]
    },
    {
      "questionBankID": 674,
      "questionName": "Origin of Bank Accounts. [check the applicable subparagraph and provide the necessary information]",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 7,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:03:52.153",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:03:52.153",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": [
        {
          "questionAnswerID": 56020,
          "questionBankID": 674,
          "answer": "Yes",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:57:04.76",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:57:04.76",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56021,
          "questionBankID": 674,
          "answer": "No",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:57:04.773",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:57:04.773",
          "isActive": true,
          "softDelete": false
        }
      ]
    },
    {
      "questionBankID": 675,
      "questionName": "Bank Account from which Capital Contributions are Paid",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 7,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:04:55.11",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:04:55.11",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": [
        {
          "questionAnswerID": 56022,
          "questionBankID": 675,
          "answer": "Yes",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:57:39.853",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:57:39.853",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56023,
          "questionBankID": 675,
          "answer": "No",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:57:39.863",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:57:39.863",
          "isActive": true,
          "softDelete": false
        }
      ]
    },
    {
      "questionBankID": 676,
      "questionName": "Location of Bank Accounts",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 7,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:05:48.82",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:05:48.82",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": [
        {
          "questionAnswerID": 56024,
          "questionBankID": 676,
          "answer": "The Investor maintains bank accounts only in the United States",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:59:59.887",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:59:59.887",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56025,
          "questionBankID": 676,
          "answer": "The Investor maintains bank accounts in the United States and other countries",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:59:59.893",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:59:59.893",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56026,
          "questionBankID": 676,
          "answer": "The Investor maintains bank accounts only in other countries",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T15:59:59.9",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T15:59:59.9",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56027,
          "questionBankID": 676,
          "answer": "The Investor represents that none of the non-United States countries in which the Investor maintains a bank account is a Non-Cooperative Jurisdiction. If the Investor cannot check this box, the General Partner may require the Investor to make additional representations and covenants with respect to such bank accounts and the Investor’s subscription will not be accepted unless anti-money laundering protections satisfactory to the General Partner, in its sole discretion, are in place. ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T16:00:34.62",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T16:00:34.62",
          "isActive": true,
          "softDelete": false
        }
      ]
    },
    {
      "questionBankID": 677,
      "questionName": "The Investor makes one of the following representations ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 8,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:07:53.58",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:07:53.58",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": [
        {
          "questionAnswerID": 56028,
          "questionBankID": 677,
          "answer": "The Investor is an individual (investing in the Fund directly or through an individual retirement plan) who is not an officer, agent, or employee of a state or political subdivision or any agency, authority or instrumentality thereof, acting in his or her official capacity. ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T16:03:49.62",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T16:03:49.62",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56029,
          "questionBankID": 677,
          "answer": "The Investor cannot make the representation set forth in Paragraph above.  ",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T16:03:49.63",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T16:03:49.63",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56030,
          "questionBankID": 677,
          "answer": "Please provide an explanation in the space below. The Investor further agrees that the General Partner may request additional information and require additional representations concerning the foregoing",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T16:03:49.64",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T16:03:49.64",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56031,
          "questionBankID": 677,
          "answer": "Please Specify",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": true,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T16:03:49.647",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T16:03:49.647",
          "isActive": true,
          "softDelete": false
        }
      ]
    },
    {
      "questionBankID": 678,
      "questionName": "Is the Investor subject to any rules, regulations, orders, or laws that might result in the disclosure of confidential information relating to the Fund?  ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 8,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:09:05.36",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:09:05.36",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": [
        {
          "questionAnswerID": 56032,
          "questionBankID": 678,
          "answer": "Yes",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T16:05:20.65",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T16:05:20.65",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56033,
          "questionBankID": 678,
          "answer": "No",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T16:05:20.7",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T16:05:20.7",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56034,
          "questionBankID": 678,
          "answer": "The Investor should indicate the relevant rules, regulations, orders or laws to which the Investor is subject and provide any additional explanatory information in the spaces below",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": false,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T16:05:20.707",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T16:05:20.707",
          "isActive": true,
          "softDelete": false
        },
        {
          "questionAnswerID": 56035,
          "questionBankID": 678,
          "answer": "Please Specify",
          "additionalInfo": null,
          "description": null,
          "childID": 0,
          "isAdditionalInfo": true,
          "isSelected": false,
          "userProvidedAnswer": "",
          "createdBy": "0",
          "createdDate": "2024-05-23T16:05:20.71",
          "updatedBy": "0",
          "updatedDate": "2024-05-23T16:05:20.71",
          "isActive": true,
          "softDelete": false
        }
      ]
    },
    {
      "questionBankID": 680,
      "questionName": "IRS Form W-9  ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 9,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:18:31.317",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:18:31.317",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 681,
      "questionName": "Instructions for IRS Form W-9  ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 9,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "https://www.irs.gov/pub/irs-pdf/iw9.pdf ",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:20:53.257",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:20:53.257",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 682,
      "questionName": "IRS Form W-8BEN",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 9,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "https://www.irs.gov/pub/irs-pdf/fw8ben.pdf  ",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:22:05.57",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:22:05.57",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 683,
      "questionName": "Instructions for IRS Form W-8BEN ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 9,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "https://www.irs.gov/pub/irs-pdf/iw8ben.pdf",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:22:55.75",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:22:55.75",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 684,
      "questionName": "IRS Form W-8ECI ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 9,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:23:56.693",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:23:56.693",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 686,
      "questionName": "Instructions for IRS Form W-8ECI",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 9,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:27:07.853",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:27:07.853",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 689,
      "questionName": "IRS Form W-8EXP ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 9,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "https://www.irs.gov/pub/irs-pdf/fw8exp.pdf ",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:29:12.977",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:29:12.977",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 690,
      "questionName": "Instructions for IRS Form W-8EXP ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 9,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "https://www.irs.gov/pub/irs-pdf/iw8exp.pdf ",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:30:11.567",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:30:11.567",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 692,
      "questionName": "IRS Form W-8IMY ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 9,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "https://www.irs.gov/pub/irs-pdf/fw8imy.pdf ",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:31:10.57",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:31:10.57",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 694,
      "questionName": "Instructions for IRS Form W-8IMY ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 9,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "https://www.irs.gov/pub/irs-pdf/iw8imy.pdf",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:31:59.15",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:31:59.15",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 625,
      "questionName": "Legal name of the investing entity",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 10,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T09:54:12.697",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T09:54:12.697",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 630,
      "questionName": "Capital Committed",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 10,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:06:19.153",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:06:19.153",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 631,
      "questionName": "Type of Investor",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 10,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:10:38.39",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:10:38.39",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 6,
      "questionPatternTypeID": 6,
      "questionPatternType": "Dropdown",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 632,
      "questionName": "Form PF Investor Type",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 10,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:12:00.5",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:12:00.5",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 6,
      "questionPatternTypeID": 6,
      "questionPatternType": "Dropdown",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 633,
      "questionName": "The investor should specify Tax Identification Number or the Investor’s tax-exempt status by checking one of the appropriate boxes",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 10,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:18:24.207",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:18:24.207",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 4,
      "questionPatternTypeID": 4,
      "questionPatternType": "Multiple choice question (MCQ)",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 634,
      "questionName": "Investor’s jurisdiction of organization",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 10,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:22:02.563",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:22:02.563",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 6,
      "questionPatternTypeID": 6,
      "questionPatternType": "Dropdown",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 635,
      "questionName": "Investor’s date of organization",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 10,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:23:12.66",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:23:12.66",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 7,
      "questionPatternTypeID": 7,
      "questionPatternType": "Datetime",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 636,
      "questionName": "Investor’s fiscal year",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 10,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:25:14.143",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:25:14.143",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 7,
      "questionPatternTypeID": 7,
      "questionPatternType": "Datetime",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 637,
      "questionName": "Primary contact person name ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 11,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:27:27.257",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:27:27.257",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 639,
      "questionName": "Address",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 11,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:29:36.5",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:29:36.5",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 641,
      "questionName": "Email ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 11,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:30:32.053",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:30:32.053",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 642,
      "questionName": "Phone number",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 11,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:31:25.24",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:31:25.24",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 644,
      "questionName": "Name of investor",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 12,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:32:51.877",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:32:51.877",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 645,
      "questionName": "Bank Name ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 12,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:33:48.087",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:33:48.087",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 647,
      "questionName": "Bank City ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 12,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:35:00.477",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:35:00.477",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 649,
      "questionName": "Bank State ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 12,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:35:40.367",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:35:40.367",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 6,
      "questionPatternTypeID": 6,
      "questionPatternType": "Dropdown",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 652,
      "questionName": "Account name",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 12,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:38:23.587",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:38:23.587",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 653,
      "questionName": "Account number ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 12,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:39:12.377",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:39:12.377",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 654,
      "questionName": "For further credit to: Name",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 12,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:40:16.633",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:40:16.633",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 655,
      "questionName": "For further credit to: Account Number ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 12,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:41:15.853",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:41:15.853",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 658,
      "questionName": "Qualified Purchaser Status ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 14,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:48:49.477",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:48:49.477",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 660,
      "questionName": " \"Family\" Corporations, \"Family\" Foundations",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 14,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "\"Family\" Endowments, \"Family\" Section 501(c)(3) Organizations, \"Family\" Trusts or Other 'Family' Entities",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:51:02.857",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:51:02.857",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 4,
      "questionPatternTypeID": 4,
      "questionPatternType": "Multiple choice question (MCQ)",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 664,
      "questionName": "Trusts or Section 501(c)(3) Organizations (Other Than Trusts or Section 501(c)(3) Organizations That Qualify under above para) ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 14,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "\"Family\" Endowments, \"Family\" Section 501(c)(3) Organizations, \"Family\" Trusts or Other 'Family' Entities",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:53:30.793",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:53:30.793",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 4,
      "questionPatternTypeID": 4,
      "questionPatternType": "Multiple choice question (MCQ)",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 667,
      "questionName": "Other Entities",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 14,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:55:39.513",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:55:39.513",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 4,
      "questionPatternTypeID": 4,
      "questionPatternType": "Multiple choice question (MCQ)",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 669,
      "questionName": "Entities That Do Not Qualify Above ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 14,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:57:13.293",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:57:13.293",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 4,
      "questionPatternTypeID": 4,
      "questionPatternType": "Multiple choice question (MCQ)",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 670,
      "questionName": "Additional Questions for \"Section 3(c)(1) or 3(c)(7) Companies\" ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 14,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:57:55.91",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:57:55.91",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 4,
      "questionPatternTypeID": 4,
      "questionPatternType": "Multiple choice question (MCQ)",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 671,
      "questionName": "Qualified Client Status ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 15,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T10:59:30.03",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T10:59:30.03",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 4,
      "questionPatternTypeID": 4,
      "questionPatternType": "Multiple choice question (MCQ)",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 685,
      "questionName": "Has the beneficial owner, within the last ten (10) years, been convicted of a felony or misdemeanor (a) in connection with the purchase or sale of any security, (b) involving the making of any false filing with the SEC or (c) arising out of the conduct of the business of an underwriter, broker, dealer, municipal securities dealer, investment adviser or paid solicitor of purchasers of securities? ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 16,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:25:40.117",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:25:40.117",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 691,
      "questionName": "Is the beneficial owner subject to a Final Order  of a state securities commission (or an agency or officer of a state performing like functions), a state authority that supervises or examines banks, savings associations, or credit unions, a state insurance commission (or an agency or officer of a state performing like functions), an appropriate federal banking agency, the U.S. Commodity Futures Trading Commission, or the National Credit Union Administration, that:",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 16,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:30:35.757",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:30:35.757",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 697,
      "questionName": "Is the beneficial owner suspended or expelled from membership in, or suspended or barred from association with a member of, a registered national securities exchange or a registered national or affiliated securities association for any act or omission to act constituting conduct inconsistent with just and equitable principles of trade? ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 16,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:35:40.29",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:35:40.29",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 698,
      "questionName": "Has the beneficial owner filed as a registrant or issuer, or has the beneficial owner been named as an underwriter in, any registration statement or Regulation A offering statement filed with the SEC that, within the last five (5) years, (a) was the subject of a refusal order, stop order, or order suspending the Regulation A exemption or (b) is currently the subject of an investigation or a proceeding to determine whether such a stop order or suspension order should be issued? ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 16,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:36:52.5",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:36:52.5",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 699,
      "questionName": "Is the beneficial owner subject to (a) a United States Postal Service false representation order entered into within the last five (5) years, or (b) a temporary restraining order or preliminary injunction with respect to conduct alleged by the United States Postal Service to constitute a scheme or device for obtaining money or property through the mail by means of false representations? ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 16,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:38:17.243",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:38:17.243",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 702,
      "questionName": "Address",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 17,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:46:14.88",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:46:14.88",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 703,
      "questionName": "Telephone number",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 17,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:46:27.893",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:46:27.893",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 704,
      "questionName": "Fax number",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 17,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:46:38.87",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:46:38.87",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 700,
      "questionName": "Under penalties of perjury, the Investor represents that it (check the applicable box):",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 17,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:40:03.87",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:40:03.87",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 701,
      "questionName": "Name",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 17,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:46:00.887",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:46:00.887",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 705,
      "questionName": "Email address",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 17,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:47:48.34",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:47:48.34",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 727,
      "questionName": "If the Investor is a partnership, a limited liability company treated as a partnership for United States federal income tax purposes, a grantor trust (within the meaning of Sections 671-679 of the Internal Revenue Code) or an S corporation (within the meaning of Section 1361 of the Internal Revenue Code) (each, a “flow-through entity”), then the Investor represents and warrants that either [check the applicable clause]:",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 18,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T12:53:20.457",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T12:53:20.457",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 723,
      "questionName": "Location of Bank Accounts. [check all applicable subparagraphs]. ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 19,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T12:38:23.623",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T12:38:23.623",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 722,
      "questionName": "Bank Account from which Capital Contributions are Paid. [check the applicable subparagraph and provide the necessary information] ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 19,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T12:37:42.217",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T12:37:42.217",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 721,
      "questionName": "Origin of Bank Accounts. [check the applicable subparagraph and provide the necessary information.",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 19,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T12:37:30.717",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T12:37:30.717",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 724,
      "questionName": "The Investor ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 20,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T12:40:49.507",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T12:40:49.507",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 725,
      "questionName": "If the Investor is an insurance company investing the assets of its general account in the Fund, then either (check the applicable clause): ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 20,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T12:41:02.047",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T12:41:02.047",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 726,
      "questionName": "If the Investor is a Benefit Plan Investor subject to Part 4 of Title I of ERISA or Section 4975 of the Internal Revenue Code (and is not subject to ERISA), the independent fiduciary acting on behalf of the Investor is one of the following (check the applicable clause): ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 20,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T12:42:15.813",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T12:42:15.813",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 707,
      "questionName": "If the Investor is acting as trustee, custodian or nominee for a beneficial owner that is a government entity, please provide the name of the government entity:",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 21,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:53:02.237",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:53:02.237",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 708,
      "questionName": "If the Investor is an entity substantially owned by a government entity (e.g., a single investor vehicle) and the investment decisions of such entity are made or directed by such government entity, please provide the name of the government entity:",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 21,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:53:14.78",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:53:14.78",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 5,
      "questionPatternTypeID": 5,
      "questionPatternType": "Open-ended question",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 706,
      "questionName": "The investor ______ a government entity.",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 21,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:52:45.19",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:52:45.19",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 709,
      "questionName": "the Investor hereby certifies that ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 21,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:53:35.673",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:53:35.673",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 710,
      "questionName": "Is the Investor subject to any rules, regulations or orders (including, without limitation, the Freedom of Information Act, 5 U.S.C. § 552 (“FOIA”), any state public records access laws, any state or other jurisdiction’s laws with similar intent or effect to the FOIA), that might result in the disclosure of confidential information relating to the Fund? ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 22,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T11:55:27.453",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T11:55:27.453",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 8,
      "questionPatternTypeID": 8,
      "questionPatternType": "Radio",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 711,
      "questionName": "IRS Form W-9 ",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 23,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T12:00:28.05",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T12:00:28.05",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 712,
      "questionName": "Instructions for IRS Form W-9",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 23,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T12:00:38.893",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T12:00:38.893",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 713,
      "questionName": "IRS Form W-8BEN",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 23,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T12:00:49.893",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T12:00:49.893",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 714,
      "questionName": "Instructions for IRS Form W-8BEN",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 23,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T12:01:00.743",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T12:01:00.743",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 715,
      "questionName": "IRS Form W-8ECI",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 23,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T12:01:10.893",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T12:01:10.893",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 716,
      "questionName": "Instructions for IRS Form W-8ECI",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 23,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T12:01:22.97",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T12:01:22.97",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 717,
      "questionName": "IRS Form W-8EXP",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 23,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T12:01:34.47",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T12:01:34.47",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 718,
      "questionName": "Instructions for IRS Form W-8EXP",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 23,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T12:01:45.61",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T12:01:45.61",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 719,
      "questionName": "IRS Form W-8IMY",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 23,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T12:01:56.173",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T12:01:56.173",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    },
    {
      "questionBankID": 720,
      "questionName": "Instructions for IRS Form W-8IMY",
      "answers": null,
      "multipleAnswers": null,
      "questionTypeId": 17,
      "softDelete": false,
      "step": 23,
      "shortName": null,
      "showInInvestProfile": false,
      "description": "",
      "footerText": null,
      "footerToggleDescription": null,
      "createdBy": "0",
      "createdDate": "2024-05-23T12:02:05.8",
      "updatedBy": "0",
      "updatedDate": "2024-05-23T12:02:05.8",
      "isActive": true,
      "askToExisitingInvestor": false,
      "isText": null,
      "bookmarks": null,
      "onboardingQuestionPattern": 9,
      "questionPatternTypeID": 9,
      "questionPatternType": "FileUpload",
      "isQuestionUsedByInvestor": false,
      "setValidations": false,
      "allowAlphabets": false,
      "allowNumbers": false,
      "characterLength": 0,
      "askMultipleTimes": true,
      "checkParentAnswer": true,
      "hasChildQuestions": false,
      "parentQuestionID": 611,
      "questionHeader": "",
      "questionNo": 1,
      "showIfParentAnswerIs": "Non-Natural Person",
      "showHorizontally": false,
      "doNotDisplayQuestion": false,
      "questionAnswerID": 0,
      "userProvidedAnswer": "",
      "userProvidedAnswerIDs": "",
      "multiChoiceQuestionAnswerIDs": null,
      "questionAnswer": []
    }
  ],
  "questionBankSteps": [
    {
      "stepID": 1,
      "stepName": "Step1",
      "stepNumber": 1,
      "stepTitle": "Investor Profile Information",
      "stepHeader": "",
      "stepDescription": "",
      "stepFooter": "",
      "isValid": false,
      "isFilled": false
    },
    {
      "stepID": 2,
      "stepName": "Step2",
      "stepNumber": 2,
      "stepTitle": "Bank Details for receiving distributions",
      "stepHeader": "",
      "stepDescription": "",
      "stepFooter": "",
      "isValid": false,
      "isFilled": false
    },
    {
      "stepID": 3,
      "stepName": "Step3",
      "stepNumber": 3,
      "stepTitle": "Accredited Investor Status",
      "stepHeader": "",
      "stepDescription": "",
      "stepFooter": "",
      "isValid": false,
      "isFilled": false
    },
    {
      "stepID": 4,
      "stepName": "Step4",
      "stepNumber": 4,
      "stepTitle": "Qualified Purchaser Status",
      "stepHeader": "",
      "stepDescription": "",
      "stepFooter": "",
      "isValid": false,
      "isFilled": false
    },
    {
      "stepID": 5,
      "stepName": "Step5",
      "stepNumber": 5,
      "stepTitle": "Qualified Client Status",
      "stepHeader": "",
      "stepDescription": "",
      "stepFooter": "",
      "isValid": false,
      "isFilled": false
    },
    {
      "stepID": 6,
      "stepName": "Step6",
      "stepNumber": 6,
      "stepTitle": "Foreign Status",
      "stepHeader": "",
      "stepDescription": "",
      "stepFooter": "",
      "isValid": false,
      "isFilled": false
    },
    {
      "stepID": 7,
      "stepName": "Step7",
      "stepNumber": 7,
      "stepTitle": "Anti Money Laundering Questions",
      "stepHeader": "",
      "stepDescription": "",
      "stepFooter": "",
      "isValid": false,
      "isFilled": false
    },
    {
      "stepID": 8,
      "stepName": "Step8",
      "stepNumber": 8,
      "stepTitle": "Miscellaneous",
      "stepHeader": "",
      "stepDescription": "",
      "stepFooter": "",
      "isValid": false,
      "isFilled": false
    },
    {
      "stepID": 9,
      "stepName": "Step9",
      "stepNumber": 9,
      "stepTitle": "Tax Form Instructions",
      "stepHeader": "",
      "stepDescription": "",
      "stepFooter": "",
      "isValid": false,
      "isFilled": false
    }
  ]
}