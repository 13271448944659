import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import ActionButton from '../../common/ActionButton/ActionButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
 
const ResetConfirmation = (props) => {

    const { open, onClose,onAccept } = props;
    const [loading, setLoading] = useState(false);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <InfoIcon className='info-i' />
                <b>Confirmation</b>
            </DialogTitle>
            <DialogContent>
                {props.content}
            </DialogContent>
            <DialogActions>
                {/* <div className='space-between'>
                    <div>
                        <Button variant='outlined' onClick={onClose} label='Cancel'>Cancel</Button>
                    </div>
                    <div className='margin-left-10'>
                        <Button
                            label='Ok'
                            loading={loading}
                            variant='contained'
                            onClick={onAccept}>confirmation</Button>
                    </div>
                </div> */}
                <div > 
                <div className='alignright'>
                    <ActionButton
                    variant="outlined"
                    icon={<CancelIcon />}
                    label='Cancel' 
                    styleProps={{margin:'5px 5px 10px 5px'}} 
                    onClick={onClose}/>

                    <ActionButton
                    loading={loading}
                    icon={<CheckCircleIcon />}
                    label='Confirm' 
                    styleProps={{margin:'5px 10px 10px 10px'}} 
                    onClick={onAccept}/>
                </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}
export default ResetConfirmation;