import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import { useState } from 'react';
import ALTTextField from '../../common/input-fields/ALTTextField';
import ActionButton from '../../common/ActionButton/ActionButton';
import Text from '../../common/Text/Text';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import secureLocalStorage from 'react-secure-storage';
import { AppDashboards } from '../../../utils/enum';
import InfoIcon from '@mui/icons-material/Info';

//#This popup displays when fund manager or compliance want to reject the fund subscription
const SubscriptionRejectionPopup = (props) => {

    //#region props
    const { open, onClose, isApproved, updateSubscriptionStatus, rejectBtnLoading , CurrStatus } = props;

    //#region variables
    const [reason, setReason] = useState('')
    const [isFirstConfirmedRejection, setIsFirstConfirmedRejection] = useState(true);  // Track whether it's the first confirm click
    const [RejectionText, setRejectionText] = useState('');

    //#region change events
    const handleChange = (name, value) => {
        setReason(value);
    }

    //#region click events
    const onConfirmClick = () => {
        if (isFirstConfirmedRejection && secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD && CurrStatus === 'Under Compliance Review') {
            setRejectionText("Kindly provide the reason for rejection. This will be sent to the investor in the mail informing about the rejection of the subscription request.");
            setIsFirstConfirmedRejection(false);
        }else{
            updateSubscriptionStatus(isApproved, reason)
        }
    }

    const rejectionPopupText = () => {
        // debugger
        if (isFirstConfirmedRejection) {
            if (secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD && CurrStatus === 'Under Compliance Review') {
                return "Please confirm whether you want to proceed anyway, acknowledging that the compliance review is still pending.";
            } else {
                return "Kindly provide the reason for rejection. This will be sent to the investor in the mail informing about the rejection of the subscription request."
            }
        }

        // After the first confirmation click, show the new confirmation text
        return RejectionText;
    }

    const rejectClickLabel = () => {
        if (secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD && CurrStatus === 'Under Compliance Review' && RejectionText === '') {
            return "Proceed with rejection"
        }else{
            return "Reject"
        }
    }


    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
            <h6> 
            <InfoIcon className='info-i' />
           Confirmation </h6>
            </DialogTitle>
            <DialogContent>
                <p>{rejectionPopupText()}</p>
                <div className=''>
                    {
                       (secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD && CurrStatus === 'Under Compliance Review'  ?  !isFirstConfirmedRejection : true) && <ALTTextField
                        name="reason"
                        value={reason}
                        multiline={true}
                        rows={4}
                        onChange={(name, value) => handleChange(name, value)} />
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <div className='space-between'>
                    <div>
                        <ActionButton className="btn-primary" variant='outlined' icon={<CancelIcon />} onClick={onClose} label='Cancel' />
                    </div>
                    <div className='margin-left-10'>
                        <ActionButton
                            label={rejectClickLabel()}
                            loading={rejectBtnLoading}
                            icon={<CheckCircleIcon />}
                            className="btn-primary"
                            variant="contained"
                            onClick={onConfirmClick} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}
export default SubscriptionRejectionPopup;