import { Tooltip } from "bootstrap";
import React from "react";

export default function SiteInvestingDetails() {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          Premier digital platform for alternative investing
        </div>
        <div className="row">
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title fs-6 mb-0">Members</h5>
                    <span className="h3 mb-0 info-box-number dummydata-yellow" title="Dummy Data">20,000</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title fs-6 mb-0">Committed Capital</h5>
                    <span className="h3 mb-0 info-box-number dummydata-yellow" title="Dummy Data">$2.6B</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title fs-6 mb-0">Countries</h5>
                    <span className="h3 mb-0 info-box-number dummydata-yellow" title="Dummy Data">24</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title fs-6 mb-0" >Funds to Date</h5>
                    <span className="h3 mb-0 info-box-number dummydata-yellow" title="Dummy Data">75</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
