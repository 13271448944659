import React, { useEffect, useState } from 'react'
import { HeaderTemplates, PageHeader } from '../../../common/PageHeader/PageHeader'
import { ToastContainer } from 'react-toastify'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save';
import StepContainer from '../StepContainer';
import { useLocation } from 'react-router-dom';
import { questionPatterns, stepsToShowWhile, subscriptionValidateFields } from '../../services/apiDataTemplate';
import { downloadOfflineSubscriptionForm, getFundSubscriptionDetails, SubscribeFund } from '../../services/services';
import { subscriptioToastMessages } from '../../../../utils/AppConstants';
import { toast } from 'react-toastify';
import { fundSubscriptionStatues } from '../../../CODashboard/Services/apiDataTempate';
import DownloadIcon from '@mui/icons-material/Download';
import Configurations from '../../../../Configurations';
import { useNavigate } from 'react-router-dom';


function FmOfflineSubscriptionPageMain() {

    //#region variables
    const location = useLocation();
    const { state } = location;
    const navigate = useNavigate();
    const offlineSubscriberDetails = state.selectedRow;
    const [investorSubscriptionDetails, setInvestorSubscriptionDetails] = useState();
    const [sbtLoading, setSbtLoading] = useState(false);
    const investorId = offlineSubscriberDetails.investorID
    const subscriptionID = offlineSubscriberDetails.subscriptionID;
    const fundID = offlineSubscriberDetails.fundID
    const fundTypeID = offlineSubscriberDetails?.fundTypeID
    const userInvestorTypeId = offlineSubscriberDetails?.userInvestorTypeId

    //#region service calls
    const getSubscriptionDetails = async () => {
        const data = await getFundSubscriptionDetails(fundID, investorId, subscriptionID);
        if (data.responseCode === 200) {
            setInvestorSubscriptionDetails(data.responseData);
            console.log('response', data.responseData)
        }
        else {
            setInvestorSubscriptionDetails(null);
        }
    }

    const submitSubscriptionAnswers = async (questionsDetails) => {
        const requestedData = questionsDetails
            /* ?.filter((question) =>
              (question.userProvidedAnswer !== null && question.userProvidedAnswer !== "") ||
              (question.multiChoiceQuestionAnswerIDs !== null && question.multiChoiceQuestionAnswerIDs.length > 0) ||
              (question.questionAnswerID !== null && question.questionAnswerID !== 0)) */
            ?.map((item) => ({
                "UserProfileID": item?.subscriptionID === 0 ? 0 : item?.userProfileID,
                "QuestionBankID": item?.questionBankID,
                "QuestionName": item?.questionName,
                "UserID": investorId,
                "fundID": 0,
                "SubscriptionID": item?.subscriptionID || 0,
                "QuestionPatternTypeID": item?.questionPatternTypeID,
                "QuestionPatternType": item?.questionPatternType,
                "QuestionAnswerID": item?.questionAnswerID || 0,
                "UserProvidedAnswerinText": item.questionPatternType === questionPatterns.FILE_UPLOAD
                    ? (item?.userProvidedAnswer?.name || "")
                    : item.questionPatternType === questionPatterns.GRID
                        ? (JSON.stringify(item?.userProvidedAnswer) || "")
                        : item?.userProvidedAnswer || "",
                "multiChoiceQuestionAnswerIDs": item?.multiChoiceQuestionAnswerIDs || [],
                /* "UploadedFile": item?.uploadedFile || '', */
            })) || [];

        const formData = new FormData();

        const filteredFileQuestions = questionsDetails ?? questionsDetails
            ?.filter((question) => {
                return question.questionPatternType === questionPatterns.FILE_UPLOAD;
            });

        if (filteredFileQuestions) {
            for (const question of filteredFileQuestions) {
                const userProvidedAnswer = question?.userProvidedAnswer;
                if (userProvidedAnswer instanceof Blob || userProvidedAnswer instanceof File) {
                    formData.append("fileList", userProvidedAnswer, `${question.questionBankID}/${userProvidedAnswer.name}`);
                }
            }
        }
        formData.append("userProvidedAnswers", JSON.stringify(requestedData))

        const capitalCommited = requestedData?.find((question) => question.QuestionName === subscriptionValidateFields.COMMITED_CAPITAL)?.UserProvidedAnswerinText

        const data = await SubscribeFund(fundID, fundTypeID, investorId, subscriptionID, capitalCommited, true, true, userInvestorTypeId, formData);
        if (data.responseCode === 200) {
            // toast.success(subscriptioToastMessages.SUBSCRIPTION_SUCESSFUL,
            //     { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            if (investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.INVESTOR_SIGN_PENDING
                || investorSubscriptionDetails?.canInvestorEdit) {
                var inputUrl = data.responseData?.investorDocuSignURL?.toString();
                if (inputUrl) {
                    window.location.href = inputUrl;
                }
                else {
                    toast.error(subscriptioToastMessages.INVESTOR_SIGN_FAILED,
                        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                }
            }

            if (investorSubscriptionDetails?.subscriptionStatusName !== fundSubscriptionStatues.INVESTOR_SIGN_PENDING
                || !investorSubscriptionDetails?.canInvestorEdit) {
                getSubscriptionDetails();
                setSbtLoading(false);
                navigate("/investor-relations/manual-subscription");
                toast.success(subscriptioToastMessages.SUBSCRIPTION_SUCESSFUL,
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
        }
        else {
            setSbtLoading(false);
            getSubscriptionDetails();
            if (investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.INVESTOR_SIGN_PENDING) {
                toast.error(subscriptioToastMessages.INVESTOR_SIGN_FAILED,
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
            else {
                toast.error(subscriptioToastMessages.SUBSCRIPTION_FAILED,
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
        }
    }


    //#region useeffect
    useEffect(() => {
        getSubscriptionDetails();
    }, [])

    const onSubmitSubscription = () => {
        setSbtLoading(true)
    }
    const offlineSubscriptionDownloadClick = async () => {
        try {
            // Show loading state if needed
            // setSbtLoading(true);

            const data = await downloadOfflineSubscriptionForm(subscriptionID);

            if (data.responseCode === 200) {
                const contentDisposition = data.headers['content-disposition'];

                // Extract the filename using regex
                const filenameMatch = contentDisposition && contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                let filename = 'downloaded_file.docx'; // Fallback filename

                if (filenameMatch && filenameMatch[1]) {
                    filename = filenameMatch[1].replace(/['"]/g, '');
                }

                // Handle different types of response data
                let responseData;
                if (data.responseData instanceof ArrayBuffer) {
                    responseData = new Uint8Array(data.responseData);
                } else if (typeof data.responseData === 'string') {
                    responseData = new TextEncoder().encode(data.responseData);
                } else {
                    responseData = data.responseData; // Assuming it's already a valid format for Blob
                }

                // Convert response data to a Blob
                // Create a download link
                const blobUrl = URL.createObjectURL(responseData);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = filename;

                // Append link to the body and trigger click
                document.body.appendChild(link);
                link.click();

                // Cleanup
                link.remove();
                URL.revokeObjectURL(blobUrl);
            } else {
                console.error('Failed to download file, response code:', data.responseCode);
            }
        } catch (error) {
            console.error('Error during file download:', error);
        } finally {
            // Hide loading state if needed
            // setSbtLoading(false);
        }
    };


    // function downloadFile() {
    //     fetch(`${Configurations.apiBaseUri}/v1/FundSubscription/DownloadOfflineSubscription?subscriptionID=${subscriptionID}`, {
    //         method: 'GET',
    //         headers: {
    //          'content-Type': 'application/octet-stream',
    //         'Access-Control-Allow-Origin': '*'
    //         },
    //       })
    //         .then(response => {
    //             debugger
    //           // Try to extract the Content-Disposition header
    //           const contentDisposition =  response.headers['content-disposition']

    //           if (contentDisposition) {
    //             // Process to extract filename
    //             const filenameMatch = contentDisposition.match(/filename\*?=([^;]+)/);
    //             let filename = 'downloaded_file';
    //             if (filenameMatch) {
    //               filename = decodeURIComponent(filenameMatch[1].replace(/['"]/g, '').replace('UTF-8\'\'', ''));
    //             }

    //             return response.blob().then(blob => {
    //               const url = window.URL.createObjectURL(blob);

    //               const link = document.createElement('a');
    //               link.href = url;
    //               link.download = filename;

    //               document.body.appendChild(link);
    //               link.click();

    //               link.remove();
    //               window.URL.revokeObjectURL(url);
    //             });
    //           } else {
    //             console.error('Content-Disposition header not found.');
    //           }
    //         })
    //         .catch(error => console.error('Download failed:', error));
    //   }
    return (
        <div>
            <PageHeader
                title="Offine Subscription"
                template={HeaderTemplates.NORMAL_VIEW} />
            <div className='wrapper white-card child-margin-15 margin-top-minus-20 margin-bottom-70'>
                <div className='twobuttonsrow'>
                    <div>
                    </div>
                    <div><LoadingButton
                        variant='contained'
                        size="large"
                        loading={sbtLoading}
                        startIcon={<DownloadIcon />}
                        //disabled={disableSbtBtn}
                        onClick={offlineSubscriptionDownloadClick}
                        loadingPosition="start">
                        <p className={`actionButtonLabel`}>DOWNLOAD FILE</p>
                    </LoadingButton></div>
                </div>
                <div>
                    <div><StepContainer investorId={offlineSubscriberDetails.investorID}
                        type={stepsToShowWhile.OFFLINE_SUBSCRIPTION}
                        fundID={offlineSubscriberDetails.fundID}
                        subscriptionId={offlineSubscriberDetails.subscriptionID}
                        investorSubscriptionDetails={investorSubscriptionDetails}
                        getQuestionAnswerDetails={getSubscriptionDetails}
                        sbtLoading={sbtLoading}
                        submitSubscriptionAnswers={submitSubscriptionAnswers}
                        setSbtLoading={setSbtLoading}
                    // setDisableSbtButton={setDisableSbtButton}
                    // submitSubscriptionAnswers={submitSubscriptionAnswers}
                    /></div>
                    <div className='space-between margin-top-20 margin-bottom-10'>
                        <div></div>
                        {
                            investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.Awaiting_Online_Processing && <div className='child-row-margin-5'>
                                <LoadingButton
                                    variant='contained'
                                    size="large"
                                    loading={sbtLoading}
                                    startIcon={<SaveIcon />}
                                    //disabled={disableSbtBtn}
                                    onClick={onSubmitSubscription}
                                    loadingPosition="start">
                                    <p className={`actionButtonLabel`}>SUBMIT</p>
                                </LoadingButton>
                            </div>
                        }

                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default FmOfflineSubscriptionPageMain