// import React from 'react';
import React, { useState } from 'react';
import './HelpPage.css'
import HelpAndSupportTopBar from './helpAndSupportTopBar';
import HelpAndSupportHeader from './HelpAndSupportHeader';
import HelpAndSupportBody from './HelpAndSupportBody';
import HelpAndSupportFooter from './HelpAndSupportFooter';
import HelpAndSupportFAQ from './HelpAndSupportFAQ';
import HelpAndSupportArticles from './HelpAndSupportArticles';
import CircleIcon from '../chatbot/Icon';
import VideosPage from './VideosPage';

const HelpPage = () => {

  const [activeBodySection, setActiveBodySection] = useState(null); // 'body' is the default section
  const [chatopen, setChatOpen] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState(
    {
      "url": "",
      "isFullScreen": false
    }
  );

  const renderSection = () => {
    switch (activeBodySection) {
      case 'faq':
        return <HelpAndSupportFAQ setActiveBodySection={setActiveBodySection} />;
      case 'articles':
        return <HelpAndSupportArticles setActiveBodySection={setActiveBodySection} />;
      case 'videos':
        return <VideosPage setActiveBodySection={setActiveBodySection} selectedVideo={selectedVideo} setSelectedVideo={setSelectedVideo} />;
      default:
        return <HelpAndSupportBody setActiveBodySection={setActiveBodySection} selectedVideo={selectedVideo} setSelectedVideo={setSelectedVideo} />;
    }
  };

  const onIconClick = () => {
    setChatOpen(true)
  }

  return (
    <div className="help-page-container">
      <div className='help-page-content'>
        <HelpAndSupportTopBar setActiveBodySection={setActiveBodySection} />
        {(activeBodySection === "faq" || activeBodySection === "articles" || activeBodySection === "videos")
          ? "" : <HelpAndSupportHeader setActiveBodySection={setActiveBodySection} onIconClick={onIconClick} />}
        {renderSection()}
      </div>
      <div className='help-page-footer'><HelpAndSupportFooter /></div>
      {
        chatopen ? <div>
        <CircleIcon chatopen={chatopen} setChatOpen={setChatOpen} />
      </div> :  <div>
        <CircleIcon chatopen={chatopen} setChatOpen={setChatOpen} />
      </div>
      }
    </div>
  )
}


export default HelpPage;
