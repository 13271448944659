import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { uploadFileTypes } from '../FileUploadButton/uploadComponentenum';
import '../FileUploadButton/styles.css';

const FileUploadField = (props) => {
  //#region props
  const {
    name,
    label,
    onChange,
    value,
    variant,
    required,
    disabled,
    error,
    errorMessage,
    readOnly,
    fileType,
  } = props;

  //#region variables
  const [file, setFile] = useState(value || null);

  //#region change events
  const handleChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleClearFile = () => {
    setFile(null);
    if (onChange) {
      onChange(name, null); // Notify parent component about the cleared file
    }
  };

  //#region useeffect
  useEffect(() => {
        onChange(name, file);
  },[file])

  useEffect(() => {
    setFile(value);
  },[value])

  //#region render functions
  const renderFilePreview = () => {
    if (file) {
      return (
        <div className='fileUploadBtn previewWrapper'>
          {fileType === uploadFileTypes.IMAGE && file && (
            <img
              src={URL.createObjectURL(file)} // Use object URL for image preview
              alt='Preview'
              className='file-preview'
            />
          )}
          <div className='upload-preview'>{file.name}</div>
          {!readOnly && <CloseOutlinedIcon onClick={handleClearFile} />}
        </div>
      );
    }
  };

  const renderFileButton = () => {
    const classes = (disabled || readOnly)
      ? error
        ? 'filebtn-disabled-error'
        : 'filebtn-disabled'
      : error
      ? 'fileUploadBtn file-error'
      : 'fileUploadBtn';

    return (
        <div className='display-column'>
        <div className={classes}>
          <input
            type='file'
            id={name}
            name={name}
            onChange={handleChange}
            disabled={disabled || readOnly}
            required={required}
            style={{ display: 'none' }} // Keep it hidden visually
          />
          <CloudUploadIcon />
          <label htmlFor={name} className='upload-label'>
            {label || 'Upload File'}
          </label>
          <br />
        </div>
        {error && <span className='errorMessage'>{errorMessage}</span>}
      </div>
      
    );
  };

  //#region return
  return <>{file ? renderFilePreview() : renderFileButton()}</>;
};

FileUploadField.propTypes = {
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  required: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  readOnly: PropTypes.bool,
  fileType: PropTypes.oneOf(Object.values(uploadFileTypes)), // Ensure fileType is validated
};

FileUploadField.defaultProps = {
  variant: 'standard',
  required: false,
  value: '',
  label: '',
  disabled: false,
  name: '',
  errorMessage: '',
  helperText: '',
  readOnly: false,
};

export default FileUploadField;
