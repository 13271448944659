import React, { useState, useEffect } from 'react';
import "../FilterBox.css";
import options from '../filterbox.json';
import { useNavigate } from 'react-router-dom';
import Slider from '@mui/material/Slider';
import { getFundFilterData } from "../services/MarketplaceService"
import RadioButtonGroup from '../../common/RadioButtonGroup/RadioButtonGroup';
/* import { Box } from "@mui/material"; */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ListIcon from '@mui/icons-material/List';

const FilterBox = ({ isExpanded, Menu, setMenu, onFilterChange }) => {
  const [topPosition, setTopPosition] = useState(344);
  const navigate = useNavigate();

  // This const is to set the fundFilterData from API
  // const [fundFilterData, setFundFilterData] = useState({});

  // Fund Filter
  const [fundTypeList, setFundTypeList] = useState([]);
  const [subfundTypeList, setSubFundTypeList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [FundSubTypeList, setFundSubTypeList] = useState([]);
  const [fundManagerList, setFundManagerList] = useState([]);
  const [sorting, setSorting] = useState("");
  const [favouritesList, setFavouritesList] = useState("");

  // Creating a useState for Maximium Values
  const [maximiumValue, setMaximiumValue] = useState([1, 10000000]);
  // Creating a useState for Term Values
  const [termValue, setTermValue] = useState([1.0, 3.0]);
  // This useState is used to set the values for min and max values 
  const [initialValues, setInitialValues] = useState([12, 36]);

  const [FilterOptions, setFilterOptions] = useState();
  const [FundTypeID, setFundTypeID] = useState([]);

  const [FilteredSubTypes, setFilteredSubTypes] = useState();
  const [FundTypeOptions, setFundTypeOptions] = useState();

  const getFundFilterCall = async () => {
    const data = await getFundFilterData();
    if (data.responseCode === 200) {
      console.log(data.responseData);
      setFilterOptions(data.responseData)
    }
    else {
    }
  }

  const fetchsubtype = () => {
    if (FilterOptions && FundTypeID.length > 0) {
      const filteredSubTypes = FilterOptions.fundSubType.filter(item => FundTypeID.includes(item.fundSubTypeID));
      setFilteredSubTypes(filteredSubTypes);
    }
  };

  // this useEffect is to send the filtered data to fund pages
  useEffect(() => {
    onFilterChange({
      fundTypes: fundTypeList,
      industries: industryList,
      fundManagers: fundManagerList,
      strategies: [],
      investmentAmount: { min: maximiumValue[0], max: maximiumValue[1] },
      termDuration: { min: termValue[0], max: termValue[1] },
      sorting: sorting,
      favourites: favouritesList
    });
  }, [fundTypeList, industryList, fundManagerList, sorting, maximiumValue, termValue, favouritesList])


  useEffect(() => {
    getFundFilterCall();
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const initialTop = 399; // Initial top position
      const scrolledTop = initialTop - scrollTop; // Calculate the adjusted top position
      setTopPosition(scrolledTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [FundType, setFundType] = useState(false);
  const [FundSubType, setFundSubType] = useState(false);
  const [Industry, setIndustry] = useState(false);
  const [Strategy, setStrategy] = useState(false);
  const [fundmanager, setfundmanager] = useState(false);
  const [Minimum, setMinimum] = useState(false);
  const [Term, setTerm] = useState(false);
  const [Favourites, setFavourites] = useState(false);

  const [Sortlist, setSortlist] = useState(false);


  const toggleSlider = (slide, setslide) => {
    setFundType(false);
    setIndustry(false);
    setStrategy(false);
    setfundmanager(false);
    setMinimum(false);
    setTerm(false);
    setSortlist(false);
    setFavourites(false);
    setslide(!slide);
  };

  const handleClick = (list, setList) => {
    setFundType(false);
    setIndustry(false);
    setStrategy(false);
    setfundmanager(false);
    setMinimum(false);
    setTerm(false);
    setSortlist(false);
    setFavourites(false);

    setFundSubType(false);
    // Set the state of the current button
    setList(!list);
  };

  const [checkboxState, setCheckboxState] = useState({
    privateequity: false,
    realestate: false,
    multiassetclass: false,
    privatecredit: false,
    venturecapital: false,
    other: false,
    Computing: false,
    Energy: false,
    LifeSciences: false,
    FinancialTechnology: false,
    FoodAgriculture: false,
    Transportation: false,
    GreenTechnology: false,
    IndustrialTechnology: false,
    HealthCare: false,
    LibertyInvestments: false,
    ICIInvestments: false,
    PrimeCapital: false,
    kalyaniInvestments: false,
    NovaSavings: false,
    IconicSavings: false,
    NewesttoOldest: false,
    Oldesttonewest: false,
    Termlowtohigh: false,
    Termhightolow: false,

    Residential: false,
    Commercial: false,
    Industrial: false,
    Retail: false,
    Hospitality: false,
    Healthcare: false,
    "Mixed-use": false,
    "Venture capital": false,
    "Growth equity": false,
    "Leveraged buyouts Distressed debt": false,
    "Mezzanine financing": false,
  });

  const handleRadioButtonChange = (obj, id) => {
    setSorting(id);
  }
  const handleCheckboxChange = (id, name, type) => {
    setCheckboxState((prevState) => {
      const updatedState = { ...prevState };
      updatedState[id] = !prevState[id];
      return updatedState;
    });

    if (type === "Fund Type") {
      if (!fundTypeList.includes(name)) {
        setFundTypeList([...fundTypeList, name]);
      }
      else {
        const updatedStrings = fundTypeList.filter((str) => str !== name);
        setFundTypeList(updatedStrings);
      }
    }
    if (type === "Industry") {
      if (!industryList.includes(name)) {
        setIndustryList([...industryList, name]);
      }
      else {
        const updatedStrings = industryList.filter((str) => str !== name);
        setIndustryList(updatedStrings);
      }
    }
    if (type === "Fund Manager") {
      if (!fundManagerList.includes(name)) {
        setFundManagerList([...fundManagerList, name]);
      }
      else {
        const updatedStrings = fundManagerList.filter((str) => str !== name);
        setFundManagerList(updatedStrings);
      }
    }
    if (type = "") {

    }
  };

  const handleCheckboxFundType = (id, name, type) => {
    setCheckboxState((prevState) => {
      const updatedState = { ...prevState };
      updatedState[id] = !prevState[id];
      return updatedState;
    });


    if (FundTypeID.includes(parseInt(id)) || (id === 0 && FundTypeID.includes(0))) {
      setFundTypeID(prevFundTypeID => prevFundTypeID.filter(item => item !== parseInt(id)));
    }

    else {
      setFundTypeID(prevFundTypeID => [...prevFundTypeID, parseInt(id)]);
    }


    if (type === "Fund Type") {
      if (!fundTypeList.includes(name)) {
        setFundTypeList([...fundTypeList, name]);
      }
      else {
        const updatedStrings = fundTypeList.filter((str) => str !== name);
        setFundTypeList(updatedStrings);
      }
    }
    if (type === "Industry") {
      if (!industryList.includes(name)) {
        setIndustryList([...industryList, name]);
      }
      else {
        const updatedStrings = industryList.filter((str) => str !== name);
        setIndustryList(updatedStrings);
      }
    }
    if (type === "Fund Manager") {
      if (!fundManagerList.includes(name)) {
        setFundManagerList([...fundManagerList, name]);
      }
      else {
        const updatedStrings = fundManagerList.filter((str) => str !== name);
        setFundManagerList(updatedStrings);
      }
    }
    if (type = "") {

    }
    if (type === "Favourites") {
      if (!favouritesList.includes(name)) {
        setFavourites([...favouritesList, name]);
      }
      else {
        const updatedStrings = favouritesList.filter((str) => str !== name);
        setFavouritesList(updatedStrings);
      }
    }
  };

  useEffect(() => {
    if (FundType != null) {
      console.log(FundTypeID);
      setFilteredSubTypes([]);
      fetchsubtype();
    }
  }, [FundTypeID, FundType]);



  const handleReset = (optionsArray, list, setList) => {
    setCheckboxState((prevState) => {
      const updatedState = { ...prevState };
      optionsArray.forEach((item) => {
        updatedState[item.id] = false;
      });
      return updatedState;
    });

    setFundTypeID([]);

    if (FundType) { setFundTypeList([]); setFundTypeID([]); }
    if (Industry) { setIndustryList([]); }
    if (fundmanager) { setFundManagerList([]); }
    if (Sortlist) { setSorting(""); }
    if (FundSubType) { setFundSubTypeList(); }
    setList(!list);
  };

  const handleResetSubType = (optionsArray, list, setList) => {
    setCheckboxState((prevState) => {
      const updatedState = { ...prevState };
      optionsArray.forEach((item) => {
        updatedState[item.fundSubTypeName] = false;
      });
      return updatedState;
    });

    if (Industry) { setIndustryList([]); }
    if (fundmanager) { setFundManagerList([]); }
    if (Sortlist) { setSorting(""); }
    if (FundSubType) { setFundSubTypeList(); }
    setList(!list);
  };

  const handleMinimumReset = (list, setList) => {
    setMaximiumValue([5, 10]);
    setList(!list);
  };

  const handleTermReset = (list, setList) => {
    setTermValue([1, 2]);
    setList(!list);
  };

  const StrategyReset = (list, setList) => {
    setList(!list);
  };


  const compare = () => {
    if (localStorage.getItem('selectedItems').length <= 1) {
      alert("Please select atleast two funds to compare")
    }
    else {
      navigate('/compare');
      window.scrollTo(0, 0);
    }
  };
  const favorite = () => {
    navigate('/favourite-page');
    window.scrollTo(0, 0);

  };

  // this event will trigger when maximium value changes
  const handleMaximiumChange = (event, newValue) => {
    setMaximiumValue(newValue);
  };

  // this event will trigger when term value changes
  // const handleTermChange = (event, newValue) => {
  //   setTermValue(newValue);
  //   setInitialValues(newValue);
  // };

  // this event will trigger to calculate the min and max value years based on value entered in months 
  const onTermTextChange = (event, destination) => {
    destination === "min" 
    ? setInitialValues([event.target.value, initialValues[1]]) 
    : setInitialValues([initialValues[0], event.target.value]);

    var minValue = (initialValues[0] / 12 | 0) + "." + initialValues[0] % 12;
    var maxValue = (initialValues[1] / 12 | 0) + "." + initialValues[1] % 12;
    var presentValue = (event.target.value / 12 | 0) + "." + event.target.value % 12;
    
    destination === "min" 
    ? setTermValue([presentValue, maxValue])
    : setTermValue([minValue, presentValue]);
  }

  const handleFavoriteClick = () => {
    // Handle the logic for the Favorite button click
    // For example, navigate to the favorite page
    navigate('/favorite-page');
  };

  //css for buttons
  const iconStyle = { width: '25px', height: '25px',marginTop: '-4px',marginLeft: '4px'}

  return (
    <div
      className={
        isExpanded
          ? "filterbox filterbox-NX"
          : "filterbox"
      }
    >
      {/* {console.log(isExpanded)} */}
      {/* {console.log(data.fundtype[0].name)} */}
      <div className='phase1'>
        <button onClick={() => {}} className={FundType
          ? "buttons buttons-NX"
          : "buttons"}><span className={
            isExpanded
              ? "type-text type-text-NX"
              : "type-text"
          }>Fund Type</span><span className='icon'><ArrowDropDownIcon sx={iconStyle} /></span></button>

        {FundType &&
          <div className="ul-options">
            <p className='reset' onClick={() => {}}>RESET</p>
            <p className='matter'>Select the fundtype you would like to invest in.</p>
            <ul className='ul-options-button-1'>
              {console.clear()}
              {console.log(FilterOptions?.fundType[0].fundTypeName)}
              {FilterOptions?.fundType.map((item) => (
                <li key={item.fundTypeID}>
                  <input
                    type="checkbox"
                    className="my-checkbox"
                    id={item.fundTypeID}
                    name={item.fundTypeName}
                    checked={checkboxState[item.fundTypeID]}
                    onChange={() => {}}
                  />
                  <label htmlFor={item.fundTypeID}>{item.fundTypeName}</label>
                </li>
              ))}

            </ul>
          </div>
        }

        <button onClick={() => {}} className={FundSubType
          ? "buttons buttons-NX"
          : "buttons"}><span className={
            isExpanded
              ? "type-text type-text-NX"
              : "type-text"
          }>Fund SubType</span><span className='icon'><ArrowDropDownIcon sx={iconStyle} /></span></button>

        {FundSubType && (
          //  <div className="ul-options-button-subtype" style={{ top: `${topPosition}px` }}>
          <div className="ul-options-button-subtype">
            <p className='reset' onClick={() => {}}>RESET</p>
            <p className='matter'>Select the fund subtype you would like to invest in.</p>
            <ul className='li-container ul'>
              {FilteredSubTypes && FilteredSubTypes.length > 0 ? (
                FilteredSubTypes?.map((item) => (
                  <li key={item.fundSubTypeID}>
                    <input
                      type="checkbox"
                      className="my-checkbox"
                      id={item.fundSubTypeID}
                      name={item.fundSubTypeName}
                      checked={checkboxState[item.fundSubTypeName]}
                      onChange={() => {}}
                    />
                    <label htmlFor={item.fundSubTypeID}>{item.fundSubTypeName}</label>
                  </li>
                ))
              ) : (
                <p className='subfundtypeText'>Select a fund type</p>
              )}
            </ul>
          </div>
        )}

        <button onClick={() => {}} className={Industry
          ? "buttons buttons-NX"
          : "buttons"}><span className={
            isExpanded
              ? "type-text type-text-NX"
              : "type-text"}>Industry</span><span className='icon'><ArrowDropDownIcon sx={iconStyle} /></span></button>
        {Industry &&
          <div className="ul-options-button-2">
            <p className='reset' onClick={() => {}}>RESET</p>
            <p className='matter'>Select the industry type</p>
            <ul className='li-container ul'>
              {FilterOptions?.industry.map((item) => (
                <li key={item.fundTargetIndustryID}>
                  <input
                    type="checkbox"
                    className="my-checkbox"
                    id={item.fundTargetIndustryID}
                    name={item.targetIndustry}
                    checked={checkboxState[item.fundTargetIndustryID]}
                    onChange={() => {}}
                  />
                  <label htmlFor={item.fundTargetIndustryID}>{item.targetIndustry}</label>
                </li>
              ))}
            </ul>
          </div>

        }
        <button onClick={() => {}} className={Strategy
          ? "buttons buttons-NX"
          : "buttons"}><span className={
            isExpanded
              ? "type-text type-text-NX"
              : "type-text"}>Investment Strategy</span><span className='icon'><ArrowDropDownIcon sx={iconStyle} /></span></button>
        {Strategy &&
          <div className="ul-options-left-3">
            <p className='reset' onClick={() => {}}>RESET</p>
            <p className='matter'>Select your preferred investment strategy</p>
            <ul className='li-container ul'>
              {FilterOptions?.strategy.map((option) => (
                <li
                  key={option.investmentStrategyID}
                  className='Stategy-button'
                  onClick={() => {}}
                >
                  {option.strategyName} <br />
                  <span className='startegy-matter'>{option.strategyDescription}</span>
                </li>
              ))}
            </ul>
          </div>
        }
        <button onClick={() => {}} className={Minimum
          ? "buttons buttons-NX"
          : "buttons"}><span className={
            isExpanded
              ? "type-text type-text-NX"
              : "type-text"}>Minimum Investment</span><span className='icon'><ArrowDropDownIcon sx={iconStyle} /></span></button>
        {Minimum &&
          <div className="ul-options-left-4">
            <p className='reset' onClick={() => handleMinimumReset(Minimum, setMinimum)}>RESET</p>
            <p className='matter'>Select your minimum investment amount</p>
            <p className='sliderData'>{`$${maximiumValue[0]?.toLocaleString()} - $${maximiumValue[1]?.toLocaleString()}`}</p>
            <div style={{ marginTop: '50px', marginLeft: '30px', marginRight: '20px' }}>
              <Slider
                value={maximiumValue}
                onChange={handleMaximiumChange}
                min={1}
                max={100000000}
                valueLabelDisplay="off"
              />
            </div>
          </div>
        }
        <div>
        <button onClick={() => {}} className={Term
          ? "buttons buttons-NX"
          : "buttons"}> <span className={
            isExpanded
              ? "type-text type-text-NX"
              : "type-text"}>Term</span><span className='icon'><ArrowDropDownIcon sx={iconStyle} /></span></button>
        {Term &&
          <div className="ul-options-left-5">
            <p className='reset' onClick={() => handleTermReset(Term, setTerm)}>RESET</p>
            <p className='matter'>Select your term duration in months</p>
            <p className='sliderData'>{`${termValue[0]} to ${termValue[1]} years`}</p>
            <br/>
            <div className='term-duration'>
              min : <input type='text' value={initialValues[0]} onChange={(event) => { onTermTextChange(event, 'min') }} />
              max : <input type='text' value={initialValues[1]} onChange={(event) => { onTermTextChange(event, 'max') }} />
              {/* <Slider
                value={termValue}
                onChange={handleTermChange}
                // min={1}
                // max={10}
                valueLabelDisplay="off"
              /> */}
            </div>
          </div>
         
        }
 </div>

      </div>
      <div className='phase2'>
        {/* New Favorite button */}
        <button onClick={() => {}} className='buttons'>
          <span className={isExpanded ? "type-text type-text-NX"
            : "type-text"}>
            Favourites
          </span>
          < img src='/MarketPlaceImages/favouriteicon1.svg' alt='dropdownicon' className='icon' />

        </button>
        <button onClick={() => {}} className='buttons'><span className={
          isExpanded
            ? "type-text type-text-NX"
            : "type-text"}>Compare</span> <img src='/icon-materialcomparearrows.svg' alt='dropdown icon' className='icon' /></button>
        <button onClick={() => {}}className={Sortlist
          ? "buttons buttons-NX"
          : "buttons"}>
          <span className={
            isExpanded
              ? "type-text type-text-NX"
              : "type-text"}>Sort</span><img src={Sortlist ? 'icons/sort-icon.svg' : '/group-319.svg'} alt='dropdown icon' className='icon' /></button>
        {Sortlist &&
          <div className="ul-options-button-7">
            <p className='reset' onClick={() => handleReset(options.SortOptions, Sortlist, setSortlist)}>RESET</p>
            {/* <RadioButtonGroup data={options?.SortOptions} handleRadioButtonChange={handleRadioButtonChange} /> */}
          </div>
        }
        <button onClick={() => {}} className='buttons listview-button'> <ListIcon style={{width: '20px',height: '20px',marginTop: '-6px'}} /></button>
      </div>
    </div>
  );
};

export default FilterBox;
