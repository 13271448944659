import React, { forwardRef, useImperativeHandle } from 'react';
import { validationsConstants } from '../../../../utils/AppConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import SelectField from '../../../common/input-fields/SelectField';
import ALTTextField from '../../../common/input-fields/ALTTextField';

const FundType = forwardRef((props, ref) => {

  //#region props
  const { fundDetails, setFundDetails, fundTypes } = props;

  //#region intialData
  const initialData = {
    fundTypeID: fundDetails?.fundTypeID || 0,
    fundName: fundDetails?.fundName || '',
    fundTypeName: fundDetails?.fundTypeName || '',
  };


  const handleChange = (field, value) => {
    if (field === "fundTypeID") {
      const currFundType = fundTypes.find(u => u.value === value)?.label;
      fundTypeDetails.setFieldValue("fundTypeName", currFundType)
    }
    fundTypeDetails.setFieldValue(field, value);
  }

  //#region formik validations
  const validationSchema = yup.object().shape({
    fundTypeID: yup.number()
      .min(1, validationsConstants.REQUIRED)
      .required(validationsConstants.REQUIRED),
    fundName: yup
      .string()
      .required(validationsConstants.REQUIRED),
  });

  const fundTypeDetails = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return true;
    },
  });

  //#region imperative handle
  useImperativeHandle(ref, () => ({
    validateFields: async () => {
      const errors = await fundTypeDetails.validateForm();
      if (Object.keys(errors).length === 0) {
        //need to update main set of values
        setFundDetails((prevValues) => {
          return { ...prevValues, ...fundTypeDetails?.values };
        });
        return true;
      }
      else {
        fundTypeDetails?.handleSubmit()
      }
    },
    updateFields: async () => {
      setFundDetails((prevValues) => {
        return { ...prevValues, ...fundTypeDetails?.values };
      });
    }
  }));

  //#region return
  return (
    <div className='display-row-items-flex'>
      <div className='width-48'>
        <SelectField
          name="fundTypeID"
          label="Fund Type"
          value={fundTypeDetails?.values?.fundTypeID}
          onChange={(name, value) => handleChange(name, value)}
          options={fundTypes?.filter((item) => item.value !== -1)}
          required={true}
          onBlur={fundTypeDetails.handleBlur}
          error={fundTypeDetails.touched.fundTypeID && Boolean(fundTypeDetails.errors.fundTypeID)}
          errorMessage={fundTypeDetails.touched.fundTypeID && fundTypeDetails.errors.fundTypeID} />
      </div>
      <div className='width-48'>
        <ALTTextField
          name="fundName"
          label="Fund Name"
          value={fundTypeDetails?.values?.fundName}
          onChange={(name, value) => handleChange(name, value)}
          required={true}
          onBlur={fundTypeDetails.handleBlur}
          error={fundTypeDetails.touched.fundName && Boolean(fundTypeDetails.errors.fundName)}
          errorMessage={fundTypeDetails.touched.fundName && fundTypeDetails.errors.fundName} />
      </div>
    </div>
  );
});

export default FundType;