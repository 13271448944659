import styles from "./InvestorOverView.module.css";
import { useState } from "react";
import AIPDataGrid from "../../common/DataGrid/AIPDataGrid";
import React from 'react';
import { useEffect } from "react";
import { CurrencyFormatter } from "../../../utils/Formater";
import FundNews from "../../common/FundNews/FundNews";
import ChartSelector from "../../common/DataChart/ChartSelector";
import CircleIcon from "../../chatbot/Icon";
import secureLocalStorage from "react-secure-storage";
import VDRoom from "../../common/VirtualDataRoom/VDRoom";
import DashboardSelect2 from "../../common/Select/DashboardSelect2";
import { getFundByInvestorId, } from "../services/DashboardService";
import DashboardSelect from "../../common/Select/DashboardSelect";
import { HeaderTemplates, PageHeader } from "../../common/PageHeader/PageHeader";
import { getrootBackgroundColorConfig } from "../../../state/slices/colorConfigSlice";
import { useSelector } from "react-redux";
import CurrencyIndicator from "../../common/DataGrid/CurrencyIndicator";
import { AppDashboards } from "../../../utils/enum";
import { formatAsUSACurrency } from "../../common/CurrencyFormater/formatAsUSACurrency";
import { useLocation } from "react-router-dom";
import { getFundTransactions } from "../../common/FundTransactions/services";
import { getSuggestedArticlesByFundID } from "../../FundManagerDashboard/Services/FundNewsService";

const InvestorOverview = (props) => {
  const moment = require('moment');
  const location = useLocation();
  const fundId = secureLocalStorage.getItem("FundId");
  const userId = secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD ? secureLocalStorage.getItem("userId") : location?.state?.userId?.itemId;
  const [selectedChart, setSelectedChart] = useState();
  const [fund, setFund] = useState();
  const [fundtransactions, setFundTransactions] = useState();
  const [selectedFundYear, setSelectedFundYear] = useState('');
  const [selectedTransactionsYear, setSelectedTransactionsYear] = useState('');
  const [selectedPerformanceYear, setSelectedPerformanceYear] = useState('');
  const years = [
    { id: 1, label: '2020', value: 2020 },
    { id: 2, label: '2021', value: 2021 },
    { id: 3, label: '2022', value: 2022 },
    { id: 4, label: '2023', value: 2023 },
  ];

  const handleChange = (event) => {
    setSelectedChart(event.target.value);
  };

  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);

  const handleFundYearChange = (year) => {
    setSelectedFundYear(year);
  };
  const handleTransactionsYearChange = (year) => {
    setSelectedTransactionsYear(year);
  };
  const handlePerformanceYearChange = (year) => {
    setSelectedPerformanceYear(year);
  };

  const FundTransactionsRowsData = fundtransactions ? fundtransactions.map(transaction => ({
    id: transaction.fundID,
    Type: transaction.transactionType,
    Name: transaction.fundName,
    InvestorName: transaction.investorName,
    Amount: transaction.transactionAmount,
    Date: moment(transaction.transactionDate).format('MM-DD-YYYY'),

  })) : [];

  const keyMapping = {
    totalCommittedFund: 'Total Commitments to Fund',
    calledCapital: 'Cumulative Paid in Capital',
    calledCapitalInPercentage: 'Capital In Percentage',
    distributedCapital: 'Cumulative Distributions',
    numberOfExits: 'Number of Exits',
    nav: 'Total Net Asset Value (NAV)',
    gav: 'Gross Asset Value',
    grossIRR: 'Gross IRR',
    grossMultipleToCost: 'Gross Multiple to Cost',
    fundNetIRR: 'Fund Net IRR (after accrual for carried interest and fees)',
    distributionPaidIn: 'Distributions to Paid in Capital (DPI)',
    residualValuePaidIn: 'Residual Value to Paid in Capital (RVPI)',
    totalValuePaidIn: 'Total value to Paid in Capital (TVPI)',
    multipleOnInvestedCapital: 'Multiple of Invested Capital (MOIC)',
    fundedToCommittedRatio: 'Funded Commitment to Committed Capital',
    paidInCapitalToCommittedCapital: 'Paid in Capital to Committed Capital (PICC)',
    totalInvestedInPortfolioCompanies: 'Total Invested In Portfolio Companies',
    additionalCommitmentsToPortfolioCompanies: 'Additional Commitments To Portfolio Companies',
  };

  const [firstSlice, setFirstSlice] = useState([]);
  const [secondSlice, setSecondSlice] = useState([]);

  useEffect(() => {
  }, [selectedPerformanceYear]);

  const [FundNewsData, setFundNewsData] = useState();

  const AccountTransactionColuns = [
    {
      "id": 1,
      "field": "Type",
      "headerName": "Type",
      "width": 105
    },
    {
      "id": 2,
      "field": "Name",
      "headerName": "Name",
      "width": 145
    },
    {
      "id": 3,
      "field": "InvestorName",
      "headerName": "Investor Name",
      "width": 115
    },
    {
      "id": 4,
      "field": "Amount",
      "headerName": "Amount",
      "width": 90,
      renderCell: (params) => {
        return <CurrencyIndicator params={params} />
      }
    },
    {
      "id": 5,
      "field": "Date",
      "headerName": "Date",
      "width": 90
    }
  ]

  const PerformanceGridColumns = [
    {
      "id": 1,
      "field": "Data",
      "headerName": "",
      "width": 370
    },
    {
      "id": 2,
      "field": "Committed Capital",
      "headerName": "",
      "width": 150,
      renderCell: (params) => {
        const { field, value } = params;
        let formattedValue = value;
        if (['calledCapitalInPercentage', 'numberOfExit', 'grossIRR', 'fundNetIRR'].includes(field) && value !== null) {
          formattedValue = value + '%';
        } else if (field === 'yearNumber') {
          formattedValue = value || '-';
        }

        return (
          <span>
            {formattedValue}
          </span>
        );
      }
    }
  ];

  const FundStats = fund ? [
    {
      primaryLabel: 'Total Capital Committed',
      primaryValue: formatAsUSACurrency(fund.totalCapitalCallAmount !== null ? fund.totalCapitalCallAmount : 0),
      secondaryLabel: '',
      secondaryValue: ''
    },
    {
      primaryLabel: 'Target Raise',
      primaryValue: formatAsUSACurrency(fund.fundSize !== null ? fund.fundSize : 0),
      secondaryLabel: '',
      secondaryValue: ''
    },
    {
      primaryLabel: 'Capital Remaining',
      primaryValue: formatAsUSACurrency(fund.fundSize - fund.totalCapitalCallAmount),
      secondaryLabel: '',
      secondaryValue: ''
    },
  ] : []
  console.log(fundId)
  const FundStat = fund ? [
    {
      primaryLabel: 'Total Committed',
      primaryValue: formatAsUSACurrency(fund?.capitalCommitted),
      secondaryLabel: '',
      secondaryValue: ''
    },
    {
      primaryLabel: 'Total Remaining',
      primaryValue: '',
      secondaryLabel: '',
      secondaryValue: ''
    },
    {
      primaryLabel: 'Portfolio Companies',
      primaryValue: fund.portfolioCompaniesCount ?? 0,
      secondaryLabel: '',
      secondaryValue: ''
    },
    {
      primaryLabel: 'Current IRR',
      className:"dummydata-yellow",
      Description:'Dummy Data',
      primaryValue: '18.5%',
      secondaryLabel: 'Target IRR',
      secondaryValue: `${fund.targettedIRR}%`
    },
    {
      primaryLabel: 'Goal Fund Amount',
      primaryValue: fund.goalFundAmount !== null ? fund.goalFundAmount : 0,
      secondaryLabel: '',
      secondaryValue: ''
    }
  ] : []

  const getTransactions = async (transactionType,selectedYear) => {
    const fundID= secureLocalStorage.getItem("FundId");
    const data = await getFundTransactions(fundID,'','');
    if (data.responseCode === 200) {
        setFundTransactions(data.responseData);
    }
    else {
        //console.log('unable to fetch data)
    }
  }

  useEffect(() => {
    const getFundDetails = async () => {
      const data = await getFundByInvestorId(userId, fundId);
      if (data.responseCode == 200) {
        const fundDetails = data.responseData?.find(fund => fund?.fundID === fundId);
        setFund(fundDetails);
      }
      else {
        console.error(data.error);
      }
    }
    const getFundNews = async () => {
      const data = await getSuggestedArticlesByFundID(fundId);
      if (data.responseCode === 200) {
        setFundNewsData(data.responseData);
      }
      else {
      }
    }

    
    getFundDetails();
    getFundNews();
    getTransactions();
  }, []);
  console.log("fundname", secureLocalStorage.getItem("FundName"))
  return (
    <div
    // className='mt-page' style={{ backgroundColor: rootBackColorConfig.color, height: 'auto' }}
    >
      {/* 
      <PageHeader
        template={HeaderTemplates.CLASSIC}
        primaryTitle={fund?.fundName}
        primaryImage={fund?.iconImage}
        secondaryTitle={
          secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD ?  secureLocalStorage.getItem("fullName") : 
          location?.state?.userId?.fullName
        }
        secondaryImage={fund?.userProfileIcon
          ? fund?.userProfileIcon
          : "/icons/defaultpropic.jpg"}
        customBackgroundImage={fund?.bgImage ? fund?.bgImage : '/DefaultBackgroundImage.png'}
      /> */}
      <div className="mt3">
        <h4> Investor Overview</h4>
        <div className="whiteCard">
          <div className="Overview_fundStatusHeader">
            <div className='space-between'>
              <h6>Fund Status</h6>
              <b className="FundStatus_indicator">{fund?.fundStatusName}</b>
            </div>
            <DashboardSelect2
              type="years"
              options={years}
              label={'Year'}
              value={selectedFundYear}
              onChange={handleFundYearChange}
            />
          </div>
          <h6 className="blue-fund-heading">My Fund Investment</h6>
          {fund && <div className={styles.statsCont}>

            {FundStats.map(stats => (
              <div className={styles.statWrapper}>
                <div className=''>{stats.primaryLabel}</div>
                <b className={`font-size-medium-heading ${stats.className}`}>{stats.primaryValue}</b>
                {
                  stats.secondaryLabel ? (
                    <div className={`${styles.secondaryStatsWrapper} space-between`}>
                      <div>
                      </div>
                      <div className="space-between">
                        <div className='font-size-extrasmall'>{stats.secondaryLabel}</div>
                        <b className='font-size-extrasmall'>:   {fund.targettedIRR}%</b>
                      </div>
                    </div>

                  ) : (
                    <div style={{ visibility: 'hidden' }}>.</div>
                  )
                }
              </div>
            ))}
          </div>}
          <h6 className="blue-fund-heading">Total Fund</h6>
          {fund && <div className={styles.statsCont}>
            {FundStat.map(stats => (
              <div className={styles.statWrapper}>
                <div className=''>{stats.primaryLabel}</div>
                <b className='font-size-medium-heading'>{stats.primaryValue}</b>
                {
                  stats.secondaryLabel ? (
                    <div className={`${styles.secondaryStatsWrapper} space-between`}>
                      <div>
                      </div>
                      <div className="space-between">
                        <div className='font-size-extrasmall'>{stats.secondaryLabel}</div>
                        <b className='font-size-extrasmall'>:   {fund.targettedIRR}%</b>
                      </div>
                    </div>

                  ) : (
                    <div style={{ visibility: 'hidden' }}>.</div>
                  )
                }
              </div>
            ))}
          </div>}
          {fund && <div className={styles.fundMetaWrapper}>
            <div className={styles.fundMetaSections}>
              <div className={styles.metaDetailWrapper}>
                <div >Vintage Year</div>
                <b >{fund.vintage}</b>
              </div>
              <div className={styles.metaDetailWrapper}>
                <div >First Closing Date</div>
                <b>{fund.fundClosingDate ? moment(fund.fundClosingDate).format('MM-DD-YYYY') : ''}</b>
              </div>
              <div className={styles.metaDetailWrapper}>
                <div >Strategy</div>
                <b >{fund.strategyName}</b>
              </div>
              <div className={styles.metaDetailWrapper}>
                <div >Geography</div>
                <b >{fund.geographicFocusName}</b>
              </div>
              <div className={styles.metaDetailWrapper}>
                <div >Visibility</div>
                <b >{fund?.visibility === "1" ? "show" : "hide"}</b>
              </div>
            </div>
            <div className={styles.fundMetaSections}>
              <div className={styles.metaDetailWrapper}>
                <div >Sector</div>
                <b >{fund.targetIndustry}</b>
              </div>
              <div className={styles.metaDetailWrapper}>
                <div >Currency</div>
                <b >{fund.fundCurrency ?? "USD"}</b>
              </div>
              {/* <div className={styles.metaDetailWrapper}>
                <div >General Partner</div>
                <b >Osage Venture Partners</b>
              </div> */}
              <div className={styles.metaDetailWrapper}>
                <div >Fund Manager</div>
                <b >{fund.fundManager}</b>
              </div>
            </div>

          </div>}
        </div>
        <div className={styles.fundPerfCont}>
          <div className={styles.fundPerfSectionCont}>
            <FundNews FundNewsData={FundNewsData} role={AppDashboards.INV_DASHBOARD} />
          </div>
          {
            props.fundperfomancetab === true &&
            <div className={styles.fundPerfSectionCont}>
              <ChartSelector selectedChart={selectedChart} handleChange={handleChange} funddropdown={false} selectedFundID={fundId} type = "performancetab" />
            </div>}
        </div>
        {
          props.fundperfomancetab === true && <div className="whiteCard mt16">
            <div className="Overview_fundStatusHeader">
              <h6 >Fund Performance</h6>
              <DashboardSelect
                options={years.map((year) => ({
                  value: year.value,
                  label: year.label,
                }))}
                label={'Year'}
                type="years"
                value={selectedPerformanceYear}
                onChange={(event) => handlePerformanceYearChange(event.target.value)}
              />
            </div>
            <div className="space-betwen">
              <div className="fundPerformance_grid">
                <AIPDataGrid
                  columns={PerformanceGridColumns}
                  rows={firstSlice}
                  fundperformanceheader={true}
                />
              </div>
              <div className="fundPerformance_grid">
                <AIPDataGrid
                  columns={PerformanceGridColumns}
                  rows={secondSlice}
                  fundperformanceheader={true}

                />
              </div>
            </div>
          </div>
        }
        <div className={styles.fundPerfCont}>
          <div className={styles.fundPerfSectionCont}>
            <VDRoom VDRType={'Fund'} isCompliance={true} cnrtFundID={fundId} crntSubscriptionID={0} />
          </div>
          <div className={styles.fundPerfSectionCont}>
            <div className={styles.secondaryStatsWrapper}>
              <h6 className=''>Fund Transactions</h6>
              <DashboardSelect2
                type="years"
                options={years}
                label={'Year'}
                value={selectedTransactionsYear}
                onChange={handleTransactionsYearChange}
              />
            </div>
            <div style={{ height: '95%' }}>
              <AIPDataGrid
                columns={AccountTransactionColuns}
                rows={FundTransactionsRowsData}
                onRowsSelectionHandler={() => { }} />
            </div>
          </div>
        </div>

      </div>

    </div>
  );
};
export default InvestorOverview;
