import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import './styles.css';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { propTypes } from 'react-bootstrap/esm/Image';
import { ToastContainer, toast } from 'react-toastify';

const FileUploadButton = (props) => {
    const {
        onChange,
        disabled,
        label,
        name,
        defaultFile,
        clearFile,
        isImage,
        fromWhiteLabeling,
        pdffileonly,
        PDFWarningMessage,
        errormessage,
        isSize,
        maxSize,
        profilePhotoValidation
    } = props;

    const [file, setFile] = useState(null);

    const handleFileChange = (ev) => {
        const { name, files } = ev.target;

        if (files?.length > 0) {
            const file = files[0];
            if (isImage) {
                const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
                const maxFileSize = maxSize ? maxSize : 10 * 1024 * 1024
                const maxFileSizeInMB = maxFileSize / (1024 * 1024);
                if (!allowedImageTypes.includes(file.type)) {
                    toast.warning(`The image should be in JPG,JPEG or PNG format and should not exceed ${maxFileSizeInMB}MB`,
                        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                    setFile(null)
                    return;
                }
                if (file.size > maxFileSize) {
                    toast.warning(`The image should be in JPG,JPEG or PNG format and should not exceed ${maxFileSizeInMB}MB`,
                        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                        setFile(null)
                        return;
                }
                // if (file.size > profilePhotoValidation.maxSize) {
                //     console.error(`File size exceeds the maximum allowed (${profilePhotoValidation.maxSize / (1024 * 1024)} MB).`);
                //     return;
                // }
            } else
                if (pdffileonly && file?.type !== 'application/pdf') {
                    console.error('Invalid file type. Please upload a PDF file.');
                    PDFWarningMessage();
                    handleClearFile()
                    return;
                }

            setFile(URL.createObjectURL(file));
            onChange(name, file);
        }
    };


    const handleClearFile = () => {

        setFile(null);
        onChange(name, null);
    }

    useEffect(() => {

        if (clearFile) {
            setFile(null);
        } else if (defaultFile) {
            if (isImage) {
                if (typeof defaultFile === "object") {
                    setFile(URL?.createObjectURL(defaultFile))
                }
                else {
                    setFile(defaultFile)
                }
            }
            else {
                setFile(defaultFile);
            }
        }
    }, [clearFile, defaultFile]);

    const handleWrapperClick = () => {
        !file && document.getElementById(`${name}_fileupload`).click();
    };

    return (
        <div className='uploadbtn-right'>
            <div
                className={`fileUploadButton ${disabled && 'fileInputDisabled'}`}
                onClick={handleWrapperClick}
            >
                {file
                    ? (<div className='previewWrapper'>
                        {
                            isImage
                                ? <img src={file} alt="Preview" className={isSize ? "addUserProfile" : "file-preview"} />
                                : <div className='upload-preview'>{file?.name}</div>
                        }
                        {(fromWhiteLabeling || (typeof defaultFile === "object")) && <CloseOutlinedIcon
                            onClick={handleClearFile}
                        />}
                    </div>
                    )
                    : (
                        <>
                            <UploadIcon />
                            {
                                isImage ?
                                    <input
                                        className='inputfile'
                                        id={`${name}_fileupload`}
                                        type="file"
                                        name={name}
                                        onChange={handleFileChange}
                                        accept=".jpeg, .jpg, .png"
                                        maxFileSize={2 * 1024 * 1024} // Set max file size to 2 MB
                                        hidden
                                    />
                                    :
                                    pdffileonly ?
                                        <input
                                            className='inputfile'
                                            id={`${name}_fileupload`}
                                            type="file"
                                            name={name}
                                            onChange={handleFileChange}
                                            accept=".pdf"
                                            maxFileSize={10 * 1024 * 1024} // Set max file size to 10 MB
                                            hidden
                                        />
                                        :
                                        <div>
                                            <input
                                                className='inputfile'
                                                id={`${name}_fileupload`}
                                                type="file"
                                                name={name}
                                                onChange={handleFileChange}
                                                aria-errormessage={errormessage}
                                                maxFileSize={10 * 1024 * 1024} // Set max file size to 10 MB
                                                hidden
                                            />

                                        </div>
                            }
                            <label>{label || 'Upload Document'}</label>
                        </>
                    )
                }

            </div>
            {errormessage && <span className='errorMessage replacerror'>{errormessage}</span>}
        </div>
    );
};

FileUploadButton.propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    defaultFile: PropTypes.string,
    clearFile: PropTypes.bool,
    isImage: propTypes.bool,
    fromWhiteLabeling: propTypes.bool,
    pdffileonly: propTypes.bool,
    isSize: propTypes.bool,
    profilePhotoValidation: PropTypes.shape({
        maxSize: PropTypes.number.isRequired,
    }).isRequired,
};

FileUploadButton.defaultProps = {
    disabled: false,
    label: "upload",
    defaultFile: null,
    clearFile: false,
    isImage: false,
    fromWhiteLabeling: false,
    pdffileonly: false,
    isSize: false,
    profilePhotoValidation: {
        maxSize: 2 * 1024 * 1024, // Default max size is 2 MB
    },
};

export default FileUploadButton;
