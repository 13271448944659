import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import {
  GridActionsCellItem,
  useGridApiContext,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CheckBox, Edit } from '@mui/icons-material';
import { questionPatterns } from '../../../investor-fund-subscription/services/apiDataTemplate';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ReOrderingPopup from '../popups/ReOrderingPopup';
import { ToastContainer, toast } from 'react-toastify';
import { configurationTypesEnum } from '../services/apiDataTemplate';
import InfoIcon from '@mui/icons-material/Info';
import { convertStringToArray } from '../../../common/Functions/ConvertStringtoArray';

const TreeGrid = memo((props) => {

  //#region props
  const { type, 
    initialColumns,
     initialRows, 
     onOpenEditPopup, 
     onOpenChildQuestionPopup, 
     getAllQuestionsDetails,
     allFundDeatils } = props;

  //#region variables
  const [sequencePopup, setSequencePopup] = useState(false);
  const [selectedStepDetails, setSelectedStepDetails] = useState(null);

  //#region click events
  const onEditClick = (questionDetails, viewOnly) => {
    onOpenEditPopup(questionDetails, viewOnly)
  }

  const onChildAddClick = (questionDetails) => {
    onOpenChildQuestionPopup(questionDetails, true)//#pass to know adding a child question and parent question details
  }

  const onChildEditClick = (rowDetails, viewOnly) => {
    const parentQuestion = rows?.find((item) => item.questionBank.questionBankID === rowDetails.questionBank.parentQuestionID)
    onOpenChildQuestionPopup(rowDetails.questionBank, false/* isAddChildQuestion */, parentQuestion?.questionBank, viewOnly)//#pass to know editng a child question and parent question details
  }

  const onOpenPopup = (stepDetails) => {
    if (Array.isArray(stepDetails?.children)) {
      setSelectedStepDetails(stepDetails);
      setSequencePopup(true);
    }
    else {
      toast.warning("This step has no questions",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  const onClosePopup = () => {
    setSelectedStepDetails(null);
    setSequencePopup(false);
    getAllQuestionsDetails();
  }

  //#region grid variables
  const [rows, setRows] = useState([]);

  const columns = [
    ...initialColumns,
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      resizable: false,
      cellClassName: 'actions',
      getActions: (params) => {
        const hierarchyLength = params.row.hierarchy.length;

        if (params.row.hierarchy.length === 1) {
          return [
            <Tooltip title="Reorder Step Questions">
              <GridActionsCellItem
                icon={<ImportExportIcon />}
                onClick={(event) => {
                  event.stopPropagation();
                  onOpenPopup(params.row);
                }}
                label=""
                variant="outlined">
              </GridActionsCellItem>
            </Tooltip>
          ];
        } else if (hierarchyLength === 2) {
          // Second level
          /*  const isEditQuestion = params.row.questionBank.createdBy !== "ALt360 Beta Admin" */
          const isEditQuestion = params.row.questionBank.isQuestionUsedByInvestor === false ||
            params.row.questionBank.showInInvestProfile === false
          const isAddQuestion = (
            params.row.questionBank.questionPatternType === questionPatterns.DROP_DOWN ||
            params.row.questionBank.questionPatternType === questionPatterns.MULTI_CHOICE_QUESTION ||
            params.row.questionBank.questionPatternType === questionPatterns.RAIDO ||
            params.row.questionBank.questionPatternType === questionPatterns.HEADER
          )
          const fundIds = convertStringToArray(params.row.questionBank?.applicableTo)
          return [
            <Tooltip title={isEditQuestion ? "EDIT QUESTION" : "VIEW"}>
              {
                isEditQuestion ?
                  <GridActionsCellItem
                    icon={<Edit />}
                    onClick={(event) => {
                      event.stopPropagation();
                      onEditClick(params.row.questionBank, false /* viewOnly */);
                    }}
                    label=""
                    variant="outlined">
                  </GridActionsCellItem> :
                  <GridActionsCellItem
                    icon={<RemoveRedEyeIcon />}
                    onClick={(event) => {
                      event.stopPropagation();
                      onEditClick(params.row.questionBank, true /* viewOnly */);
                    }}
                    label=""
                    variant="outlined">
                  </GridActionsCellItem>
              }
            </Tooltip>,
            <Tooltip title={isAddQuestion ? "ADD CHILD QUESTION" : "This question type does not support child questions."}>
              {
                isAddQuestion ?
                  <GridActionsCellItem
                    icon={<AddIcon />}
                    onClick={(event) => {
                      event.stopPropagation();
                      onChildAddClick(params.row.questionBank);
                    }}
                    color="primary"
                    label=""
                    variant="outlined">
                  </GridActionsCellItem>
                  :
                  <GridActionsCellItem
                    icon={<AddIcon />}
                    onClick={(event) => { }}
                    label=""
                    variant="outlined">
                  </GridActionsCellItem>
              }
            </Tooltip>,
            <Tooltip
                title={
                  fundIds.includes(0) ? (
                    "All Funds"
                  ) : (
                    <div className='child-margin-5'>
                      {allFundDeatils
                        ?.filter((item) => fundIds.includes(item.listItemID))
                        .map((item) => (
                          <div key={item.listItemID}>{item.listItemValue}</div>
                        ))}
                    </div>
                  )
                }>
                <GridActionsCellItem
                  icon={<InfoIcon color="primary" />}
                  onClick={(event) => { }}
                  label=""
                  variant="outlined">
                </GridActionsCellItem>
            </Tooltip>
          ];
        } else if (hierarchyLength === 3) {
          // Third level
          /* const isEditQuestion = params.row.questionBank.createdBy !== "ALt360 Beta Admin" */
          const isEditQuestion = params.row.questionBank.isQuestionUsedByInvestor === false ||
            params.row.questionBank.showInInvestProfile === false
          return [
            <Tooltip title={isEditQuestion ? "EDIT QUESTION" : "VIEW"}>
              {
                isEditQuestion ?
                  <GridActionsCellItem
                    icon={<Edit />}
                    onClick={(event) => {
                      event.stopPropagation();
                      onChildEditClick(params.row, false /* View only */)
                    }}
                    disabled={!isEditQuestion}
                    label=""
                    variant="outlined">
                  </GridActionsCellItem> :
                  <GridActionsCellItem
                    icon={<RemoveRedEyeIcon />}
                    onClick={(event) => {
                      event.stopPropagation();
                      onChildEditClick(params.row, true /* View only */)
                    }}
                    label=""
                    variant="outlined">
                  </GridActionsCellItem>
              }
            </Tooltip>
          ];
          /* return [] */
        } else {
          // More levels if needed
          return [];
        }
      },
    }
  ];

  //#region functions
  function transformRows(rows, parentPath = []) {
    let transformedRows = [];

    rows.forEach((row, index) => {
      const { branchID, branchTitle, children } = row;
      const path = [...parentPath, branchID]; // Add current title to parentPath

      // Add current row
      transformedRows.push({
        id: branchID,
        branchTitle,
        hierarchy: path,
        children: [],
        ...row // Initialize with an empty array
      });

      // Recursively transform children
      if (children?.length > 0) {
        const childRows = transformRows(children, path);
        transformedRows = [...transformedRows, ...childRows];
      }
    });
    return transformedRows;
  }

  const getTreeDataPath = useCallback((row) => {
    return row.hierarchy;
  }, [rows]);

  const groupingColDef = {
    headerName: type === configurationTypesEnum.SUBSCRIPTION ? 'Step'
      : type === configurationTypesEnum.KYC_AML ? 'Section'
        : '',
    /* flex: 1, */
    width: 300,
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  //#region useeffect
  useEffect(() => {
    if (initialRows?.length > 0) {
      const processedRows = transformRows(initialRows);
      setRows(processedRows);
    }
  }, [initialRows])

  //#region return
  return (
    <>
      <DataGridPro
        treeData
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColDef}
        getCellClassName={(params) => {
          return params.field === 'branchTitle' && params.row.hierarchy.length === 3 ? "child-row-space-left" : "";
        }}
        sx={{
          ".child-row-space-left": {
            paddingLeft: "30px",
          },
          width: '98%'
        }}
        hideFooter />
      {
        sequencePopup && selectedStepDetails &&
        <ReOrderingPopup open={sequencePopup} selectedStepDetails={selectedStepDetails} onClose={onClosePopup} />
      }
    </>
  );
})

function CustomGridTreeDataGroupingCell(props) {
  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box sx={{ ml: rowNode.depth * 4 }}>
      <div onClick={handleClick} className='align-items-center'>
        {filteredDescendantCount > 0 ? (
          rowNode.childrenExpanded ? (
            <KeyboardArrowDownIcon fontSize="small" />
          ) : (
            <ChevronRightIcon fontSize="small" />
          )
        ) : (
          <span />
        )}
        <span className='margin-left-5'>{props.row.branchType === "Step" ? props.row.branchTitle : ""}</span>
      </div>
    </Box>
  );
}


export default TreeGrid;
