import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  FormControlLabel,
  Radio,
  Button
} from '@mui/material';
import TextInput from '../../common/TextInput/TextInput';
import secureLocalStorage from 'react-secure-storage';
import ActionButton from '../../common/ActionButton/ActionButton';
import FileUploadButton from '../../common/FileUploadButton/FileUploadButton';
import FileUploadBtn from '../../common/FileUploadButton/FileUploadBtn';
import AddIcon from '@mui/icons-material/Add';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import CancelIcon from '@mui/icons-material/Cancel';
import { uploadFileTypes } from '../../common/FileUploadButton/uploadComponentenum';
import CloseIcon from '@mui/icons-material/Close';
const UploadMultiplefiles = (props) => {

  const {open,onClose,onSubmit,files,setFiles,folderNames,foldernotrequired,comments} = props;

  //add extra document 
  const onaddFile = () => {
    setFiles([...files,
      {
        id : files.length + 1,
        file : "",
        path : ""
      }
    ])
  }

  //fileUplaod
  const handleFileUpload = (name, file) => {
    const findIndex = files.findIndex((item) => item.id === parseInt(name));
    if (file) {
        const formData = new FormData();
        formData.append(name, file, file.name);
        setFiles((prevItems) => {
          return prevItems.map((item) => {
            if (item.id === parseInt(name)) {
              item.file = file;
            }
            return item;
          });
        });
    }
}

const handleChange = (id,value) => {
  const findIndex = files.findIndex((item) => item.id === id);
   
  files[findIndex].path = value;
}

  const [loading,setLoading] = useState(false)
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        Attach Documents
      </DialogTitle>
      <DialogContent>
          {
            files?.map((file) => (
              <div className="fundRow legalFieldWrapper" key={file.id}>
                 {
                    !foldernotrequired &&
                    <div className="fundDetail">
                      <TextInput 
                        type="select"
                        label="Document Type"
                        name={file.id}
                        defaultValue={file.path}
                        options={folderNames?.map(option => ({ label: option.folderName, value: option.folderID }))}
                        onChange={(name,value) => handleChange(name,value)} />
                    </div>
                 }
                  <div className="legalFileBtn">
                    <FileUploadBtn
                      name={file.id}
                      maxSize={5 * 1024 * 1024}
                      fileType={uploadFileTypes.PDF}
                      defaultFile={file.file}
                      label="UPLOAD DOCUMENT"
                      onChange={(name, value) => handleFileUpload(name, value)}
                    />
                  </div>
              </div>
            ))
          }
          <div className='fundRow legalFieldWrapper'>
            <Button
              onClick={onaddFile}
              variant="contained" 
              className="btn-primary">
              <AddIcon />
              Add Document
            </Button>
          </div>
      </DialogContent>
      <DialogActions>
        <ActionButton
          onClick={onClose}
          variant="outlined" className="btn-primary"
          icon={<CloseIcon/>}
          label="Cancel"
          />

        <ActionButton
          onClick={onSubmit}
          /* disabled={SupportedFile != null ? false : true} */
          loading={loading}
          icon={<TouchAppIcon/>}
          label="Submit"
          />
      </DialogActions>
    </Dialog>
  )
}

export default UploadMultiplefiles