import TextInput from "../../../common/TextInput/TextInput";
import FileUploadButton from "../../../common/FileUploadButton/FileUploadButton";
import Text from "../../../common/Text/Text";
import './styles.css';
import { useState, useEffect, useRef } from "react";
import { getFolders } from "./Services/DropDownData";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from "react-secure-storage";
import Button from '@mui/material/Button';
import AppTextInput from "../../../common/TextInput/AppTextField";
import { validationsTypeEnum, InputTypes, validationMessages, textFormatTypeEnum } from "../../../common/TextInput/appInputenum";
import { CreateInvestmentStrategy, updateInvestmentStrategy } from "../../services/MarketplaceService";
import FileUploadBtn from "../../../common/FileUploadButton/FileUploadBtn";
import { uploadFileTypes } from "../../../common/FileUploadButton/uploadComponentenum";
import ActionButton from "../../../common/ActionButton/ActionButton";
import { FundTypes } from "./InitialFundData";


const InvestmentStrategy = (props) => {


    const componentRef = useRef(null);
    useEffect(() => {
        componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const [btnDisabled, setBtnDisabled] = useState(false);
    const [componentLoad, setComponentLoad] = useState(true)

    const handleFileUpload = (name, file) => {
        if (file) {
            const formData = new FormData();
            formData.append(name, file, file.name);
            handleChange(name, file);
        }
        else {
            handleChange(name, '');
        }
    }

    const handleChange = (field, value) => {
        const currState = { ...fundDetails };
        currState[field] = value;
        setFundDetails(currState);
        props.updateData(currState);
    };



    const initialFundDetails = {
        dscInvestmentStrategy: "",
        dscInvestmentCriteria: "",
        dscInvestmentProcess: "",
        dscRiskManagementApproach: "",
        dscValueCreationAndOperImprovementStrategies: "",
        dscExitStrategies: "",
        startegyfilepath: "",
        strategyDocument: "",
        ...props.data,
    }

    const [fundDetails, setFundDetails] = useState(initialFundDetails);


    const [FolderNames, setFolderNames] = useState();
    const getAllFolderNames = async () => {
        const data = await getFolders();
        if (data.responseCode === 200) {
            console.clear()
            console.log(data.responseData);
            const NamesAndIds = data?.responseData?.filter(d => d.parentType != "Investor Reporting" && d.title != "Investor Reporting")?.map(item => ({
                label: item.title,
                value: `${item.defaultFolderID}_${item.documentTypeID}`
            }));
            console.log(NamesAndIds);
            setFolderNames(NamesAndIds);
        } else {

        }
    }
    useEffect(() => {
        getAllFolderNames();
    }, []);

    const onSave = async (index, setEdit, fundDetails) => {
        setBtnDisabled(true);
        const requestData = {
            "fundInvestmentStrategyID": fundDetails.fundInvestmentStrategyID === null ? 0 : fundDetails.fundInvestmentStrategyID,
            "fundID": fundDetails.fundID,
            "investmentStrategy": fundDetails.dscInvestmentStrategy,
            "investmentCriteria": fundDetails.dscInvestmentCriteria,
            "investmentProcess": fundDetails.dscInvestmentProcess,
            "riskManagementApproach": fundDetails.dscRiskManagementApproach,
            "valueCreationAndOperImprovementStrategies": fundDetails.dscValueCreationAndOperImprovementStrategies,
            "exitStrategies": fundDetails.dscExitStrategies,
            "isActive": true,
            "softDelete": true,
            "createdBy": 0,
            "createdDate": new Date(),
            "updatedBy": 0,
            "updatedDate": new Date()
        }

        if (requestData.fundInvestmentStrategyID === 0) {
            const response = await CreateInvestmentStrategy(requestData);
            if (response.responseCode === 200) {
                toast.success("Successfully Saved", { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                setBtnDisabled(false);
                setEdit(false);
            }
            else {
                toast.error("Failed to Save,Please try again", { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                setBtnDisabled(false);
                setEdit(false);
            }
        }

        else {

            const response = await updateInvestmentStrategy(requestData);
            if (response.responseCode === 200) {
                toast.success("Successfully Saved", { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                setBtnDisabled(false);
                setEdit(false);
            }
            else {
                toast.error("Failed to Save,Please try again", { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                setBtnDisabled(false);
                setEdit(false);
            }
        }
    }

    const isFundMatured = secureLocalStorage.getItem("selectedFund")?.FundMaturity;;
    const isFundTerminated = secureLocalStorage.getItem("selectedFund")?.FundTermination;

    const [noofValidFields, setNoofValidFields] = useState({ validfields: 0 });

    const [checkIfValid, setcheckIfValid] = useState(false);

    const checknoofValidFields = (isValid, name, defaultValue) => {
        const TotalFiledsToValidate =
            props.fundData.FundDetails.fundTypeName === FundTypes.PRIVATE_EQUITY ? props.showdocuments !== false ? 8 : 6
                : props.showdocuments !== false ? 2 : 0;

        noofValidFields['validfields'] = isValid ?
            noofValidFields['validfields'] + 1 : noofValidFields['validfields'];
        if (noofValidFields['validfields'] === TotalFiledsToValidate) {
            noofValidFields['validfields'] = 0;
            if (props.showdocuments == false) {
                onSave(props.index, props.setEdit, fundDetails)
            }
            else {
                props.setFundDetailErrors(false);
                props.setStep(5);
            }
        }
    };

    const handleSave = () => {
        if (props.showdocuments == false) {
            onSave(props.index, props.setEdit, fundDetails)
        }
        else {
            setcheckIfValid(true);
            noofValidFields['validfields'] = 0;
        }
    }

    useEffect(() => {
        if (props.FundDetailerrors === true) {
            handleSave();
        }
    }, [props.FundDetailerrors])

    // useEffect(() => {
    //     debugger;
    //     return () => {
    //         props.updateData(fundDetails);
    //     }
    // }, [fundDetails, fundDetails?.startegyfilepath]);

    // useEffect(() => {
    //     debugger;
    //     return () => {
    //         props.updateData(fundDetails.dscRiskManagementApproach);
    //     }
    // }, [fundDetails?.dscRiskManagementApproach])





    return (
        <div>       {
            componentLoad && <div ref={componentRef}>
                {props.showdocuments !== false && (
                    ((props.fundData.FundDetails.fundTypeName !== FundTypes.PRIVATE_CREDIT)) &&
                    <>
                        <div className="fundRow">
                            <Text label="Investment Strategy and Philosophy" />
                            <div>

                            </div>
                        </div>
                        <div className="space-between">
                            <div></div>
                            <div className="">
                                <FileUploadBtn
                                    name='strategyDocument'
                                    label="UPLOAD DOCUMENT"
                                    fileType={uploadFileTypes.PDF}
                                    required={true}
                                    defaultFile={fundDetails.strategyDocument}
                                    onChange={(name, value) => handleFileUpload(name, value)}
                                    checkIfValid={checkIfValid}
                                    validationType={validationsTypeEnum.ONLY_PDF_FILE}
                                    validationMessage={validationMessages.ONLY_PDF_FILE}
                                    checknoofValidFields={checknoofValidFields}
                                    setcheckIfValid={setcheckIfValid} />
                            </div>
                        </div>
                        <div className="space-between margin-top-5">
                            <div></div>
                            <div className="width-200">
                                {/* <TextInput
                                    type="select"
                                    label={<span className="requiredField"> Select Document Type</span>}
                                    name='startegyfilepath'
                                    defaultValue={fundDetails.startegyfilepath}  
                                    placeholder="Folder Path"
                                    options={FolderNames?.map(option => ({
                                        label: option.folderName,
                                        value: `${option.parentfolderid}_${option.folderID}`
                                    }))}

                                    onChange={(name, value) => handleChange(name, value)} 
                                    // value={selectedDocumentType}
                                    errormessage={errors.startegyfilepath || undefined}
                                    /> */}
                                <AppTextInput
                                    type={InputTypes.SELECT}
                                    name="startegyfilepath"
                                    label="Select Document Type"
                                    defaultValue={fundDetails?.startegyfilepath}
                                    options={FolderNames || []}
                                    onChange={(name, value) => handleChange(name, value)}
                                    required={true}
                                    validateField={true}
                                    validationType={validationsTypeEnum.REQUIRED}
                                    validationMessage={validationMessages.REQUIRED}
                                    checkIfValid={checkIfValid}
                                    checknoofValidFields={checknoofValidFields}
                                    disabled={props?.editable ? !props.editable : false}
                                    setcheckIfValid={setcheckIfValid} />

                            </div>
                        </div>
                    </>
                )}

                {props.showdocuments == false && (
                    <div className="fundRow no-margin">
                        <div>

                        </div>
                        <div>
                            <div className="btns">
                                {
                                    !isFundTerminated && !isFundMatured ? (props.editable ? (
                                        <>
                                            <div>
                                                <ActionButton variant="outlined" className="btn-primary"
                                                    onClick={() => props.handleClickCancel(props.index, props.setEdit)}
                                                    label="CANCEL"
                                                    startIconName="Cancel" />
                                            </div>
                                            <div className="margin-left-5">
                                                <ActionButton variant="contained" className="btn-primary"
                                                    onClick={() => handleSave()}
                                                    label="SAVE"
                                                    loading={btnDisabled}
                                                    /* disabled={Object.values(errors).some(error => error !== null)} */
                                                    startIconName="Save" />
                                            </div>
                                        </>
                                    ) : (
                                        <ActionButton variant="contained" className="btn-primary"
                                            onClick={() => props.handleClickEdit(props.index, props.setEdit)}
                                            label="EDIT"
                                            startIconName='Edit'
                                        >
                                        </ActionButton>
                                    )) : null
                                }

                                {/* {!isFundTerminated && !isFundMatured && (
                        
                    )} */}
                            </div>
                        </div>
                    </div>
                )}

                <div className="fundRow">
                    <AppTextInput
                        variant="outlined"
                        multiline={true}
                        name='dscInvestmentStrategy'
                        defaultValue={fundDetails?.dscInvestmentStrategy}
                        rows={4}
                        placeholder="Description of investment strategy"
                        disabled={props.showdocuments == false ? !(props.editable) : false}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={false}
                        validationType={validationsTypeEnum.MIN_CHARACTERS}
                        validationMessage={validationMessages.MIN_CHARACTERS}
                        charactersMinLength={50}
                        charactersMaxLength={2000}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid} />
                </div>
                <div className="fundRow">
                    <AppTextInput
                        variant="outlined"
                        multiline={true}
                        name='dscInvestmentCriteria'
                        defaultValue={fundDetails?.dscInvestmentCriteria}
                        rows={4}
                        placeholder="Description of investment criteria (e.g., stage, size, growth potential, etc.)"
                        onChange={(name, value) => handleChange(name, value)}
                        disabled={props.showdocuments == false ? !(props.editable) : false}
                        validateField={false}
                        validationType={validationsTypeEnum.MIN_CHARACTERS}
                        validationMessage={validationMessages.MIN_CHARACTERS}
                        charactersMinLength={50}
                        charactersMaxLength={2000}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid}
                    />
                </div>
                <div className="fundRow">
                    <AppTextInput
                        variant="outlined"
                        multiline={true}
                        name='dscInvestmentProcess'
                        defaultValue={fundDetails?.dscInvestmentProcess}
                        rows={4}
                        placeholder="Description of investment process (eg., sourcing, due diligence, etc.)"
                        onChange={(name, value) => handleChange(name, value)}
                        disabled={props.showdocuments == false ? !(props.editable) : false}
                        validateField={false}
                        validationType={validationsTypeEnum.MIN_CHARACTERS}
                        validationMessage={validationMessages.MIN_CHARACTERS}
                        charactersMinLength={50}
                        charactersMaxLength={2000}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid}
                    />
                </div>
                <div className="fundRow">
                    <AppTextInput
                        variant="outlined"
                        multiline={true}
                        name='dscRiskManagementApproach'
                        defaultValue={fundDetails?.dscRiskManagementApproach}
                        rows={4}
                        placeholder="Description of risk management approach"
                        onChange={(name, value) => handleChange(name, value)}
                        disabled={props.showdocuments == false ? !(props.editable) : false}
                        validateField={false}
                        validationType={validationsTypeEnum.MIN_CHARACTERS}
                        validationMessage={validationMessages.MIN_CHARACTERS}
                        charactersMinLength={50}
                        charactersMaxLength={2000}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid}
                    />
                </div>

                {
                    ((props.showdocuments !== false && 
                        props.fundData.FundDetails.fundTypeName !== FundTypes.PRIVATE_CREDIT) || 
                    (props.showdocuments === false && 
                        props.data.fundTypeName !== FundTypes.PRIVATE_CREDIT)) && 
                        <>             
                    <div className="fundRow">
                        <AppTextInput
                            variant="outlined"
                            multiline={true}
                            name='dscValueCreationAndOperImprovementStrategies'
                            defaultValue={fundDetails?.dscValueCreationAndOperImprovementStrategies}
                            rows={4}
                            placeholder="Description of value creation and operational improvement strategies"
                            onChange={(name, value) => handleChange(name, value)}
                            disabled={props.showdocuments == false ? !(props.editable) : false}
                            validateField={false}
                            validationType={validationsTypeEnum.MIN_CHARACTERS}
                            validationMessage={validationMessages.MIN_CHARACTERS}
                            charactersMinLength={50}
                            charactersMaxLength={2000}
                            checkIfValid={checkIfValid}
                            checknoofValidFields={checknoofValidFields}
                            setcheckIfValid={setcheckIfValid}
                        />
                    </div>
                        <div className="fundRow mb2">
                            <AppTextInput
                                variant="outlined"
                                multiline={true}
                                name='dscExitStrategies'
                                defaultValue={fundDetails?.dscExitStrategies}
                                rows={4}
                                placeholder="Description of exit strategies"
                                onChange={(name, value) => handleChange(name, value)}
                                disabled={props.showdocuments == false ? !(props.editable) : false}
                                validateField={false}
                                validationType={validationsTypeEnum.MIN_CHARACTERS}
                                validationMessage={validationMessages.MIN_CHARACTERS}
                                charactersMinLength={50}
                                charactersMaxLength={2000}
                                checkIfValid={checkIfValid}
                                checknoofValidFields={checknoofValidFields}
                                setcheckIfValid={setcheckIfValid}
                            />
                        </div></>
                }


            </div>
        }</div>


    )
}

export default InvestmentStrategy;