import React, { useEffect, useState } from "react";
import ConstructionIcon from '@mui/icons-material/Construction';

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../AppAuthentication/authConfig";
import SiteHeader from "./SiteHeader";
import SiteHero from "./SiteHero";


const AboutUs = () => {


  return (
    <div>
        <><SiteHeader /></>
       <div className="dummybanner"></div>
        <div className="UnderDevPage"><ConstructionIcon/> Under development</div>
    </div>
  );
};

export default AboutUs;