import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  LineChart, Line,
  Cell,
  Label,
} from 'recharts';

//#this component will display both nav and cashflow
const NavandCashflow = (props) => {

  const { selectedYear,selectedFund } = props;

  const [data, setData] = useState([]);
  const [jsonData, setJsonData] = useState([]);

  const generateRandomValue = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  const generateRandomData1 = () => {
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
    const currentYear = new Date().getFullYear();
    const startYear = selectedYear || currentYear;

    let data = [];
    for (let i = 0; i < 12; i++) { // Generate data for 12 quarters (3 years)
      const year = startYear + Math.floor(i / 4);
      const quarter = quarters[i % 4];
      const name = `${quarter} ${year}`;
      const contributions = generateRandomValue(-4000, 4000);
      const amt = generateRandomValue(2000, 2500);
      data.push({ name, contributions, amt });
    }

    return data;
  };

  const generateRandomData2 = () => {
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
    const currentYear = new Date().getFullYear();
    const startYear = selectedYear || currentYear;

    let data = [];
    for (let i = 0; i < 12; i++) { // Generate data for 12 quarters (3 years)
      const year = startYear + Math.floor(i / 4);
      const quarter = quarters[i % 4];
      const x = `${quarter} ${year}`;
      const y = generateRandomValue(10, 30);
      data.push({ x, y });
    }

    return data;
  };

  useEffect(() => {
    setData(generateRandomData1());
    setJsonData(generateRandomData2());
  }, [selectedYear,selectedFund]);


  return (
   <div className='child-margin-15'>
        <div style={{ width: '100%', height: '600px', padding: '16px' }}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={jsonData}margin={{ top: 20, right: 30, left: 0, bottom: 140 }}>
                    <XAxis dataKey="x"
                        tick={{ fontSize: 13}} 
                        />

                <YAxis  tick={{ fontSize: 12 }}
                    domain={[0, 30]}>
                       <Label 
                          value="Net asset value ($)" 
                          angle={-90} 
                          position="insideLeft" 
                          style={{ textAnchor: 'middle' }}
                      />
                </YAxis>
                <Tooltip />
                <Legend
                    iconType="circle"
                    iconSize={10}
                    wrapperStyle={{
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '10px',
                    padding: '10px',
                    }}
                />
                <Line type="monotone" dataKey="y" stroke="##1F61C5" name="NetIRR" strokeWidth={5} dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </div>
        <div style={{ width: '100%', height: '600px', padding: '16px' }}>
            <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis>
                    <Label 
                          value="Contibution / Distribution ($)" 
                          angle={-90} 
                          position="insideLeft" 
                          style={{ textAnchor: 'middle' }}
                      />
                </YAxis>
                <Tooltip />
                <Legend />
                <ReferenceLine y={0} stroke="#000" />
                <Bar dataKey="contributions">
                {
                    data.map((item, index) => (
                    <Cell key={index} fill={item.contributions > 0 ? "#1F61C5": "#1F61C5"}/>
                    ))
                }
                </Bar>
            </BarChart>
            </ResponsiveContainer>
        </div>
   </div>
  );
};

export default NavandCashflow;
