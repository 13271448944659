import React, { useEffect, useState } from 'react'
import IndividualInformation from './IndividualInformation'
import BankingInformation from './BankingInformation'
import InvestmentProfile from './InvestmentProfile'
import SubscriptionDetails from './SubscriptionDetails'
import { HeaderTemplates, PageHeader } from '../../../common/PageHeader/PageHeader'
import secureLocalStorage from 'react-secure-storage'
import { Grid } from '@mui/material'
import { getInvestorProfileDetails } from '../../services/services'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDispatch } from 'react-redux';
import { updateProfileIcon, updateUserData } from '../../../../state/slices/userDetailsSlice'
import TabsComponment from '../../../common/Tabs/TabsComponment'
import { getKeyValuePairs } from '../../../portfolio-companies/services/services'
import KycAmlDetails from './KycAmlDetails'
import InfoIcon from '@mui/icons-material/Info';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
const InvestorUserProfileMain = () => {

  //#region variables
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window?.location?.search);
  const tabId = queryParams.get('goto');
  const [selectedTab,setSelectedTab] = useState(tabId ? parseInt(tabId) : 0);
  const [investorDetails,setInvestorDetails] = useState();
  const [investorTypesValues,setInvestorTypesValues] = useState([]);
  const [investorTypeName,setInvestorTypeName] = useState();

  //#region api get calls
  const getInvestorInfo = async () => {
    const emailId = secureLocalStorage.getItem("userEmail");
    const data = await getInvestorProfileDetails(emailId)
    if (data.responseCode === 200) {
        setInvestorDetails(data.responseData)

        const fullName = `${data.responseData.personalInformation.firstName} ${data.responseData.personalInformation.lastName}`;
        dispatch(updateUserData({ field: "firstName", value: data.responseData.personalInformation.firstName }));
        dispatch(updateUserData({ field: "lastName", value: data.responseData.personalInformation.lastName }));
        dispatch(updateUserData({ field: "fullName", value: fullName }));
        secureLocalStorage.setItem("fullName", fullName)

        dispatch(updateProfileIcon({ value: data.responseData?.personalInformation?.profileIcon }))
    } else {
      // Handle error if needed
    }
  } 

  const getInvestorTypes = async() => {
    const data = await getKeyValuePairs("InvestorType");
    if(data.responseCode === 200){
       setInvestorTypesValues(data?.responseData);
    }
    else{
      setInvestorTypesValues([]);
    }
  }

  //#region tabs
  const commonTabsforInvestorTypes = [
    {
        key : 1,
        icon : <AccountBalanceOutlinedIcon />,
        label : "Banking Information",
        component : <BankingInformation investorDetails={investorDetails} getInvestorInfo={getInvestorInfo} investorTypesValues={investorTypesValues} />
    },
    {
        key : 2,
        icon : <ManageAccountsIcon />,
        label : "KYC/AML",
        component : <KycAmlDetails investorDetails={investorDetails} investorTypeName={investorTypeName} investorTypesValues={investorTypesValues} readOnly = {false}/>
    },
    {
        key : 3,
        icon : <FactCheckOutlinedIcon />,
        label : "Subscription Details",
        isBackgroundColor: true,
        component : <SubscriptionDetails investorDetails={investorDetails} />
    },
    {
        key : 4,
        icon : <MonetizationOnOutlinedIcon />,
        label : "Investment Profile",
        isBackgroundColor: true,
        component : <InvestmentProfile investorDetails={investorDetails} getInvestorInfo={getInvestorInfo} investorTypesValues={investorTypesValues} />
    },
  ]
  const tabs = [
    {
        key : 0,
        icon : <PersonOutlineOutlinedIcon />,
        label : "Personal Information",
        component : <IndividualInformation  investorDetails={investorDetails} getInvestorInfo={getInvestorInfo} investorTypesValues={investorTypesValues} />
    },
    ...commonTabsforInvestorTypes
  ]

  //#region useEffect
  useEffect(()=>{
    getInvestorInfo();
    getInvestorTypes();
  },[])

  useEffect(()=>{
    if(investorDetails && investorTypesValues?.length > 0){
         const investorType = investorTypesValues
                              ?.find((item) => item?.listItemID === investorDetails?.personalInformation?.investorTypeID)?.listItemValue
         setInvestorTypeName(investorType);
    }
  },[investorTypesValues,investorDetails])

  return (
    <div>
        <PageHeader
            title={`Investor Profile : ${secureLocalStorage.getItem("fullName")}`}
            template={HeaderTemplates.NORMAL_VIEW}
            hideBackButton={true}/>
        <div className="wrapper3">
            {tabs && investorDetails && 
                <Grid xs={12} >
                    <TabsComponment 
                        tabs={tabs} 
                        selectedTab={selectedTab} 
                        setSelectedTab={setSelectedTab} 
                        isScrollable={true} />
                </Grid>}
        </div>
    </div>
  )
}

export default InvestorUserProfileMain