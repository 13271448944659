import React from 'react'
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, Button, MenuItem } from '@mui/material';
import ActionButton from '../../../common/ActionButton/ActionButton';
import InfoIcon from '@mui/icons-material/Info';
import { deleteDocumnet } from '../services/services';
import { ToastContainer, toast } from 'react-toastify';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import secureLocalStorage from 'react-secure-storage';
import { AppDashboards } from '../../../../utils/enum';
const DeleteFile = (props) => {

  const {open,onClose,DocumnetDetails,refreshAccordians,K1Accordians,getFoldersDetails,setRefreshComponent} = props;

  const [loading,setLoading] = useState(false);

  const handleDelete = async () => { 
    setLoading(true);

    const data = await deleteDocumnet(DocumnetDetails);
    if (data?.responseCode === 200) {
      setLoading(false);
      onClose();
     if(K1Accordians){
      refreshAccordians();
     // getFoldersDetails(secureLocalStorage.getItem('FundId'),(secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD ? secureLocalStorage.getItem('userId'): 0))
      setRefreshComponent(false)
      setTimeout(() => {
        setRefreshComponent(true)
      }, 700);
      //
      }else{
        refreshAccordians();
      }
      toast.success("Document Deleted Successfully",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
    else{
      setLoading(false);
      onClose();
      toast.error("Unable to Delete the Document. Please try again later.",
         { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  return (
    <Dialog open={open}>
        <DialogTitle>
             <InfoIcon className='info-i' />
            <b>Confirmation</b>
        </DialogTitle>
        <DialogContent>
           Are you sure you want to delete the file?
        </DialogContent>
        <DialogActions>
           <div className="space-between text-center">
                  <div>
                      <ActionButton 
                        className="btn-primary"
                        component="label"
                        variant="outlined"            
                        onClick={onClose}
                        icon={<CancelIcon/>}
                        label="Cancel"
                        />
                  </div>
                  <div className="margin-left-10">
                     <ActionButton
                     icon={<DeleteIcon/>}
                     className="btn-primary"
                     variant='contained'
                        label="Delete"
                        loading={loading}
                        onClick={handleDelete} />
                  </div>
              </div>
        </DialogActions>
    </Dialog>
  )
}

export default DeleteFile