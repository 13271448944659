import ProgressBar from "../../../common/ProgressBar/ProgressBar";
import ActionButton from '../../../common/ActionButton/ActionButton';

const NotifyTitle = (props) => {

    const { step, onClose,noofsteps,title} = props; 

    return(
        <>
            <div className="modalCloseBtn">
                <ActionButton
                    variant="text"
                    endIconName="CloseOutlined" 
                    onClick={()=>onClose()}
                    />
            </div>
            <div className="fundRow-pc">

                 <div className="fundHeader fund">{title}</div>
                
                 <div className="progress-container">
                    <div className="fundProgress-pc"><ProgressBar progressValue={(step/noofsteps)*100} /></div>
                    <span className="fundstep-pc">{`${step} of ${noofsteps}`}</span>
                </div>

             
            </div>
        </>
    )
}

export default NotifyTitle;