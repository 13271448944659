import axios from 'axios';
import resolve from '../../common/resolve';
import secureLocalStorage from 'react-secure-storage';
import { getAPIResponse } from '../../../utils/common';
import axiosApiCall from '../../Services/axiosApiCall';

export async function getStatesandCitieslist() {
    return await axiosApiCall.get(`/v1/MasterData/GetCountryStateCityList`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
  }

export async function getInvestorQA() {
    return await axiosApiCall.get(`/v1/FundSubscription/GetInvestorSubscriptionQuestions?InvestorID=${secureLocalStorage.getItem("userId")}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
  }

export async function GetDocuSignURL(subscriptionID) {
    return await axiosApiCall.get(`/v1/FundSubscription/GetDocuSignURL?subscriptionID=${subscriptionID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}