import { textFormatTypeEnum, validationsTypeEnum } from "../../common/TextInput/appInputenum";
import { FieldTypes } from "../../common/input-fields/utlis/InputFieldsEnum";
import { getKeyValuePairs } from "../../portfolio-companies/services/services";
import { getStatesandCitieslist } from "../../user-profile/InvestorUserProfile/Services/Investorinfo";
import { SpousehasIIAaccdropdown, dependentsOptions, employmentstatusdropdown, isemployesdropdown } from "./apiDataTemplate";


//#region fields
export const personalInformationFields = [
    {
        name: 'emailAddress',
        label: 'Email Address',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        required: true,
        validationType: validationsTypeEnum.EMAIL_ADDRESS
    },
    {
        name: 'phoneNo',
        label: 'Phone Number',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.US_PHONE_NUMBER,
        required: true,
        validationType: validationsTypeEnum.US_PHONE_NUMBER
    },
    {
        name: 'addressLine1',
        label: 'Address Line 1',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'addressLine2',
        label: 'Address Line 2',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        required: false,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'countryID',
        label: 'Country',
        type: FieldTypes.SELECT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        options: [],
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'stateMasterID',
        label: 'State',
        type: FieldTypes.SELECT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        options: [],
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'city',
        label: 'City',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'zipCode',
        label: 'Zip Code',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.ZIPCODE,
        required: true,
        validationType: validationsTypeEnum.ZIP_CODE
    },
    {
        name: 'taxFillingContactID',
        label: 'Tax Filing Status',
        childName: 'taxFillingContactName',
        type: FieldTypes.AUTO_SELECT_FIELD,
        options: [],
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    /* {
        name: 'taxFillingContactOthers',
        label: 'Other Tax Filling Contact ',
        type: FieldTypes.TEXT_FIELD,
        options: [],
        required: true,
        validationType: validationsTypeEnum.REQUIRED,
        hasParentQuestion: true,
        parentQuestionName: "taxFillingContact" 
    }, */
    {
        name: 'annualPreTaxIncome',
        label: 'Annual Pre-Tax Income',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.US_CURRENCY,
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'numberOfDependents',
        label: 'No of Dependents',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.POSITIVE_NUMBERS,
        options: dependentsOptions,
        charactersMaxLength: 2,
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'employerStatus',
        label: 'Employment Status',
        type: FieldTypes.SELECT_FIELD,
        options: employmentstatusdropdown,
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'employer',
        label: 'Employer',
        type: FieldTypes.TEXT_FIELD,
        options: isemployesdropdown,
        required: true,
        validationType: validationsTypeEnum.REQUIRED,
        formatType: textFormatTypeEnum.ALPHA_HYPHEN_PERIOD
    },
    {
        name: 'occupation',
        label: 'Occupation',
        type: FieldTypes.TEXT_FIELD,
        formatType: validationsTypeEnum.ONLY_ALPHABETS,
        required: true,
        validationType: validationsTypeEnum.ONLY_ALPHABETS
    },
    {
        name: 'spouseAnnualPreTaxIncome',
        label: 'Spouse Annual Pre-Tax Income',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.US_CURRENCY,
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'spouseHasIIAAccount',
        label: 'Spouse Has IIA Account',
        type: FieldTypes.SELECT_FIELD,
        options: SpousehasIIAaccdropdown,
        required: false,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'federalTaxBracket',
        label: 'Federal Tax Bracket',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.PERCENTAGE,
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'householdInvestableAssets',
        label: 'Household Investable Assets',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.US_CURRENCY,
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'website',
        label: 'Website',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        required: false,
        optionalRequired: true,
        validationType: validationsTypeEnum.URL
    },
    {
        name: 'numberOfFamilyMembers',
        label: 'Number of Family Members',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.POSITIVE_NUMBERS,
        required: true,
        validationType: validationsTypeEnum.REQUIRED,
        charactersMaxLength:3
    },
    {
        name: 'languagesSpoken',
        label: 'Languages Spoken',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.ONLY_ALPHABETS,
        required: false,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'investmentGoalID',
        label: 'Investment Goals',
        childName: 'investmentGoalName',
        type: FieldTypes.AUTO_SELECT_FIELD,
        options: [],
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    /* {
        name: 'investmentGoalOthers',
        label: 'Other Investment Goals',
        type: FieldTypes.TEXT_FIELD,
        options: [],
        required: true,
        validationType: validationsTypeEnum.REQUIRED,
        hasParentQuestion: true,
        parentQuestionName: "investmentGoalID" 
    }, */
    {
        name: 'advisoryServicesRequiredID',
        label: 'Advisory Services',
        type: FieldTypes.SELECT_FIELD,
        childName: 'otheradvisoryServices',
        required: false,
        validationType: validationsTypeEnum.REQUIRED
    },
    /* {
        name: 'otheradvisoryServices',
        label: 'Other Adivisory Services',
        type: FieldTypes.TEXT_FIELD,
        options: [],
        required: true,
        validationType: validationsTypeEnum.REQUIRED,
        hasParentQuestion: true,
        parentQuestionName: "advisoryServicesRequiredID" 
    }, */
    {
        name: 'referralSource',
        label: 'Referal Source',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        options: [],
        required: false,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'totalAssetsUnderManagement',
        label: 'Total assets under management',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.US_CURRENCY,
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'regulatoryStatusID',
        label: 'Regulatory Status',
        type: FieldTypes.AUTO_SELECT_FIELD,
        childName : "regulatoryStatusName",
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    /* {
        name: 'regulatoryStatusOthers',
        label: 'Other Regulatory Status',
        type: FieldTypes.TEXT_FIELD,
        options: [],
        required: true,
        validationType: validationsTypeEnum.REQUIRED,
        hasParentQuestion: true,
        parentQuestionName: "regulatoryStatusID" 
    }, */
    {
        name: 'tin',
        label: 'Tax Identification number (TIN)',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.TIN,
        required: true,
        validationType: validationsTypeEnum.TIN
    },
]

export const bankingInformationFields = [
    {
        name: 'bankName',
        label: 'Bank Name',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'addressLine1',
        label: 'Address Line 1',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'addressLine2',
        label: 'Address Line 2',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        required: false,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'accountNo',
        label: 'Account Number',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.ONLY_NUMBERS,
        required: true,
        validationType: validationsTypeEnum.ACCOUNT_NUMMBER
    },
    {
        name: 'stateMasterId',
        label: 'State',
        type: FieldTypes.SELECT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        options: [],
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'countryID',
        label: 'Country',
        type: FieldTypes.SELECT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        options: [],
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'city',
        label: 'City',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'branchLocation',
        label: 'Branch Location',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'zipCode',
        label: 'Zip Code',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.ZIPCODE,
        required: true,
        validationType: validationsTypeEnum.ZIP_CODE
    },
]

export const firmDetailsFields = [
    {
        name: 'firmName',
        label: 'Firm Name',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        readOnly: true,
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'firmTypeId',
        label: 'Firm Type',
        type: FieldTypes.SELECT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        options: [],
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'firmId',
        label: 'Entity ID',
        type: FieldTypes.TEXT_FIELD,
        readOnly: true,
        formatType: textFormatTypeEnum.NONE,
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'registrationNumber',
        label: 'Firm Registration Number',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        readOnly: true,
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'regulatoryComplianceStatusId',
        label: 'Regulatory Body',
        type: FieldTypes.SELECT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        options: [],
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'taxFillingContactOthers',
        label: 'Other Regulatory Body',
        type: FieldTypes.TEXT_FIELD,
        options: [],
        required: true,
        validationType: validationsTypeEnum.REQUIRED,
        hasParentQuestion: true,
        parentQuestionName: "regulatoryComplianceStatusId" 
    },
    {
        name: 'jurisdiction',
        label: 'Firm Jurisdiction',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        options: [],
        required: false,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'firmAddress',
        label: 'Address',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        required: false,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'website',
        label: 'Website URL',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        options: [],
        required: false,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'firmStructureId',
        label: 'Firm Structure',
        type: FieldTypes.SELECT_FIELD,
        options: [],
        formatType: textFormatTypeEnum.NONE,
        required: true,
        validationType: validationsTypeEnum.REQUIRED
    },
    {
        name: 'firmDescription',
        label: 'Firm Description',
        type: FieldTypes.TEXT_FIELD,
        formatType: textFormatTypeEnum.NONE,
        required: true,
        validationType: validationsTypeEnum.REQUIRED,
        isMultiline: true
    },
]