import React from 'react'
import { TopNavBar } from '../../../common/navigation-bar/TopNavBar'
import { useState,useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import Grid from '@mui/system/Unstable_Grid/Grid';
import TopBarObq from './TopBarObq';
import ProgressBar from '../../../common/ProgressBar/ProgressBar';
import { Box } from '@mui/system';
import { getQuestionBank } from '../../../administration/InvestorOnBoardingQues/Services/OBQServices';
import { getOnBoardingDetails, updateUserRegistrationStatus } from '../../services/RegistrationProfilingService';
import ObqOptions from './ObqOptions';
import Button from '@mui/material/Button';
import { updateExcistingOBQAnswer } from '../../services/RegistrationProfilingService';
import { CreateOBQAnswer } from '../../services/RegistrationProfilingService';
import { Dialog, DialogTitle, DialogActions, DialogContent} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getInvestorDetails } from '../../../user-profile/InvestorUserProfile/Services/Investorinfo';
import ReactLoading from 'react-loading';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { convertStringToArray } from '../../../common/Functions/ConvertStringtoArray';
import { getOnbordingQuestionPatterns } from '../../../administration/InvestorOnBoardingQues/Services/OBQServices';
import { OnboardingQuestionPattern } from '../../../administration/InvestorOnBoardingQues/OnBoardingEnum';
import { useSelector } from "react-redux";
import { getrootBackgroundColorConfig } from '../../../../state/slices/colorConfigSlice';
import ActionButton from '../../../common/ActionButton/ActionButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const OBQuestions = () => {

  //#region Naviagtions
  const navigate = useNavigate();

  //#region variables
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const [activeitem,setActiveItem] = useState(null);
  const [filledAnswers,setFilledAnsers] = useState(null);
  const [QuestionsDetails,setQuestionsDetails] = useState(null);
  const [Answers,setAnswers] = useState(null);
  const [isfooterpopup,setfooterpopup] = useState(false);
  const [AllInvestmentData,setAllInvestmentData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isReviewenabled,setisReviewenabled] = useState(false);
  const [apiansweritem,setApiansweritem] = useState();
  const [loading,setLoading] = useState(false);

  //For Onboarding Question Pattern TYPES
  const [QuestionTypeOptions,setQuestionTypeOptions] = useState(null);
  const [mcqType,setMcqType] = useState(null);
  const [textType,setTextType] = useState(null);

  //#region api get calls
  const getInvestorInfo = async () => {
    const emailId = secureLocalStorage.getItem("userEmail");
    const data = await getInvestorDetails(emailId);
    if (data.responseCode === 200) {
      console.log(data.responseData);
      const ActiveQuestions = data.responseData?.investmentProfile?.filter((item)=>item.isActive === true);
      setAllInvestmentData(ActiveQuestions);
    } else {
      // Handle error if needed
      /* console.log('error in getInvestorInfo:', data?.responseData); */
    }
  }

  const getallAnswers = async (isallquestionsreq,islastQuestion) => {
    const emailId = secureLocalStorage.getItem("userEmail");
    const data = await getOnBoardingDetails(emailId);
    if (data.responseCode === 200) {
      /*   */
      const apiData = data.responseData;
      setAnswers(data.responseData);
      const lastQuestionId = data?.data ? data.responseData[data.responseData?.length - 1].questionBankID : 0;
      /*   */
      if(isallquestionsreq){
        getallQuestions(lastQuestionId,data.responseData);
      }
      //forlast Question
      if(islastQuestion){
        setApiansweritem(apiData[apiData?.length-1]);
        const findindexitem = QuestionsDetails?.findIndex(
          (item) => item.questionBankID === apiData[apiData?.length-1]?.questionBankID
        ); 
        const ActiveIndex = findindexitem === -1 ? 0 : findindexitem;
        const index = ActiveIndex === 0 ? ActiveIndex : ActiveIndex + 1
        setActiveIndex(index);
        setActiveItem(QuestionsDetails[index]);
      }
    } else {
      // Handle error if needed
      if(isallquestionsreq){
        getallQuestions(null,null); 
      }
    }
  }

  const getallQuestions = async (lastQuestionId,allanswersdata) => {
    const data = await getQuestionBank(secureLocalStorage.getItem("investorType"));
    if (data.responseCode === 200) {
      const ActiveQuestions = data.responseData.filter((item)=>item.isActive === true);
      setQuestionsDetails(ActiveQuestions);
      const findindexitem = ActiveQuestions?.findIndex(
        (item) => item.questionBankID === lastQuestionId
      ); 
      /* const ActiveIndex = findindexitem === -1 ? 0 : findindexitem; */
      const ActiveIndex = allanswersdata?.length > 1 ? allanswersdata?.length - 1 : 0;debugger
      setActiveIndex(ActiveIndex === 0 ? ActiveIndex : ActiveIndex + 1);
      if(allanswersdata?.length >= ActiveQuestions?.length){
        getInvestorInfo();
        setisReviewenabled(true);
      }
      else{
        setActiveItem(ActiveQuestions[ActiveIndex === 0 ? ActiveIndex : ActiveIndex + 1]);
      }
    } else {
      // Handle error if needed
    }
  }

  const getOnbordingQuestionOptions = async () => {
    const data = await getOnbordingQuestionPatterns();
    if (data?.responseCode === 200) {
      const questionPattrenData = data?.responseData?.map((item) => {
        return {
          value: item?.listItemID,
          label: item?.listItemValue,
          };
        });
        setMcqType(questionPattrenData?.find(item => item?.label === OnboardingQuestionPattern.MCQ_TYPE)?.value);
        setTextType(questionPattrenData?.find(item => item?.label === OnboardingQuestionPattern.TEXT_TYPE)?.value);
        setQuestionTypeOptions(questionPattrenData);
      }
    else{
      /* console.log('error in getOnbordingQuestionPatterns:', data?.responseData); */
    }
  }

  //#region click events
  const onNextClick = async() => {
    const nextIndex = activeIndex + 1;
    /*   */
    /*   */
      if (nextIndex < QuestionsDetails?.length) {
        /*   */
        setApiansweritem(Answers?.find((item) => item.questionBankID === QuestionsDetails[nextIndex]?.questionBankID));
        setActiveIndex(nextIndex);
        setActiveItem(QuestionsDetails[nextIndex]);
        //To set the item if user dont do any changes 
        if(apiansweritem?.userProfileID === 0){
          try {
            const response = await CreateOBQAnswer(apiansweritem);
            if (response.responseCode === 200) {
              console.log("API call successful:", response.responseData); 
              /*   */
              getallAnswers(false, false);
            } else {
              console.log("API call unsuccessful. Status:", response.responseCode);
            }
          } catch (error) {
            console.error("API call failed:", error);
          }
        }
        else{
          try {
            const response = await updateExcistingOBQAnswer(apiansweritem);
            if (response.responseCode === 200) {
              console.log("API call successful:", response.responseData);
              getallAnswers(false, false);
            } else {
              console.log("API call unsuccessful. Status:", response.responseCode);
            }
          } catch (error) {
            console.error("API call failed:", error);
          }
        } 
      } else {
        console.log("Cannot go to the next step. Reached the end.");
        if(apiansweritem?.userProfileID === 0){
          try {
            const response = await CreateOBQAnswer(apiansweritem);
            if (response.responseCode === 200) {
              console.log("API call successful:", response.responseData); 
              getallAnswers(false,true);
            } else {
              console.log("API call unsuccessful. Status:", response.responseCode);
            }
          } catch (error) {
            console.error("API call failed:", error);
          }
        }
        else{
          try {
            const response = await updateExcistingOBQAnswer(apiansweritem);
            if (response.responseCode === 200) {
              console.log("API call successful:", response.responseData);
              getallAnswers(false,true); 
            } else {
              console.log("API call unsuccessful. Status:", response.responseCode);
            }
          } catch (error) {
            console.error("API call failed:", error);
          }
        } 
        getInvestorInfo();
        setisReviewenabled(true);
      }
  };
  
  const onPrevClick = () => {
    const prevIndex = activeIndex - 1;
    /*  
    console.log(QuestionsDetails); */
    if (prevIndex >= 0) {
      setApiansweritem(Answers?.find((item) => item.questionBankID === QuestionsDetails[prevIndex]?.questionBankID));
      setActiveIndex(prevIndex);
      setActiveItem(QuestionsDetails[prevIndex]);
    } else {
      console.log("Cannot go to the previous step. Already at the beginning.");
    }
  };

  const onfooterpopupclick = () =>{
    setfooterpopup(true);
  }

  const onfooterpopupclose = () =>{
    setfooterpopup(false);
  }

  const onAnswerclick = (reviewitem) =>{
    setisReviewenabled(false);
    const findindexitem = QuestionsDetails.findIndex(
      (item) => item.questionBankID === reviewitem?.questionID
    );      
    setActiveIndex(findindexitem);
    setActiveItem(QuestionsDetails[findindexitem]);
}

const onBacktoReviewClick = () => {
  setisReviewenabled(true);
}

  //#region update calls
  const onCompleteRegistration = async() =>{
    /*   */
    setLoading(true);
    try {
      const emailId = secureLocalStorage.getItem("userEmail");
    
      const data = await updateUserRegistrationStatus(emailId);
      

      if(data.responseCode === 204){
        navigate('/user-profile');
        return
      }
    
      const InvestorDetails = data.responseData
      if(InvestorDetails.fundID !== 0){
        secureLocalStorage.setItem("FundId", InvestorDetails.fundID);
        secureLocalStorage.setItem("FundName", InvestorDetails.fundName)
        navigate('/fund-details', {
          state: {
            FundID: InvestorDetails.fundID,
            InvestorID: secureLocalStorage.getItem("userId"),
            SubscriptionID: 0,
            navigatefrommarketplace: true,
            navigatefromonboarding: true,
            disableSubscriptionButton:false,
          }
        });
      }
      else{
        navigate('/user-profile');
      }
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setLoading(false);
      console.error("An error occurred:", error);
    }    
  };


  //#region useeffect
  useEffect(() => {
    getallAnswers(true, false);
    getOnbordingQuestionOptions();
  }, []);

  
  //#region components
   const renderMultipleAnswers = (item) => {
      const selectedOptions = convertStringToArray(item?.answer);
      /* const filteredOptions = QuestionsDetails?.find((option) => option.questionBankID === item.questionID)
                              ?.questionAnswer?.filter((option) => selectedOptions.includes(option.questionAnswerID)); */
      const selectedquestion = QuestionsDetails?.find((option) => option.questionBankID === item.questionID);
      const filteredOptions = selectedquestion?.questionAnswer?.filter((option) => selectedOptions.includes(option.questionAnswerID))
      /*   */
      return (
        <ol className='review-multioptions'>
          <div>
            {filteredOptions?.map((option) => (
              <li key={option.questionAnswerID}>{option?.answer}</li>
            ))}
          </div>
        </ol>
      );
   };
    
  const renderReviewOptions = (item) =>{
    return(
         <div className='obq-option-holder' onClick={() => {onAnswerclick(item)}}>
            <div className='space-between'>
              <div>
                <div className='review-optionanswer'>
                  {item.shortName}
                </div>
                    {item.multipleAnswers ? renderMultipleAnswers(item) : 
                        <div className='review-optiondescription'>
                          {item.answer}
                        </div>
                    }
              </div>
              <div className='center-icon-container'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path class="pencilicon" fill="#0a1a27" d="M121.637,221.363h1.2l12.087-12.087-1.2-1.2-12.087,12.087ZM138.39,208.1,134.9,204.61l1.146-1.146a1.648,
                        1.648,0,0,1,2.292,0l1.2,1.2a1.648,1.648,0,0,1,0,2.292Zm-1.146,1.146L123.492,223H120v-3.492l13.752-13.752Zm-2.919-.573-.6-.6,1.2,1.2Z"
                            transform="translate(-120 -203)" /></svg>
              </div>
            </div>
         </div>
    );
  }

  const validateMultiCheckBox = () => {
     
    if(activeitem?.bookmarks === ""){
      return Answers?.find((item) => item.questionBankID === activeitem?.questionBankID)?.answer === "" || null ? true : false;
    }
    else{
      const mimimumselectcount = parseInt(activeitem?.bookmarks)
      const selectedOptions = convertStringToArray(Answers?.find((item) => item.questionBankID === activeitem?.questionBankID)?.answer);
      return !(selectedOptions.length >= mimimumselectcount);
    }
  }

  return (
    <>
    <TopBarObq position="sticky" hideMenuButton={false} hideNotificationButton={true}/>
      {
      !secureLocalStorage.getItem("isRegistrationCompleted") &&
      <div style={{ backgroundColor: rootBackColorConfig.color }} className='investor-onboardbg'>
        {
          QuestionsDetails ? 
            isReviewenabled === true? 
            (<div className='centerFlex-Review'>
                    <div className='obqquestionName'>
                        Please confirm your answers.
                    </div>
                    <div className='reviewdescription'>
                      Before we run the analysis, please review your answers to the questions you just answered for us. You can adjust these now or later by visiting your account settings.
                    </div>
                    <div className='selectedanswers'>
                        {(
                          <div className='obq-option-container'>
                              {AllInvestmentData?.map((item) => renderReviewOptions(item))}
                          </div>)}
                    </div>
                    <div className='obqfooter'>
                        <div className='obqfooter'>
                              <ActionButton 
                                className='btn-primary'
                                variant="contained"
                                label="Complete Registration"
                                loading={loading}
                                onClick={onCompleteRegistration}
                                styleProps={{
                                  padding: '8px 36px'
                              }}>  
                              </ActionButton>
                        </div>
                    </div>
            </div>) : (
            
            <>
            <div className='centerFlex'>
                <div className='ob1Progress'>
                  <div className='stepnumber'>Step {activeIndex + 1} of {QuestionsDetails?.length}</div>
                  <div><ProgressBar progressValue={( (activeIndex + 1) / QuestionsDetails?.length ) * 100} /></div>
                </div>
                <div className='obqquestionName'>
                  {activeitem ? activeitem.questionName : null}
                </div>
                <div className='obqquestionDesc obqsmallfont'>
                  {activeitem ? activeitem.description : null}
                </div>
                <div className='obqoptions'>
                  {
                    activeitem && mcqType && textType? <ObqOptions data={activeitem} setData={setActiveItem}
                    setApiansweritem={setApiansweritem} dataAnswers={Answers} setAnswers={setAnswers}
                    Texquestionformat={textType} optionsformat={mcqType}/> : null
                  }
                </div>
                <div className='obqfooter'>
                  <div className='obqfooter'>
                  {/* <InfoOutlinedIcon
                       onClick={onfooterpopupclick}/> */}
                       <Tooltip className='icon'  onClick={onfooterpopupclick} title="View Details" arrow>
                                               <IconButton>
                                                   <InfoOutlinedIcon />
                                               </IconButton>
                                               </Tooltip>
                      
                      <div className="obqfootertext margin-left-10">{activeitem?.footerText}</div>
                    </div>
                </div>
                <div className="space-between">
                    <div className='leftside-btn'>
                      {activeIndex > 0 && (
                        <Button
                          component="label"
                          variant="contained"
                         className='btn-primary'
                          onClick={onPrevClick}
                        >
                         
                         <ArrowBackOutlinedIcon />
                          Back
                        </Button>
                      )}
                    </div>
                    <div className='rightside-btn'>
                      {activeIndex <= QuestionsDetails?.length - 1 ? (
                            <Button
                              component="label"
                              variant="contained"
                             className='btn-primary'
                              onClick={onNextClick}
                              disabled={
                                activeitem &&
                                activeitem?.onboardingQuestionPattern == mcqType && activeitem?.multipleAnswers === false ? //single chocie
                                (Answers?.find((item) => item.questionBankID == activeitem?.questionBankID)?.questionAnswerID === 0
                                  ? true
                                  : false) : (activeitem?.multipleAnswers == true ? validateMultiCheckBox() //multi answers
                                              : Answers?.find((item) => item.questionBankID == activeitem?.questionBankID)?.answer === "" || null //text answers
                                              ? true
                                              : false)
                              }
                            >
                              Next <ArrowForwardOutlinedIcon/>
                            </Button>
                          ) : (
                            <Button
                              component="label"
                              variant="contained"
                             className='btn-primary'
                              onClick={onNextClick}  
                              disabled={
                                activeitem &&
                                activeitem?.onboardingQuestionPattern === mcqType && activeitem?.multipleAnswers === false ? //single chocie
                                (Answers?.find((item) => item.questionBankID === activeitem?.questionBankID)?.questionAnswerID === 0
                                  ? true
                                  : false) : (activeitem?.multipleAnswers === true ? validateMultiCheckBox() //multi answers
                                              : Answers?.find((item) => item.questionBankID === activeitem?.questionBankID)?.answer === "" || null //text answers
                                              ? true
                                              : false)
                              }
                            >
                              <VisibilityOutlinedIcon/>
                              Review
                            </Button>
                          )}
                    </div>
                </div>
                <div className='align-items-center'>
                  {
                      AllInvestmentData?.length === QuestionsDetails?.length &&
                      <div className='cursor-pointer align-item-center color-blue' onClick={onBacktoReviewClick}>
                        {/* <Tooltip title="Back to Review"> */}
                          <span className='margin-right-10'>Back to Review Screen</span>
                          <ArrowForwardOutlinedIcon sx={{ width: '23px', height: '19px',marginBottom: '6px' }} />
                        {/* </Tooltip> */}
                      </div> 
                  }
                </div>
          </div>
            </>)
           :  <div className='centerFlex'>
                    <ReactLoading type="spin" color="#0000FF" height={100} width={100} />
              </div>
        }
            
            {
              isfooterpopup && 
                                <Dialog open={isfooterpopup} fullWidth>
                                  <DialogTitle>
                                      <div className="space-between">
                                        <div className='popup-footertext'>
                                            {activeitem?.footerText}
                                        </div>
                                        <div>
                                            <CloseIcon sx={{cursor: 'pointer'}}  onClick={onfooterpopupclose}/>
                                        </div>
                                      </div>
                                  </DialogTitle>
                                  <DialogContent>
                                    <div className='popup-footerdesc'>
                                        {activeitem?.footerToggleDescription}
                                      </div>
                                  </DialogContent>
                                </Dialog>
            }
      </div>
    }</>
  )
}

export default OBQuestions;
