import ProgressBar from "../../../common/ProgressBar/ProgressBar";
import ActionButton from '../../../common/ActionButton/ActionButton';

const FundTitle = (props) => {
    const { step, onClose } = props;
    return (
        <>
            <div className="modalCloseBtn">
                <ActionButton
                    variant="text"
                    endIconName="CloseOutlined"
                    onClick={() => onClose()}
                />
            </div>
            <div className="fundHeaderWrapper">
                <div className="fundHeader">Create a Fund</div>
                <div className="fundProgress"><ProgressBar progressValue={(step / 5) * 100} /></div>
                <div className="fundStep">{`${step} of 5`}</div>
            </div>
        </>
    )
}
export default FundTitle;