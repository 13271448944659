export const fundTypesEnum = {
  ALL : -1,
  PRIVATE_EQUITY : "Private Equity",
  PRIVATE_CREDIT : "Private Credit",
  VENTURE_CAPITAL: "Venture Capital",
  REAL_ESTATE_FUND: "Real Estate"
}

export const fundStatusesEnum = {
  ACTIVE: 'avtive',
  IN_ACTIVE: 'in-active'
}

export const fundStatusEnum = {
  TERMINATED: 'Terminated',
  MATURED: 'Matured'
}


export const sortOptions = [
    {
        label: 'Newest to Oldest',
        value: 1,
        isSelected: false
    },
    {
      "label": "Oldest to Newest",
      "value": 2,
      "isSelected": false
    },
    {
      "label": "Min. Investment low to high",
      "value": 3,
      "isSelected": false
    },
    {
      "label": "Min. Investment high to low",
      "value": 4,
      "isSelected": false
    },
    {
      "label": "Term low to high",
      "value": 5,
      "isSelected": false
    },
    {
      "label": "Term high to low",
      "value": 6,
      "isSelected": false
    }
]

export const privateCreditSortOptions = [
  {
      label: 'Newest to Oldest',
      value: 1,
      isSelected: false
  },
  {
    "label": "Oldest to Newest",
    "value": 2,
    "isSelected": false
  },
  {
    "label": "Min. Investment low to high",
    "value": 3,
    "isSelected": false
  },
  {
    "label": "Min. Investment high to low",
    "value": 4,
    "isSelected": false
  },
]

export const termOptions = {
   'name': 'termOptions',
   'minimumvalue': -1,
   'maximumvalue': -1
}

export const minimumInvestmentOptions = {
  'name': 'minimumInvestmentOptions',
  'minimumvalue': -1,
  'maximumvalue': -1
}

export const termSliderMarks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
];

export const investmentSliderData = {
  "label": "Minimum Investment",
  "valueRange": 1-100000000,
}