import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, TextField, Typography, Divider, Paper, Avatar, IconButton, InputAdornment } from '@mui/material';
import { Search, Add, EmojiEmotions, Send } from '@mui/icons-material';
import { getUserList, getUserListForFirm, getUserListForInvestors } from '../../InvestorRelations/Services/IRServices';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import Badge from '@mui/material/Badge';
import { GetReadMessengerMessages, LogMessengerMessages, MarkAsReadMessengerMessages } from './services/messengerServices';
import { height, maxWidth } from '@mui/system';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import CallIcon from '@mui/icons-material/Call';
import VideocamIcon from '@mui/icons-material/Videocam';
import { AppDashboards } from '../../../utils/enum';


const styles = {
  sidebar: {
    width: '300px',
    borderRight: '1px solid #ccc',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F6F6F6',
    overflowY: 'scroll !important',
    height: '80vh'
  },
  chatContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    height: '80vh',
    //maxWidth:'80%'
    //overflowY: 'scroll',
    //maxHeight:'200px'
  },
  userListItem: {
    padding: '10px 20px',
    cursor: 'pointer',
  },
  selectedUserListItem: {
    padding: '10px 20px',
    backgroundColor: '#E4E6EB',
  },
  chatHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    borderBottom: '1px solid #ccc',
    //maxWidth:'70%'
  },
  chatMessages: {
    flex: 1,
    //overflowY: 'auto',
    padding: '20px',
    overflowY: 'scroll'
  },
  chatInputContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    borderTop: '1px solid #ccc',
    backgroundColor: '#F6F6F6',
    borderRadius: '25px',
    margin: '10px',
    width: '400px',
  },
  chatInput: {
    flex: 1,
    marginRight: '10px',
    backgroundColor: '#FFF',
    borderRadius: '25px',
  },
  messageBox: {
    display: 'flex',
    marginBottom: '10px',
  },
  messageText: {
    padding: '10px',
    borderRadius: '10px',
  },
  senderMessage: {
    backgroundColor: '#0084FF',
    color: '#FFF',
    marginLeft: 'auto',
    maxWidth: '60%',
  },
  receiverMessage: {
    backgroundColor: '#E4E6EB',
    color: '#000',
    maxWidth: '60%',
  }

};

function MessengerModel({ onlineUsers, sendMessage, setMessageTo, messagesList, setMessagesList }) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState({});
  const [searchTerm, setSearchTerm] = useState(''); // Added search term state

  const emptyObject = [
    {
      state: '',
      to_user: '',
      from_user: '',
      messageReceived: '',
    },
  ];

  const getUsersList = async () => {
    try {
      const data = await (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD
        ? getUserListForInvestors() // <-- Call the function here
        : getUserListForFirm());    // <-- Call the function here

      if (data?.responseCode === 200) {
        setUsers(data?.responseData.map(user => ({
          ...user,
          isOnline: onlineUsers?.includes(user.adB2CUserName),
          allMessagesForThisUser: messagesList ? (messagesList?.filter(u =>
            (u.to_user === secureLocalStorage.getItem('userName') || u.to_user === user.adB2CUserName)
            && (u.from_user === secureLocalStorage.getItem('userName') || u.from_user === user.adB2CUserName)
            && u.state === "Show") ?? emptyObject) : emptyObject,
        })));
      } else {
        toast.warning(data?.responseData, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
    } catch (cex) {
      console.log(cex);
    }
  };

  const handleUserClick = async (user) => {
    setSelectedUser(user);
    await MarkAsReadMessengerMessages(secureLocalStorage.getItem('userName'), selectedUser?.adB2CUserName)
    setMessageTo(user.adB2CUserName);
    setUnreadMessages((prevUnread) => ({
      ...prevUnread,
      [user.adB2CUserName]: 0,
    }));
    try {
      const data = await GetReadMessengerMessages(secureLocalStorage.getItem('userName'), user.adB2CUserName);
      if (data.responseCode === 200) {
        setMessagesList(data.responseData.map(msg => ({
          from_user: msg.messageFromUserName,
          to_user: msg.messageToUserName,
          messageReceived: msg.messageText,
          state: 'Show',
        })));
      } else {
        //toast.warning(data.responseData, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMessageSend = async () => {
    const requestBody = {
      "instantMessageID": 0,
      "messageFrom": secureLocalStorage.getItem("userId"),
      "messageFromUserName": secureLocalStorage.getItem('userName'),
      "messageTo": selectedUser.userId,
      "messageToUserName": selectedUser.adB2CUserName,
      "messageText": message,
      "messageGroupName": secureLocalStorage.getItem('userName') + '_' + selectedUser.adB2CUserName,
      "isMessageRead": true,
      // "messageDatetime": "2024-08-:25:27.429Z"
    };
    const data = await LogMessengerMessages(requestBody)
    if (message.trim()) {
      const newMessage = {
        from_user: secureLocalStorage.getItem('userName'),
        to_user: selectedUser.adB2CUserName,
        messageReceived: message,
        state: 'Show',
      };
      setMessages([...messages, newMessage]);
      setMessagesList([...messagesList, newMessage]);
      sendMessage(message);
      setMessage('');
    }
  };

  useEffect(() => {
    getUsersList();
  }, []);

  useEffect(() => {
    getUsersList();
  }, [onlineUsers, messagesList]);

  useEffect(() => {
    const handleNewMessage = (message) => {
      const { from_user, to_user, messageReceived } = message;
      const currentUser = secureLocalStorage.getItem('userName');

      if (to_user === currentUser && from_user !== selectedUser?.adB2CUserName &&
        messageReceived.indexOf("joined") === -1 && from_user !== "MyChat Bot") {
        setUnreadMessages((prevUnread) => ({
          ...prevUnread,
          [from_user]: (prevUnread[from_user] || 0) + 1,
        }));
      }
    };

    messagesList.forEach(handleNewMessage);
  }, [messagesList, selectedUser]);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));


  return (
    <Box>
      {/* <h3>Instant Messanger</h3> */}
      <div className='instantMessangerbox'>
        {/* <Typography variant="h3" style={{ padding: '20px' }}>Instant Messenger</Typography> */}
        <Box sx={styles.sidebar}>
          {/* <Typography variant="h6" style={{ padding: '20px' }}>Chats</Typography> */}
          <TextField
            placeholder="Search messenger..."
            variant="outlined"
            size="small"
            value={searchTerm} // Bind the search term
            onChange={(e) => setSearchTerm(e.target.value)} // Update search term
            style={{ margin: '10px 20px 10px' }}
            InputProps={{
              startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
            }}
          />
          <Divider />
          <List>
            {users.filter(user => user.adB2CUserName.toLowerCase().includes(searchTerm.toLowerCase()))
              .filter(u => u.adB2CUserName.length > 0 && secureLocalStorage.getItem("userName") !== u.adB2CUserName)?.map((user) => (
                <ListItem
                  key={user.adB2CUserName}
                  sx={selectedUser?.adB2CUserName === user.adB2CUserName ? styles.selectedUserListItem : styles.userListItem}
                  onClick={() => handleUserClick(user)}
                >
                  <div>
                    {user.isOnline ? (
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                      >
                        <Avatar alt={user.profileIcon ? user.profileIcon : user.adB2CUserName
                        } src={user.profileIcon ? user.profileIcon : "/static/images/avatar/1.jpg"} sx={{ marginRight: '5px' }} />
                      </StyledBadge>
                    ) : (
                      <Avatar
                        style={{ marginRight: '5px' }}
                        alt={user.profileIcon ? user.profileIcon : user.adB2CUserName
                        } src={user.profileIcon ? user.profileIcon : "/static/images/avatar/1.jpg"}
                      />
                    )}
                  </div>
                  {/* <span style={{position:'absolute', marginTop:'-15px'}} >43</span> */}
                  <ListItemText primary={<div>
                    <span>{user.adB2CUserName}</span>
                    {/* {user.isOnline === true ? <span className="userGreenIndicator"  ></span> : <span className="userGrayIndicator"  ></span>} */}
                  </div>} />
                  {unreadMessages[user.adB2CUserName] > 0 && (
                    <Typography variant="body2" color="error">
                      <Badge badgeContent={unreadMessages[user.adB2CUserName]} color="primary">
                      </Badge>
                    </Typography>
                  )}
                </ListItem>
              ))}
          </List>
        </Box>
        <Box sx={styles.chatContainer}>
          <Box sx={{ ...styles.chatHeader, justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {selectedUser ? (
                <>
                  <div>
                    {selectedUser.isOnline ? (
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                      >
                        <Avatar alt={selectedUser.profileIcon ? selectedUser.profileIcon : selectedUser.adB2CUserName
                        } src={selectedUser.profileIcon ? selectedUser.profileIcon : "/static/images/avatar/1.jpg"} sx={{ marginRight: '5px' }} />
                      </StyledBadge>
                    ) : (
                      <Avatar style={{ marginRight: '5px' }} alt={selectedUser.profileIcon ? selectedUser.profileIcon : selectedUser.adB2CUserName
                      } src={selectedUser.profileIcon ? selectedUser.profileIcon : "/static/images/avatar/1.jpg"} />
                    )}
                  </div>
                  <Typography variant="h6" sx={{ marginLeft: '10px' }}>
                    {selectedUser.adB2CUserName}
                  </Typography>
                </>
              ) : <Typography variant="h6" sx={{ height: '40px' }}>Select a user to start chatting</Typography>}
            </Box>
            {selectedUser && (
              <Box>
                <IconButton>
                  <CallIcon />
                </IconButton>
                <IconButton>
                  <VideocamIcon />
                </IconButton>
              </Box>
            )}
          </Box>

          <Box sx={styles.chatMessages}>
            {selectedUser && messagesList
              .filter(
                (msg) =>
                  (msg.to_user === secureLocalStorage.getItem('userName') && msg.from_user === selectedUser.adB2CUserName) ||
                  (msg.from_user === secureLocalStorage.getItem('userName') && msg.to_user === selectedUser.adB2CUserName)
              )
              .map((msg, index) => (
                <Box key={index} sx={styles.messageBox}>
                  <Paper
                    elevation={3}
                    sx={{
                      ...styles.messageText,
                      ...(msg.from_user === secureLocalStorage.getItem('userName')
                        ? styles.senderMessage
                        : styles.receiverMessage),
                    }}
                  >
                    {msg.messageReceived}
                  </Paper>
                </Box>
              ))}
          </Box>
          {selectedUser && (
            <Box sx={styles.chatInputContainer}>
              <TextField
                placeholder="Type your message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleMessageSend()}
                sx={styles.chatInput}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <EmojiEmotions />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton color="primary" onClick={handleMessageSend}>
                <Send />
              </IconButton>
            </Box>
          )}
        </Box>
      </div>

      <ToastContainer />
    </Box>
  );
}

export default MessengerModel;
