import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import LinearProgress from '@mui/material/LinearProgress';
import { ComponentsTab } from '../../common/Tabs/ComponentsTab';
import AudtingGrid from './AudtingGrid';
import { HeaderTemplates, PageHeader } from '../../common/PageHeader/PageHeader';
import { AuditingType, CompanyDetails, PCTransactionDetailsColumm } from '../audititngenum';
import { useLocation } from 'react-router-dom';
import { getPCAuditing } from '../services/services';
import TabsComponment from '../../common/Tabs/TabsComponment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BusinessIcon from '@mui/icons-material/Business';

import moment from 'moment';

const PCAudit = () => {
  //#region variables

  const [companyDetailsInfo, setCompanyDetailsInfo] = useState(null);
  const [pCTransactionDetailsInfo, setPCTransactionDetailsInfo] = useState();


  const [companyDetailsColumns, setCompanyDetailsColumns] = useState(null);
  const [pCTransactionDetailsColumns, setPCTransactionDetailsColumns] = useState();
  const [companyName, setCompanyName] = useState();


  const [selectedTab, setSelectedTab] = useState(0);
  const location = useLocation();
  const { state } = location;
  const auditingType = state.auditingType;

  const columns = [
    { field: 'field', headerName: '', width: 400, },
    { field: 'value', headerName: '', width: 400 },
  ]

  //#region tabs
  const [tabs, setTabs] = useState([]);

  const PCTabs = [
    {
      key: 0,
      icon: <BusinessIcon />,
      label: "COMPANY DETAILS",
      component: <AudtingGrid />
    },
    {
      key: 1,
      icon: <ReceiptIcon />,
      label: "TRANSACTION DETAILS",
      component: <AudtingGrid />
    }
  ]

  //#region api get calls
  const getFundAuditInfo = async () => {
    const selectedID = state.auditID;

    const data = await getPCAuditing(selectedID);

    if (data?.responseCode === 200) {
      // Extract personal information fields
      const companyDetails = data.responseData?.companyDetails;
      const pCTransactionDetails = data.responseData?.pCTransactionDetails;
      if (data.responseData?.companyDetails != null) {
        setCompanyName(data.responseData?.companyDetails[0].companyName);
      }
      const companyDetailsFields = companyDetails?.map((dataItem, index) => ({
        id: index,
        ...dataItem,
        updatedDate: moment(dataItem.updatedDate).format('MM-DD-YYYY HH:mm'),
      }));
      /* debugger */
      const companyDetailsColumns = companyDetails && companyDetails?.length > 0 ?
        Object.entries(companyDetails[0]).map(([key, value], index) => ({
          field: key,
          headerName: CompanyDetails[key],
          width: 200,
        })) : [];

      const pCTransactionDetailsFields = pCTransactionDetails?.map((dataItem, index) => ({
        id: index,
        ...dataItem,
        updatedDate: moment(dataItem.updatedDate).format('MM-DD-YYYY HH:mm'),
      }));
      // debugger 
      const pCTransactionDetailsColumns = pCTransactionDetails && pCTransactionDetails.length > 0 ?
        Object.entries(pCTransactionDetails[0]).map(([key, value], index) => ({
          field: key,
          headerName: PCTransactionDetailsColumm[key],
          width: 200,
        })) : [];

      // debugger 
      setCompanyDetailsColumns(companyDetailsColumns)
      setCompanyDetailsInfo(companyDetailsFields);
      setPCTransactionDetailsColumns(pCTransactionDetailsColumns);
      setPCTransactionDetailsInfo(pCTransactionDetailsFields);

    } else {
      // Handle error if needed
    }
  }

  //#region useEffect
  useEffect(() => {
    getFundAuditInfo();
  }, [])

  useEffect(() => {
    if (auditingType === AuditingType.PC) {
      let modifiedTabs = PCTabs;
      if (modifiedTabs[0] && selectedTab === 0) {
        modifiedTabs[0].component = <AudtingGrid columns={companyDetailsColumns} rows={companyDetailsInfo} fileName={`${companyName}_CompanyDetailsAuditData`} />;
      }
      if (modifiedTabs[1] && selectedTab === 1) {
        modifiedTabs[1].component = <AudtingGrid columns={pCTransactionDetailsColumns} rows={pCTransactionDetailsInfo} fileName={`${companyName}_CompanyTransactionDetailsAuditData`} />;
      }
      setTabs(modifiedTabs)
    }
  }, [companyDetailsInfo, selectedTab])

  //#region return
  return (
    <div>
      <PageHeader
        title="Auditing Overview"
        template={HeaderTemplates.NORMAL_VIEW} />
      <div className="wrapper3">
        {companyDetailsInfo && companyDetailsColumns ? (
          <>

            <TabsComponment tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

          </>
        ) : (
          <Grid container xs={12} className='whiteCard'>
            <LinearProgress />
          </Grid>
        )}
      </div>
      <ToastContainer />
    </div>
  )
}

export default PCAudit