import axios from 'axios';
import resolve from '../../../common/resolve';
import secureLocalStorage from 'react-secure-storage';
import axiosApiCall from '../../../Services/axiosApiCall';
import { getAPIResponse } from '../../../../utils/common';


export async function getConfiguredQuestions() {
    return await axiosApiCall.get(`/v1/QuestionBank/getConfiguredSubscriptionQuestions`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getQuestionBankTree(investorTypeID,fundTypeID,questionTypeID) {
    return await axiosApiCall.get(`/v1/QuestionBank/getQuestionBankTree?investorTypeID=${investorTypeID}&fundTypeID=${fundTypeID}&questionTypeID=${questionTypeID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        });
}

export async function getDefaultConfigurations() {
    return await axiosApiCall.get(`/v1/QuestionBank/DefaultSubscriptionQuestionConfigurations`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
    });
}

export async function setQuestion(questionDetails) {
    return await axiosApiCall.post(`/v1/QuestionBank/SetSubscriptionQuestion`, questionDetails)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
    });
}

export async function addUpdateQuestion(questionDetails) {
    return await axiosApiCall.post(`/v1/QuestionBank/AddUpdateQuestion`, questionDetails)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
    });
}

export async function setSubscriptionQuestionStep(details) {
    return await axiosApiCall.post(`/v1/QuestionBank/SetSubscriptionQuestionStep`, details)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
    });
}

export async function addQuestionBankStep(details) {
    return await axiosApiCall.post(`/v1/QuestionBank/AddQuestionBankStep`, details)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
    });
}

export async function updateSubscriptionQuestionOrder(details) {
    return await axiosApiCall.post(`/v1/QuestionBank/updateSubscriptionQuestionOrder`, details)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
    });
}
