import React from 'react'
import { useState } from 'react'
import { FMRegistartionStep } from '../FirmRegistartionenum';
import { TopNavBar } from '../../../../common/navigation-bar/TopNavBar';
import FirmOnboardingProfile from './FirmOnboardingProfile';
import OBChangePassword from './OBChangePassword';
import FirmRegistrationDetails from './FirmRegistrationDetails';
import secureLocalStorage from 'react-secure-storage';
import Button from '@mui/material/Button';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { getUserByEmail } from '../../../services/RegistrationProfilingService';
import { useEffect } from 'react';
import ReactLoading from 'react-loading';
import { updateUserName } from '../../../services/RegistrationProfilingService';
import { updateFirmRegistartionData } from '../../../../administration/Services/TabsData';
import { ToastContainer, toast } from 'react-toastify';
import { AppDashboards } from '../../../../../utils/enum';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle, DialogActions, DialogContent, Grid } from '@mui/material';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../../../../AppAuthentication/authConfig';

const FirmRegistationModel = () => {
  //#region initialData
  const InitialData = {
    newpassword: '',
    confirmpassword: ''
  }

  //#region usestate variables
  const [firmUserDetails, setFirmUserDetails] = useState(null);
  const [Passwords, setPasswords] = useState(InitialData);
  const [btnloading, setLoading] = useState(false);
  const [skipbtnloading, setSkipLoading] = useState(false);
  const [currstep, setCurrStep] = useState(1);
  const navigate = useNavigate();
  const firstStep = 1;
  const lastStep = 2;

  //#region popup
  const [openPopup, setOpenPopUp] = useState(false);
  const msalInstance = new PublicClientApplication(msalConfig);

  //#region click events
  const handleNavigation = (next) => {
    if (next && currstep < lastStep) {
      setCurrStep(currstep + 1);
    } else if (!next && currstep > 1) {
      setCurrStep(currstep - 1);
    }
  }
  const Logout = () => {
    setOpenPopUp(false);
    secureLocalStorage.clear();
    msalInstance.logoutRedirect({ postLogoutRedirectUri: "/?login_page=true" });
  };

  //#region API GET Calls
  const getUserDetails = async () => {
    const response = await getUserByEmail(secureLocalStorage.getItem('userEmail'));
    if (response.responseCode == 200) {
      setFirmUserDetails(response.responseData);
    }
    else {
      /* console.error('Error in fetching user details'); */
    }
  }

  //#region API post calls
  const handleSubmit = async (isPasswordSkip) => {
    //based on skip show loader
    if (isPasswordSkip) setSkipLoading(true); else setLoading(true);
    let requestBody = {
      "userId": firmUserDetails?.userId,
      "userFirstName": firmUserDetails?.firstName,
      "userLastName": firmUserDetails?.lastName,
      "userName": firmUserDetails?.userName,
      "userEmailAddress": firmUserDetails?.emailAddress,
      "phoneNumber": firmUserDetails?.phoneNo,
      "dateOfBirth": firmUserDetails?.dateOfBirth || null,
      "profilePath": firmUserDetails?.profileIcon,
      "briefDescription": firmUserDetails?.briefDescription,
      "userRoleID": firmUserDetails?.userRoleId,
      "userRoleName": firmUserDetails?.roleName,
      "tenantGUID": firmUserDetails?.tenantGUID,
      "isRegistrationCompleted": firmUserDetails?.isRegistrationCompleted
    } 

    const requestedBody = {
      "userId": secureLocalStorage.getItem("userId"),
      "userFirstName": firmUserDetails?.firstName,
      "userLastName": firmUserDetails?.lastName,
      "userEmailAddress": secureLocalStorage.getItem("userEmail"),
      "userName": firmUserDetails?.userName,
      "investorTypeID": 0,
      "investorSubTypeID":  0
    }

    /*   */
    const data = await updateUserName(requestedBody);

    if (data?.responseCode == 200) {
      //userName available
      const formData = new FormData();
      formData.append('profileImage', firmUserDetails?.profileIcon ? firmUserDetails.profileIcon : null);
      if(typeof(firmUserDetails?.profileIcon) === 'object'){
        requestBody = {...requestBody, profilePath : null};
      }
      formData.append('userData', JSON.stringify(requestBody));
      let result = '';
      if (isPasswordSkip) {
        result = await updateFirmRegistartionData(formData, "");
      }
      else {
        result = await updateFirmRegistartionData(formData, Passwords.confirmpassword);
      }
      if (result.responseCode === 200) {
        console.log('Data saved successfully:', result);
        if (result.responseCode === 200) {
          //based on skip show loader
          if (isPasswordSkip) setSkipLoading(false); else setLoading(false);
          if (!isPasswordSkip) {
            setOpenPopUp(true);
            return;
          }
          secureLocalStorage.setItem("userName", firmUserDetails?.userName);
          if (firmUserDetails.userDashboard === AppDashboards.CO_DASHBOARD) {
            navigate("/CoDashboard");
          } else if (firmUserDetails.userDashboard === AppDashboards.FIRM_DASHBOARD) {
            navigate("/firm-wide-dashboard");
          } else if (firmUserDetails.userDashboard === AppDashboards.FM_DASHBOARD) {
            navigate("/FundManager-Dashboard");
          }
          else if (firmUserDetails.userDashboard === AppDashboards.PC_DASHBOARD) {
            secureLocalStorage.setItem("FundId", firmUserDetails.pcFundId);
            secureLocalStorage.setItem("companyID", firmUserDetails.pcCompanyId);
            firmUserDetails.currentOnboardingStep === 5 ?
              navigate('/PortfolioOnboarding') :
              navigate("/portfolio-company-overview");
          }
        }
      }
      else {
        //based on skip show loader
        if (isPasswordSkip) setSkipLoading(false); else setLoading(false);
        toast.error("Error Updating Details",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
    }
    else {
      //userName not available
      toast.warning("error updating details",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      //based on skip show loader
      if (isPasswordSkip) setSkipLoading(false); else setLoading(false);
    }
  }

  //#region useEffect
  useEffect(() => {
    getUserDetails();
  }, []);

  //#region render popup
  const reloginPopup = () => {
    return (
      <Dialog open>
        <DialogTitle>

        </DialogTitle>
        <DialogContent>
          Password has been changed, please relogin.
        </DialogContent>
        <DialogActions>
          <Button className='btn-primary' variant='contained' onClick={() => { Logout() }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  //#region render components
  const renderStep = () => {
    switch (currstep) {
      case 1:
        return <FirmOnboardingProfile firmUserDetails={firmUserDetails} setFirmUserDetails={setFirmUserDetails} handleNavigation={handleNavigation} />
      case 2:
        return <OBChangePassword handleNavigation={handleNavigation} handleSubmit={handleSubmit} skipbtnloading={skipbtnloading} btnloading={btnloading} Passwords={Passwords} setPasswords={setPasswords} />
      default:
        return "Invalid Step"
    }
  }

  return (
    <div>
      {firmUserDetails ? renderStep() :
        (
          <div className="loading-container">
            <ReactLoading type="spin" color="#0000FF" height={100} width={100} />
          </div>
        )}
      <ToastContainer />
      {openPopup && reloginPopup()}
    </div>
  )
}

export default FirmRegistationModel
