import React, { useEffect, useState } from 'react'
import { getUserDetails } from "../../../state/slices/userDetailsSlice";
import { useSelector } from "react-redux";
import { firmWideChartData } from '../jsonData';
import AIPChart from '../../common/DataChart/AIPChart';
import { Grid } from '@mui/material';
import Indicator from '../../common/DataGrid/Indicator';
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid';
import CurrencyIndicator from '../../common/DataGrid/CurrencyIndicator';
import { CurrencyFormatter } from '../../../utils/Formater';
import { getFirmWideDashboardDetails } from '../services/services';
import PercentageIndicator from '../../common/DataGrid/PercentageIndicator'; 
import SelectField from '../../common/input-fields/SelectField';
import { FieldVariants } from '../../common/TextInput/appInputenum';
import { coFundTypeDropdownOptions } from '../../FundManagerDashboard/fundTypeValues';
import CircleIcon from '../../chatbot/Icon';
import { getKeyValuePairs } from '../../portfolio-companies/services/services';
import { fundTypesEnum } from '../../marketplace-dashboard/jsonData';
import { color } from 'd3';

const FirmWideDashboard = () => {

  //#region intialdata
  const intialData = {
    "funds" : fundTypesEnum.ALL,
 }

  //#region variables
  const userDetails = useSelector(getUserDetails);
  const [dashboardDetails,setDashboardDetails] = useState(null);
  const [fundsDetails,setFundDetails] = useState([]);
  const [filters,setFilters] = useState(intialData)
  const [fundTypes,setFundTypes] = useState([]);
  const [statusBars, setStatusBars] = useState([
    {
      id: 1,
      title: 'Total Number of Funds',
      value: dashboardDetails?.new || '.'
    },
    {
      id: 2,
      title: 'Total Committed',
      value: dashboardDetails?.updateRequired || '.'
    },
    {
      id: 3,
      title: 'Total Funded',
      value: dashboardDetails?.underReview || '.'
    },
    {
      id: 4,
      title: 'Gross IRR',
      headerClassName: "dummydata-yellow",
      description: "Dummy Data",
      value: dashboardDetails?.underReview || '-s'
    }
  ]);

  const AllFundsColumns = [
    {
      id: 1,
      field: "fundName",
      headerName: "Fund Name",
      width: 220,
      renderCell: (params) => {
          return <Indicator params={params} />
      }
    },
    {
      id:2,
      field:'fundTypeName',
      headerName:'Fund Type',
      width:150,

    },
    {
      id: 2,
      field: "fundStatusName",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
          return <Indicator params={params} />
      }
    },
    {
      id: 3,
      field: "capitalCommitted",
      headerName: "Capital Committed",
      width: 180,
      renderCell: (params) => {
          return <CurrencyIndicator params={params}/>
      }
    },
    {
      id: 4,
      field: "funded",
      headerName: "Funded",
      width: 100,
      renderCell: (params) => {
        return <CurrencyIndicator params={params}/>
      }
    },
    {
      id: 5,
      field: "targettedIRR",
      headerName: "IRR",
      width: 100,
      headerClassName: "dummydata-yellow",
      description: "Dummy Data",
      renderCell: (params) => {
        return <PercentageIndicator params={params}/>
      }
    },
    {
      id: 6,
      field: "numberofPortfolioCompanies",
      headerName: "Portfolio Companies",
      width: 150,
      renderCell: (params) => {
        return params?.value || 0
    }
    },
    {
      id: 6,
      field: "fundManager",
      headerName: "Fund Manager",
      width: 100,
    }
  ];

  const AllInvestorsColumns = [
    {
      id: 1,
      field: "userName",
      headerName: "Investor Name",
      width: 220,
      renderCell: (params) => {
          return <Indicator params={params} />
      }
    },
    {
      id: 2,
      field: "investorType",
      headerName: "Investor Type",
      width: 220,
      renderCell: (params) => {
          return <Indicator params={params} />
      }
    },
    {
      id: 3,
      field: "aum",
      headerName: "AUM",
      headerClassName: "dummydata-yellow",
      description: "Dummy Data",
      width: 120,
      renderCell: (params) => {
          return <CurrencyIndicator params={params}/>
      }
    },
    {
      id: 4,
      field: "capitalCommitted",
      headerName: "Capital Committed",
      width: 200,
      renderCell: (params) => {
          return <CurrencyIndicator params={params}/>
      }
    },
    {
      id: 5,
      field: "totalFundsReceived",
      headerName: "Funded",
      width: 100,
      renderCell: (params) => {
        return <CurrencyIndicator params={params}/>
      }
    },
    {
      id: 6,
      field: "irr",
      headerName: "IRR",
      headerClassName: "dummydata-yellow",
      description: "Dummy Data",
      width: 100,
      renderCell: (params) => {
        return <PercentageIndicator params={params}/>
      }
    },
    {
      id: 7,
      field: "noOfFunds",
      headerName: "# of Funds",
      width: 150,
    }
  ];

  const AllMangersColumns = [
    {
      id: 1,
      field: "firstName",
      headerName: "Manager Name",
      width: 220,
      renderCell: (params) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{params.row.firstName}</span>
                <span style={{ marginLeft: '0.2rem' }}>{params.row.lastName}</span>
            </div>
        );
      }
    },
    {
      id: 2,
      field: "aum",
      headerName: "AUM",
      headerClassName: "dummydata-yellow",
      description: "Dummy Data",
      width: 220,
      renderCell: (params) => {
          return <CurrencyIndicator params={params}/>
      }
    },
    {
      id: 6,
      field: "noofFunds",
      headerName: "# of Funds",
      width: 150,
      renderCell: (params) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{params.row.noofFunds ? params.row.noofFunds : 0}</span>
            </div>
        );
      }
    },
    {
      id: 3,
      field: "committedCapital",
      headerName: "Capital Committed",
      width: 200,
      renderCell: (params) => {
          return <CurrencyIndicator params={params}/>
      }
    },
    {
      id: 4,
      field: "funded",
      headerName: "Funded",
      width: 100,
      renderCell: (params) => {
        return <CurrencyIndicator params={params}/>
      }
    },
    {
      id: 5,
      field: "irr",
      headerName: "IRR",
      headerClassName: "dummydata-yellow",
      description: "Dummy Data",
      width: 100,
      renderCell: (params) => {
        return <PercentageIndicator params={params}/>
      }
    }
  ];

  //#region change events
  const handleChange = (field,value) => {
    const currState = { ...filters };
    currState[field] = value;
    setFilters(currState)
  }
  
  //#region api get calls
  const getDashboardDetails = async() => {
    const data = await getFirmWideDashboardDetails();
    if(data.responseCode === 200){
      setDashboardDetails(data.responseData);
    }else{

    }
  }

  const getFundTypes = async() => {
    const data = await getKeyValuePairs("FundType");
    if(data.responseCode === 200){
        const dropdownValues = data.responseData?.map(option => ({ label: option.listItemValue, value: option.listItemID })) || []
        setFundTypes(dropdownValues)
    }
    else{
        setFundTypes([])
    }
  }

  //#region useeffect
  useEffect(()=>{
    getDashboardDetails();
    getFundTypes();
  },[])

  useEffect(() => {
    setStatusBars([
      {
        id: 1,
        title: 'Total Number of Funds',
        value: dashboardDetails?.totalNumberOfFunds || '-'
      },
      {
        id: 2,
        title: 'Total Committed',
        value: CurrencyFormatter(dashboardDetails?.totalCommitted) || '-'
      },
      {
        id: 3,
        title: 'Total Funded',
        value: CurrencyFormatter(dashboardDetails?.targetRaised) || '-'
      },
      {
        id: 4,
        title: 'Gross IRR',
        color:'yellow',
        headerClassName: "dummydata-yellow",
        description: "Dummy Data",
        value: dashboardDetails?.irr || '-'
      }
    ]);
  }, [dashboardDetails]);

  useEffect(() => {
    const allFunds = dashboardDetails?.fundDetails?.map((fund) => ({
      id: fund.fundID,
      ...fund
    })) || [];
    if(filters?.funds === fundTypesEnum.ALL){
      setFundDetails(allFunds)
    }else{
      // debugger
      setFundDetails(allFunds?.filter((fund)=>fund?.fundTypeID === filters.funds))
    }
  }, [filters, dashboardDetails]);
  

  //#region return
  return (
    <div className='wrapper mt2-page'>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12}>
          <div className="font-size-color">
            Welcome Back, {userDetails?.fullName}
          </div>
          <h1>Firm Wide Dashboard</h1>
        </Grid>
        <Grid item container lg={12} md={12} xs={12}  display={"flex"} justifyContent={"space-evenly"}>
          {
            statusBars.map((item,index)=>{
              return (
                <Grid item container xs={12} md={2.89} className='kyc-statuscard' key={index}>
                  <Grid item xs={12} className={`${item.headerClassName}`}>
                      {item.title}
                  </Grid>
                  <Grid item xs={12} marginTop={1} className='kyc-statusValue'>
                      {item.value}
                  </Grid>
                </Grid>
              )
            })
          }
        </Grid>
        <Grid item container xs={12} display={"flex"} justifyContent={"space-evenly"}>
          <Grid item xs={12} className='white-card'>
            <div className='display-column'>
               <h6 className='dummydata-yellow' title='Dummy Data'>Firm Performance</h6>
               <div className='height-360'>
                  <AIPChart
                    series={firmWideChartData.map((i) => i.value)}
                    labels={firmWideChartData.map((i) => i.label)}
                    text={"Total investment made till date"} />
               </div>
            </div>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} className='white-card'>
            <div className='display-column'>
               <div className="space-between">
                  <h6>All Funds</h6>
                  <div className='dash-select'>
                    <SelectField
                        className="fundtype-select"
                        name='funds'
                        label="Fund Type"
                        size='small'
                        fullWidth
                        variant={FieldVariants?.OUTLINED}
                        value={filters?.funds}
                        options={fundTypes}
                        onChange={(name, value) => handleChange(name, value)} />
                  </div>
                </div>
                <div className='height-360'>
                  <AIPDataGrid
                    columns={AllFundsColumns}
                    rows={fundsDetails || []}
                    handleRowClick={() => {}}
                    onRowsSelectionHandler={() => {}}
                    sx={{
                      "& .MuiDataGrid-columnHeader.dummydata-yellow": {
                          backgroundColor: "yellow",
                          color: "#ffffff",
                          fontWeight: "bold"
                        }   
                      }}
                    />
                </div>
            </div>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} className='white-card'>
            <div className='display-column'>
               <h6>All Investors</h6>
                <div className='height-360'>
                  <AIPDataGrid
                    columns={AllInvestorsColumns}
                    rows={dashboardDetails?.investorDetails?.map((user) => {
                      return {
                        id: user.userId,  ...user
                      }
                    }) || []}
                    handleRowClick={() => {}}
                    onRowsSelectionHandler={() => {}}
                    sx={{
                      "& .MuiDataGrid-columnHeader.dummydata-yellow": {
                          backgroundColor: "yellow",
                          color: "#ffffff",
                          fontWeight: "bold"
                        }   
                      }}
                    />
                </div>
            </div>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} className='white-card'>
            <div className='display-column'>
               <h6>All FundManagers</h6>
                <div className='height-360'>
                  <AIPDataGrid
                      columns={AllMangersColumns}
                      rows={dashboardDetails?.fundManagerDetails?.map((user) => {
                        return {
                          id: user.userId, ...user
                        }
                      }) || []}
                      handleRowClick={() => {}}
                      onRowsSelectionHandler={() => {}}
                      sx={{
                        "& .MuiDataGrid-columnHeader.dummydata-yellow": {
                            backgroundColor: "yellow",
                            color: "#ffffff",
                            fontWeight: "bold"
                          }   
                        }}
                      />
                </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <CircleIcon/>
    </div>
  )
}

export default FirmWideDashboard