import React, { useEffect, useState } from 'react'
import { HeaderTemplates, PageHeader } from '../PageHeader/PageHeader'
import { toast, ToastContainer } from 'react-toastify';
import { downloadSharedLinkVDRItem } from './Services/VDRServices';
import secureLocalStorage from 'react-secure-storage';

const SharedVdrFile = () => {

  //#region variables  
  const [message, setMessage] = useState("Loading...");
  const queryParams = new URLSearchParams(window.location.search);
  const vdrItemID = queryParams.get('vdrItemID');
  const fileName = queryParams.get('fileName');

  //#region api get calls
  const handleDownloadFile = async () => {
    try {
      // Show loading state if needed
      // setSbtLoading(true);

      const data = await downloadSharedLinkVDRItem(vdrItemID);

      if (data.responseCode === 200) {
        const contentDisposition = data.headers['content-disposition'];

        // Extract the filename using regex
        const filenameMatch = contentDisposition && contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        let filename = 'downloaded_file.docx'; // Fallback filename

        if (filenameMatch && filenameMatch[1]) {
          filename = filenameMatch[1].replace(/['"]/g, '');
        }

        // Handle different types of response data
        let responseData;
        if (data.responseData instanceof ArrayBuffer) {
          responseData = new Uint8Array(data.responseData);
        } else if (typeof data.responseData === 'string') {
          responseData = new TextEncoder().encode(data.responseData);
        } else {
          responseData = data.responseData; // Assuming it's already a valid format for Blob
        }

        // Convert response data to a Blob
        // Create a download link
        const blobUrl = URL.createObjectURL(responseData);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = filename;

        // Append link to the body and trigger click
        document.body.appendChild(link);
        link.click();

        // Cleanup
        link.remove();
        URL.revokeObjectURL(blobUrl);
      } else {
        console.error('Failed to download file, response code:', data.responseCode);
      }
    } catch (error) {
      console.error('Error during file download:', error);
    } finally {
      // Hide loading state if needed
      // setSbtLoading(false);
    }
  }

  //#region useeffect
  useEffect(() => {
    if (secureLocalStorage.getItem("userLoggedIn")) {
      handleDownloadFile();
    }
  }, [])

  //#region return
  return (
    <div>
      <PageHeader
        title="Shared File"
        template={HeaderTemplates.NORMAL_VIEW}
        isHideBackbtn={true} />
      <div className='align-items-center child-margin-15'>
        <h6 className='margin-top-20'>
          {message}
        </h6>
        <div class='margin-top-20 child-margin-5'>
          <div>Please click the link below to initiate the Manual download </div>
          <a href='#' onClick={handleDownloadFile} className='margin-left-5'>
            Download File
          </a>
        </div>
      </div>
    </div>
  )
}

export default SharedVdrFile