import React from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { checkFeaturePermissions } from '../../../utils/common';
import { FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import { Tabs } from '../../common/Tabs/Tabs';
import ContextMenu from '../../common/ContextMenu/ContextMenu';
import FundDetailsDialog from '../../marketplace/components/FunddetailsPopUp';
import DialogPopup from '../../common/DialogPopup/DialogPopup';
import { fundTypesEnum } from '../../marketplace-dashboard/jsonData';
import axiosApiCall from '../../Services/axiosApiCall';
import stylesfortab from '../../dashboards/FundDetailDashboards/OverView.module.css'
import { HeaderTemplates, PageHeader } from '../../common/PageHeader/PageHeader';


export const MarketplaceTabTemplateType = {
  OVERVIEW: 'OverviewPage',
  PERFORMANCE: 'PerformancePage',
  PORTFOLIO: 'portfolio-page',
  INVESTOR: 'fund-detail-investor-relations',
  SERVICES: 'fund-detail-services-1',
  COMPLIANCE: 'fund-detail-compliance'
}

const OverviewHeader = (props) => {

  //#region props
  const { fundDetails,getFundDetails } = props; 

  //#region variables
  const navigate = useNavigate();
  const params = useParams();
  const [currentTab, setCurrentTab] = useState(props.selectedTab);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [SupportedFile, setSupportedFile] = useState()
  const [Terminate, setTerminate] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [tabs, setTabs] = useState([
    {
      icon: <InfoOutlinedIcon />,
      label: 'OVERVIEW',
      key: MarketplaceTabTemplateType.OVERVIEW
    },
    {
      icon: <EqualizerOutlinedIcon />,
      label: 'PERFORMANCE',
      key: MarketplaceTabTemplateType.PERFORMANCE
    },
    {
      icon: <BusinessOutlinedIcon />,
      label: secureLocalStorage.getItem('FundTypeName') === fundTypesEnum.REAL_ESTATE_FUND ? "Assets" : 'PORTFOLIO',
      key: MarketplaceTabTemplateType.PORTFOLIO
    },
    {
      icon: <HandshakeOutlinedIcon />,
      label: 'INVESTORS',
      key: MarketplaceTabTemplateType.INVESTOR
    },
    {
      icon: <DesignServicesOutlinedIcon />,
      label: 'SERVICING',
      key: MarketplaceTabTemplateType.SERVICES
    },
    {
      icon: <FactCheckOutlinedIcon />,
      label: 'COMPLIANCE',
      key: MarketplaceTabTemplateType.COMPLIANCE
    },
  ]);

  //#region click events
  const handleTabSelection = (selectedTabValue) => {
    setCurrentTab(selectedTabValue);
    navigate(`/marketplace/${selectedTabValue}`);
  };

  const onCancelDialog = () => {
    setSupportedFile(null);
    setDialogOpen(false);
  }

  const onAcceptDialog = () => {
    handleTermination();
  }

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const openDialog = () => {
    setIsDialogOpen(true);
    // Show the toast warning
    toast.warning('Press ESC to close the page', {
      autoClose: 5000, // Display the toast for 5 seconds
    });
  };

  const FundTerminationPopup = (terminate) => {
    if (fundDetails?.isFundMatured) {
      toast.warning(
        "This fund is matured you cannot terminate it.",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored", style: { zIndex: 9999 } }
      );
    }
    else {
      setDialogOpen(true);
      setTerminate(terminate);
    }

  };
  
  //#region api update calls
  const handleTermination = async () => {

    setLoading(true);
    const fundId = secureLocalStorage.getItem("FundId");

    const formDataBody = new FormData();
    formDataBody.append("file", SupportedFile);
    formDataBody.append("fundID", fundId);
    formDataBody.append("isFundTerminate", Terminate);
    return await axiosApiCall.put(`/v1/Fund/FundTerminateOrReInstate`,  formDataBody)
      .then(async response => {
        if (response.status === 200) {
          toast.success(`Fund '${fundDetails?.fundName}' is ${fundDetails?.fundTerminated ? 'Reinstated' : 'Terminated'} Successfully`,
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
          getFundDetails();
          setSupportedFile(null);
          setLoading(false);
          setDialogOpen(false);
        }
        else {
          setLoading(false);
          toast.error("Trouble Updating Status",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
        /* return res.json(); */
      })

  }

  //#region useeffect
  useEffect(() => {
    if (!checkFeaturePermissions(RolesAndPermissions.PORTFOLIO_COMPANY, FeatureAccess.READ)) {
      setTabs(tabs?.filter(a => a.label !== "PORTFOLIO"));
    }
  }, []);

  //#region menu items
  const MenuItemsTop = [
    checkFeaturePermissions(RolesAndPermissions.PUBLIC_PROFILE_VIEW, FeatureAccess.READ) ?
      {
        id: 1,
        label: "Public Profile View",
        icon: "",
        isdisable: fundDetails?.fundTerminated ? true : false,
        onClick: openDialog,
      } : { onClick: {} },
    fundDetails?.fundTerminated
      ? checkFeaturePermissions(RolesAndPermissions.REINSTATE_FUND, FeatureAccess.CREATE) && {
        id: 3,
        label: "Reinstate Fund",
        icon: "",
        onClick: () => {
          FundTerminationPopup(false);
        }
      }
      :
      checkFeaturePermissions(RolesAndPermissions.TERMINATE_FUND, FeatureAccess.CREATE) && {
        id: 2,
        label: "Terminate Fund",
        icon: "",
        onClick: () => {
          FundTerminationPopup(true);
        }
      }
  ];
  const MenuItemsBottom = [
  ]

  //#region return
  return (
    <>
      <div>
        <ToastContainer />
        <PageHeader
          onBtnClick={props.onclick}
          headerImage={props?.icon}
          btnLabel={props?.buttonName}
          btnIcon={props?.buttonIcon}
          template={HeaderTemplates.Standard}
          fundName={fundDetails?.fundName}
          customBackgroundImage={props.background}
          button={props?.buttonVisibility}
          isNavigate={props.navigateBackto}
          fundType = {secureLocalStorage.getItem('FundTypeName')}
        />
        <div className={stylesfortab.dashboardTabsCont}>
          <div className={stylesfortab.dashboardTabsSubCont}>
            <Tabs tabSelected={tabs?.findIndex(tab => tab.key == params.detail)} tabs={tabs} onSelectTab={handleTabSelection} />
          </div>
          {(!checkFeaturePermissions(RolesAndPermissions.PUBLIC_PROFILE_VIEW, FeatureAccess.READ) &&
            !checkFeaturePermissions(RolesAndPermissions.TERMINATE_FUND, FeatureAccess.CREATE) &&
            !checkFeaturePermissions(RolesAndPermissions.REINSTATE_FUND, FeatureAccess.CREATE)) ? <></> :
            <div className={stylesfortab.dashboardTabsContextMenu}>
              <ContextMenu MenuItemsTop={MenuItemsTop} MenuItemsBottom={MenuItemsBottom} />
            </div>}
        </div>
        <DialogPopup open={dialogOpen} onAccept={onAcceptDialog} onClose={onCancelDialog} SupportedFile={SupportedFile} setSupportedFile={setSupportedFile} loading={loading} setLoading={setLoading} isFundTerminated={fundDetails?.fundTerminated}/>
        <FundDetailsDialog open={isDialogOpen} onClose={closeDialog} HideLeftMenu={true} />
      </div>
    </>
  )
}

export default OverviewHeader;