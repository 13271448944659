import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Grid, Container, Box, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Sample articles data
const articles = [
    {
        title: "Alternative investments and regulation",
        description: "Article",
        time: "5m",
        image: "/Article 1.png",
        content: `Alternative Investment Fund or AIF means any fund established or
incorporated in India which is a privately pooled investment vehicle which
collects funds from sophisticated investors, whether Indian or foreign, for
investing it in accordance with a defined investment policy for the benefit of its
investors.
AIF does not include funds covered under the SEBI (Mutual Funds)
Regulations, 1996, SEBI (Collective Investment Schemes) Regulations, 1999
or any other regulations of the Board to regulate fund management activities.
Further, certain exemptions from registration are provided under the AIF
Regulations to family trusts set up for the benefit of 'relatives‘ as defined
under Companies Act, 1956, employee welfare trusts or gratuity trusts set up
for the benefit of employees, 'holding companies‘ within the meaning of
Section 4 of the Companies Act, 1956 etc
Alternative Investment Fund or AIF means any fund established or
incorporated in India which is a privately pooled investment vehicle which
collects funds from sophisticated investors, whether Indian or foreign, for
investing it in accordance with a defined investment policy for the benefit of its
investors.
AIF does not include funds covered under the SEBI (Mutual Funds)
Regulations, 1996, SEBI (Collective Investment Schemes) Regulations, 1999
or any other regulations of the Board to regulate fund management activities.
Further, certain exemptions from registration are provided under the AIF
Regulations to family trusts set up for the benefit of 'relatives‘ as defined
under Companies Act, 1956, employee welfare trusts or gratuity trusts set up
for the benefit of employees, 'holding companies‘ within the meaning of
Section 4 of the Companies Act, 1956 etc

Alternative Investment Fund or AIF means any fund established or
incorporated in India which is a privately pooled investment vehicle which
collects funds from sophisticated investors, whether Indian or foreign, for
investing it in accordance with a defined investment policy for the benefit of its
investors.
AIF does not include funds covered under the SEBI (Mutual Funds)
Regulations, 1996, SEBI (Collective Investment Schemes) Regulations, 1999
or any other regulations of the Board to regulate fund management activities.
Further, certain exemptions from registration are provided under the AIF
Regulations to family trusts set up for the benefit of 'relatives‘ as defined
under Companies Act, 1956, employee welfare trusts or gratuity trusts set up
for the benefit of employees, 'holding companies‘ within the meaning of
Section 4 of the Companies Act, 1956 etc`
    },
    {
        title: "Fundamentals of Alternative Investments",
        description: "Article",
        time: "8m",
        image: "/Article 2.png",
        content: `Alternative Investment Fund or AIF means any fund established or
incorporated in India which is a privately pooled investment vehicle which
collects funds from sophisticated investors, whether Indian or foreign, for
investing it in accordance with a defined investment policy for the benefit of its
investors.
AIF does not include funds covered under the SEBI (Mutual Funds)
Regulations, 1996, SEBI (Collective Investment Schemes) Regulations, 1999
or any other regulations of the Board to regulate fund management activities.
Further, certain exemptions from registration are provided under the AIF
Regulations to family trusts set up for the benefit of 'relatives‘ as defined
under Companies Act, 1956, employee welfare trusts or gratuity trusts set up
for the benefit of employees, 'holding companies‘ within the meaning of
Section 4 of the Companies Act, 1956 etc`
    },
    {
        title: "What is an Alternate Investment Fund",
        description: "Article",
        time: "8m",
        image: "/Article 3.png",
        content: `Alternative Investment Fund or AIF means any fund established or
incorporated in India which is a privately pooled investment vehicle which
collects funds from sophisticated investors, whether Indian or foreign, for
investing it in accordance with a defined investment policy for the benefit of its
investors.
AIF does not include funds covered under the SEBI (Mutual Funds)
Regulations, 1996, SEBI (Collective Investment Schemes) Regulations, 1999
or any other regulations of the Board to regulate fund management activities.
Further, certain exemptions from registration are provided under the AIF
Regulations to family trusts set up for the benefit of 'relatives‘ as defined
under Companies Act, 1956, employee welfare trusts or gratuity trusts set up
for the benefit of employees, 'holding companies‘ within the meaning of
Section 4 of the Companies Act, 1956 etc`
    },
    {
        title: "Championing transformation of investment landscape",
        description: "Article",
        time: "6m",
        image: "/Article 4.png",
        content: `Alternative Investment Fund or AIF means any fund established or
incorporated in India which is a privately pooled investment vehicle which
collects funds from sophisticated investors, whether Indian or foreign, for
investing it in accordance with a defined investment policy for the benefit of its
investors.
AIF does not include funds covered under the SEBI (Mutual Funds)
Regulations, 1996, SEBI (Collective Investment Schemes) Regulations, 1999
or any other regulations of the Board to regulate fund management activities.
Further, certain exemptions from registration are provided under the AIF
Regulations to family trusts set up for the benefit of 'relatives‘ as defined
under Companies Act, 1956, employee welfare trusts or gratuity trusts set up
for the benefit of employees, 'holding companies‘ within the meaning of
Section 4 of the Companies Act, 1956 etc`

    }
];

const HelpAndSupportArticles = (props) => {
    const {setActiveBodySection} = props
    const [selectedArticle, setSelectedArticle] = useState(null);

    const handleArticleClick = (article) => {
        setSelectedArticle(article);
    };

    const handleBackToList = (article) => {
        if(article === 'mainpage'){
            setActiveBodySection('body')
        }else{
            setSelectedArticle(null);
        }
    };

    return (
        <div className='article-container'>
            <Box>
                {selectedArticle ? (
                    <Box>
                        <Button onClick={() => handleBackToList('article')}> <ArrowBackIcon  className='helpbackicon'/> Back to Articles </Button>
                        <h2>{selectedArticle.title}</h2>
                        <div className='detailarticle'>
                        <CardMedia className='articleImgDetails'
                            component="img"
                            image={selectedArticle.image}
                            alt={selectedArticle.title}
                            sx={{ mb: 2 }}
                        />
                        <p>
                         {selectedArticle.content}
                         </p>
                         </div>
                    </Box>
                ) : (
                    <>
                       <Button onClick={() => handleBackToList('mainpage')}> <ArrowBackIcon className='helpbackicon'/>  Go Back </Button>
                        <h2>Articles</h2>
                        <Grid container spacing={2}>
                            {articles.map((article, index) => (
                                <Grid item xs={12} sm={6} md={3} key={index}>
                                    <Card onClick={() => handleArticleClick(article)} className='articleCard'>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={article.image}
                                            alt={article.title}
                                        />
                                        <CardContent>
                                            <p className='articlecardtitle' > {article.title} </p>                    
                                           <p>  {article.description} <span  className='articleTime'>  <AccessTimeIcon sx={{marginRight:'5px',marginTop:'1px'}}/> {article.time} </span> </p>

                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )}
            </Box>
        </div>
    );
};

export default HelpAndSupportArticles;
