import React, { useState, useEffect } from 'react';
import { FieldVariants } from '../TextInput/appInputenum';
import SelectField from '../input-fields/SelectField';
import propTypes from 'prop-types';
import { useFormik } from 'formik';
import { chartsDropDownValues, chartTypes, PerformancePages, yearsDropDownValues } from './apiDataTemplate';
import LinearLineChart from '../DataChart/LinearLineChart';
import TwoColorBarChart from '../DataChart/Combined';
import PerformanceHistory from '../DataChart/PerformanceHistory';
import NavandCashflow from './charts/NavandCashflow';
import JCurveChart from './charts/JCurveChart';
import LinearInterpolationGraph from './charts/LinearInterpolationGraph';
import MonthlyTotalReturns from './charts/MonthlyTotalReturns';
import PercentageIndicator from '../DataGrid/PercentageIndicator';
import CurrencyIndicator from '../DataGrid/CurrencyIndicator';
import secureLocalStorage from 'react-secure-storage';
import { fundTypesEnum } from '../../marketplace-dashboard/jsonData';
import { getKeyValuePairs } from '../../portfolio-companies/services/services';
import { Tooltip } from 'recharts';

const FundPerformance = (props) => {

  //#region props
  const { allFundsDetails ,type,defaultChart,fundTypeName } = props;

  //#region intialdata
  const initialData = {
    fundName: '',
    selectedChart: defaultChart ? defaultChart : "",
    selectedYear: '',
    classOfShares: ''
  };

  //#region variables
  const [fundType, setFundType] = useState('')
  const [selectedClass, setSelectedClass] = useState('');
  const [fundTypes,setFundTypes] = useState([]);

  //#region functions
  const currFundTypeName = () => {
    const label = fundTypes?.find((option)=>option.value === fundType)?.label;
    return label;
  }

  //#region change events
  const handleFundTypeChange = (name, value) => {
    setFundType(value);
    filterDetails.setFieldValue('fundType', value);
    filterDetails.setFieldValue('fundName', '');
    filterDetails.setFieldValue('selectedChart', '');
    filterDetails.setFieldValue('classOfShares', '');
    filterDetails.setFieldValue('selectedYear', '');
  };

  const handleChange = (name, value) => {
    if (name === 'classOfShares') {
      setSelectedClass(value);
    }
    filterDetails.setFieldValue(name, value);
  };

  //#region api get calls
  const getFundTypes = async() => {
    const data = await getKeyValuePairs("FundType");
    if(data.responseCode === 200){
        const dropdownValues = data.responseData
                                  ?.filter((option)=>option.listItemID !== -1)
                                  ?.map(option => ({ label: option.listItemValue, value: option.listItemID })) || []
        setFundTypes(dropdownValues)
    }
    else{
        setFundTypes([])
    }
  }

  //#region formik
  const filterDetails = useFormik({
    initialValues: initialData,
    onSubmit: () => { },
  });

  //#region useeffect
  useEffect(()=>{
   getFundTypes();
  },[])

  useEffect(()=>{
     if(type !== PerformancePages.FUND_FILTERS){
       const fundTypeID = fundTypes?.find((option)=>option.label === fundTypeName)?.value; 
       setFundType(fundTypeID);
     }
  },[fundTypeName,fundTypes])

  //#region render and get dummy data functions
  const renderChart = () => {
    switch (filterDetails.values.selectedChart) {
      case chartTypes.PERFORMANCE_HISTORY:
        return <PerformanceHistory selectedYear={filterDetails.values.selectedYear} selectedFund={filterDetails.values.fundName} />;
      case chartTypes.J_CURVE:
        return <JCurveChart selectedYear={filterDetails.values.selectedYear} selectedFund={filterDetails.values.fundName} />;
      case chartTypes.IRR_BENCHMARK:
        return <LinearInterpolationGraph selectedYear={filterDetails.values.selectedYear} selectedFund={filterDetails.values.fundName} />;
      case chartTypes.FUND_TVPI:
        return <TwoColorBarChart selectedYear={filterDetails.values.selectedYear} selectedFund={filterDetails.values.fundName} />;
      case chartTypes.NAV_CASHFLOW:
        return <NavandCashflow selectedYear={filterDetails.values.selectedYear} selectedFund={filterDetails.values.fundName} />;
      case chartTypes.MONTHLY_TOTAL_RETURNS:
        return (
          <MonthlyTotalReturns
            indicator = {'percentage'}
            className={selectedClass.toLowerCase()} 
            monthlyReturnsData={getMonthlyReturns(selectedClass)} 
          />
        );
      case chartTypes.HISTORICAL_NAV:
        return <MonthlyTotalReturns
        indicator = {'dollar'}
        className={selectedClass.toLowerCase()} 
        monthlyReturnsData={getMonthlyReturns(selectedClass)} 
      />;
      case chartTypes.PER_SHARE_DISTRIBUTION:
        return <MonthlyTotalReturns
        indicator = {'dollar'}
        className={selectedClass.toLowerCase()} 
        monthlyReturnsData={getMonthlyReturns(selectedClass)} 
      />;
      default:
        return 'No Data';
    }
  };

  const getMonthlyReturns = (selectedClass) => {
    // Example data for monthly returns based on selected class
    const monthlyReturnsData = {
      'Class I': [
        { id: 1 , year: 2024, Jan: 25.41, Feb: 25.43, Mar: 25.51, Apr: 25.54 , May: 24.74, Jun: 24.86, Jul: 24.98, Aug: 25.04, Sep: 25.23, Oct: 25.18,Nov:25,Dec:24,YTD:25.67  },
        { id: 2,year: 2023, Jan: 24.85, Feb: 24.84, Mar: 24.77, Apr: 24.87, May: 24.74, Jun: 24.86, Jul: 24.98, Aug: 25.04, Sep: 25.23, Oct: 25.18,Nov:25,Dec:24,YTD:25.67 },
        {id:3, year: 2022, Jan: 25.93, Feb: 25.80, Mar: 25.82, Apr: 25.76, May: 25.28, Jun: 24.80, Jul: 25.02, Aug: 25.12, Sep: 24.62, Oct: 24.60 , Nov:25,Dec:24,YTD:25.67 },
        {id:4, year: 2021, Jan: 25.25, Feb: 25.36, Mar: 25.49, Apr: 25.59, May: 25.80, Jun: 25.81, Jul: 25.80, Aug: 25.84, Sep: 25.90, Oct: 25.93 , Nov:25,Dec:24,YTD:25.67 }
      ],
      'Class S': [
        {id:1, year: 2024, Jan: 26.41, Feb: 26.43, Mar: 26.51, Apr: 26.54 , May: 24.74, Jun: 24.86, Jul: 24.98, Aug: 25.04, Sep: 25.23, Oct: 25.18,Nov:25,Dec:24,YTD:25.67  },
        {id:2, year: 2023, Jan: 25.85, Feb: 25.84, Mar: 25.77, Apr: 25.87, May: 25.74, Jun: 25.86, Jul: 25.98, Aug: 26.04, Sep: 26.23, Oct: 26.18 , Nov:25,Dec:24,YTD:25.67},
        {id:3, year: 2022, Jan: 26.93, Feb: 26.80, Mar: 26.82, Apr: 26.76, May: 26.28, Jun: 25.80, Jul: 26.02, Aug: 26.12, Sep: 25.62, Oct: 25.60 , Nov:25,Dec:24,YTD:25.67},
        {id:4, year: 2021, Jan: 26.25, Feb: 26.36, Mar: 26.49, Apr: 26.59, May: 26.80, Jun: 26.81, Jul: 26.80, Aug: 26.84, Sep: 26.90, Oct: 26.93 , Nov:25,Dec:24,YTD:25.67}
      ],
      'Class D': [
        {id:1, year: 2024, Jan: 27.41, Feb: 27.43, Mar: 27.51, Apr: 27.54 , May: 24.74, Jun: 24.86, Jul: 24.98, Aug: 25.04, Sep: 25.23, Oct: 25.18,Nov:25,Dec:24,YTD:25.67 },
        {id:2, year: 2023, Jan: 26.85, Feb: 26.84, Mar: 26.77, Apr: 26.87, May: 26.74, Jun: 26.86, Jul: 26.98, Aug: 27.04, Sep: 27.23, Oct: 27.18 , Nov:25,Dec:24,YTD:25.67 },
        {id:3, year: 2022, Jan: 27.93, Feb: 27.80, Mar: 27.82, Apr: 27.76, May: 27.28, Jun: 26.80, Jul: 27.02, Aug: 27.12, Sep: 26.62, Oct: 26.60 , Nov:25,Dec:24,YTD:25.67},
        {id:4, year: 2021, Jan: 27.25, Feb: 27.36, Mar: 27.49, Apr: 27.59, May: 27.80, Jun: 27.81, Jul: 27.80, Aug: 27.84, Sep: 27.90, Oct: 27.93 , Nov:25,Dec:24,YTD:25.67}
      ],
    };

    return monthlyReturnsData[selectedClass];
  };

  const getChartOptions = () => {
    const selectedFundType = currFundTypeName();
    if (selectedFundType === fundTypesEnum.PRIVATE_EQUITY) {
      return [
        { label: 'Performance History', value: chartTypes.PERFORMANCE_HISTORY },
        { label: 'J-Curve Analysis', value: chartTypes.J_CURVE },
        { label: 'NAV and Cashflow', value: chartTypes.NAV_CASHFLOW },
        { label: 'IRR and Benchmark', value: chartTypes.IRR_BENCHMARK },
        { label: 'Fund TVPI/RVPI/DPI', value: chartTypes.FUND_TVPI }
      ];
    } else if (selectedFundType === fundTypesEnum.PRIVATE_CREDIT) {
      return [
        { label: 'Monthly Total Returns', value: chartTypes.MONTHLY_TOTAL_RETURNS },
        { label: 'Historical NAV Per Share', value: chartTypes.HISTORICAL_NAV },
        { label: '$ Per Share Distribution', value: chartTypes.PER_SHARE_DISTRIBUTION }
      ];
    }
    else if (selectedFundType === fundTypesEnum.VENTURE_CAPITAL) {
      return [
        { label: 'Performance History', value: chartTypes.PERFORMANCE_HISTORY },
        { label: 'J-Curve Analysis', value: chartTypes.J_CURVE },
        { label: 'Fund TVPI/RVPI/DPI', value: chartTypes.FUND_TVPI }
      ];
    }
    else if(selectedFundType === fundTypesEnum.REAL_ESTATE_FUND){
      return [
        { label: 'Performance History', value: chartTypes.PERFORMANCE_HISTORY },
        { label: 'J-Curve Analysis', value: chartTypes.J_CURVE },
        { label: 'NAV and Cashflow', value: chartTypes.NAV_CASHFLOW },
        { label: 'IRR and Benchmark', value: chartTypes.IRR_BENCHMARK },
        { label: 'Fund TVPI/RVPI/DPI', value: chartTypes.FUND_TVPI }
      ];
    }
    else if(type === PerformancePages.SUBSCRIBER_VIEW){
      return [
        { label: 'Performance History', value: chartTypes.PERFORMANCE_HISTORY },
        { label: 'IRR and Benchmark', value: chartTypes.IRR_BENCHMARK },
        { label: 'Fund TVPI/RVPI/DPI', value: chartTypes.FUND_TVPI }
      ];
    }
    else {
       return [
        { label: 'Performance History', value: chartTypes.PERFORMANCE_HISTORY },
        { label: 'J-Curve Analysis', value: chartTypes.J_CURVE },
        { label: 'NAV and Cashflow', value: chartTypes.NAV_CASHFLOW },
        { label: 'IRR and Benchmark', value: chartTypes.IRR_BENCHMARK },
        { label: 'Fund TVPI/RVPI/DPI', value: chartTypes.FUND_TVPI }
      ];;
    }
  };

  const getFundOptions = () => {
    return allFundsDetails
      ? allFundsDetails
        .filter((item) => (item.fundTypeName === currFundTypeName()))
        .map((item) => ({ label: item.fundName, value: item.fundID }))
      : [];
  };

  //#region useeffect
  useEffect(()=>{
    if(type !== PerformancePages.FUND_FILTERS && !defaultChart){
       if(fundTypeName === fundTypesEnum.PRIVATE_CREDIT){
        setSelectedClass("Class I");
        filterDetails.setFieldValue("classOfShares","Class I")
        filterDetails.setFieldValue("selectedChart",chartTypes.MONTHLY_TOTAL_RETURNS)
       }
       else{
        filterDetails.setFieldValue("selectedChart",chartTypes.PERFORMANCE_HISTORY)
       }
    }
  },[type,fundTypeName,fundTypes])

  //#region return
  return (
    <div className='child-margin-10'>
      <div className='space-between res-spacebetween'>
        
        <h6 className='dummydata-yellow'title='Dummy Data' >  Detail Performance Data </h6>
        <div className='child-row-margin-5'>
          {
            type === PerformancePages.FUND_FILTERS && 
              <div className='width-180'>
                    <SelectField 
                       name="fundType"
                       label="Fund Type"
                       variant={FieldVariants.OUTLINED}
                       value={fundType}
                       options={fundTypes}
                       onChange={(name, value) => handleFundTypeChange(name, value)} />
              </div>
          }
          
          {(fundType || type === PerformancePages.SUBSCRIBER_VIEW) && (
            <>
             {
              type === PerformancePages.FUND_FILTERS &&    
              <div className='dash-select'>
                <SelectField
                  className="fundname-performance-select"
                  name='fundName'
                  label="Fund Name"
                  size='small'
                  fullWidth
                  variant={FieldVariants?.OUTLINED}
                  value={filterDetails.values.fundName}
                  options={getFundOptions()}
                  onChange={(name, value) => handleChange(name, value)}
                />
              </div>
             }
              <div className='dash-select'>
                <SelectField
                  className='charts'
                  name='selectedChart'
                  label="Select Chart"
                  size='small'
                  variant={FieldVariants?.OUTLINED}
                  value={filterDetails.values.selectedChart}
                  options={getChartOptions()}
                  onChange={(name, value) => handleChange(name, value)}
                />
              </div>
              {currFundTypeName() === fundTypesEnum.PRIVATE_CREDIT && (
                <div className='dash-select'>
                  <SelectField
                    className='class-of-shares'
                    name='classOfShares'
                    label="Class of Shares"
                    size='small'
                    variant={FieldVariants?.OUTLINED}
                    value={filterDetails.values.classOfShares}
                    options={[
                      { label: 'Class I', value: 'Class I' },
                      { label: 'Class S', value: 'Class S' },
                      { label: 'Class D', value: 'Class D' }
                    ]}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </div>
              )}
              {
                (currFundTypeName() !== fundTypesEnum.PRIVATE_CREDIT || type === PerformancePages.SUBSCRIBER_VIEW) &&               
                <div className='dash-select-year'>
                  <SelectField
                    name='selectedYear'
                    label="Year"
                    size='small'
                    fullWidth
                    variant={FieldVariants?.OUTLINED}
                    value={filterDetails.values.selectedYear}
                    options={yearsDropDownValues}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </div>
              }
            </>
          )}
        </div>
      </div>
      <div className='margin-top-20'>
        {renderChart()}
      </div>
    </div>
  );
};

FundPerformance.propTypes = {
  currPage: propTypes.string,
  allFundsDetails: propTypes.array
};

FundPerformance.defaultProps = {
  currPage: '',
  allFundsDetails: []
};

export default FundPerformance;
