export const branchTypes = {
    STEP: 'Step',
    QUESTION: 'Question'
} 

export const configurationTypesEnum = {
    SUBSCRIPTION: 'subscription',
    KYC_AML: 'kyc/aml'
}

export const addQuestionInitialData = {
    "questionBankID": 0,
    "questionName": "",
    "answers": "",
    "multipleAnswers": false,
    "questionTypeId": 17,
    "softDelete": false,
    "step": "",
    "stepNo": "",
    "stepName": "",
    "shortName": "",
    "showInInvestProfile": false,
    "description": "",
    "footerText": "",
    "footerToggleDescription": "",
    "createdBy": "",
    "createdDate": new Date(),
    "updatedBy": "0",
    "updatedDate": new Date(),
    "isActive": true,
    "askToExisitingInvestor": false,
    "isText": false,
    "bookmarks": "",
    "questionPatternTypeID": "",
    "questionPatternType": "",
    "onboardingQuestionPattern": null,
    "isQuestionUsedByInvestor": false,
    "setValidations": false,
    "allowAlphabets": false,
    "allowNumbers": null,
    "characterLength": 250,
    "askMultipleTimes": false,
    "checkParentAnswer": true,
    "hasChildQuestions": false,
    "parentQuestionID": 1520,
    "questionHeader": "",
    "questionNo": 100,
    "showIfParentAnswerIs": "Individual Investor",
    "gridSchema": "",
    "showHorizontally": false,
    "doNotDisplayQuestion": false,
    "isFullWidth": false,
    "stopIfAnswerIDIs": 0,
    "userProfileID": 0,
    "questionAnswerID": 0,
    "userProvidedAnswer": "",
    "userProvidedAnswerIDs": "",
    "showIfParentAnswerIDIsIn": "",
    "multiChoiceQuestionAnswerIDs": null,
    "questionAnswer": [],
    "investorTypeID": 0,
    "investorType": "",
    "fundTypeID": 0,
    "fundType": "",
    "fundIDs": "",
    "applicableTo": [],
}

export const questionOptionSchema = {
    "questionAnswerID": 0,
    "questionBankID": 0,
    "answer": "",
    "additionalInfo": null,
    "description": null,
    "childID": 0,
    "isAdditionalInfo": false,
    "isSelected": false,
    "userProvidedAnswer": "",
    "createdBy": "0",
    "createdDate": new Date(),
    "updatedBy": "0",
    "updatedDate": new Date(),
    "isActive": true,
    "softDelete": false
}