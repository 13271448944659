import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import ChatIcon from '@mui/icons-material/Chat';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/system';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

function HelpAndSupportHeader(props) {

    const {setActiveBodySection} = props
    // Custom styled text field to remove borders
    const CustomTextField = styled(TextField)(({ theme }) => ({
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#fff',
            borderRadius: '8px',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    }));

    const onArticleClick = () => {
        setActiveBodySection('articles')
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#0061d5', padding: '40px', color: '#fff' }}>
            {/* Left section */}
            <div style={{ width: '60%' }}>
                <h1 style={{color:'white'}}>We're here for you.</h1>
                <p>Your goals are our goals. Connect with our Investor Relations team directly, or use our resource library below for answers at your fingertips.</p>
                <CustomTextField
                    placeholder="Search how-tos-and-more"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon style={{ color: '#0061d5' }} />
                            </InputAdornment>
                        ),
                    }}
                    style={{ width: '100%', marginTop: '20px' }}
                />
                <div className='topArticleSec'>
                    <h6>Top Articles</h6>
                    <ul>
                        <li>
                            <ArrowRightAltIcon  />
                            <a href="#" onClick={onArticleClick}>Alternative investments and regulation</a>
                        </li>
                        <li>
                            <ArrowRightAltIcon />
                            <a href="#" onClick={onArticleClick}>Fundamentals of Alternative Investments</a>
                        </li>
                        <li>
                            <ArrowRightAltIcon />
                            <a href="#" onClick={onArticleClick}>What is an Alternate Investment Fund</a>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Right section */}
            <div className='child-row-margin-5' style={{ width: '35%', textAlign: 'left' }}>
               <div className='help-mike'>
                    <HeadsetMicIcon />
                </div>
                <div>
                   <h2 style={{color:'white'}}>Contact Investor Relations</h2>
                    <p>Get in touch with us your way – our team is standing by to assist.</p>
                    <div className='help-chatbot' onClick={props?.onIconClick}>
                        <ChatIcon />
                        <a href="#">Chat</a>
                    </div>
                    <div className='help-chatbot'>
                        <MailOutlineIcon />
                        <a href="mailto:info@fusionfoundry.com">info@fusionfoundry.com</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HelpAndSupportHeader;
