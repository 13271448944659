import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AIPDataGrid from "../../common/DataGrid/AIPDataGrid";
import secureLocalStorage from "react-secure-storage";
import CurrencyIndicator from "../../common/DataGrid/CurrencyIndicator";
import { checkFeaturePermissions } from "../../../utils/common";
import { FeatureAccess, RolesAndPermissions } from "../../../utils/enum";
import ContextMenu from "../../common/ContextMenu/ContextMenu";
import moment from "moment";
import Checkbox from '@mui/material/Checkbox';
import { Grid } from '@mui/material';
import { getFundSubscriptions } from "../../dashboards/services/DashboardService";
import { yearsDropDownValues } from "../../common/FundPerformance/apiDataTemplate";
import { FieldVariants } from "../../common/TextInput/appInputenum";
import SelectField from "../../common/input-fields/SelectField";
import { investorRelationJsonData } from "../jsonData";
import { ConvertToUSCurrency } from "../../common/Functions/CommonConvertions";
import { fundTypesEnum } from "../../marketplace-dashboard/jsonData";
import { getEventsByFundId } from "../services/services";
import { formatDate } from "../../common/Functions/TimeConvestion";

const InvestorRelationsTab = () => {

  //#region variables  
  const navigate = useNavigate();
  const [showRejected, setShowRejected] = useState(false);
  const fundID = secureLocalStorage.getItem("FundId");
  const [ccdata, setccData] = useState();
  const [ccgridrows,setCCGridRows] = useState([]); 
  const [eventRowsData,setEventRowsData] = useState([]);
  const [filtersData,setFiltersData] = useState({
    "fundRasingYear": 2024,
    "lpcommunicationyear": 2023,
    "upcomingevents": 2024
  })

  //#region chaneg events
  const handleChange = (name,value) => {
    setFiltersData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
  }

  //#region click events
  const handleStatusNavigation = (STATUS, params) => {
    if(STATUS === 'Rejected'){
      navigate("/subscription-details", { state: { FundId: { itemId: params?.row?.fundID }, subscriptionId: { subscriptionID: params.row.subscriptionsID }, status : {status:params.row.subscriptionStatus}, selectedRow: params.row } });
    }
    if (STATUS === "Approved") {
      secureLocalStorage.setItem("FundId", params?.row?.fundID);
      secureLocalStorage.setItem("investorId", params?.row?.investorID);
      navigate("/Investor-Overview-Page",
        { state: { userId: { itemId: params?.row?.investorID, fullName: params?.row?.investor } } });
    } else {
      navigate("/subscription-details", { state: { FundId: { itemId: params?.row?.fundID }, subscriptionId: { subscriptionID: params.row.subscriptionsID }, status : {status:params.row.subscriptionStatus}, selectedRow: params.row } });
    }
  }

  //#region api get calls
  const getSubscribers = async (showRejected) => {
    const data = await getFundSubscriptions(fundID);
    if (data.responseCode === 200) {
      const subs = showRejected === true
        ? data.responseData.filter(u => u.subscriptionStatus === "Rejected")
        : data.responseData.filter(u => u.subscriptionStatus !== "Pending Review" && u.subscriptionStatus !== "Rejected" && u.subscriptionStatus !== "Cancelled")
      setccData(subs)
    }
    else {

    }
  }

  const getCalendarEventsByFundId = async() => {
      const data = await getEventsByFundId(fundID);
      if(data.responseCode === 200){
        setEventRowsData(data.responseData);
      }
      else{
        setEventRowsData([]);
      }
  }

  //#region useeffect
  useEffect(() => {
    getSubscribers(showRejected);
  }, [showRejected]);

  useEffect(() => {
    getCalendarEventsByFundId();
  }, []);

  useEffect(()=>{
    const gridrows = ccdata ?
    ccdata.filter(u => u.subscriptionStatus !== 'Awaiting Online Processing' && u.subscriptionStatus !== 'Investor Sign Pending')
      .map((ccrows) => ({
        id: ccrows.subscriptionsID,
        investorID: ccrows.investorID,
        fundID: ccrows.fundID,
        subscriptionsID: ccrows.subscriptionsID,
        investor: ccrows.investor,
        subscriptionStatus: ccrows.subscriptionStatus.includes('Under Compliance Review') ? 'Under Review' : ccrows.subscriptionStatus,
        TYPE: ccrows.investorType === 0 || ccrows.investorType === "0" ? '-' : ccrows.investorType,
        subscribed: ccrows.subscribed,
        "CALL AMOUNT": 0,
        CONTRIBUTED: ccrows.contributed,
        UNFUNDED: ccrows.unFunded,
        NAV: ccrows.nav,
        DISTRIBUTIONS: ccrows.distributions,
        OWNERSHIP: ccrows.ccOwnership,
        RECEIVED: ccrows.received,
        FEE: ccrows.fee,
        CARRY: ccrows.carry,
        UPDATE: moment(ccrows.updatedDate).format("MM-DD-YYYY"),
        isInvestorVerified: ccrows.isInvestorVerified,
        isOfflineSubscription: ccrows.isOfflineSubscription,
        "DATA RECEIVED": ccrows.isOfflineSubscription ? 0 : ccrows.dataReceived,
        field: "subscriptionTYPE",
        headerName: "SUBSCRIPTION TYPE",
        investorType: ccrows.userInvestorType,
        width: 110,
        // subscriptionTYPE: ccrows.isOfflineSubscription ? "Online" : "Offline",
        subscriptionTYPE: ccrows.subscriptionStatus === "Approved" ||
          ccrows.subscriptionStatus === "Rejected"
          ? "-"
          : ccrows.isOfflineSubscription
            ? "Offline"
            : "Online",
      }))
    : [];
    setCCGridRows(gridrows);
  },[ccdata])


  //#region cloumns
  const eventColumnsData = [
    { "id": 1,"field": "title","headerName": "Meeting / Event","width": 350 },
    { "id": 2,"field": "startDate","headerName": "Date","width": 350,
      renderCell: (params) => {
        return (
          <>{formatDate(params?.value)}</>
        );
      }
     },
  ]
  const creditTypeColumns = [
    { "id": 1,"field": "investor","headerName": "INVESTOR NAME","width": 150 },
    { "id": 2,"field": "subscriptionStatus","headerName": "STATUS","width": 250,
      renderCell: (params) => {
        return (
          <a onClick={() => handleStatusNavigation(params.value, params)} align="left" className="cell-highlight">
            {params.value}
          </a>
        );
      }
    },
    { "id": 3,"field": "TYPE","headerName": "TYPE","width": 110 },
    { "id": 4,"field": "investorType","headerName": "INVESTOR TYPE","width": 150 },
    { "id": 16,"field": "subscriptionTYPE","headerName": "SUBSCRIPTION","width": 110 },
    { "id": 5,"field": "subscribed","headerName": "INVESTED","width": 110,
      renderCell: (params) => {
        return <CurrencyIndicator params={params} />
      }
    },
    { "id": 8,"field": "NAV","headerName": "NAV","width": 150,
      renderCell: (params) => <div>{ConvertToUSCurrency(params?.value)}</div> 
    },
    { "id": 9,"field": "DISTRIBUTIONS","headerName": "DISTRIBUTIONS","width": 110 },
    { "id": 10,"field": "OWNERSHIP","headerName": "OWNERSHIP","width": 90 },
    { "id": 11,"field": "RECEIVED","headerName": "RECEIVED","width": 150,
      renderCell: (params) => <div>{ConvertToUSCurrency(params?.value)}</div> 
    },
    { "id": 12,"field": "FEE","headerName": "FEE","width": 70 },
    { "id": 13,"field": "CARRY","headerName": "CARRY","width": 90 },
    { "id": 14,"field": "UPDATE","headerName": "UPDATE","width": 110 },
    { "id": 15,"field": "ACTIONS","headerName": "ACTIONS","width": 110,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {params.row.TYPE === 'Action' ? <ContextMenu /> : '-'}
          </div>
        );
      },
    },
  ]
  
  const RelColumns = [
    { id: 1, field: "investor", headerName: "INVESTOR NAME", width: 150 },
    { id: 2, field: "subscriptionStatus", headerName: "STATUS", width: 250,
       renderCell: (params) => (<a onClick={() => handleStatusNavigation(params.value, params)} align="left" className="cell-highlight">{params.value}</a>) },
    /* { id: 3, field: "TYPE", headerName: "TYPE", width: 110 }, */
    { id: 4, field: "investorType", headerName: "INVESTOR TYPE", width: 150 },
    { id: 16, field: "isOfflineSubscription", headerName: "SUBSCRIPTION TYPE", width: 150,
      renderCell: (params) => <div>{params.value === true ? "Offline" : "Online"}</div> 
     },
    { id: 5, field: "subscribed", headerName: "SUBSCRIBED", width: 110, 
      renderCell: (params) => <CurrencyIndicator params={params} /> },
    { id: 6, field: "CONTRIBUTED", headerName: "CONTRIBUTED", width: 120,
      renderCell: (params) => <div>{ConvertToUSCurrency(params?.value)}</div> 
     },
    { id: 7, field: "UNFUNDED", headerName: "UNFUNDED", width: 150,
      renderCell: (params) => <div>{ConvertToUSCurrency(params?.value)}</div> 
    },
    { id: 8, field: "NAV", headerName: "NAV", width: 150,
      renderCell: (params) => <div>{ConvertToUSCurrency(params?.value)}</div> 
    },
    { id: 9, field: "DISTRIBUTIONS", headerName: "DISTRIBUTIONS", width: 110 },
    { id: 10, field: "OWNERSHIP", headerName: "OWNERSHIP", width: 90 },
    { id: 11, field: "RECEIVED", headerName: "RECEIVED", width: 150,
      renderCell: (params) => <div>{ConvertToUSCurrency(params?.value)}</div> 
    },
    { id: 12, field: "FEE", headerName: "FEE", width: 70 },
    { id: 13, field: "CARRY", headerName: "CARRY", width: 90 },
    { id: 14, field: "UPDATE", headerName: "UPDATE", width: 110 },
    { id: 15, field: "ACTIONS", headerName: "ACTIONS", width: 110, 
      renderCell: (params) => (<div style={{ display: "flex", alignItems: "center" }}>
        {params.row.TYPE === 'Action' ? <ContextMenu /> : '-'}
        </div>) 
    }];


  //#region return
  return (
    // <div className={styles.fundDetailInvestorRelatio}>
    <div>
      <h4>Investor Relations </h4>

      <Grid container spacing={2} marginTop={1}>
        <Grid item xs='12' sm='12' md='6' lg='6'>
          <div className="whiteCard">
            <div className="space-between">
              <h6>Fundraising Status</h6>
              <div className="width-130">
                <SelectField
                        name='fundRasingYear'
                        label="Year"
                        value={filtersData?.fundRasingYear}
                        size='small'
                        fullWidth
                        variant={FieldVariants?.OUTLINED}
                        options={yearsDropDownValues}
                        onChange={(name, value) => handleChange(name, value)} />
              </div>
            </div>
            <div style={{ height: '95%' }}>
              <AIPDataGrid
                columns={investorRelationJsonData.InvestorRelationsColumns}
                rows={investorRelationJsonData.InvestorRelationsRows}
                onRowsSelectionHandler={() => { }} />
            </div>
          </div>
          {checkFeaturePermissions(RolesAndPermissions.LP_COMMUNICATION_AND_REPORTING, FeatureAccess.READ)
            &&
            <div className="whiteCard mt16">
              <div className="space-between">
                <h6>LP Communication and Reporting</h6>
                <div className="width-130">
                <SelectField
                        name='lpcommunicationyear'
                        label="Year"
                        value={filtersData?.lpcommunicationyear}
                        size='small'
                        fullWidth
                        variant={FieldVariants?.OUTLINED}
                        options={yearsDropDownValues}
                        onChange={(name, value) => handleChange(name, value)} />
              </div>
              </div>
              <div style={{ height: '95%' }}>
                <AIPDataGrid
                  columns={investorRelationJsonData.LPCommunicationColumns}
                  rows={investorRelationJsonData.LPCommunicationRows}
                  onRowsSelectionHandler={() => { }} />
              </div>
            </div>
          }
        </Grid>
        {
          checkFeaturePermissions(RolesAndPermissions.INVESTOR_MEETINGS_AND_EVENTS, FeatureAccess.READ) &&
          <Grid item xs='12' sm='12' md='6' lg='6'>
            <div className="white-card">
              <div className="space-between" >
                <h6>Upcoming Investor Meetings and Events</h6>
                <div className="width-130">
                <SelectField
                        name='upcomingevents'
                        label="Year"
                        size='small'
                        value={filtersData?.upcomingevents}
                        fullWidth
                        variant={FieldVariants?.OUTLINED}
                        options={yearsDropDownValues}
                        onChange={(name, value) => handleChange(name, value)} />
              </div>
              </div>
              <div className="height-405">
                <AIPDataGrid
                  columns={eventColumnsData}
                  rows={eventRowsData?.map((row, index) => ({ ...row, id: index + 1 }))}
                  onRowsSelectionHandler={() => { }} />
              </div>
            </div>
          </Grid>
        }
      </Grid>

      <div className="whiteCard mt16">
        <Grid container xs={12} md={12}>
          <Grid item xs={9} md={9}>
            <h6>Investors</h6>
          </Grid>
          <Grid item xs={3} md={3} container justifyContent="flex-end">
            <Checkbox className="mtmin5"
              checked={showRejected}
              onChange={() => setShowRejected(!showRejected)}
              color="primary"
              inputProps={{ 'aria-label': 'Show Rejected' }}
            />
            <span style={{ fontSize: '14px' }}>Show Rejected</span>
          </Grid>

        </Grid>
        <Grid container xs={12} md={12}>
          <Grid item xs={12} style={{ height: '400px', width: '1000px' }}>
            <AIPDataGrid
              columns={(secureLocalStorage.getItem('FundTypeName') === fundTypesEnum.PRIVATE_CREDIT ? creditTypeColumns : RelColumns)}
              rows={ccgridrows}
              onRowsSelectionHandler={() => { }}
            />
          </Grid>
        </Grid>

      </div>
    </div>
  );
};

export default InvestorRelationsTab;
