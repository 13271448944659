import React from 'react'
import secureLocalStorage from 'react-secure-storage';
import { Dialog, DialogContent, DialogTitle,Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AwaitingPopup = (props) => {

  //#region props
  const {open,onClose,fundName} = props;

  return (
    <Dialog open={open}>
        <DialogTitle className='space-between'>
            <div></div>
            <div>
                <Button onClick={onClose} className='steps-close-icon' >
                    <CloseIcon onClick={onClose} />
                </Button>
            </div>
        </DialogTitle>
        <DialogContent>
         <div>
            <p>
                Dear <b>{secureLocalStorage.getItem("fullName")}</b>,<br />
            </p>
            <p>
               Thank you for your interest in <b>{fundName}</b>. We are currently processing your offline subscription form.
            </p>
            <p>
                Please note: Our team is diligently entering the details from your submitted form into our online system. Once this process is complete, the fund management team and you will be able to view and verify the information online.
            </p>
            <p>
                We appreciate your patience as we work to finalize your subscription.
            </p>
         </div>
        </DialogContent>
    </Dialog>
  )
}

export default AwaitingPopup