import React from 'react'
import '../../../Generalize.css';
import { useState } from 'react';
import ManagementTeam from '../CreateFundModal/ManagementTeam';
import FeesExpenses from '../CreateFundModal/FeesExpenses';
import { useEffect } from 'react';
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandMore from "@mui/icons-material/ExpandMore";
import '../../../common/NestedList/styles.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import DeleteIcon from '@mui/icons-material/Delete';
import { ExpandLess } from '@mui/icons-material';
import Button from "@mui/material/Button";
import { createManagementTeam, updateManagementteams, createFeesandExpenses, updateFeesandExpenses, deleteFeesandExpenses, deleteManagementteamemeber } from '../../services/MarketplaceService';

const Accordian = (props) => {

  //#region props
  const { arraydata, fundData, getFundDetails, managementStaffData } = props;

  //#region initialdata
  const updatedList = arraydata.map((item, i) => ({
    ...item,
    editable: false,
  }));

  //#region variables
  const [items, setItems] = useState(updatedList);
  const [editable, setEditable] = useState(false);
  const [openIndex, setOpenIndex] = React.useState(-1);
  const [Details, setDetails] = useState(props.data);
  const isFundMatured = secureLocalStorage.getItem("selectedFund")?.FundMaturity;;
  const isFundTerminated = secureLocalStorage.getItem("selectedFund")?.FundTermination;

  //#region functions
  const isDeleteDisabled = () => {
    if (props.componentName === 'managementteams') {
      return fundData?.fundManagementTeams?.length === 1
    } else {
      return fundData?.feesExpenses?.length === 1
    }
  }

  //#region click events
  const handleClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const handleClickEdit = (index) => {
    /*      */
    const updatedList = items.map((item, i) => ({
      ...item,
      editable: i === index ? true : "",
    }));
    setItems(updatedList);

  };

  const handleClickCancel = (index) => {
    const updatedList = items.map((item, i) => ({
      ...item,
      editable: i === index ? false : "",
    }));
    setItems(updatedList);
    setOpenIndex();
  };

  //#region api post calls
  const handleClickSave = async (index, data) => {

    /* setOpenIndex(index); */

    const fundId = secureLocalStorage.getItem("FundId");

    if (props.componentName == 'managementteams') {

      const requestData = {
        "fundManagementTeamID": data.fundManagementTeamID,
        "fundID": fundId,
        "firstName": data.firstName,
        "lastName": data.lastName,
        "description": data.description,
        "title": data.title,
        "emailAddress": data.emailAddress,
        "addressLine1": data.addressLine1,
        "addressLine2": data.addressLine2,
        "phoneNumber": data.phoneNumber,
        "city": "",
        "state": 0,
        "country": 0,
        "zipCode": "",
        "managementTeamStaff": data.managementTeamStaff,
        "authorizedSignatory": data.authorizedSignatory
      }

      if (requestData.fundManagementTeamID == 0 || requestData.fundManagementTeamID == undefined) {
        const response = await createManagementTeam(requestData);
        if (response.responseCode === 200) {
          const updatedArrayList = arraydata.filter(item => item.fundManagementTeamID !== 0);
          props.setArrayData([...updatedArrayList, response.responseData]);
          const updatedData = {
            ...response.responseData,
            editable: false
          };
          const updatedList = items.filter(item => item.fundManagementTeamID !== 0);
          setItems([...updatedList, updatedData]);
          toast.success("Member Successfully Added",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
          getFundDetails();
        }
        else {
          toast.error("Member not Created Please fill the required fields",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
      }
      else {
        const response = await updateManagementteams(data, requestData);
        if (response.responseCode === 200) {
          const updatedItems = items.map(item => {
            if (item.fundManagementTeamID === data.fundManagementTeamID) {
              return {
                ...requestData
              };
            }
            return item; // Leave other items unchanged
          });
          /*   */

          props.setArrayData(updatedItems);

          const updatedList = updatedItems.map((item, i) => ({
            ...item,
            editable: i === index ? false : "",
          }));

          setItems(updatedList);
          getFundDetails()
          toast.success("Successfully Saved",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
          console.log("Success");
        }
        else {
          toast.error("Member not Updated Please try again",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
      }
    }
    else if (props.componentName == 'feesandexpenses') {

      const requestData = {
        "id": data.id,
        "fundID": fundId,
        "managementFees": data.managementFees,
        "performanceFeesOrCarriedInterest": data.performanceFeesOrCarriedInterest,
        "additionalFeesAndExpenses": data.additionalFeesAndExpenses
      }

      if (requestData.id == 0 || requestData.id == undefined) {
        const response = await createFeesandExpenses(requestData);
        if (response.responseCode === 200) {
          const updatedArrayList = arraydata.filter(item => item.id !== 0);
          props.setArrayData([...updatedArrayList, response.responseData]);
          const updatedData = {
            ...response.responseData,
            editable: false
          };
          const updatedList = items.filter(item => item.id !== 0);
          setItems([...updatedList, updatedData]);
          getFundDetails();
          toast.success("Fees and Expenses Added Successfully",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
        else {
          toast.error("Fees and Expenses not Created Please try again",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
      }

      else {
        const response = await updateFeesandExpenses(data, requestData);
        if (response.responseCode === 200) {
          const updatedItems = items.map(item => {
            if (item.id === data.id) {
              return {
                ...requestData
              };
            }
            return item; // Leave other items unchanged
          });
          const updatedList = updatedItems.map((item, i) => ({
            ...item,
            editable: i === index ? false : "",
          }));
          getFundDetails();
          toast.success("Successfully Saved",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
          /*  console.log("Success"); */
        }
        else {
          toast.error("Fees and Expenses not Updated Please try again",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
      }
    }


    const updatedList = items.map((item, i) => ({
      ...item,
      editable: i === index ? false : "",
    }));


  };

  const ondelete = async (index, data) => {

    if (props.componentName == 'managementteams') {
      const response = await deleteManagementteamemeber(data);
      //#TODO: Geeting error While Deleted Need to solve this bug
      if (response.responseCode === 200) {

        const updatedItems = items.filter(item => item.fundManagementTeamID !== data.fundManagementTeamID);
        const updatedList = arraydata.map((item, i) => ({
          ...item,
          editable: false,
        }));
        setItems(updatedList);
        props.setArrayData(updatedItems);
        getFundDetails();
        toast.success("Successfully Deleted",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });

      }
      else {
        toast.error("Error deleting please try again",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
    }

    else if (props.componentName == 'feesandexpenses') {
      const response = await deleteFeesandExpenses(data);
      if (response.responseCode === 200) {
        const updatedItems = items.filter(item => item.id !== data.id);
        const updatedList = arraydata.map((item, i) => ({
          ...item,
          editable: false,
        }));
        getFundDetails();
        setItems(updatedList);
        props.setArrayData(updatedItems);
        console.log('Folder deleted successfully');
        toast.success("Successfully Deleted",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
      else {
        toast.error("Error deleting please try again",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
    }
  }

  //#region useeffect
  useEffect(() => {
    return () => {
      props.updateData(Details);
    }
  }, []);

  useEffect(() => {
    const updatedList = arraydata.map((item, i) => ({
      ...item,
      editable: false,
    }));
    setItems(updatedList);
  }, [arraydata])

  //#region return
  return (
    <div>
      <List
        sx={{ width: "100%" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {/* Nested List Items */}
          </ListSubheader>
        } >
        {items !== undefined && items !== null && items.map((item, index) => (
          <div
            key={index}
            style={{
              border: '1px solid #ccc',
              marginLeft: '10px',
              marginRight: '10px',
              marginTop: '10px',
              paddingTop: '5px',
              paddingBottom: '5px',
              borderRadius: '5px',
              backgroundColor: 'white',
            }}
          >
            <ListItemButton
              sx={{
                "&.MuiButtonBase-root.MuiListItemButton-root": {
                  // Override max-width here
                  maxWidth: "none !important",
                  background: "white",
                },
              }}
            >
              {props.componentName === 'managementteams' && (
                <ListItemText
                  primary={
                    (arraydata[index]?.managementTeamStaff && props.managementStaffData) ?
                      props.managementStaffData.find(fmitem => arraydata[index].managementTeamStaff === fmitem.value)?.label :
                      ""
                  }
                />

              )}
              {props.componentName === 'feesandexpenses' && (
                <ListItemText primary={`Primary Expenses`} />
              )}
              {openIndex === index ? (
                <Button sx={{ color: 'black' }}>
                  <ExpandLess close={openIndex === index} onClick={() => handleClick(index)} />
                </Button>
              ) : (
                <div>
                  <Button
                    onClick={() => handleClick(index)}
                    sx={{ color: 'black' }}
                  >
                    <ExpandMore open={openIndex === index} />
                  </Button>
                  {!isFundTerminated && !isFundMatured ?
                    <Button
                      onClick={() => { ondelete(index, arraydata[index]) }}
                      sx={{ color: 'black' }}
                      disabled={isDeleteDisabled()}
                    >
                      <DeleteIcon />
                    </Button> : null}
                </div>
              )}
            </ListItemButton>
            <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {openIndex === index && (
                  <div style={{ marginLeft: '15px', width: '94%' }}>
                    {/* Render the dynamic component */}
                    {props.componentName === 'feesandexpenses' && (
                      <FeesExpenses
                        data={arraydata[index]}
                        updateData={props.updateData}
                        isedit={true}
                        handleClickEdit={handleClickEdit}
                        handleClickCancel={handleClickCancel}
                        handleClickSave={handleClickSave}
                        openIndex={openIndex}
                        index={index}
                        editable={item.editable}
                        setEdit={setEditable}
                        getFundDetails={props.getFundDetails}
                      />
                    )}
                    {
                      props.componentName === 'managementteams' && managementStaffData && (
                        <ManagementTeam
                          funddata={props.funddata}
                          data={arraydata[index]}
                          updateData={props.updateData}
                          isedit={true}
                          handleClickEdit={handleClickEdit}
                          handleClickCancel={handleClickCancel}
                          handleClickSave={handleClickSave}
                          openIndex={openIndex}
                          index={index}
                          editable={item.editable}
                          setEdit={setEditable}
                          getFundDetails={props.getFundDetails}
                          managementStaffData={managementStaffData}
                          allManagementDetails={arraydata}
                        />
                      )
                    }
                  </div>
                )}
              </List>
            </Collapse>
          </div>
        ))}
      </List>
    </div>
  )
}

export default Accordian