import React, { useState, useEffect, useRef } from 'react';
import "../../marketplace/FilterBox.css";
import options from '../../marketplace/filterbox.json';
import { useNavigate } from 'react-router-dom';
import Slider from '@mui/material/Slider';
import { getFundFilterData } from '../../marketplace/services/MarketplaceService';
import RadioButtonGroup from '../../common/RadioButtonGroup/RadioButtonGroup';
/* import { Box } from "@mui/material"; */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ListIcon from '@mui/icons-material/List';
import { getFundsView, updateView } from '../../../state/slices/MarketPlaceSlice';
import { MarketPlaceView } from '../../../utils/enum';
import { useDispatch, useSelector } from 'react-redux';
import GridViewIcon from '@mui/icons-material/GridView';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { FiltersTypes, MarketPlacePagesEnum, SlideFilterValues } from '../mpenum';
import RowRadioButtonsField from '../../common/RadioButtonGroup/RadiobuttonsField';
import RadioGroupVertial from '../../common/RadioButtonGroup/RadioGroupVertial';
import { Button, TextField, Tooltip } from '@mui/material';
import { fundTypesEnum, minimumInvestmentOptions, sortOptions, privateCreditSortOptions, termSliderMarks, fundStatusEnum } from '../jsonData';
import ActionButton from '../../common/ActionButton/ActionButton';
import InvestmentFilter from './filters/InvestmentFilter';
import RadioGroupField from '../../common/input-fields/RadioGroupField';
import TermFilter from './filters/TermFilter';
import FilterListIcon from '@mui/icons-material/FilterList';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import TuneIcon from '@mui/icons-material/Tune';
const MPFilterBox = (props) => {

  //#region props
  const {filterOptions,setFilterOptions,resetFundTypeFilters,selectedFundType,setSelectedFundType,handlePageChange} = props;

  //#region variables
  const dispatch = useDispatch();
  const marketplaceView = useSelector(getFundsView);
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(null);
  const filterBoxRef = useRef(null);

  //css for buttons
  const iconStyle = { width: '25px', height: '25px',marginTop: '-7px',marginLeft: '-4px'}

  //#region filter items
  const initialFilters = [
    {
      id: 1,
      name: 'Fund Type',
      apiName: 'fundType',
      type: FiltersTypes.RADIO_GROUP,
      options: filterOptions.fundType,
      icon: <ArrowDropDownIcon sx={iconStyle} />,
      fundtypes: [fundTypesEnum.PRIVATE_EQUITY, fundTypesEnum.PRIVATE_CREDIT, fundTypesEnum.REAL_ESTATE_FUND,fundTypesEnum.VENTURE_CAPITAL]
    },
    {
      id: 2,
      name: 'Fund SubType',
      apiName: 'fundSubType',
      type: FiltersTypes.CHECKBOX,
      options: filterOptions.fundSubType,
      icon: <ArrowDropDownIcon sx={iconStyle} />,
      fundtypes: [fundTypesEnum.PRIVATE_EQUITY]
    },
    {
      id: 3,
      name: 'Industry',
      apiName: 'industry',
      type: FiltersTypes.CHECKBOX,
      options: filterOptions.industry,
      icon: <ArrowDropDownIcon sx={iconStyle} />,
      fundtypes: [fundTypesEnum.PRIVATE_EQUITY,fundTypesEnum.REAL_ESTATE_FUND,fundTypesEnum.VENTURE_CAPITAL]
    },
    {
      id: 4,
      name: 'Investment Strategy',
      apiName: 'strategy',
      type: FiltersTypes.CHECKBOX,
      options: filterOptions.strategy,
      icon: <ArrowDropDownIcon sx={iconStyle} />,
      fundtypes: [fundTypesEnum.PRIVATE_EQUITY,fundTypesEnum.VENTURE_CAPITAL]
    },
    {
      id: 5,
      name: 'Minimum Investment',
      apiName: 'minimumInvestmentOptions',
      type: FiltersTypes.TEXT_FIELD,
      icon: <ArrowDropDownIcon sx={iconStyle} />,
      valueType: 'currency',
      maxValue: 100000,
      minValue: 100,
      fundtypes: [fundTypesEnum.PRIVATE_EQUITY, fundTypesEnum.PRIVATE_CREDIT, fundTypesEnum.REAL_ESTATE_FUND,fundTypesEnum.VENTURE_CAPITAL]
    },
    {
      id: 6,
      name: 'Term',
      apiName: 'termOptions',
      type: FiltersTypes.TEXT_FIELD,
      icon: <ArrowDropDownIcon sx={iconStyle} />,
      valueType: 'int',
      maxValue: 1,
      minValue: 10,
      fundtypes: [fundTypesEnum.PRIVATE_EQUITY, fundTypesEnum.VENTURE_CAPITAL]
    },
  ]

  const [filterLeftItems,setFilterLeftItems] = useState(initialFilters)

  //#region functions
  const currFundType = () => {
    const selectedType = filterOptions?.fundType.find(option => option.value === selectedFundType);
    return selectedType?.label
  }

  //#region click events
  const handleCompare = () => {
      handlePageChange(MarketPlacePagesEnum.COMPARE);
      /* navigate('/fund-compare' ,{ state: { fundTypeName: currFundType() } });
      window.scrollTo(0, 0); */
  };

  const handleFavorite = () => {
    handlePageChange(MarketPlacePagesEnum.FAVOURITE);
    /* navigate('/favourite-funds' ,{ state: { fundTypeName: currFundType() } });
    window.scrollTo(0, 0); */
  };

  const handleChangeView = (boxview) =>{
    if(boxview){
      dispatch(updateView({ view : MarketPlaceView.LIST_VIEW }));
    }
    else{
      dispatch(updateView({ view : MarketPlaceView.BOX_VIEW }));
    }
  }

  const handleButtonClick = (itemID) => {
    if(itemID === activeButton){
      setActiveButton(null);
    }
    else{
      setActiveButton(itemID);
    }
  };

  const handleCheckBoxResetClick = (filterName) => {
    const updatedFilterOptions = filterOptions[filterName].map(option => ({
        ...option,
        isSelected: false
    }));
    setFilterOptions(prevOptions => ({
        ...prevOptions,
        [filterName]: updatedFilterOptions
    }));
    setActiveButton(null);
  }


  //#region change events
  const handleCheckboxChange = (value, details) => {
    const selectedItemIndex = filterOptions[details.apiName].findIndex(option => option.value === value);
    if (selectedItemIndex !== -1) {
        const updatedFilterOptions = [...filterOptions[details.apiName]];
        updatedFilterOptions[selectedItemIndex].isSelected = !updatedFilterOptions[selectedItemIndex].isSelected;
        setFilterOptions(prevOptions => ({
            ...prevOptions,
            [details.apiName]: updatedFilterOptions
        }));
    }
  }

  const handleRadioChange = (value,filterName) => {
    const intValue = parseInt(value);
    const updatedOptions = filterOptions?.[filterName].map(option => ({
        ...option,
        isSelected: option.value === intValue
    }));

    setFilterOptions(prevOptions => ({
        ...prevOptions,
        [filterName]: updatedOptions
    }));

    if(filterName === "fundType"){
      handleFundType(intValue,updatedOptions);
    }
  };

  const handleFundType = (value,updatedOptions) => {
     resetFundTypeFilters(updatedOptions,value);
     setSelectedFundType(value);
  }

  const handleTermChange = (termValue,filterName) => {
    /* console.log(termValue,filterName); */
    const intValue = parseInt(termValue);
    setFilterOptions(prevOptions => ({
        ...prevOptions,
        termOptions: {
          ...filterOptions[filterName],
          value: intValue
        }
    }));
    
  };

  const handleRadioGroupReset = (filterName) => {
    setFilterOptions(prevOptions => ({
        ...prevOptions,
        termOptions: {
          ...filterOptions[filterName],
          value: -1
        }
    }));
    setActiveButton(null);
  };

  const handleFundTypeReset = (filterName) => {
    resetFundTypeFilters();
    setSelectedFundType(null);
    setActiveButton(null);
  }

  //#region render functions
  const renderMultipleCheckboxes = (details) => {
    return (
        <ul className='ul-options'>
          <div className='space-between margin-top-5'>
            <p className='matter'>Select the {details.name} you would like to invest in.</p>
            <p className='reset' onClick={()=>{handleCheckBoxResetClick(details.apiName)}}>RESET</p>
          </div>
          <div className='filter-types-box display-row-items-3'>
            {filterOptions[details.apiName]
              ?.filter((option) => option?.isFundExcistforThisFilter === true)
              ?.map((item,index) => (
              <li key={item?.value}>
                <input
                  type="checkbox"
                  className="my-checkbox"
                  id={item.value}
                  name={item.name}
                  checked={filterOptions?.[details.apiName]?.find((option)=> option.value === item.value)?.isSelected}
                  onChange={() => handleCheckboxChange(item.value, details)}
                />
                <label htmlFor={item.value} className='no-select'>{item.label}</label>
              </li>
            ))} 
          </div>
        </ul>
    );
  };

  const renderRadioGroup = (details) => {
    return (
        <ul className='radio-group-box'>
          <div className='space-between margin-top-5'>
            <p className='matter'>Select the {details.name} you would like to invest in.</p>
            <p className='reset margin-top-5' onClick={()=>{handleFundTypeReset(details.name)}}>RESET</p>
          </div>
          <div className='margin-top-minus-10'>
            <RadioGroupVertial 
              options={details?.options}
              value={selectedFundType}
              onChange={(ev) => handleRadioChange(ev,"fundType")}/>
          </div>
        </ul>
    );
  };

  const renderSlider = (details) => {
    let sliderValue = filterOptions[details.apiName].value === -1 ? 1 : filterOptions[details.apiName].value ; 
    const handleSliderChange = (value) => {
      sliderValue = value; 
    };

    return(
      <div className="ul-options-left-5">
          <div className='space-between'>
            <p className='matter'>Select the {details.name} you would like to invest in.</p>
            <p className='reset' onClick={() => {handleRadioGroupReset(details?.apiName)}}>RESET</p>
          </div>
          <div style={{ marginLeft: '30px', marginRight: '20px' }}>
            <Slider
              aria-label="Always visible"
              valueLabelDisplay="auto"
              defaultValue={sliderValue}
              getAriaValueText={handleSliderChange}
              shiftStep={30}
              step={1}
              marks={termSliderMarks}
              min={SlideFilterValues.TERM_MIN_VALUE}
              max={SlideFilterValues.TERM_MAX_VALUE} />
          </div>
          <div className='space-between'>
              <div></div>
              <div className='margin-right-5'>
                <ActionButton
                  label="APPLY"
                  variant="contained"
                  className="btn-primary"
                  onClick={() =>{handleTermChange(sliderValue,details?.apiName)}}>
                </ActionButton>
              </div>
          </div>
      </div>
    )
  }

  //#region useEffect
  useEffect(() => {
    const handleBodyClick = (event) => {
      if (filterBoxRef.current && filterBoxRef.current.contains(event.target)) {
      } else {
        setActiveButton(null);
      }
    };

    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, [filterBoxRef]);

  useEffect(()=>{
    const selectedType = filterOptions?.fundType.find(option => option.value === selectedFundType);
    if(selectedFundType){
      const filtersBasedonFundType = initialFilters?.filter((item)=>item.fundtypes?.includes(selectedType?.label));
      setFilterLeftItems(filtersBasedonFundType)
    }
    else{
      setFilterLeftItems([initialFilters[0]])
    }
  },[selectedFundType])

  //#region return
  return (
    <div className='filterbox' ref={filterBoxRef}>
        <div className='phase1-container'>
          {
            filterLeftItems?.map((item,index)=>{
             return(
                <>
                  <button className={activeButton === item.id ? "buttons-NX" : "buttons"} onClick={()=>{handleButtonClick(item.id)}} key={item.id}>
                    <span className="type-text">{item.name}</span>
                    <span className='icon'>{item.icon}</span>
                  </button>
                  {activeButton === item.id && item.type === FiltersTypes.CHECKBOX ? renderMultipleCheckboxes(item) : null}
                  {activeButton === item.id && item.type === FiltersTypes.RADIO_GROUP ? renderRadioGroup(item) : null}
                  {activeButton === item.id && item.type === FiltersTypes.SLIDER ? renderSlider(item) : null}
                  {activeButton === item.id && item.type === FiltersTypes.TEXT_FIELD && item.name === "Minimum Investment" ? <InvestmentFilter currType={currFundType()} filterDetails={item} filterOptions={filterOptions} setFilterOptions={setFilterOptions} setActiveButton={setActiveButton}/>: null}
                  {activeButton === item.id && item.type === FiltersTypes.TEXT_FIELD && item.name === "Term" ? <TermFilter currType={currFundType()} filterDetails={item} filterOptions={filterOptions} setFilterOptions={setFilterOptions} setActiveButton={setActiveButton}/>: null}
                </>
             )
            })
          }
        </div>
        <div className='phase2'>
            {
              selectedFundType &&
              <button className="buttons" onClick={handleFundTypeReset}>
                <Tooltip title="Clear all filters">
                  <span className="icon-filter">
                  <><FilterListIcon className='icon' /></>
                  </span>
                </Tooltip>
              </button>
            }
            <button className="buttons" onClick={handleFavorite}>
            <Tooltip title="Favorite">
              <span className="icon-filter">
                <FavoriteIcon className='icon'/>
              {/* <img src='/MarketPlaceImages/favouriteicon1.svg' alt='dropdownicon'  /> */}
              </span>
              </Tooltip>
            </button>
            <button className="buttons" onClick={handleCompare}>
            <Tooltip title="Compare">
              <span className="icon-filter">
                <CompareArrowsIcon  className='icon'/>
              {/* <img src='/icon-materialcomparearrows.svg' alt='dropdown icon' className='icon' /> */}
              </span>
              </Tooltip>
            </button>

            <button className={activeButton === 7 ? "buttons-NX" : "buttons"} onClick={()=>{handleButtonClick(7)}}>
            <Tooltip title="Sort by">
              <span className="icon-filter">
                <TuneIcon className='icon'/> 
             {/* <img src={activeButton === 7 ? 'icons/sort-icon.svg' : '/group-319.svg'} alt='dropdown icon' className='icon' /> */}
              </span>
              </Tooltip>
            </button>
            {activeButton === 7 ?
              (
                <div className={currFundType() === fundTypesEnum.PRIVATE_CREDIT ? "ul-options-button-7 ul-options-button-7-pc" : "ul-options-button-7"}>
                  <div className='space-between'>
                    <p className='filter-matter margin-top-5 margin-left-10'>Sort By</p>
                    <p className='reset-button margin-top-5' onClick={()=>{handleCheckBoxResetClick("sortOptions")}}>RESET</p>
                  </div>
                  <div className='margin-left-10'>
                    {
                      filterOptions &&
                      <RadioGroupVertial 
                        options={filterOptions?.sortOptions}
                        onChange={(ev) => handleRadioChange(ev,"sortOptions")}
                        refreshOptions={filterOptions} />
                    }
                  </div>
                </div>
              ) : null}

            <button className='buttons listview-button' onClick={() => handleChangeView(marketplaceView === MarketPlaceView.BOX_VIEW)}>
              {
                marketplaceView === MarketPlaceView.BOX_VIEW ?
                <Tooltip title="Funds list view">
                <span className="icon-filter">
                <ListIcon className='icon' /> 
                </span>
                </Tooltip>
                :
                <Tooltip title="Funds card view">
                    <span className="icon-filter">
                <GridViewIcon className='icon' />
                </span>
                </Tooltip>
              }
            </button>
        </div>
    </div>
  );
};

export default MPFilterBox;
