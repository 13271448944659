import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useTimeout } from '@mui/x-data-grid/internals';

const roles = ['Market', 'Finance', 'Development'];
const randomRole = () => {
  return randomArrayItem(roles);
};

//#this grid allow users to perform crud operations
const CrudDataGrid = (props) => {

  //#region props
  const { initialColumns, initialRows, name, onRowChange, disabled, maxLimit, height,addLabel } = props;

  //#region variables
  const rowsWithId = Array.isArray(initialRows) ? initialRows.map((row, index) => ({ ...row, id: index })) : [];
  const [rows, setRows] = React.useState(rowsWithId);
  const [rowModesModel, setRowModesModel] = React.useState({});

  //#region funcitons
  const validateRow = (rowData) => {
    let isValid = true;
    isValid = Object.keys(rowData)
              .filter(item => item !== "id" && item !== "isNew")
              .some(key => rowData[key] !== "");
    return isValid;
  };

  //#region click events
  const handleAddClick = () => {
    const id = randomId();
    const newRow = {
      id,
      ...columns?.reduce((acc, item) => {
        acc[item.field] = '';
        return acc;
      }, {})
    };
    // Create a new object based on newRow
    const modifiedNewRow = { ...newRow };
    // Remove the 'actions' field if it exists
    delete modifiedNewRow.actions;
    // Add the 'isNew' field set to false
    modifiedNewRow.isNew = false;
    setRows(oldRows => [...oldRows, modifiedNewRow]);
    /* onRowChange(name, [rows, modifiedNewRow]); */
    setRowModesModel(oldModel => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (rowData) => () => {
    setRowModesModel({ ...rowModesModel, [rowData?.id]: { mode: GridRowModes.View } });
  
  };


  const handleDeleteClick = (id) => () => {
    /* onRowChange(name, rows.filter((row) => row.id !== id)); */
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      /* onRowChange(name, rows.filter((row) => row.id !== id)); */
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    const updatedRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
    /* onRowChange(name, updatedRows); */
    const isValid = validateRow(newRow);
    if(!isValid){
      setRowModesModel({ ...rowModesModel, [newRow?.id]: { mode: GridRowModes.Edit } });
      toast.warning("Please fill at least one column",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" }); 
      return
    }
    setRowModesModel({ ...rowModesModel, [newRow?.id]: { mode: GridRowModes.View } });
    setRows(updatedRows);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  //#region grid columns
  const columns = [
    ...initialColumns,
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      cellClassName: 'actions',
      getActions: ({ id,row  }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(row)}
              disabled={disabled}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              disabled={disabled}
              color="inherit"
            />,
          ];
        } else {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              disabled={disabled}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              disabled={disabled}
              color="inherit"
            />,
          ];
        }
      },
    },
  ];

  //#region useeffect
   useEffect(() => {
    onRowChange(name, rows);
   }, [rows]);
   
  //#region return
  return (
    <div className='child-margin-10'>
     <div className='space-between'>
          <div></div>
          <div>
            <Button
              variant='outlined'
              startIcon={<AddIcon />}
              onClick={handleAddClick}
              disabled={disabled || rows?.length >= maxLimit}>
              {addLabel ? addLabel : "ADD"}
            </Button>
          </div>
     </div>
     <Box
      sx={{
        height: height ? height : 200,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        disableColumnSelector={true}
        /* slots={{
          toolbar: EditToolbar,
        }} */
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        disableColumnMenu
        hideFooter
      />
    </Box>
    </div>
  );
}
export default CrudDataGrid;
