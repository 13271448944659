export function convertStringToArray(input) {
    if(input === ""){
        return [];
    }
    if (Array.isArray(input)) {
        return input.map((item) => (typeof item === 'string' ? parseInt(item, 10) : item));
    }

    if (typeof input === 'string' && input.length > 0) {
        const array = input.split(',');
        return array.map((item) => (isNaN(item) ? item : parseInt(item, 10)));
    }
    return [];
}

export function convertArrayToString(input) {
    if (Array.isArray(input) && input.length > 0) {
        return input.join(','); 
    } else if (Array.isArray(input) && input.length === 0) {
        return ''; 
    }

    return input ? input.toString() : '';  
}

export function convertStringToArrayWithEmptyArray(input) {
    if(input === ""){
        return [];
    }
    if (Array.isArray(input)) {
        return input.map((item) => (typeof item === 'string' ? parseInt(item, 10) : item));
    }

    if (typeof input === 'string' && input.length > 0) {
        const array = input.split(',');
        return array.map((item) => (isNaN(item) ? item : parseInt(item, 10)));
    }
    return [];
}

export function trimAndLowercaseArray(arr) {
    return arr?.map(item => {
        if (typeof item === 'string') {
            return item.trim().toLowerCase();
        }
        return item; // or you could handle non-string items differently
    }) || [];
}



