import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import propTypes from 'prop-types';
import AlphabeticInput, { usCurrencyFormat, usPhoneNumberFormat, zipcode, Percentage, AlphanumericInput, SSNFormat, NumericInput, AlphaHyphenPeriodInput, AlphaHyphenPeriodFormat, PositiveNumericInput } from '../TextInput/InputTypes';
import { FieldVariants, textFormatTypeEnum } from '../TextInput/appInputenum';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const ALTTextField = (props) => {

    //#region props
    const {
        name,
        label,
        rows,
        size,
        variant,
        multiline,
        placeholder,
        textFormatType,
        value,
        onChange,
        disabled,
        required,
        onBlur,
        charactersMaxLength,
        error,
        errorMessage,
        readOnly,
        characterLengthRemaining
    } = props;

    //#region variables\
    const [localValue, setLocalValue] = useState(value || "");
    const [showPassword, setShowPassword] = useState(true);

    //#region change events
    const handleChange = (ev) => {
        const { name, value } = ev.target;
        setLocalValue(value);
    }

    //#region click events
    const onClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    //#region render functions
    const currentInputType = () => {
        if (textFormatType === textFormatTypeEnum.US_PHONE_NUMBER) {
            return usPhoneNumberFormat;
        }
        else if (textFormatType === textFormatTypeEnum.US_CURRENCY) {
            return usCurrencyFormat
        }
        else if (textFormatType === textFormatTypeEnum.ONLY_ALPHABETS) {
            return AlphabeticInput;
        }
        else if (textFormatType === textFormatTypeEnum.ONLY_NUMBERS) {
            return NumericInput;
        }
        else if (textFormatType === textFormatTypeEnum.ZIPCODE) {
            return zipcode;
        }
        else if (textFormatType === textFormatTypeEnum.PERCENTAGE) {
            return Percentage;
        }
        else if (textFormatType === textFormatTypeEnum.ALPHA_NUMERIC) {
            return AlphanumericInput;
        }
        else if (textFormatType === textFormatTypeEnum.TIN) {
            return SSNFormat;
        }
        else if (textFormatType === textFormatTypeEnum.ALPHA_HYPHEN_PERIOD) {
            return AlphaHyphenPeriodFormat;
        }
        else if (textFormatType === textFormatTypeEnum.POSITIVE_NUMBERS) {
            return PositiveNumericInput;
        }
    }

    const currstartAdornment = () => {
        if (textFormatType === textFormatTypeEnum.US_CURRENCY) {
            return <InputAdornment position="start">$</InputAdornment>;
        }
        else {
            return '';
        }
    }

    const currendAdornment = () => {
        if (textFormatType === textFormatTypeEnum.PERCENTAGE) {
            return <InputAdornment position="end">%</InputAdornment>;
        }
        else if (textFormatType === textFormatTypeEnum.PASSWORD) {
            return <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={onClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
        } else if (textFormatType === textFormatTypeEnum.characterLength) {
            return <InputAdornment position="end">
                {`${characterLengthRemaining}/${charactersMaxLength}`}
            </InputAdornment>
        }
        else {
            return '';
        }
    }

    //#region useeffect
    useEffect(() => {
        setLocalValue(value);
    }, [value])

    useEffect(() => {
        onChange(name, localValue)
    }, [name, localValue])

    //#region return
    return (
        <TextField
            name={name}
            label={label}
            required={required}
            disabled={disabled}
            type={(textFormatType === textFormatTypeEnum.PASSWORD && showPassword) ? 'password' : 'text'}
            value={localValue}
            variant={rows > 1 ? FieldVariants.OUTLINED : variant}
            placeholder={placeholder}
            multiline={multiline || rows > 1}
            rows={rows}
            size={size}
            fullWidth
            sx={{ lineHeight: '1.5em !important' }}
            onChange={handleChange}
            inputProps={
                {
                    maxLength: charactersMaxLength,
                }}
            InputProps={{
                readOnly: readOnly,
                startAdornment: textFormatType === textFormatTypeEnum.US_CURRENCY ? currstartAdornment() : "",
                endAdornment: currendAdornment(),
                inputComponent: currentInputType()
            }}
            onBlur={!readOnly && onBlur}
            error={error}
            helperText={error ? errorMessage : ''}
            InputLabelProps={{
                shrink: true,
                focused: true,
            }} />
    );
};


ALTTextField.propTypes = {
    type: propTypes.string,
    textFormatType: propTypes.oneOf(['usPhoneNumberFormat', 'usCurrencyFormat', 'text', 'onlyAlphabets', 'zipcode', 'percentage', 'alphaNumeric']),
    onChange: propTypes.func.isRequired,
    variant: propTypes.oneOf(['standard', 'filled', 'outlined']),
    required: propTypes.bool,
    multiline: propTypes.bool,
    value: propTypes.string,
    placeholder: propTypes.string,
    rows: propTypes.number,
    label: propTypes.string,
    disabled: propTypes.bool,
    options: propTypes.array,
    name: propTypes.string,
    size: propTypes.string,
    tabIndex: propTypes.number,
    charactersMaxLength: propTypes.number,
    charactersMinLength: propTypes.number,
    dataType: propTypes.string,
    readOnly: propTypes.bool
};

ALTTextField.defaultProps = {
    type: 'text',
    textFormatType: 'text',
    variant: 'standard',
    size: 'small',
    required: false,
    multiline: false,
    value: '',
    placeholder: '',
    rows: 1,
    label: '',
    options: [],
    disabled: false,
    name: '',
    tabIndex: 0,
    charactersMaxLength: 100, //set based on your preference
    charactersMinLength: 100,
    dataType: 'string',
    readOnly: false
};

export default ALTTextField;