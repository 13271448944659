import React, { useEffect, useState } from 'react'
import FileUploadBtn from '../../../common/FileUploadButton/FileUploadBtn';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import EditIcon from '@mui/icons-material/Edit';
import ActionButton from '../../../common/ActionButton/ActionButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Grid, LinearProgress, Select } from '@mui/material';
import SelectField from '../../../common/input-fields/SelectField';
import { UpdateProfileInformation, getInvestorDetails } from '../../../user-profile/InvestorUserProfile/Services/Investorinfo';
import { getKeyValuePairs } from '../../../portfolio-companies/services/services';
import { InvestorTypesEnum, SpousehasIIAaccdropdown, addditionalDropDownOptions, dependentsOptions, employmentstatusdropdown, isemployesdropdown } from '../../services/apiDataTemplate';
import DiscussionBox from '../../../KYCVerification/components/DiscussionBox';
import secureLocalStorage from 'react-secure-storage';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VDRoom from '../../../common/VirtualDataRoom/VDRoom';
import { userProfileToastMessages, validationsConstants } from '../../../../utils/AppConstants';
import { getInvestorProfileDetails, updateUserProfile } from '../../services/services';
import { ToastContainer, toast } from 'react-toastify';
import { personalInformationFields } from '../../services/fieldsData';
import InputField from '../../../common/input-fields/InputField';
import { textFormatTypeEnum, validationsTypeEnum } from '../../../common/TextInput/appInputenum';
import { uploadFileTypes } from '../../../common/FileUploadButton/uploadComponentenum';
import VerificationOtp from '../../popups/VerificationOtp';
import { getOTPAttemptsIn24Hours, PostOTP, updateUserProfileIconData } from '../../../user-profile/services/UserProfileService';
import { convertStringToArrayWithEmptyArray } from '../../../common/Functions/ConvertStringtoArray';
import { FieldTypes } from '../../../common/input-fields/utlis/InputFieldsEnum';
import AutoSelectField from '../../../common/input-fields/AutoSelectFiled';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { KycAmlStuatus, KycStatusvalues } from '../../../KYCVerification/enum';
import { updatekycamlstatus } from '../../../KYCVerification/services/services';
import { getStatesandCitieslist } from '../../../InvestorFundSubscription/Services/Statecountry';
import { display } from '@mui/system';
import { addCustomYupMethods } from '../../../common/input-fields/utlis/CustomYupValidations';

const IndividualInformation = (props) => {

  //#region props
  const { investorDetails,getInvestorInfo,investorTypesValues,isKycVerification,kycStatus,amlStatus,getInvestorKycAml,isViewOnly } = props; 

  //#region variables
  const [isSaveEnabled,setIsSaveEnabled] = useState(false);
  const [fieldsDetails, setFieldsDetais] = useState(personalInformationFields); 
  const [currUserFields,setCuurUserFields] = useState([]);
  const [showComments, setshowComments] = useState(false);
  const [loading,setLoading] = useState(false);
  const [verificationPopup,setVerificationPopup] = useState(false);
  const [otpAttemptsLeft, setOTPAttemptsLeft] = useState(0);
  const [legalEntityTypes,setLegalEntityTypes] = useState([]);
  const [count, setCount] = useState(0);
  const [validationFields,setValidationFields] = useState();//for dependent validations
  const [verifyBtnLoading,setVerifyBtnLoading] = useState(false);
  const [rejectBtnLoading,setRejectBtnLoading] = useState(false);
  const [refreshDetails,setRefreshDetails] = useState(false);
  const [linearProgress,setLinearProgress] = useState(true);

  //#region functions
  const convertStringToArray = (fields) => {
     const convertedFields =  fields?.split(',').map(s => s.trim().toLowerCase());
     setCuurUserFields(convertedFields);
  }

  const showThisField = (field) => {
    if(currUserFields?.includes(field?.name?.toLowerCase()) || field.hasParentQuestion){
      if(field?.hasParentQuestion){
        const fieldOptions = fieldsDetails?.find(item => item.name === field.parentQuestionName)?.options;
        const selectedOption = fieldOptions?.find(item => item.label?.toLowerCase() === addditionalDropDownOptions.OTHER)?.value;
        //#using validation fields instead of personalinformation details beacus of intialozation issues
        if(validationFields?.[field.parentQuestionName] === selectedOption){
          return true;
        }
        else{
          return false;
        }
      }
      else{
        return true;
      }
    }
    else
    {
      return false;
    }
  }

  //#region change events
  const handleFileUpload = async (name, file) => {
    if (file) {
      handleChange(name,file);
    }
    else {
      handleChange(name,"");
    }
  }

  const handleChange = (name, value) => {
    personalInformationDetails.setFieldValue(name, value);
  }

  //#region click events
  const onEditClick = () => {
    setIsSaveEnabled(true);
  }

  const onSaveClick = () =>{
    if(investorDetails?.personalInformation?.emailAddress === personalInformationDetails.values?.emailAddress){
      updatePersonalInformation();
    }
    else{
      if(otpAttemptsLeft <= 0){
        toast.error(userProfileToastMessages.ATTEMPTS_REACHED, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
      }
      else{
        checkEmail();
      }
    }
  }

  const onCancelClick = () => {
    setIsSaveEnabled(false);
    personalInformationDetails.resetForm();
    getInvestorInfo();
    setLoading(false)
  }

  const onVerifyClick = () => {
    setVerifyBtnLoading(true);
    updateKycStatus(true);
  }

  const onRejectClick = () => {
    setRejectBtnLoading(true);
    updateKycStatus(false);
  }

  const onOpenVerificationPopup = () => {
     setVerificationPopup(true);
  }

  const onCloseVerificationPopup = () => {
    setVerificationPopup(false);
  }

  const handleComments = () => {
    const details = {
      "id": personalInformationDetails?.values?.userId
    }
    secureLocalStorage.setItem("KYCInvestorDetails", details);
    setshowComments(!showComments);
  }

  //#region api get calls
  const getDropDownDetails = async () => {
      const statesData = await getStatesandCitieslist();
      const taxFilingStatusData = await getKeyValuePairs('TaxFilingStatus');
      // const legalEntityTypeData = await getKeyValuePairs('LegalEntityType');
      const investmentGoalData = await getKeyValuePairs('InvestmentGoal');
      const advisoryServicesRequiredData = await getKeyValuePairs('AdvisoryServicesRequired');
      const regulatoryStatusData = await getKeyValuePairs('RegulatoryStatus');

      let stateOptions = []
      let taxFilingStatusOptions = []
      let legalEntityTypeOptions = []
      let investmentGoalOptions = []
      let advisoryServicesOptions = []
      let regulatoryStatusOptions = []
      let countryOptions = []

      if(statesData.responseCode === 200){
        stateOptions = statesData.responseData
            ? statesData.responseData?.states?.map(option => ({ label: option.stateName, value: option.stateMasterId })) || []
            : []; 
      }

      if(statesData.responseCode === 200){
        countryOptions = statesData.responseData
            ? statesData.responseData?.countries?.map(option => ({ label: option.countryName, value: option.countryMasterID })) || []
            : []; 
      }
      
      if(taxFilingStatusData?.responseCode === 200){
        taxFilingStatusOptions = taxFilingStatusData.responseCode === 200
            ? taxFilingStatusData.responseData?.map(option => ({ label: option.listItemValue, value: option.listItemID })) || []
            : [];
      }
      /* if(legalEntityTypeData?.responseCode === 200){
        legalEntityTypeOptions = legalEntityTypeData.responseCode === 200
            ? legalEntityTypeData.responseData?.map(option => ({ label: option.listItemValue, value: option.listItemID })) || []
            : [];
      } */
      if(investmentGoalData?.responseCode === 200){
        investmentGoalOptions = investmentGoalData.responseCode === 200
            ? investmentGoalData.responseData?.map(option => ({ label: option.listItemValue, value: option.listItemID })) || []
            : [];
      }
      if(advisoryServicesRequiredData?.responseCode === 200){
        advisoryServicesOptions = advisoryServicesRequiredData.responseCode === 200
            ? advisoryServicesRequiredData.responseData?.map(option => ({ label: option.listItemValue, value: option.listItemID })) || []
            : [];
      }
      if(regulatoryStatusData?.responseCode === 200){
        regulatoryStatusOptions = regulatoryStatusData.responseCode === 200
            ? regulatoryStatusData.responseData?.map(option => ({ label: option.listItemValue, value: option.listItemID })) || []
            : [];
      }

      const updatedFields = personalInformationFields.map(field => {
        if (field.name === 'stateMasterID') {
          return { ...field, options: stateOptions };
        }
        else if (field.name === "countryID") {
          return { ...field, options: countryOptions };
        }
        else if (field.name === "taxFillingContactID") {
          return { ...field, options: taxFilingStatusOptions };
        }
        else if (field.name === "investmentGoalID") {
          return { ...field, options: investmentGoalOptions };
        }
        else if (field.name === "advisoryServicesRequiredID") {
          return { ...field, options: advisoryServicesOptions };
        }
        else if (field.name === "regulatoryStatusID") {
          return { ...field, options: regulatoryStatusOptions };
        }
        return field;
      });
      
      setFieldsDetais(updatedFields);
      setLinearProgress(false);
  } 

  const otpAttemptsIn24Hours = async () => {
    const data = await getOTPAttemptsIn24Hours();
    if (data?.responseCode === 200) {
      setOTPAttemptsLeft(data?.responseData);
    }
    else {
      setOTPAttemptsLeft(0);
    }
  }

  const checkEmail = async() => {
    const emailCheck = await getInvestorProfileDetails(personalInformationDetails.values.emailAddress);
    if(emailCheck.responseCode === 200){
      toast.error(userProfileToastMessages.EXCISTING_EMIAL, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
    else{
      if(emailCheck.responseData.pendingDaysToChangeEmailAddress > 60 
      ){
      onOpenVerificationPopup();
      }
      else{
        toast.error(userProfileToastMessages.OLD_EMAIL_CANNOT_USE, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
      }
    }
  }

  const getLegalEntityTypes = async() => { 
    const data = await getKeyValuePairs("LegalEntityType");
    if(data.responseCode === 200){
      const dropdownOptions = data?.responseData?.map(item => ({ "label": item?.listItemValue, "value":  item?.listItemID })) || [];
      setLegalEntityTypes(dropdownOptions);
    }
    else{
      setLegalEntityTypes([]);
    }
  }

  //#region api post calls
  const updatePersonalInformation = async (fromOtp, verifiedOtp, otpID) => {
    setLoading(true);
    const requestData = {
      "personalInformation": personalInformationDetails.values,
      "bankingInformation": null,
      "investmentProfile": null
    };
    const newImage = personalInformationDetails.values.profileIcon
    if (personalInformationDetails.values.profileIcon !== investorDetails?.personalInformation?.profileIcon) {
      requestData.personalInformation.profileIcon = personalInformationDetails.values.profileIcon.name
    }
    const data = await updateUserProfile(requestData,personalInformationDetails.values.userId)
    if(data.responseCode === 200){
      if (personalInformationDetails.values.profileIcon !== investorDetails?.personalInformation?.profileIcon) {
        uploadProfileIcon(newImage,fromOtp,otpID, verifiedOtp);
      }
      else{
        if(fromOtp){
          //#for verification popup
          updateEmail(otpID,verifiedOtp);
        }
        onCancelClick();
        toast.success(userProfileToastMessages.INFORMATION_SUCESSFUL, {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: "colored",
          });

        /* Once we are saving the profile information we are enabling the other tabs for investor*/
        secureLocalStorage.setItem('isProfilingCompleted', true);
      }
    }
    else{
      setLoading(false);
      onCancelClick();
      toast.error(userProfileToastMessages.INFORMATION_FAILED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      //#for verification popup
      return false;
    }
  }

  //#region update calls
  const updateEmail = async (otpID,verifiedOtp) => {
    const requestData = {
      "otpId": otpID,
      "otp": verifiedOtp,
      "newEmailAddress": personalInformationDetails.values.emailAddress,
      "oldEmailAddress": secureLocalStorage.getItem("userEmail"),
      "adb2cUserID": secureLocalStorage.getItem("tenantUserId"),
      "userName": secureLocalStorage.getItem("userName")
    };
    const data = await PostOTP(requestData);
    if(data.responseCode === 200){
      return true;
    }
    else{
      return false;
    }
  }
  const uploadProfileIcon = async(newImage,fromOtp,otpID,verifiedOtp) => {
    const formDataBody = new FormData();
    formDataBody.append("file", newImage);
    const imgResponse = await updateUserProfileIconData(formDataBody, personalInformationDetails.values.emailAddress);
    if(imgResponse.responseCode === 200){
      if(fromOtp){
        //#for verification popup
        updateEmail(otpID,verifiedOtp);
      }
      onCancelClick();
      toast.success(userProfileToastMessages.INFORMATION_SUCESSFUL, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
    else{
      onCancelClick();
      toast.error(userProfileToastMessages.PROFILE_IMG_ERROR, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      //#for verification popup
      return false;
    }
  }

  const updateKycStatus = async (isVerified) => {
    const requestBody = {
      "userId": investorDetails.personalInformation.userId,
      "kycaml": KycAmlStuatus.KYC_STATUS,
      "kycStatus": isVerified ? KycStatusvalues.VERIFIED : KycStatusvalues.REJECTED,
      "amlStatus": amlStatus,
    }
    const data = await updatekycamlstatus(requestBody);
    if (data.responseCode === 200) {
      getInvestorKycAml();
      setVerifyBtnLoading(false);
      setRejectBtnLoading(false);
      toast.success(userProfileToastMessages.VERIFICATION_SUCCESS, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
    else {
      toast.error(userProfileToastMessages.VERIFICATION_FAILED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
  }

  //#region formik validations
  addCustomYupMethods();

  const validationSchema = () => {
    const isIndividualInvestor = investorTypesValues
      ?.find(item => item?.listItemID === investorDetails?.personalInformation?.investorTypeID)
      ?.listItemValue === InvestorTypesEnum.INDIVIDUAL_INVESTOR;
  
    const shape = {};
  
    fieldsDetails?.forEach(item => {
      const childQuestionValidation = item?.hasParentQuestion && showThisField(fieldsDetails);
      if ((item?.required || item?.optionalRequired) && showThisField(item)) {
        if(item?.validationType === validationsTypeEnum.US_PHONE_NUMBER){
          shape[item.name] = yup.string().required(validationsConstants.REQUIRED)
                              .required(validationsConstants.REQUIRED)
                              .matches(/^\(\d{3}\) \d{3}-\d{4}$/, validationsConstants.US_PHONE_NUMBER)
        }
        else if(item?.validationType === validationsTypeEnum.EMAIL_ADDRESS){
          shape[item.name] = yup
                            .string()
                            .required(validationsConstants.REQUIRED)
                            .customEmail(validationsConstants.EMAIL_ADDRESS)
        }
        else if(item?.validationType === validationsTypeEnum.ZIP_CODE){
          shape[item.name] = yup
                            .string()
                            .required(validationsConstants.REQUIRED)
                            .matches(/^\d{5}(?:-\d{4})?$/, 'Invalid ZIP code format')
        }
        else if(item?.validationType === validationsTypeEnum.URL){
          shape[item.name] = yup
                            .string()
                            .matches(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*\.[a-z]{2,}$/i,validationsConstants.URL)
        }
        else if(item?.validationType === validationsTypeEnum.TIN){
          shape[item.name] = yup
                            .string()
                            .required(validationsConstants.REQUIRED)
                            .matches(/^\w{3}-\w{2}-\d{4}$/, validationsConstants.TIN)
        }
        else if(item?.type === FieldTypes.SELECT_FIELD){
          shape[item.name] = yup.lazy(value => {
            if (typeof value === 'number') {
              return yup.number()
                .min(1, validationsConstants.REQUIRED)
                .required(validationsConstants.REQUIRED);
            } else {
              return yup.string()
                .required(validationsConstants.REQUIRED);
            }
          });
        }
        else if(item?.type === FieldTypes.AUTO_SELECT_FIELD) {
          shape[item.name] = yup.lazy(value => {
            if (value === -1) {
              // If -1 is a valid value, you might not want to apply any validation
              return yup.mixed().notRequired();
            } else if (typeof value === 'number') {
              return yup.number()
                .min(1, validationsConstants.REQUIRED)
                .required(validationsConstants.REQUIRED);
            } else {
              return yup.string()
                .required(validationsConstants.REQUIRED);
            }
          });
        }        
        else {
          shape[item.name] = yup.string().trim().required(validationsConstants.REQUIRED);
        }
      }
    });
  
    if (isIndividualInvestor) {
      shape.firstName = yup.string().trim().required(validationsConstants.REQUIRED)
                       .matches(/^[A-Za-z]+[A-Za-z\s]*$/, validationsConstants.ONLY_ALPHABETS);
      shape.lastName = yup.string().trim().required(validationsConstants.REQUIRED)
                      .matches(/^[A-Za-z]+[A-Za-z\s]*$/, validationsConstants.ONLY_ALPHABETS);
    } else {
      shape.firstName = yup.string().trim().required(validationsConstants.REQUIRED);
      shape.legalEntityTypeID = yup.number().test('is-valid', validationsConstants.REQUIRED, value => value === -1 || value >= 1).required(validationsConstants.REQUIRED);
    }
  
    return yup.object().shape(shape);
  };
  
  const personalInformationDetails = useFormik({
    initialValues: {
      ...investorDetails
    },
    validationSchema: validationSchema(), 
    onSubmit: (values) => {
      onSaveClick();
    },
  });

  //#region useeffect
  useEffect(()=>{
    getDropDownDetails();
    otpAttemptsIn24Hours();
    getLegalEntityTypes();

    !secureLocalStorage.getItem('isProfilingCompleted') && 
    toast.warning(userProfileToastMessages.PROFILE_COMPLETE_WARNING, {
    position: toast.POSITION.BOTTOM_RIGHT,
    theme: "colored",
    });
  },[])

  useEffect(()=>{
    if(investorDetails){
      convertStringToArray(investorDetails?.personalInformation?.manageProfileFields);
      personalInformationDetails.setValues(investorDetails?.personalInformation);
      getLegalEntityTypes();
      getDropDownDetails();
    }
  },[investorDetails])

  useEffect(()=>{
    setValidationFields(personalInformationDetails.values);
  },[personalInformationDetails.values])
  

  //#region return
  return (
    <div className='child-margin-15'>
      
      {linearProgress && <LinearProgress />}
      {!linearProgress && 
        investorDetails ? 
        <>
        {
          isKycVerification ? 
          isViewOnly ? "" :
          <div className='firm-verify-btns space-between'>
             <div></div>
             {
              (kycStatus === KycStatusvalues.VERIFIED || kycStatus === KycStatusvalues.REJECTED) ? 
              <div>
                 <ActionButton 
                      label={kycStatus}
                      variant='contained'
                      disabled={true}
                      onClick={()=>{}}/>
              </div> :
              <div className='child-row-margin-10'>
                 <div>
                    <ActionButton 
                        label="VERIFY"
                        icon={<CheckCircleIcon />}
                        disabled={ kycStatus === KycStatusvalues.INCOMPLETE
                                   || rejectBtnLoading}
                        loading={verifyBtnLoading}
                        variant='contained'
                        onClick={onVerifyClick}/>
                 </div>
                 <div>
                  <ActionButton 
                        label="REJECT"
                        icon={<ThumbDownIcon />}
                        disabled={verifyBtnLoading}
                        loading={rejectBtnLoading}
                        variant='outlined'
                        onClick={onRejectClick}/>
                 </div>
              </div>
             }
          </div>  :
          <div className='investor-save-cancel-btns space-between'>
              <div></div>
              <div className='child-row-margin-10'>
                {
                  isSaveEnabled ? 
                  <>
                    <ActionButton 
                      label="CANCEL"
                      icon={<CancelIcon />}
                      disabled={loading}
                      variant='outlined'
                      onClick={onCancelClick}/>
                    <ActionButton
                        label="SAVE"
                        loading={loading}
                        icon={<SaveIcon />}
                        onClick={personalInformationDetails.handleSubmit} />
                  </> : 
                  <>
                    <ActionButton 
                      label="EDIT" 
                      icon={<EditIcon />}
                      onClick={onEditClick}/>
                  </>
                }
              </div>
          </div>
        }
         <form className='white-card child-margin-15' novalidate="novalidate">
            <Grid container spacing={2} alignItems="center">
              <Grid item lg='4' md='4' sm='12' xs='12'>
                  <FileUploadBtn 
                    name="profileIcon"
                    label="UPLOAD PROFILE ICON"
                    defaultFile={personalInformationDetails?.values?.profileIcon ? personalInformationDetails?.values?.profileIcon : '/icons/defaultpropic.jpg'}
                    fileType={uploadFileTypes.IMAGE}
                    readOnly={isSaveEnabled ? false : true}
                    fromWhiteLabeling={isSaveEnabled ? true : false}
                    isSize={true}
                    onChange={(name, value) => handleFileUpload(name, value)}/>
              </Grid>
              {
                investorTypesValues?.
                  find((item) => item?.listItemID === investorDetails?.personalInformation?.investorTypeID)?.listItemValue 
                     === InvestorTypesEnum.INDIVIDUAL_INVESTOR 
                 ? <>
                    <Grid item lg='4' md='4' sm='6' xs='12'>
                    <ALTTextField 
                      textFormatType={textFormatTypeEnum.ONLY_ALPHABETS}
                      name="firstName"
                      label="First Name"
                      value={personalInformationDetails?.values?.firstName}
                      onChange={(name, value) => handleChange(name, value)}
                      required={true}
                      readOnly={!isSaveEnabled}
                      onBlur={personalInformationDetails.handleBlur}
                      error={personalInformationDetails.touched.firstName && Boolean(personalInformationDetails.errors.firstName)}
                      errorMessage={personalInformationDetails.touched.firstName && personalInformationDetails.errors.firstName} />
                    </Grid>
                    <Grid item lg='4' md='4' sm='6' xs='12'>
                      <ALTTextField 
                        textFormatType={textFormatTypeEnum.ONLY_ALPHABETS}
                        name="lastName"
                        label="Last Name"
                        value={personalInformationDetails?.values?.lastName}
                        onChange={(name, value) => handleChange(name, value)}
                        required={true}
                        readOnly={!isSaveEnabled}
                        onBlur={personalInformationDetails.handleBlur}
                        error={personalInformationDetails.touched.lastName && Boolean(personalInformationDetails.errors.lastName)}
                        errorMessage={personalInformationDetails.touched.lastName && personalInformationDetails.errors.lastName}/>
                    </Grid>
                   </>
                 : <>
                    <Grid item lg='4' md='4' sm='6' xs='12'>
                      <ALTTextField 
                        name="firstName"
                        label="Legal Entity Name"
                        value={personalInformationDetails?.values?.firstName}
                        onChange={(name, value) => handleChange(name, value)}
                        required={true}
                        readOnly={!isSaveEnabled}
                        onBlur={personalInformationDetails.handleBlur}
                        error={personalInformationDetails.touched.firstName && Boolean(personalInformationDetails.errors.firstName)}
                        errorMessage={personalInformationDetails.touched.firstName && personalInformationDetails.errors.firstName} />
                    </Grid>
                    <Grid item lg='4' md='4' sm='6' xs='12'>
                      <AutoSelectField 
                        name="legalEntityTypeID"
                        childName="legalEntityTypeName"
                        label="Legal Entity Type"
                        value={personalInformationDetails?.values?.legalEntityTypeID}
                        childValue={personalInformationDetails?.values?.legalEntityTypeName}
                        onChange={(name, value) => handleChange(name, value)}
                        options={legalEntityTypes}
                        required={true}
                        readOnly={!isSaveEnabled}
                        onBlur={personalInformationDetails.handleBlur}
                        error={personalInformationDetails.touched.legalEntityTypeID && Boolean(personalInformationDetails.errors.legalEntityTypeID)}
                        errorMessage={personalInformationDetails.touched.legalEntityTypeID && personalInformationDetails.errors.legalEntityTypeID}/>
                    </Grid>
                   </>
              }
            </Grid>
            <Grid container spacing={2} mt={2} >
              {
                fieldsDetails?.map((field)=>{
                    return(
                      <>
                      {
                        showThisField(field) && 
                        <Grid item lg='4' md='4' sm='6' xs='12'>
                          <InputField
                              textFormatType={field.formatType || ''}
                              type={field.type}
                              name={field.name}
                              label={field.label}
                              childName={field.childName}
                              variant="standard"
                              rows={1}
                              required={field.required}
                              charactersMaxLength={field.charactersMaxLength}
                              options={field?.options?.length > 0 ? field?.options : []}
                              value={personalInformationDetails?.values?.[field.name] || ''}
                              childValue={personalInformationDetails?.values?.[field.childName] || ''}
                              onChange={(name, value) => handleChange(name, value)}
                              readOnly={!isSaveEnabled}
                              onBlur={personalInformationDetails.handleBlur}
                              error={personalInformationDetails.touched?.[field.name] && Boolean(personalInformationDetails?.errors?.[field.name])}
                              errorMessage={personalInformationDetails?.touched?.[field.name] && personalInformationDetails?.errors?.[field.name]}/>
                        </Grid>
                      }
                      </>
                    )
                })
              }
            </Grid>
           {/*  <div>
                <Button
                  onClick={handleComments}
                  icon={<VisibilityIcon />}
                  variant="contained"
                  className="btn-primary">
                  {showComments ? 'Hide Comments' : 'Show Comments'}
                </Button>
            </div> */}
         </form> 
         {/* {
            showComments &&
            <div item xs={12} className='whiteCard' sx={{ marginTop: '10px' }}>
              <DiscussionBox setRefreshDetails={setRefreshDetails}/>
            </div>
          }
          <div className='white-card margin-top-10'>
            {
              !refreshDetails &&
               <VDRoom 
                 VDRType={'KYCAML'}
                 cnrtFundID={0}
                 crntSubscriptionID={0}
                 crntInvestorID={investorDetails?.personalInformation?.userId}
                 hideMenu={true} />
            }
          </div> */}
          {
            verificationPopup &&
            <VerificationOtp 
              open={verificationPopup}
              onClose={onCloseVerificationPopup}
              emailID={personalInformationDetails.values.emailAddress}
              otpAttemptsLeft={otpAttemptsLeft}
              getOTPAttemptsIn24Hours={otpAttemptsIn24Hours}
              updateFunction={updatePersonalInformation}
              loading={loading}
               />
          }
        </> : 
        <div>
            {/* <LinearProgress /> */}
        </div>
      }
      <ToastContainer />
    </div>
  )
}

export default IndividualInformation