import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { validationsConstants } from '../../../../utils/AppConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import SelectField from '../../../common/input-fields/SelectField';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import { uploadFileTypes } from '../../../common/FileUploadButton/uploadComponentenum';
import FileUploadBtn from '../../../common/FileUploadButton/FileUploadBtn';
import { fundTypesEnum } from '../../../marketplace-dashboard/jsonData';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ActionButton from '../../../common/ActionButton/ActionButton';
import { FundCreationModes } from '../../services/apiDataDemplate';
import { FieldVariants } from '../../../common/TextInput/appInputenum';
import { CreateInvestmentStrategy, updateInvestmentStrategy } from '../../../marketplace/services/MarketplaceService';
import { ToastContainer, toast } from 'react-toastify';

const InvestmentStrategy = forwardRef((props, ref) => {

  //#region props
  const { type,fundDetails, setFundDetails, fundTypes, folderNames,getFundDetails } = props;

  //#region intialData
  const initialData = {
    ...fundDetails
  };

  //#region variables
  const [loading,setLoading] = useState(false);
  const [isSaveEnabled,setIsSaveEnabled] = useState(type === FundCreationModes.EDIT ? false : true);

  //#region change events
  const handleFileUpload = (name, file) => {
    if (file) {
      const formData = new FormData();
      formData.append(name, file, file.name);
      handleChange(name, file);
    }
    else {
      handleChange(name, '');
    }
  }

  const handleChange = (field, value) => {
    if (field === "fundTypeID") {
      const currFundType = fundTypes.find(u => u.value === value)?.label;
      investmentStrategyDetails.setFieldValue("fundTypeName", currFundType)
    }
    investmentStrategyDetails.setFieldValue(field, value);
  }

  //#region click events
  const onEditClick = () => {
    setIsSaveEnabled(true);
  }

  const onSaveClick = async() => {
    updateFundInvestmentStrategy();
  }

  const onCancelClick = () => {
    setIsSaveEnabled(false);
    investmentStrategyDetails?.resetForm();
  }

  //#region update calls
  const updateFundInvestmentStrategy = async () => {
    setLoading(true);
    const requestData = {
      "fundInvestmentStrategyID": investmentStrategyDetails.values.fundInvestmentStrategyID === null ? 0 : investmentStrategyDetails.values.fundInvestmentStrategyID,
      "fundID": investmentStrategyDetails.values.fundID,
      "investmentStrategy": investmentStrategyDetails.values.dscInvestmentStrategy,
      "investmentCriteria": investmentStrategyDetails.values.dscInvestmentCriteria,
      "investmentProcess": investmentStrategyDetails.values.dscInvestmentProcess,
      "riskManagementApproach": investmentStrategyDetails.values.dscRiskManagementApproach,
      "valueCreationAndOperImprovementStrategies": investmentStrategyDetails.values.dscValueCreationAndOperImprovementStrategies,
      "exitStrategies": investmentStrategyDetails.values.dscExitStrategies,
      "isActive": true,
      "softDelete": true,
      "createdBy": 0,
      "createdDate": new Date(),
      "updatedBy": 0,
      "updatedDate": new Date()
    }
    
    let response = "";

    if(requestData.fundInvestmentStrategyID === 0){
      response = await CreateInvestmentStrategy(requestData);
    }
    else{
      response = await updateInvestmentStrategy(requestData);
    }

    if (response.responseCode === 200) {
      toast.success("Successfully Saved", { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setIsSaveEnabled(false);
      setLoading(false);
      getFundDetails();
    }
    else {
        toast.error("Failed to Save,Please try again", { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        setIsSaveEnabled(false);
    }

  }

  //#region formik validations
  const validationSchema = yup.object().shape({
    startegyfilepath: yup
      .string()
      .required(validationsConstants.REQUIRED),
    strategyDocument: yup
      .string()
      .required(validationsConstants.REQUIRED),
  });

  const investmentStrategyDetails = useFormik({
    initialValues: initialData,
    validationSchema: (type === FundCreationModes.EDIT || fundDetails?.fundType === fundTypesEnum.PRIVATE_EQUITY || fundDetails?.fundType === fundTypesEnum.VENTURE_CAPITAL )? validationSchema : "",
    onSubmit: (values) => {
      return true;
    },
  });

  //#region imperative handle
  useImperativeHandle(ref, () => ({
    validateFields: async () => {
      const errors = await investmentStrategyDetails.validateForm();
      if (Object.keys(errors).length === 0) {
        //need to update main set of values
        setFundDetails((prevValues) => {
          return { ...prevValues, ...investmentStrategyDetails?.values };
        });
        return true;
      }
      else {
        investmentStrategyDetails?.handleSubmit()
      }
    },
    updateFields: async () => {
      setFundDetails((prevValues) => {
        return { ...prevValues, ...investmentStrategyDetails?.values };
      });
    }
  }));

  //#region return
  return (
    <>
      <div className='space-between'>
            <div></div>
            <div className='child-row-margin-5 margin-right-10'>
              {
                type === FundCreationModes.EDIT ?
                isSaveEnabled ?
                  <>
                    <div>
                      <ActionButton 
                          label="Cancel"
                          variant={FieldVariants.OUTLINED}
                          disabled={loading}
                          icon={<CancelIcon />}
                          onClick={() => onCancelClick()}/>
                    </div>
                    <div>
                      <ActionButton
                        label="SAVE"
                        icon={<SaveIcon />}
                        loading={loading}
                        onClick={() => onSaveClick()} />
                    </div>
                  </> 
                  :
                  <div>
                    <ActionButton 
                        label="Edit"
                        icon={<EditIcon />}
                        onClick={() => onEditClick()}/>
                  </div>
                :  <div className='child-margin-10'>
                {
                  (fundDetails?.fundTypeName === fundTypesEnum.PRIVATE_EQUITY || fundDetails?.fundTypeName === fundTypesEnum.VENTURE_CAPITAL) &&
                  <>
                    <div>
                      <FileUploadBtn
                        name='strategyDocument'
                        label="UPLOAD DOCUMENT"
                        fileType={uploadFileTypes.PDF}
                        required={true}
                        defaultFile={fundDetails?.strategyDocument}
                        onChange={(name, value) => handleFileUpload(name, value)}
                        error={investmentStrategyDetails.touched.strategyDocument && Boolean(investmentStrategyDetails.errors.strategyDocument)}
                        validationMessage={investmentStrategyDetails.touched.strategyDocument && investmentStrategyDetails.errors.strategyDocument} />
                    </div>
                    <div className='width-300'>
                      <SelectField
                        name="startegyfilepath"
                        label="Select Document Type"
                        value={investmentStrategyDetails?.values?.startegyfilepath}
                        onChange={(name, value) => handleChange(name, value)}
                        options={folderNames}
                        required={true}
                        onBlur={investmentStrategyDetails.handleBlur}
                        error={investmentStrategyDetails.touched.startegyfilepath && Boolean(investmentStrategyDetails.errors.startegyfilepath)}
                        errorMessage={investmentStrategyDetails.touched.startegyfilepath && investmentStrategyDetails.errors.startegyfilepath} />
                    </div>
                  </>
                }
              </div>

              }
              <div></div>
            </div>
        </div>
      <div className='display-row-items-flex'>
        <div className='width-98'>
          <ALTTextField
            name="dscInvestmentStrategy"
            placeholder="Description of investment strategy"
            value={investmentStrategyDetails?.values?.dscInvestmentStrategy}
            onChange={(name, value) => handleChange(name, value)}
            multiline={true}
            rows={4}
            required={true}
            readOnly={!isSaveEnabled}
            onBlur={investmentStrategyDetails.handleBlur}
            error={investmentStrategyDetails.touched.dscInvestmentStrategy && Boolean(investmentStrategyDetails.errors.dscInvestmentStrategy)}
            errorMessage={investmentStrategyDetails.touched.dscInvestmentStrategy && investmentStrategyDetails.errors.dscInvestmentStrategy} />
        </div>
        <div className='width-98'>
          <ALTTextField
            name="dscInvestmentCriteria"
            placeholder="Description of investment criteria (e.g., stage, size, growth potential, etc.)"
            value={investmentStrategyDetails?.values?.dscInvestmentCriteria}
            onChange={(name, value) => handleChange(name, value)}
            multiline={true}
            rows={4}
            required={true}
            readOnly={!isSaveEnabled}
            onBlur={investmentStrategyDetails.handleBlur}
            error={investmentStrategyDetails.touched.dscInvestmentCriteria && Boolean(investmentStrategyDetails.errors.dscInvestmentCriteria)}
            errorMessage={investmentStrategyDetails.touched.dscInvestmentCriteria && investmentStrategyDetails.errors.dscInvestmentCriteria} />
        </div>
        <div className='width-98'>
          <ALTTextField
            name="dscInvestmentProcess"
            placeholder="Description of investment process (eg., sourcing, due diligence, etc.)"
            value={investmentStrategyDetails?.values?.dscInvestmentProcess}
            onChange={(name, value) => handleChange(name, value)}
            multiline={true}
            rows={4}
            required={true}
            readOnly={!isSaveEnabled}
            onBlur={investmentStrategyDetails.handleBlur}
            error={investmentStrategyDetails.touched.dscInvestmentProcess && Boolean(investmentStrategyDetails.errors.dscInvestmentProcess)}
            errorMessage={investmentStrategyDetails.touched.dscInvestmentProcess && investmentStrategyDetails.errors.dscInvestmentProcess} />
        </div>
        <div className='width-98'>
          <ALTTextField
            name="dscRiskManagementApproach"
            placeholder="Description of risk management approach"
            value={investmentStrategyDetails?.values?.dscRiskManagementApproach}
            onChange={(name, value) => handleChange(name, value)}
            multiline={true}
            rows={4}
            required={true}
            readOnly={!isSaveEnabled}
            onBlur={investmentStrategyDetails.handleBlur}
            error={investmentStrategyDetails.touched.dscRiskManagementApproach && Boolean(investmentStrategyDetails.errors.dscRiskManagementApproach)}
            errorMessage={investmentStrategyDetails.touched.dscRiskManagementApproach && investmentStrategyDetails.errors.dscRiskManagementApproach} />
        </div>
        <div className='width-98'>
          <ALTTextField
            name="dscValueCreationAndOperImprovementStrategies"
            placeholder="Description of value creation and operational improvement strategies"
            value={investmentStrategyDetails?.values?.dscValueCreationAndOperImprovementStrategies}
            onChange={(name, value) => handleChange(name, value)}
            multiline={true}
            rows={4}
            required={true}
            readOnly={!isSaveEnabled}
            onBlur={investmentStrategyDetails.handleBlur}
            error={investmentStrategyDetails.touched.dscValueCreationAndOperImprovementStrategies && Boolean(investmentStrategyDetails.errors.dscValueCreationAndOperImprovementStrategies)}
            errorMessage={investmentStrategyDetails.touched.dscValueCreationAndOperImprovementStrategies && investmentStrategyDetails.errors.dscValueCreationAndOperImprovementStrategies} />
        </div>
        <div className='width-98'>
          <ALTTextField
            name="dscExitStrategies"
            placeholder="Description of exit strategies"
            value={investmentStrategyDetails?.values?.dscExitStrategies}
            onChange={(name, value) => handleChange(name, value)}
            multiline={true}
            rows={4}
            required={true}
            readOnly={!isSaveEnabled}
            onBlur={investmentStrategyDetails.handleBlur}
            error={investmentStrategyDetails.touched.dscExitStrategies && Boolean(investmentStrategyDetails.errors.dscExitStrategies)}
            errorMessage={investmentStrategyDetails.touched.dscExitStrategies && investmentStrategyDetails.errors.dscExitStrategies} />
        </div>
      </div>
    </>
  );
});

export default InvestmentStrategy;