import React from 'react'
import OnboardingQuestions from './OnboardingQuestions'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getInvestorsTypes } from '../../../InvestorRelations/Services/IRServices';
import { useState,useEffect } from 'react';

const OnBoardingQuesModel = () => {

  //#region Variables
  const [investorTypes,setInvestorTypes] = useState(null);
  const [expanded, setExpanded] = useState({});

  //#region click events
  const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded({ ...expanded, [panel]: isExpanded });
  };

  //#region api get calls
  const getInvestorsType = async () => {
    const data = await getInvestorsTypes('QuestionType');
    if (data?.responseCode === 200) {
      setInvestorTypes(data?.responseData);
    } else {
      // Handle error if needed
      /* console.log("Error in getting investor types"); */
    }
  }

  useEffect(() => {
    getInvestorsType();
  },[]);

  return (
    <div>
      <div>
        <h4>Investor Onboarding Questions</h4>
      </div>
      <div className='dispaly-column white-card margin-top-10'>
          {
            investorTypes ? investorTypes.map((item,index) => {
              return (
                <div className='margin-top-10'>
                    <Accordion expanded={expanded[item.listItemID] || false}
                                onChange={handleAccordionChange(item.listItemID)}
                                style={{border:'1px lightgray solid'}}>
                        <AccordionSummary
                                aria-controls="panel1bh-content"
                                expandIcon={<ExpandMoreIcon />}
                                id="panel1bh-header">
                                  {item.listItemValue}
                        </AccordionSummary>
                        <AccordionDetails>
                          {
                            expanded[item.listItemID] && 
                            <OnboardingQuestions investorType={item?.listItemValue} investorTypeID={item?.listItemID} isexpanded={expanded[item.listItemID]}/>
                          }
                        </AccordionDetails>
                    </Accordion>
                </div>
              )
            }) : "Loading..."
          }
      </div>
    </div>
  )
}

export default OnBoardingQuesModel