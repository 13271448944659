import React, { useEffect, useState, useCallback } from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import secureLocalStorage from 'react-secure-storage';
import PrintIcon from '@mui/icons-material/Print';
import MinimizeIcon from '@mui/icons-material/Minimize';
import CloseIcon from '@mui/icons-material/Close';
import { renderWebChat, createDirectLine, createStyleSet, createBrowserWebSpeechPonyfillFactory, createStore } from 'botframework-webchat';

const CircleIcon = (props) => {
  const [open, setOpen] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);

  const handleIconClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props?.setChatOpen(false)
  };

  const dialogStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    //eight: '657px',
    //width: '390px',
    zIndex: 9000,
    background: 'transparent linear-gradient(180deg, #0A1A27 0%, #EAF0F7 100%)',
    color: 'white',
    overflow: 'hidden',
  };

  const chatIconSize = 40;
  const backgroundCircleSize = 40;

  const name = secureLocalStorage.getItem("fullName");
  const [fullname, setFullName] = useState(name);

  useEffect(() => {
    setFullName(name);
  }, [name]);


  useEffect(() => {
    if (open) {
      const initializeWebChat = async () => {
        const directLineSecret = 'ooyXokBdOtk.tiUEk3YU7A1YHLUpF7g-n1C9Yj1vOQB8g-4s7JyNr38'; // Replace with your actual Direct Line secret
        const directLineEndpoint = 'https://directline.botframework.com/v3/directline/tokens/generate';

        try {
          const response = await fetch(directLineEndpoint, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${directLineSecret}`
            }
          });

          if (response.ok) {
            const { token } = await response.json();
            const speechRecognitionPonyfillFactory = await createBrowserWebSpeechPonyfillFactory();

            const styleSet = createStyleSet({
              bubbleBackground: 'rgba(0, 0, 255, .1)',
              bubbleFromUserBackground: 'rgba(0, 255, 0, .1)'
            });

            styleSet.textContent = Object.assign({}, styleSet.textContent, {
              fontFamily: "'Comic Sans MS', 'Arial', sans-serif",
              fontWeight: 'bold'
            });

            const store = createStore({}, ({ dispatch }) => next => action => {
              if (action.type === 'WEB_CHAT/SEND_EVENT' && action.payload.name === 'webchat/join') {
                dispatch({
                  type: 'WEB_CHAT/SET_LANGUAGE',
                  payload: { language: 'en-US' }
                });
              }
              return next(action);
            });

            const activityMiddleware = () => next => card => {
              setChatHistory(prevHistory => [...prevHistory, card.activity]);
              if (card.activity.type === 'messageReaction') {
                return false;
              } else if (card.activity.from.role === 'bot') {
                return (...renderArgs) => (
                  <BotActivityDecorator key={card.activity.id} activityID={card.activity.id} store={store}>
                    {next(card)(...renderArgs)}
                  </BotActivityDecorator>
                );
              }
              return next(card);
            };

            const typingIndicatorMiddleware = () => next => ({ activeTyping }) => {
              activeTyping = Object.values(activeTyping);

              return (
                !!activeTyping.length && (
                  <span className="webchat__typing-indicator">
                    Currently typing: {activeTyping.map(({ role }) => role).sort().join(', ')}
                  </span>
                )
              );
            };

            renderWebChat({
              directLine: createDirectLine({ token }),
              styleOptions: {
                botAvatarImage:
                  'https://docs.microsoft.com/en-us/azure/bot-service/v4sdk/media/logo_bot.svg?view=azure-bot-service-4.0',
                botAvatarInitials: 'ALT',
                userAvatarImage: 'https://github.com/compulim.png?size=64',
                userAvatarInitials: 'WC',
                emojiSet: {
                  ':sheep:': '🐑',      // Sheep
                  '<3': '❤️',           // Heart
                  ':)': '😊',           // Smiling face with smiling eyes
                  ':D': '😄',           // Grinning face with smiling eyes
                  ':(': '😞',           // Disappointed face
                  ';)': '😉',           // Winking face
                  ':P': '😛',           // Face with tongue
                  ':O': '😮',           // Face with open mouth
                  'B)': '😎',           // Smiling face with sunglasses
                  ':|': '😐',           // Neutral face
                  ':*': '😘',           // Face blowing a kiss
                  ':/': '😕',           // Confused face
                  'XD': '😂',           // Face with tears of joy
                  ':@': '😡',           // Pouting face
                },
                hideUploadButton: false,
                sendTypingIndicator: true,
              },
              styleSet,
              webSpeechPonyfillFactory: speechRecognitionPonyfillFactory,
              activityMiddleware,
              typingIndicatorMiddleware,
              store,
            }, document.getElementById('webchat'));
          } else {
            throw new Error('Failed to fetch Direct Line token');
          }
        } catch (error) {
          console.error('Error initializing Web Chat:', error.message);
        }
      };

      initializeWebChat();
    }
  }, [open]);

  const BotActivityDecorator = ({ activityID, children, store }) => {
    const dispatch = store.dispatch;

    const addMessageReaction = (helpful) => {
      dispatch({
        type: 'WEB_CHAT/SEND_EVENT',
        payload: {
          name: 'reaction',
          value: {
            helpful,
            replyToId: activityID
          }
        }
      });
    };

    const handleDownvoteButton = useCallback(() => {
      addMessageReaction(-1);
    }, [activityID, dispatch]);

    const handleUpvoteButton = useCallback(() => {
      addMessageReaction(1);
    }, [activityID, dispatch]);

    return (
      <div className="botActivityDecorator">
        <ul className="botActivityDecorator__buttonBar">
          <li>
            <button className="botActivityDecorator__button" onClick={handleUpvoteButton}>
              👍
            </button>
          </li>
          <li>
            <button className="botActivityDecorator__button" onClick={handleDownvoteButton}>
              👎
            </button>
          </li>
        </ul>
        <div className="botActivityDecorator__content">{children}</div>
      </div>
    );
  };

  const downloadChatHistory = () => {
    // Format chatHistory into a string to be downloaded
    const formattedChat = chatHistory.map(message => `${message.from.role}: ${message.text}`).join('\n');

    // Create a blob containing the formatted chat history
    const blob = new Blob([formattedChat], { type: 'text/plain' });

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'chat_history.txt';
    link.click();

    // Clean up
    URL.revokeObjectURL(link.href);
  };

  return (
    <>
      <div
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          width: `${backgroundCircleSize}px`,
          height: `${backgroundCircleSize}px`,
          backgroundColor: '#38B5E8',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          zIndex: 9999,
          fontSize: `${chatIconSize}px`,
        }}
        onClick={handleIconClick}
      >
        <ChatIcon
          sx={{
            color: 'white',
            fontSize: chatIconSize,
          }}
        />
      </div>

      <Dialog open={open || props.chatopen} PaperProps={{ style: dialogStyle }}>
        <DialogTitle>
          <div className='space-between'>
            <div></div>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Button onClick={handleClose} sx={{ minWidth: '36px', padding: '6px' }}>
                <MinimizeIcon sx={{ color: 'white' }} />
              </Button>
              <Button onClick={handleClose} sx={{ minWidth: '36px', padding: '6px', marginRight: '-10px' }}>
                <CloseIcon sx={{ color: 'white' }} />
              </Button>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <img
              className="groupChildStyle"
              alt=""
              src="/group-330.svg"
              style={{ width: '170px', height: '70px' }}
            />
            <div style={{ color: "white" }}>ALT BUDDY</div>
          </div>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100% !important' }}>
          <div id="webchat"></div>
          <Button variant="outlined" startIcon={<PrintIcon />} onClick={downloadChatHistory} sx={{ marginTop: '10px', alignSelf: 'flex-end' }}>
            Print & Download
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CircleIcon;
