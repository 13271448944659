import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import filterTreeData from "./VDRFilter";
import renderTree from "./RenderTree";
import MoveDownIcon from '@mui/icons-material/MoveDown';
import { ComplianceVDR, GetDocumentTypes, GetVDRoom, KYCAMLVDR, VDRFileUpload, UpdatingVDR, GetFinalVDRoom, VDRItemVersionHistory, GetVDRSharedItems, GetSharedWithUsersData, VdrUploadFile, downloadVDRFile } from "./Services/VDRServices";
import { justifyContentAllignItem } from "../../CommonCss";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Text from "../Text/Text";
import CloseIcon from '@mui/icons-material/Close';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ShareIcon from '@mui/icons-material/Share';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import IosShareIcon from '@mui/icons-material/IosShare';
import Configurations from "../../../Configurations";
import secureLocalStorage from "react-secure-storage";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ToastContainer, toast } from 'react-toastify';
import { TreeView } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { checkFeaturePermissions } from "../../../utils/common";
import TextField from "@mui/material/TextField";
import { AppDashboards, RolesAndPermissions } from "../../../utils/enum";
import { FeatureAccess } from "../../../utils/enum";
import { Autocomplete, Chip, Grid, InputAdornment } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import AIPDataGrid from "../DataGrid/AIPDataGrid";
import TextInput from "../TextInput/TextInput";
import ActionButton from "../ActionButton/ActionButton";
import SearchIcon from '@mui/icons-material/Search';
import { UsersToShareVDRFolder, getUserList } from "../../InvestorRelations/Services/IRServices";
import InvestorsListPopup from "../../InvestorRelations/Components/InvestorCommunications/Groups/Popups/InvestorsListPopup";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LinkIcon from '@mui/icons-material/Link';
import { axiosApiCallForFiles } from "../../Services/axiosApiCallForFiles";
import { VirtualDataRoomToastMessages } from "../../../utils/AppConstants";


function VDRoom(props) { //## main parent function of VDR , we can call this whenever we need vdr 

  const { VDRType, showToggle = false,
    isCompliance,
    cnrtFundID,
    crntInvestorID, crntSubscriptionID, crntPortfolioid, hideMenu = false, refreshComponents = () => { } } = props //## props coming from parent component

  /* --------------------------------------------------------------
       Variables
  -------------------------------------------------------------- */
  let Portfolioid = crntPortfolioid ? crntPortfolioid : 0;
  let requestBody = null;
  const [popupVDR, setpopupVDR] = useState(false)
  const [VDRJson, setVDRJson] = useState(null)
  const [VdrVersionHistoryData, setVdrVersionHistoryData] = useState(null)
  const [selectedVDRID, setSelectedVDRID] = useState([]);
  const [popupSelectedVDRID, setpopupSelectedVDRID] = useState([])
  const crntUserRole = secureLocalStorage.getItem("userrole");
  const [searchQuery, setSearchQuery] = useState("");
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedNode, setSelectedNode] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAddFolderDialogOpen, setIsAddFolderDialogOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [folderName, setFolderNameFilter] = useState("");
  const [documentTypes, setDocumentTypes] = useState([]);
  const [documentTypeFilter, setDocumentTypeFilter] = useState("");
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showRenamePopup, setShowRenamePopup] = useState(false);
  const [CopyFilePopup, setCopyFilePopup] = useState(false)
  const [MoveFilePopup, setMoveFilePopup] = useState(false)
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);
  const [VersionHistoryPopupOpen, setVersionHistoryPopupOpen] = useState(false);
  const [sharePopupOpen, setsharePopupOpen] = useState(false)
  const [selectedInvestors, setSelectedInvestors] = useState([]);
  const [investorsPopup, setInvestorsPopup] = useState(false);
  const [investorsList, setInvestorsList] = useState(null);
  const [shareablelinkPopupOpen, setshareablelinkPopupOpen] = useState(false)
  const [shareableLinkValue, setshareableLinkValue] = useState('')
  const [selectedvdritems, setselectedvdritems] = useState([])
  const [popupSelectedVDRItems, setpopupSelectedVDRItems] = useState([])
  const [folderType, setFolderType] = useState('')
  const [renameValue, setRenameValue] = useState('')
  const [replaceCopyMoveItemsPopup, setreplaceCopyMoveItemsPopup] = useState(false)
  const [replaceUploadItemsPopup, setreplaceUploadItemsPopup] = useState(false)
  const [VDRSharedItems, setVDRSharedItems] = useState([])
  const [AccessLevel, setAccessLevel] = useState('')
  const [sharedWithUsers, setSharedWithUsers] = useState([])
  let AddFoldererror = ''
  const [showError, setShowError] = useState(false)

  /* --------------------------------------------------------------
    End of Variables
  -------------------------------------------------------------- */

  /* --------------------------------------------------------------
     Click Events & Menu Item Action Service calls
  -------------------------------------------------------------- */

  const onAutoCompleteChange = (e, value) => {
    const latestSelection = value.map(u => u?.userId);
    //const newItems = investorsList.filter(u => latestSelection?.includes(u.userId));
    setSelectedInvestors(investorsList.filter(u => latestSelection?.includes(u.userId)));
  }

  const handleOpenInvestorsList = () => {
    setInvestorsPopup(true);
  }
  const handleCloseInvestorsList = () => {
    setInvestorsPopup(false);
  }

  const handleContextMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNodeExpand = (event, nodeIds) => {
    setExpandedNodes(nodeIds);
  };

  const handleContextMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleLinkClick = () => {
  //   setInvestorsPopup(true);
  // };

  const handleMenuItemClick = (item) => {
    item.action();
    handleContextMenuClose();
  };
  const rowSelected = (event, nodeId, node) => {
    if (event.target.checked) {
      setselectedvdritems(prevItems => [...prevItems, node]);
      setSelectedVDRID(prevSelected => prevSelected.includes(nodeId) ? prevSelected : [...prevSelected, nodeId]);
    } else {
      setselectedvdritems(prevItems => prevItems.filter(item => item.vdrItemID !== node.vdrItemID));
      setSelectedVDRID(prevSelected => prevSelected.filter(id => id !== nodeId));
    }
  };

  const onPopupRowSelection = (event, nodeIds, node) => {
    if (node) {
      setpopupSelectedVDRID(() => {
        return [nodeIds];
      }
      );
      setpopupSelectedVDRItems(node);
    }
  }

  const handleTreeNodeClick = (node) => {
    setSelectedNode(node);

  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = filterTreeData(VDRJson, query, folderName);
    setFilteredData(filtered);
  };

  const handleDocumentTypeChange = (event) => {
    if (event.target.value === "All") {
      const selectedDocumentType = null;
      setFolderNameFilter(event.target.value.split("~")[1]);
      setDocumentTypeFilter(event.target.value);
      const filtered = filterTreeData(VDRJson, searchQuery, selectedDocumentType);
      setFilteredData(filtered);
    } else {
      const selectedDocumentType = event.target.value.split("~")[1];
      setFolderNameFilter(event.target.value.split("~")[1]);
      setDocumentTypeFilter(event.target.value);
      const filtered = filterTreeData(VDRJson, searchQuery, selectedDocumentType);
      setFilteredData(filtered);
    }
  };

  const handleAddFolder = () => {
    setIsAddFolderDialogOpen(true)
  }

  const handleRefreshClick = () => {
    getVdrData();
  }

  let foundMatch = false;
  const handleUploadFile = async () => {
    setIsUploadDialogOpen(true);
    const [firstSelectedVDRItem] = selectedvdritems;

    if (firstSelectedVDRItem && selectedFile) {
      for (const child of firstSelectedVDRItem.children) {
        if (child.title === selectedFile?.name) {
          foundMatch = true;
          break;
        }
      }
      if (foundMatch) {
        // toast.warning(VirtualDataRoomToastMessages.FILE_ALREADY_EXISTS, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        setreplaceUploadItemsPopup(true);
        return; // Stop further execution
      } else {
        const formData = new FormData();
        formData.append("file", selectedFile);
        const data = await VdrUploadFile(formData, selectedVDRID, VDRType);

        if (data.responseCode === 200) {
          setSelectedFile(null);
          setIsUploadDialogOpen(false)
          setSelectedVDRID([])
          setpopupSelectedVDRID([])
          setselectedvdritems([])
          setpopupSelectedVDRID([])
          getVdrData();
        }
        else {
          setSelectedFile(null);
          setIsUploadDialogOpen(false)
          setSelectedVDRID([])
          setpopupSelectedVDRID([])
          setselectedvdritems([])
          setpopupSelectedVDRID([])
          toast.warning(VirtualDataRoomToastMessages.ACTION_FAILED, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
          getVdrData();
        }
      }
    }
  };

  const handleDownloadFile = async (node) => {
    if (selectedNode) {
      const title = selectedvdritems[0]?.title;
      const vdrid = selectedvdritems[0]?.vdrItemID;

      const data = await axiosApiCallForFiles(`/v1/VirtualDataRooms/DownloadVDRItem?vdrID=${vdrid}`, title)
      if (data.responseCode === 200) {
        toast.success(VirtualDataRoomToastMessages.FILE_SUCCESS_DOWNLOAD, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      } else {
        toast.warning(VirtualDataRoomToastMessages.ACTION_FAILED, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleRename = () => {
    setShowRenamePopup(true)
  }

  const handleCopy = () => {
    setCopyFilePopup(true)
    setpopupVDR(true)
  }

  const handleMoveto = () => {
    setMoveFilePopup(true)
    setpopupVDR(true)
  }

  const handleDeleteFile = () => {
    setIsDeleteConfirmationDialogOpen(true);
  }

  const handleVersionHistory = () => {
    setVersionHistoryPopupOpen(true);
  }

  const handleShare = () => {
    SharedWithUsersData()
  }

  const handleShareableLink = () => {
    if (selectedNode) {
      const title = selectedvdritems[0]?.title;
      const vdrid = selectedvdritems[0]?.vdrItemID;
      const Url = `${Configurations.apiBaseFrontEndUri}/vdr-shared-file/details?vdrItemID=${vdrid}&fileName=${title}`
      /*  const Url = `${Configurations.apiBaseFrontEndUri}/vdr-shared-file/details?vdrItemID=${vdrid}` */
      setshareableLinkValue(Url)
    }
    setshareablelinkPopupOpen(true)
  }

  const handleChange = (name, value) => {
    if (name === "foldername") {
      setNewFolderName(value)
    }
    if (name === 'foldertype') {
      setFolderType(value);
    }
    if (name === 'accesslevel') {
      setAccessLevel(value)
    }
  }

  //## Region Common Service Call -- For All Actions

  const UpdateVDR = async (vdrAction) => {
    if (vdrAction === "Copy" || vdrAction === "Move") {
      const [firstSelectedVDRItem] = selectedvdritems;
      const firstPopupSelectedVDRItem = popupSelectedVDRItems?.children;

      if (firstPopupSelectedVDRItem) {
        let foundMatch = false;
        for (const child of firstPopupSelectedVDRItem) {
          if (child.title === firstSelectedVDRItem?.title) {
            foundMatch = true;
            break;
          }
        }

        if (foundMatch) {
          const actionText = vdrAction === "Copy" ? "copy" : "move";
          // toast.warning(`You have selected the node which has the same file you are trying to ${actionText}`, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
          setreplaceCopyMoveItemsPopup(true);
        } else {
          requestBody = {
            "vdrAction": vdrAction,
            "vdrSelectedItemIDs":
              selectedVDRID,
            "vdrDestinationItemID": selectedNode.vdrItemID,
            "actionByUserID": secureLocalStorage.getItem("userId")
          };
        }
      }
    }
    else if (vdrAction === "Rename") {
      const [firstSelectedVDRItem] = selectedvdritems;
      //const firstPopupSelectedVDRItem = popupSelectedVDRItems?.children;
      requestBody = {
        "vdrAction": vdrAction,
        "vdrSelectedItemIDs": [
          selectedVDRID[0]
        ],
        "newFolderName": (firstSelectedVDRItem?.isFolder) ? renameValue : `${renameValue}.${selectedvdritems[0]?.title.split('.').pop()}`,
        "actionByUserID": secureLocalStorage.getItem("userId")
      };
    }
    else if (vdrAction === "GetSharedFolderDetails") {

      requestBody = {
        "vdrAction": vdrAction,
        "vdrSelectedItemIDs": [
          selectedVDRID[0]
        ],
        "actionByUserID": secureLocalStorage.getItem("userId")
      };
    }

    else if (vdrAction === "ShareFolder") {
      requestBody = {
        "vdrAction": vdrAction,
        "vdrSelectedItemIDs": [
          selectedVDRID[0]
        ],
        "sharedWith": [
          ...selectedInvestors.map(id => ({ userID: id.userId, accessLevel: AccessLevel })),
          ...sharedWithUsers?.map(item => ({ userID: item.sharedUserID, accessLevel: item.accessLevel }))
        ],
        "actionByUserID": secureLocalStorage.getItem("userId")
      };
    }
    else if (vdrAction === "ShareFile") {
      requestBody = {
        "vdrAction": vdrAction,
        "vdrSelectedItemIDs": [
          selectedVDRID[0]
        ],
        "actionByUserID": secureLocalStorage.getItem("userId")
      };
    }
    else if (vdrAction === "CreatePrivateFolder") {
      // debugger
      const [firstSelectedVDRItem] = selectedvdritems;
      let foundMatch = false;
      for (const child of firstSelectedVDRItem.children) {
        if (child.title === newFolderName) {
          foundMatch = true;
          break;
        }
      }

      if (foundMatch) {
        const actionText = vdrAction;
        // setShowError(true)
        //const actionText = vdrAction;
        AddFoldererror = `${VirtualDataRoomToastMessages.FOLDER_ALREADY_EXISTS} ${actionText}`
        toast.warning(`${VirtualDataRoomToastMessages.SHARING_FILE_ALREADY_EXISTS} ${actionText}`, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        //setIsAddFolderDialogOpen(false)
      } else if (newFolderName === '') {
        toast.warning(`Please enter folder name`, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        // setShowError(true)
        //  AddFoldererror = `This field is required.`
      } else {
        requestBody = {
          "vdrAction": vdrAction,
          "vdrSelectedItemIDs": [
            selectedVDRID[0]
          ],
          "newFolderName": newFolderName,
          //"isPrivateFolder": true,
          "actionByUserID": secureLocalStorage.getItem("userId")
        };
      }
    }

    else if (vdrAction === "CreatePublicFolder") {
      const [firstSelectedVDRItem] = selectedvdritems;
      let foundMatch = false;
      for (const child of firstSelectedVDRItem?.children) {
        if (child.title === newFolderName) {
          foundMatch = true;
          break;
        }
      }

      if (foundMatch) {
        const actionText = vdrAction;
        //setShowError(true)
        AddFoldererror = `${VirtualDataRoomToastMessages.FOLDER_ALREADY_EXISTS} ${actionText}`
        toast.warning(`${VirtualDataRoomToastMessages.FOLDER_ALREADY_EXISTS} ${actionText}`, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        setIsAddFolderDialogOpen(false)
      } else if (newFolderName === '') {
        toast.warning(`Please enter folder name`, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        // setShowError(true)
        // AddFoldererror = `This field is required.`
      } else {
        requestBody = {
          "vdrAction": vdrAction,
          "vdrSelectedItemIDs": [
            selectedVDRID[0]
          ],
          "newFolderName": newFolderName,
          //"isPrivateFolder": false,
          "actionByUserID": secureLocalStorage.getItem("userId")
        };
      }
    }
    else if (vdrAction === "Delete") {
      requestBody = {
        "vdrAction": vdrAction,
        "vdrSelectedItemIDs":
          [selectedVDRID[0]],
        "actionByUserID": secureLocalStorage.getItem("userId")
      };
    }

    // Service call remains here
    const data = await UpdatingVDR(requestBody);
    if (data?.responseCode === 200) {
      getVdrData();
      setCopyFilePopup(false)
      setSelectedInvestors([])
      setNewFolderName('')
      setpopupSelectedVDRID([])
      setpopupSelectedVDRID([])
      setRenameValue('')
      setsharePopupOpen(false)
      setIsDeleteConfirmationDialogOpen(false)
      setShowRenamePopup(false)
      setFolderType('')
      if ((vdrAction === 'CreatePublicFolder' || vdrAction === 'CreatePrivateFolder') && (AddFoldererror === '' && newFolderName !== '')) {
        setIsAddFolderDialogOpen(false)
        setselectedvdritems([])
      }
      if ((vdrAction !== 'CreatePublicFolder' || vdrAction !== 'CreatePrivateFolder')) {
        setselectedvdritems([])
      }
      setMoveFilePopup(false)
      setSelectedVDRID([])
      refreshComponents();
    } else {
      //toast.warning(VirtualDataRoomToastMessages.ACTION_FAILED, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      //setCopyFilePopup(false)
      setsharePopupOpen(false)
      if ((vdrAction === 'CreatePublicFolder' || vdrAction === 'CreatePrivateFolder') && (AddFoldererror === '' && newFolderName !== '')) {
        setIsAddFolderDialogOpen(false)
        setselectedvdritems([])
      }
      if ((vdrAction !== 'CreatePublicFolder' || vdrAction !== 'CreatePrivateFolder')) {
        setselectedvdritems([])
      }
      setShowRenamePopup(false)
      setRenameValue('')
      getVdrData();
      setIsDeleteConfirmationDialogOpen(false)
      setNewFolderName('')
      setFolderType('')
      setSelectedInvestors([])
      setSelectedVDRID([])
      setpopupSelectedVDRID([])
      setpopupSelectedVDRID([])
      //setMoveFilePopup(false)
      refreshComponents();
    }

  }

  const replaceCopyPasteFilesAgreed = async () => {
    requestBody = {
      "vdrAction": selectedVDRID.length > 1 ? "Move" : "Copy",
      "vdrSelectedItemIDs":
        selectedVDRID,
      "vdrDestinationItemID": selectedNode.vdrItemID,
      "actionByUserID": secureLocalStorage.getItem("userId")
    };
    const data = await UpdatingVDR(requestBody);
    if (data?.responseCode === 200) {
      getVdrData();
      setCopyFilePopup(false)
      setreplaceCopyMoveItemsPopup(false)
      setpopupSelectedVDRID([])
      setsharePopupOpen(false)
      //setIsAddFolderDialogOpen(false)
      setMoveFilePopup(false)
    } else {
      toast.warning(VirtualDataRoomToastMessages.ACTION_FAILED, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setCopyFilePopup(false)
      getVdrData();
      setsharePopupOpen(false)
      setreplaceCopyMoveItemsPopup(false)
      setpopupSelectedVDRID([])
      //setIsAddFolderDialogOpen(false)
      setMoveFilePopup(false)
    }
  }

  const replaceUploadFilesAgreed = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      await VdrUploadFile(formData, selectedVDRID, VDRType);
      setSelectedFile(null);
      getVdrData();
      setreplaceUploadItemsPopup(false)
      setIsUploadDialogOpen(false)
    } catch (error) {
      console.error("File upload error:", error);
      setreplaceUploadItemsPopup(false)
      setIsUploadDialogOpen(false)
    }
  }

  const copyShareableLink = (text) => {
    navigator.clipboard.writeText(text)
    const buttons = document.getElementsByClassName('actionButtonLabel');
    for (const button of buttons) {
      button.textContent = 'Copied';
      //button.style.color = 'blue';
      setTimeout(() => {
        button.textContent = 'Copy Link';
        button.style.color = '';
      }, 5000);
    }
  }

  //## to render context menu items
  const contextMenuItems = useMemo(() => {
    let menuItemToShow = [];
    const selectedItem = selectedvdritems[0];


    menuItemToShow.push(
      {
        label: "Refresh",
        action: handleRefreshClick,
        icon: <AutorenewIcon />
      }
    );

    if (!selectedvdritems || selectedvdritems.length === 0) {
      return menuItemToShow;
    }

    if (selectedvdritems.length === 1) {

      if (selectedItem.vdrItemID === 0) {
        return menuItemToShow;
      }



      if (selectedItem.isFolder) {
        if (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD
          || selectedItem.vdrType === "KYCAML"
        ) {
          return menuItemToShow;
        }
        //Is Private Fodler
        if (selectedItem.isPrivate) {
          // If Owner
          if (selectedItem.uploadedBy === secureLocalStorage.getItem('userId')) {
            menuItemToShow.push(
              {
                label: "Upload File",
                action: handleUploadFile,
                icon: <FileUploadIcon />
              }
            );
            if (selectedItem.children.length == 0) {
              menuItemToShow.push(
                {
                  label: "Delete Folder",
                  action: handleDeleteFile,
                  icon: <DeleteIcon />
                }
              );
              if (selectedItem.children.length === 0 || selectedItem.children === null) {
                menuItemToShow.push(
                  {
                    label: "Rename",
                    action: handleRename,
                    icon: <DriveFileRenameOutlineIcon />
                  }
                );
              }
            }
            if (VDRType !== "Portfolio") {
              menuItemToShow.push(
                {
                  label: "Share",
                  action: handleShare,
                  icon: <IosShareIcon />
                }
              );
            }
          }
          //if not owner
          else {
            if (VDRSharedItems.find(u => u.vdrItemID === selectedItem.vdrItemID)
              && (VDRSharedItems.find(u => u.vdrItemID === selectedItem.vdrItemID).accessLevel === "Add File"
                || VDRSharedItems.find(u => u.vdrItemID === selectedItem.vdrItemID).accessLevel === "Delete File")) {
              menuItemToShow.push(
                {
                  label: "Upload File",
                  action: handleUploadFile,
                  icon: <FileUploadIcon />
                }
              );
            }
          }
          return menuItemToShow

        }
        else {
          menuItemToShow.push(
            {
              label: "Upload File",
              action: handleUploadFile,
              icon: <FileUploadIcon />
            }
          );
          menuItemToShow.push(
            {
              label: "Add Folder",
              action: handleAddFolder,
              icon: <CreateNewFolderIcon />
            }
          );
          if (!selectedItem.isDefaultFolder) {
            menuItemToShow.push(
              {
                label: "Delete Folder",
                action: handleDeleteFile,
                icon: <DeleteIcon />
              }
            );
            if (selectedItem.children.length === 0 || selectedItem.children === null) {
              menuItemToShow.push(
                {
                  label: "Rename",
                  action: handleRename,
                  icon: <DriveFileRenameOutlineIcon />
                }
              );
            }
          }
          return menuItemToShow
        }
      }
      //If File
      else {

        menuItemToShow.push(
          {
            label: "Download File",
            action: handleDownloadFile,
            icon: <DownloadIcon />
          }
        );

        if (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD
          || selectedItem.vdrType === "KYCAML"
        ) {
          return menuItemToShow;
        }
        menuItemToShow.push({
          label: "Copy",
          action: handleCopy,
          icon: <ContentCopyIcon />
        });

        menuItemToShow.push(
          {
            label: "Delete",
            action: handleDeleteFile,
            icon: <DeleteIcon />
          }
        ); ///need to cross check again
        if (!selectedItem.isPrivate) {
          menuItemToShow.push({
            label: "Move",
            action: handleMoveto,
            icon: <MoveDownIcon />
          });

          if (selectedItem.children.length === 0 || selectedItem.children === null) {
            menuItemToShow.push({
              label: "Rename",
              action: handleRename,
              icon: <DriveFileRenameOutlineIcon />
            });
          }
          if (VDRType !== "Portfolio") {
            menuItemToShow.push({
              label: "Share Link",
              action: handleShareableLink,
              icon: <ShareIcon />
            });
          }
        }
        else {
          if (VDRSharedItems.find(u => u.vdrItemID === selectedItem.parentID)
            && (VDRSharedItems.find(u => u.vdrItemID === selectedItem.parentID).accessLevel === "Delete File")) {
            menuItemToShow.push(
              {
                label: "Delete",
                action: handleDeleteFile,
                icon: <DeleteIcon />
              }
            );
          }
        }
        if (selectedItem.versionNumber > 1) {
          menuItemToShow.push({
            label: "Version History",
            action: handleVersionHistory,
            icon: <SettingsBackupRestoreIcon />
          });
        }
        return menuItemToShow
      }
    } else {
      // Multiple Items Selected
      const allFolders = selectedvdritems.every(item => item.isFolder);
      const allFiles = selectedvdritems.every(item => !item.isFolder);
      if (allFolders || (!allFolders && !allFiles)) {
        // Only Folders Selected or both selected
        return menuItemToShow;
      } else if (allFiles) {
        // Only Files Selected
        if (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD) {
          return menuItemToShow;
        }
        menuItemToShow.push({
          label: "Move",
          action: handleMoveto,
          icon: <MoveDownIcon />
        });
        return menuItemToShow;
      }
    }

  }, [selectedvdritems]);


  /* --------------------------------------------------------------
     End of Click Events & Menu Item Action Service calls
  -------------------------------------------------------------- */

  /* --------------------------------------------------------------
     Service Calls to get initial VDR
  -------------------------------------------------------------- */
  const getVdrData = async () => {
    let data;
    data = await (GetFinalVDRoom(cnrtFundID, crntSubscriptionID, Portfolioid, (crntUserRole === AppDashboards.INV_DASHBOARD ? secureLocalStorage.getItem("userId") : (crntInvestorID ? crntInvestorID : 0)), VDRType));
    if (data?.responseCode == 200) {
      setVDRJson(data?.responseData);
      setFilteredData(data?.responseData);
    } else {
      toast.warning(VirtualDataRoomToastMessages.NOT_FOUND, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" })
      //toast.warning(data?.responseData, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  const VersionHistory = async () => {
    let data;
    data = await (VDRItemVersionHistory(selectedVDRID[selectedVDRID.length - 1] ?? selectedVDRID[selectedVDRID.length - 1]));
    if (data?.responseCode == 200) {
      setVdrVersionHistoryData(data?.responseData)
    } else {
      //toast.warning(data?.responseData, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  const UserSharedVDRItems = async () => {
    let data;
    data = await (GetVDRSharedItems(secureLocalStorage.getItem("userId")));
    if (data?.responseCode == 200) {
      setVDRSharedItems(data?.responseData)
    } else {
      //toast.warning(data?.responseData, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }


  const SharedWithUsersData = async () => {
    let data;
    data = await (GetSharedWithUsersData(selectedVDRID ?? selectedVDRID[0]));
    if (data?.responseCode == 200) {
      setSharedWithUsers(data?.responseData)
      setsharePopupOpen(true)
    } else {
      setSharedWithUsers([])
      setsharePopupOpen(true)
      //toast.warning(data?.responseData, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      //setsharePopupOpen(false)
    }
  }

  const DocumentTypes = async () => {
    const data = await GetDocumentTypes(VDRType)
    if (data.responseCode === 200) {
      const allOption = {
        folderID: "all",
        folderName: "All",
        documentType: "All",
        documentTypeID: "All"
      };

      let updatedDocumentTypes = data.responseData.filter(dtd => dtd.parentFolderName != 'Investor Reporting' && dtd.folderName != 'Investor Reporting');
      if (VDRType !== 'Portfolio') {
        updatedDocumentTypes = [allOption, ...updatedDocumentTypes];
      }
      setDocumentTypes(updatedDocumentTypes);
    } else {
      toast.warning(VirtualDataRoomToastMessages.DOCUMENTTYPES_NOT_FOUND, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  const getInvestors = async () => {
    const data = await UsersToShareVDRFolder();
    if (data?.responseCode === 200) {
      setInvestorsList(data?.responseData);
    } else {
      toast.warning(VirtualDataRoomToastMessages.NO_USERS_FOUND_TO_SHARE_FOLDER, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      /* console.log(data?.responseData); */
    }
  }

  useEffect(() => {
    getVdrData()
    DocumentTypes()
    getInvestors()
    // VersionHistory()
    //UserSharedVDRItems()
  }, [])

  const formattedVersionHistoryData = VdrVersionHistoryData ? VdrVersionHistoryData?.map(item => ({
    id: item.vdrItemID,
    filename: item.title,
    fileversion: item.versionNumber,
    size: (item.fileSizeInBytes / (1024 * 1024)).toFixed(2) + " MB",
    date: new Date(item.uploadedDate).toLocaleDateString() // Convert to localized date string
  })) : [];


  /* --------------------------------------------------------------
      End of Service Calls
  -------------------------------------------------------------- */

  return (
    <div>
      {VDRJson ?
        <div>
          <Grid
            container
            xs={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h6 style={{ width: '100%' }}> Virtual Data Room </h6>
            {checkFeaturePermissions(
              RolesAndPermissions.FUND_VDR,
              FeatureAccess.READ) &&
              <Grid>
                <TextField classes="vdr-search"
                  label="Search"
                  variant="outlined"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  size="small"
                  className="dataroomfeilds"
                />
                {VDRType !== "KYCAML" &&
                  <FormControl variant="outlined" size="small" className="docType">
                    <InputLabel htmlFor="document-type-filter">
                      Document Type
                    </InputLabel>
                    <Select
                      label="Document Type"
                      value={documentTypeFilter}
                      onChange={handleDocumentTypeChange}
                      inputProps={{
                        name: "document-type-filter",
                        id: "document-type-filter",
                      }}
                    >
                      {documentTypes.map((documentType) => (
                        <MenuItem
                          key={documentType.folderID}
                          value={`${documentType.folderName}~${documentType.documentTypeID}`}
                        >
                          {documentType.documentType.replace(/[()?]/g, '')}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>}
                <IconButton
                  aria-label="more"
                  aria-controls="folder-menu"
                  aria-haspopup="true"
                  onClick={handleContextMenuClick}
                  sx={{ marginTop: "10px" }}
                  disabled={hideMenu}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="folder-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleContextMenuClose}
                >
                  {contextMenuItems.map((item, index) => (
                    <MenuItem key={index} onClick={() => handleMenuItemClick(item)}>
                      <div style={{ marginRight: '10px' }}>{item.icon}</div>
                      <span className="icon-text">{item.label}</span>
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            }
          </Grid>
          <div>
            <TreeView
              aria-label="Virtual Data Rooms"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              sx={{
                height: 300,
                flexGrow: 1,
                maxWidth: "100%",
                overflowY: "auto",
                marginTop: "30px",
              }}
              //onNodeSelect={rowSelected}
              expanded={expandedNodes}
              onNodeToggle={handleNodeExpand}
            >
              {filteredData?.map((node) => renderTree(node, selectedVDRID, showToggle, isCompliance, handleTreeNodeClick, checkFeaturePermissions, RolesAndPermissions, FeatureAccess, rowSelected))}
            </TreeView>
          </div>

          {/* Dialog for menu items actions */}
          {/* Add Folder popup */}
          <Dialog
            open={isAddFolderDialogOpen}
            onClose={() => {
              setIsAddFolderDialogOpen(false)
              setFolderType('');
              setNewFolderName('')
            }}
            fullWidth
          >
            <DialogTitle sx={justifyContentAllignItem}>
              <Text label="Add Folder" />
              <IconButton edge="end" color="inherit" onClick={() => {
                setIsAddFolderDialogOpen(false)
                setFolderType('');
                setNewFolderName('')
              }}
                aria-label="close">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div className="fundRow">
                <div className="fundDetail">
                  <TextInput label={"Folder Name"}
                    defaultValue={newFolderName}
                    name={'foldername'}
                    required={true}
                    errormessage={AddFoldererror}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </div>
                <div className="fundDetail">
                  <TextInput
                    type={"select"}
                    label={"Folder Type"}
                    defaultValue={folderType}
                    name={'foldertype'}
                    onChange={(name, value) => handleChange(name, value)}
                    options={
                      secureLocalStorage.getItem('userrole') === AppDashboards.PC_DASHBOARD
                        ? [{ label: 'Public', value: 'Public' }]
                        : [
                          { label: 'Public', value: 'Public' },
                          { label: 'Private', value: 'Private' }
                        ]
                    } />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <ActionButton icon={<CancelIcon />} label={'Cancel'} onClick={() => {
                setIsAddFolderDialogOpen(false);
                setFolderType('');
                setNewFolderName('')
              }} color="primary" variant={"outlined"}>
              </ActionButton>
              <ActionButton
                icon={<AddIcon />}
                label={'Add'}
                onClick={() => { UpdateVDR(folderType === "Private" ? "CreatePrivateFolder" : "CreatePublicFolder") }}
                color="primary">
              </ActionButton>
            </DialogActions>
          </Dialog>

          {/* Upload File popup */}
          <Dialog
            open={isUploadDialogOpen}
            onClose={() => setIsUploadDialogOpen(false)}
          >
            <DialogTitle sx={justifyContentAllignItem}>
              <Text label="Upload File" />
              <IconButton edge="end" color="inherit" onClick={() => setIsUploadDialogOpen(false)} aria-label="close">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div className="fileUploadBtn previewWrapper">
                <input
                  type="file"
                  accept=".pdf, .doc, .docx"
                  onChange={handleFileSelect}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <ActionButton icon={<CancelIcon />} label={'Cancel'} onClick={() => {
                setIsUploadDialogOpen(false);
                setSelectedFile(null)
              }

              } className="btn-primary" variant="outlined">
              </ActionButton>
              <ActionButton
                icon={<SaveIcon />}
                label={'Save'}
                variant="contained"
                className="btn-primary"
                onClick={handleUploadFile}
              >
              </ActionButton>
            </DialogActions>
          </Dialog>

          {/* Rename File popup */}
          {showRenamePopup && (
            <Dialog open={showRenamePopup}
              onClose={() => {
                setShowRenamePopup(false)
                setRenameValue('')
              }}
              fullWidth>
              <DialogTitle sx={justifyContentAllignItem}>
                <Text label={selectedvdritems[0]?.isFolder ? "Rename Folder" : "Rename File"}
                />
                <IconButton edge="end" color="inherit"
                  onClick={() => {
                    setShowRenamePopup(false)
                    setRenameValue('')
                  }}
                  aria-label="close">
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label={selectedvdritems[0]?.isFolder ? "Please provide a new folder name" : "Please provide a new file name"}
                  type="text"
                  fullWidth
                  value={renameValue}
                  InputProps={{
                    endAdornment: selectedvdritems[0]?.isFolder
                      ? null
                      : <InputAdornment position="end">{"." + (selectedvdritems[0]?.title.split('.').pop() || '')}</InputAdornment>
                  }}
                  onChange={(e) => setRenameValue(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <ActionButton label={'Cancel'} onClick={() => {
                  setShowRenamePopup(false)
                  setRenameValue('')
                }} className="btn-primary" variant="outlined" icon={<CancelIcon />}>
                </ActionButton>
                <ActionButton
                  label={'Update'}
                  onClick={() => { UpdateVDR('Rename') }}
                  className="btn-primary"
                  variant="contained"
                  icon={<CheckCircleIcon />}
                >
                </ActionButton>
              </DialogActions>
            </Dialog>
          )}

          {/* Copy File popup */}

          <Dialog
            open={CopyFilePopup}
            onClose={() => setCopyFilePopup(false)}
            fullWidth
          >
            <DialogTitle sx={justifyContentAllignItem}>
              <Text label="Copy to" />
              <IconButton edge="end" color="inherit" onClick={() => setCopyFilePopup(false)} aria-label="close">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <TreeView
                aria-label="Virtual Data Rooms"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{
                  height: 300,
                  flexGrow: 1,
                  maxWidth: "100%",
                  overflowY: "auto",
                  marginTop: "30px",
                }}
                onNodeSelect={onPopupRowSelection}
              >
                {filteredData?.map((node) => renderTree(node, popupSelectedVDRID, showToggle, isCompliance, handleTreeNodeClick, checkFeaturePermissions, RolesAndPermissions, FeatureAccess, onPopupRowSelection, popupVDR))}
              </TreeView>
            </DialogContent>
            <DialogActions>
              <ActionButton label={'Cancel'} onClick={() => setCopyFilePopup(false)} className="btn-primary" variant="outlined" icon={<CancelIcon />} >
              </ActionButton>
              <ActionButton
                label={'Paste'}
                onClick={() => { UpdateVDR('Copy') }}
                className="btn-primary"
                variant="contained"
                icon={<ContentCopyIcon />}
              >
              </ActionButton>
            </DialogActions>
          </Dialog>

          {/* Move File popup */}

          <Dialog
            open={MoveFilePopup}
            onClose={() => setMoveFilePopup(false)}
            fullWidth
          >
            <DialogTitle sx={justifyContentAllignItem}>
              <Text label="Move to" />
              <IconButton edge="end" color="inherit" onClick={() => setMoveFilePopup(false)} aria-label="close">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <TreeView
                aria-label="Virtual Data Rooms"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{
                  height: 300,
                  flexGrow: 1,
                  maxWidth: "100%",
                  overflowY: "auto",
                  marginTop: "30px",
                }}
                onNodeSelect={onPopupRowSelection}
              >
                {filteredData?.map((node) => renderTree(node, popupSelectedVDRID, showToggle, isCompliance, handleTreeNodeClick, checkFeaturePermissions, RolesAndPermissions, FeatureAccess, onPopupRowSelection, popupVDR))}
              </TreeView>
            </DialogContent>
            <DialogActions>
              <ActionButton label={'Cancel'} onClick={() => setMoveFilePopup(false)} className="btn-primary" variant="outlined" icon={<CancelIcon />} >
              </ActionButton>
              <ActionButton
                label={'Move'}
                onClick={() => { UpdateVDR("Move") }}
                className="btn-primary"
                variant="contained"
                icon={<DescriptionIcon />}
              >
              </ActionButton>
            </DialogActions>
          </Dialog>

          {/* Confirmation dialog for deletion */}
          <Dialog
            open={isDeleteConfirmationDialogOpen}
            onClose={() => setIsDeleteConfirmationDialogOpen(false)}
          >
            <DialogTitle sx={justifyContentAllignItem}>
              <Text label="Delete" />
              <IconButton edge="end" color="inherit" onClick={() => setIsDeleteConfirmationDialogOpen(false)} aria-label="close">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              Are you sure you want to delete this {selectedvdritems[0]?.isFolder ? 'folder' : "file"}?
            </DialogContent>
            <DialogActions>
              <ActionButton label={'Cancel'} onClick={() => setIsDeleteConfirmationDialogOpen(false)} className="btn-primary" variant="outlined" icon={<CancelIcon />}>
              </ActionButton>
              <ActionButton
                label={'Delete'}
                onClick={() => { UpdateVDR("Delete") }}
                className="btn-primary"
                variant="contained"
                icon={<CheckCircleIcon />}
              >
              </ActionButton>
            </DialogActions>
          </Dialog>
          {/* dialog for versionhistory */}
          <Dialog
            open={VersionHistoryPopupOpen}
            onClose={() => setVersionHistoryPopupOpen(false)}
          >
            <DialogTitle sx={justifyContentAllignItem}>
              <Text label="Version History" />
              <IconButton edge="end" color="inherit" onClick={() => setVersionHistoryPopupOpen(false)} aria-label="close">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <AIPDataGrid
                columns={[
                  { field: 'filename', headerName: 'Filename', width: 150 },
                  { field: 'fileversion', headerName: 'File Version', width: 150 },
                  { field: 'size', headerName: 'Size', width: 120 },
                  { field: 'date', headerName: 'Date', width: 150 },
                ]}
                rows={formattedVersionHistoryData}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setVersionHistoryPopupOpen(false)}
                color="primary"
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>

          {/* dialog for share */}
          <Dialog
            open={sharePopupOpen}
            onClose={() => setsharePopupOpen(false)}
            fullWidth
          >
            <DialogTitle sx={justifyContentAllignItem}>
              <h6>Share Folder</h6>

              <IconButton edge="end" color="inherit" onClick={() => setsharePopupOpen(false)} aria-label="close">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid item container xs={12}>
                <Grid item xs={11}>
                  <div>
                    <Autocomplete
                      multiple
                      id="emailTo"
                      size="small"
                      sx={{ width: "100%" }}
                      options={investorsList || []}
                      getOptionLabel={(option) => option?.emailAddress}
                      value={selectedInvestors}
                      onChange={onAutoCompleteChange}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option?.userName}
                            size="small"
                            {...getTagProps({ index })}

                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          label="All Users"
                          placeholder="All Users"
                          sx={{
                            color: 'blue', // Set the color for the link
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                        //onClick={handleLinkClick}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div style={{
                    // width: "100%",
                    // height: "80%",
                    backgroundColor: "#1f61c5",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                    verticalAlign: "center",
                    padding: '6px 0px',
                    textAlign: 'center',
                    marginTop: '5px'
                  }}>
                    <SearchIcon sx={{
                      color: 'white',
                      cursor: 'pointer',
                      // height: '80%',
                      // width: '80%',
                      // marginTop: '5px',
                      // marginLeft: '5px',
                    }} onClick={handleOpenInvestorsList} fontSize='large' />
                  </div>
                </Grid>
              </Grid>
              <div className="fundRow">
                <div className="fundDetail">
                  <TextInput
                    label={"Access Level"}
                    type={"select"}
                    name={'accesslevel'}
                    defaultValue={AccessLevel}
                    options={[
                      { label: 'View Only', value: 'View Only' }
                      , { label: 'Add File', value: 'Add File' }
                      , { label: 'Delete File', value: 'Delete File' }
                    ]}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="mt2 child-margin-15">
                <div className="heading color-gray">Shared With users</div>
                <div className="child-margin-15  no-border-padding-5">
                  {sharedWithUsers ? sharedWithUsers.filter(u => u.accessLevel !== "Owner")?.map((item, index) => (
                    <div key={index} className="space-between">
                      <div className="child-row-margin-10">
                        <div className="margin-top-3">
                          <AccountCircleIcon style={{ width: '44px', height: '44px', color: 'darkgray' }} />
                        </div>
                        <div className="child-margin-5">
                          <b>{item.userFullName}</b>
                          <div>{item.userEmail}</div>
                        </div>
                      </div>
                      <div className="margin-top-5">
                        <FormControl className="width-150" style={{ marginLeft: '10px' }}>
                          <InputLabel id={`access-level-label-${index}`}>Access Level</InputLabel>
                          <Select
                            labelId={`access-level-label-${index}`}
                            id={`access-level-select-${index}`}
                            label="Access Level"
                            defaultValue={item.accessLevel}
                            sx={{
                              '& .MuiSelect-select': {
                                paddingRight: '30px', // Add space for the dropdown icon
                                '&:focus': {
                                  backgroundColor: 'transparent', // Remove background color on focus
                                },
                              },
                              '& .MuiSelect-icon': {
                                right: '10px', // Adjust icon position
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none', // Remove the outlined border
                              },
                            }}
                          >
                            <MenuItem value="Remove Access">Remove Access</MenuItem>
                            <MenuItem value="View Only">View Only</MenuItem>
                            <MenuItem value="Add File">Add File</MenuItem>
                            <MenuItem value="Delete File">Delete File</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  )) : "Not shared to anyone yet"}
                </div>
                <div className="border-padding-5 space-between background-color-lightgray">
                  <div className="child-row-margin-10">
                    <div className="margin-top-3">
                      <AccountCircleIcon style={{ width: '44px', height: '44px', color: 'darkgray' }} />
                    </div>
                    <div className="child-margin-5">
                      <b>
                        {investorsList?.find(
                          (user) => user.userId === selectedvdritems[0]?.uploadedBy
                        )?.userName ?? ""}
                      </b>
                      <div>
                        {investorsList?.find(
                          (user) => user.userId === selectedvdritems[0]?.uploadedBy
                        )?.userEmailAddress ?? ""}
                      </div>
                    </div>
                  </div>
                  <div className="vdr-folder-owner-text">
                    Owner
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <ActionButton label={'Cancel'} icon={<CancelIcon />} onClick={() => setsharePopupOpen(false)} color="primary" variant={"outlined"}>
              </ActionButton>
              <ActionButton
                label={'Share'}
                icon={<ShareIcon />}
                onClick={() => { UpdateVDR("ShareFolder") }}
                //onClick={handleConfirmDelete}
                color="primary">
              </ActionButton>
            </DialogActions>
          </Dialog>

          {/* dialog for shareable link */}
          <Dialog
            open={shareablelinkPopupOpen}
            onClose={() => setshareablelinkPopupOpen(false)}
            fullWidth
          >
            <DialogTitle sx={justifyContentAllignItem}>
              <Text label="Sharelink Documents" />
              <IconButton edge="end" color="inherit" onClick={() => setshareablelinkPopupOpen(false)} aria-label="close">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <TextField disabled value={shareableLinkValue} style={{ height: '50px', width: '400px', marginRight: '10px' }} />
              <ActionButton label={"Copy Link"} icon={<LinkIcon />} styleProps={{ height: "55px" }} onClick={() => copyShareableLink(shareableLinkValue)} />
            </DialogContent>
          </Dialog>

          {/* dialog for warning to replace copy/move items */}
          <Dialog
            open={replaceCopyMoveItemsPopup}
            onClose={() => setreplaceCopyMoveItemsPopup(false)}
            fullWidth
          >
            <DialogTitle sx={justifyContentAllignItem}>
              <Text label="Warning" />
              <IconButton edge="end" color="inherit" onClick={() => setreplaceCopyMoveItemsPopup(false)} aria-label="close">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              File(s) already exists with the same name(s), Do you want to replace?
            </DialogContent>
            <DialogActions>
              <ActionButton
                label={'Yes'}
                onClick={() => { replaceCopyPasteFilesAgreed() }}
                color="primary">
              </ActionButton>
              <ActionButton label={'No'} onClick={() => setreplaceCopyMoveItemsPopup(false)} color="primary" variant={"outlined"}>
              </ActionButton>
            </DialogActions>
          </Dialog>

          {/* dialog for warning to replace upload items */}
          <Dialog
            open={replaceUploadItemsPopup}
            onClose={() => setreplaceUploadItemsPopup(false)}
            fullWidth
          >
            <DialogTitle sx={justifyContentAllignItem}>
              <Text label="Warning" />
              <IconButton edge="end" color="inherit" onClick={() => setreplaceUploadItemsPopup(false)} aria-label="close">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              File(s) already exists with the same name(s), Do you want to replace?
            </DialogContent>
            <DialogActions>
              <ActionButton
                label={'Yes'}
                onClick={() => { replaceUploadFilesAgreed(selectedFile) }}
                color="primary">
              </ActionButton>
              <ActionButton label={'No'} onClick={() => setreplaceUploadItemsPopup(false)} color="primary" variant={"outlined"}>
              </ActionButton>
            </DialogActions>
          </Dialog>
          <ToastContainer />
        </div> : <div>loading...</div>

      }
      {
        investorsPopup && <InvestorsListPopup
          open={investorsPopup}
          onClose={handleCloseInvestorsList}
          investorsList={investorsList}
          selectedInvestors={selectedInvestors}
          setSelectedInvestors={setSelectedInvestors} />
      }
    </div>
  )
}

export default VDRoom