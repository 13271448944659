import { useState, useEffect, useRef } from "react";
import { IconButton, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Text from "../../../common/Text/Text";
import ActionButton from "../../../common/ActionButton/ActionButton";
import AppTextInput from "../../../common/TextInput/AppTextField";
import ALTTextField from "../../../common/input-fields/ALTTextField";
import { validationsTypeEnum, InputTypes, validationMessages } from "../../../common/TextInput/appInputenum";
import { AppDashboards } from "../../../../utils/enum";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ToastContainer, toast } from 'react-toastify';
import './styles.css';
import secureLocalStorage from "react-secure-storage";

const CreateManagementTeams = (props) => {
  const { data, fundData, FundDetailerrors, updateData, fundstaffdropdown, setStep, setFundDetailErrors, handleClickSave } = props;


  const initialManagementTeamDetails = {
    "id": 1,
    "managementTeamStaff": '',
    "description": '',
    "title": '',
    "authorizedSignatory": false // Initialize authorizedSignatory as false
  };


  const componentRef = useRef(null);
  const [managementTeamDetails, setManagementTeamDetails] = useState(data.length > 0 ? data : [initialManagementTeamDetails])
  const [noofValidFields, setNoofValidFields] = useState({ validfields: 0 });
  const [checkIfValid, setcheckIfValid] = useState(false);
  const [selectedSignatoryId, setSelectedSignatoryId] = useState(null); // Track selected signatory ID

  const handleChange = (name, value) => {
    const selectedUser = props?.fundstaffdropdown.find((user) => user.value === value);
    const updatedMembers = managementTeamDetails.map(item => {
      props.setFMDisabledFMOptions(prev => ({
        ...prev,
        [item.id]: value
      }))
      if (item.id === parseInt(name)) {
        return {
          ...item,
          managementTeamStaff: value,
          title: selectedUser?.title,
          description: selectedUser?.briefDescription
        };
      }
      return item;
    });

    //setting the dropdown disabled users
    const existingFmIds = fundstaffdropdown
      ?.map((item) => {
        const isDisabled = fundData.FundDetails?.fundStaff?.find((userID) => userID === item.value);
        return isDisabled ? item.value : null;
      })
      .filter((value) => value !== null);


    const extractids = updatedMembers.map((item) => item.managementTeamStaff);

    const extractedids = [...existingFmIds, ...extractids];

    const newFundStaffData = fundstaffdropdown.map((item) =>
      extractedids.includes(item.value)
        ? { ...item, disabled: true }
        : { ...item, disabled: false }
    )


    props.setFundStaffDropdown(newFundStaffData);

    setManagementTeamDetails(updatedMembers);
  }

  //#region validations
  let count = 0;   //to Show Toast Message only once
  const checknoofValidFields = (isValid, name, defaultValue) => {
    count = ++count;
    const TotalFiledsToValidate = managementTeamDetails?.length;
    noofValidFields['validfields'] = isValid ? noofValidFields['validfields'] + 1 : noofValidFields['validfields'];
    if (noofValidFields['validfields'] === TotalFiledsToValidate) {
      noofValidFields['validfields'] = 0;
      setFundDetailErrors(false);
      const isSignatorySelected = managementTeamDetails.some(member => member.authorizedSignatory);
      // debugger
      if (props.isFirmAsGP === true) {
        if (!isSignatorySelected) {
          toast.warning("Please select at least one Authorized Signatory.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: "colored",
            style: { zIndex: 9999 }
          });
          return; // Prevent proceeding to the next step
        }
      }
      if (isSignatorySelected && props.isFirmAsGP) {
        setStep(currStep => currStep + 1);
      }
      if (props.isFirmAsGP === undefined || props.isFirmAsGP === false) { //#TODO: need to pass isfirmasGP prop correctly
        setStep(currStep => currStep + 1);
      }
      count = 0;
    }
    else {
      if (count === TotalFiledsToValidate) {
        toast.warning("Please verify the input fields",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored", style: { zIndex: 9999 } });
        count = 0;
      }
    }
  };
  //#region click events
  const onAddMoreClick = () => {
    const addMember = initialManagementTeamDetails
    addMember.id = managementTeamDetails.length > 0 ? managementTeamDetails[managementTeamDetails.length - 1].id + 1 : 1
    setManagementTeamDetails([...managementTeamDetails, addMember]);
  }

  const onDeleteClick = (data) => {
    const updatedMembers = managementTeamDetails.filter(member => member.id !== data.id);
    setManagementTeamDetails(updatedMembers);

    props.setFMDisabledFMOptions(prev => {
      const updatedOptions = { ...prev };
      delete updatedOptions[data.id];
      return updatedOptions;
    });
  }

  const handleSave = () => {
    setcheckIfValid(true);
    noofValidFields['validfields'] = 0;
  }

  useEffect(() => {
    componentRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (FundDetailerrors) {
      handleSave();
    }
  }, [FundDetailerrors]);

  useEffect(() => {
    updateData(managementTeamDetails);
  }, [managementTeamDetails]);

  const handleAuthorizedSignatoryChange = (memberId) => {
    if (selectedSignatoryId === memberId) {
      // If the same member is clicked again, uncheck it
      setSelectedSignatoryId(null);
      const updatedMembers = managementTeamDetails.map(item => ({
        ...item,
        authorizedSignatory: false
      }));
      setManagementTeamDetails(updatedMembers);
    } else {
      // Check the selected member and uncheck the previously selected one
      setSelectedSignatoryId(memberId);
      const updatedMembers = managementTeamDetails.map(item => ({
        ...item,
        authorizedSignatory: item.id === memberId
      }));
      setManagementTeamDetails(updatedMembers);
    }
  };

  return (
    <div ref={componentRef}>
      <div className="child-margin-15">
        <div className="space-between">
          <div><Text label="Management Team" /></div>
          <div>
            <ActionButton
              variant="outlined"
              label="ADD TEAM MEMBER"
              startIconName="ControlPointOutlined"
              onClick={onAddMoreClick} />
          </div>
        </div>
        {
          managementTeamDetails?.map((member, index) => (
            <div key={member.id} >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ flex: 1 }}>
                  <b>Member {index + 1}</b>
                </div>
                {props.isFirmAsGP && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={member.authorizedSignatory}
                          onChange={() => handleAuthorizedSignatoryChange(member.id)}
                        />
                      }
                      label={
                        <>Authorized Signatory<span style={{ color: 'red', marginLeft: '5px' }}>*</span></>
                      }
                    />
                  </div>
                )}
              </div>

              <div className="display-row-items-2" style={{alignItems:'flex-start'}}>
                <div>
                  <AppTextInput
                    type={InputTypes.SELECT}
                    id={member.id}
                    name={member.id}
                    label="Name"
                    defaultValue={member?.managementTeamStaff}
                    options={fundstaffdropdown
                      ?.filter(a => a.userRoleName !== AppDashboards.INV_DASHBOARD)
                      ?.map(option => ({
                        label: option.label,
                        value: option.value,
                        title: option?.title, description: option?.briefDescription,
                        isdisabled: option.disabled
                      }))
                    }
                    onChange={(name, value) => handleChange(name, value)}
                    required={true}
                    validateField={true}
                    setcheckIfValid={setcheckIfValid}
                    validationType={validationsTypeEnum.REQUIRED}
                    validationMessage={validationMessages.REQUIRED}
                    checkIfValid={checkIfValid}
                    checknoofValidFields={checknoofValidFields} />
                </div>
                <div className="child-row-margin-5 align-items-center">
                  <ALTTextField
                    value={member.title}
                    name='title'
                    label="Title"
                    editable={false}
                    className="InputFieldClass-FundNews"
                    onChange={() => { }}
                    disabled={true}
                  />
                  <div>
                    <Tooltip
                      className='deleteicon'
                      onClick={() => onDeleteClick(member)}
                      disabled={index === 0}
                      title={index === 0 ? "Please add another team member to delete this member" : "Remove Member"}
                      arrow>
                      <IconButton>
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div>
                <ALTTextField
                  variant="outlined"
                  multiline={true}
                  rows={4}
                  id={member.id}
                  name={member.id}
                  value={member?.description}
                  disabled={true}
                  placeholder="Brief description their role, responsibility and fund history"
                  onChange={() => {}}
                />
              </div>

            </div>
          ))
        }
      </div>
    </div>
  );
};

export default CreateManagementTeams;
