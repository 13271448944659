import React, { useState } from "react";
import LinkIcon from '@mui/icons-material/Link';
import "./ThankYou.css";
import CircleIcon from "../../chatbot/Icon";
import secureLocalStorage from "react-secure-storage";
import ActionButton from "../../common/ActionButton/ActionButton";
import { toast, ToastContainer } from "react-toastify";
import { ReSendActivationLinkToUser } from "../services/RegistrationProfilingService";

const ThankYou = () => {
  const [loading, setLoading] = useState(false);
  const reSendLink = async () => {
    setLoading(true);
    if (typeof secureLocalStorage.getItem("registeredMailID") !== undefined &&
      secureLocalStorage.getItem("registeredMailID") !== null) {
      const data = await ReSendActivationLinkToUser(secureLocalStorage.getItem("registeredMailID"));
      if (data?.responseCode === 200) {
        toast.success("A new activation link has been sent to your email.",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        setLoading(false);
        secureLocalStorage.setItem("isReSendClick", true);
      }
      else {
        toast.warning("We’re unable to resend the activation email at the moment. Please try again later.",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        setLoading(false);
      }
    }
    else {
      toast.error("We’re unable to resend the activation email at the moment. Please try again later.",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setLoading(false);
    }
  }

  return (
    <div className="child-margin-5 align-items-center">
      <h6 className="message-thankyou margin-top-75">
        Thank You for Signing Up
      </h6>
      <p>
        Please click the activation link sent to your
        <b> {secureLocalStorage.getItem("registeredMailID")}</b> to activate your account.
      </p>
      <div className="wrapper-20">
        {!secureLocalStorage.getItem("isReSendClick") && <ActionButton
          label='Resend Activation Link'
          loading={loading}
          startIconName=''
          icon={<LinkIcon />}
          onClick={reSendLink} />
        }
      </div>
      <div className="google-recaptcha">
        The site is protected by reCAPTCHA and the <br />
        <p>
          Google <a href="">Privacy Policy</a> and <a href="">Terms of service </a> apply.
        </p>
      </div>

      <CircleIcon />
      <ToastContainer />
    </div>
  );
};
export default ThankYou;