import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../AppAuthentication/authConfig";

import AuthenticationPage from "../AppAuthentication/AuthenticationPage";
import secureLocalStorage from "react-secure-storage";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router";

export const msalInstance = new PublicClientApplication(msalConfig);

const SiteHeader = () => {
  secureLocalStorage.clear();
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const investorFundView = queryParams.get('InvestorFundView');
  const fundID = queryParams.get('FundID');
  const investorID = queryParams.get('InvestorID');
  const fundName = queryParams.get('FundName');
  if (investorFundView) {
    secureLocalStorage.setItem("investorFundView", investorFundView);
    secureLocalStorage.setItem("FundId", fundID);
    secureLocalStorage.setItem("FundName", fundName);
  }
  const onLoginClick = () => {
    setShowMenu(!showMenu);
  };
  const onAccountClick = (type) => {
    secureLocalStorage.setItem('userrole', type);
    navigate("/login");
  }
  const [showMenu, setShowMenu] = useState(false);

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="container d-flex align-items-center justify-content-between">
        <a href="/" className="logo d-flex align-items-center me-auto me-lg-0">
          {/* This is for AIPPOC */}
          <img src="../Alt360-Logo-Royal-SM.png" alt="" />
          {/* This is for AIPDEMO */}
          {/* <div className="groupChildAIPDEMO">
            <img className="groupChildImgAIPDEMO" src="/AIPDEMO.png" alt="" />
          </div> */}
          {/* <div className="alternativeInvestmentPlatforContainer divProductLog">
            Alternative Investment Platform</div> */}
        </a>
        <nav id="navbar" className="navbar">
          <ul>
            <li>
              {/* <a href="#">Marketplace</a> */}
              <Link to="/marketplace-demo">Marketplace</Link>
            </li>
            <li>
              {/* <a href="#">How it Works</a> */}
              <Link to="/how-it-works">How it Works</Link>
            </li>
            <li>
              <a href="#">
                <Link to="/private-markets-academy">
                  <span>Private Markets Academy</span></Link>
              </a>

            </li>
            <li>
              <a href="#">
                <Link to="/about-us">
                  <span>About Us &#9660;</span></Link>
              </a>
            </li>
          </ul>
        </nav>

        <div className="action-buttons">
          <AuthenticationPage msalInstance={msalInstance} />
          <Link to="/create-an-account">
            <Button variant="contained" className="hsignup">Sign Up</Button>
          </Link>
        </div>
        <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
        <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
      </div>
    </header>
  );
};

export default SiteHeader;