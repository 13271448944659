import React, { useEffect } from 'react'
import {
  DialogContent,
  DialogActions,
} from '@mui/material';
import TextInput from '../../common/TextInput/TextInput';
import { useState } from 'react';
import { getnonSubscribedInvestors } from '../Services/IRServices';
import { updateExcistingInvestor } from '../Services/IRServices';
import ActionButton from '../../common/ActionButton/ActionButton';
import InvestorDropDown from '../../common/Multi-Select-Dropdown/InvestorDropDown';
import { toast } from 'react-toastify';
import { getInvestorsTypes } from '../Services/IRServices';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import CancelIcon from '@mui/icons-material/Cancel';

const InviteExcistingInvestor = (props) => {


  //#region Variables
  const initialData = {
    fundname: '',
    investortype: '',
    investors: ''
  }

  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState();
  const [selectedFund, setSelectedFund] = useState(null);
  const [investorData, setInvestorData] = useState(initialData);
  const [investorDetails, setinvestorDetails] = useState(null);
  const [investorsDropdown, setInvestorsDropdown] = useState(null);
  const [InvestorTypeID,setInvestorTypeID] = useState(null)
  const [submitbtn, setSubmitbtn] = useState(true);
  const [investortypes, setinvestorTypes] = useState(null)

  //#region Click Events
  const handleCancel = () => {
    setOpen(false);
    props.onClose();
  }

  const handleChange = (field, value) => {
    if (field === "fundname") {
      setSelectedFund(value);
    }
    if(field === 'investortype'){
      setInvestorTypeID(value)
    }
    investorData[field] = value;
    checkErrors();
  }

  //#region Validations
  const checkErrors = () => {
    const fieldsToRemove = [''];
    const trimmedValues = { ...investorData };
    fieldsToRemove.forEach(field => delete trimmedValues[field]);
    const Mandatory = Object.entries(trimmedValues).map(([field, value]) => ({ field, value }));
    const EmptyFields = Mandatory.filter(entry => entry.value === "" || entry.value === null || (Array.isArray(entry.value) && entry.value.length === 0)).map(entry => entry.field);
    if (EmptyFields.length > 0) {
      setSubmitbtn(true);
    } else {
      setSubmitbtn(false);
    }
  };

  //#region API Data Posting/Updating
  const handleSave = async () => {
    const fundName = props.fundsDropdown?.filter((item) => item.value === selectedFund)
    const investors = investorData?.investors?.map(item => ({
      "investorID": item,
      "investorName": investorsDropdown?.find(investor => investor.value === item)?.label,
      "investorEmailAddress": investorDetails?.find(investor => investor.id === item)?.emailAddress,
    }));

    const requestedData = {
      "fundID": selectedFund,
      "fundName": props.fundsDropdown?.find(item => item.value === selectedFund)?.label,
      "investors": investors || [{}]
    };
    setLoading(true);
    // debugger;
    const response = await updateExcistingInvestor(requestedData);
    if (response?.responseCode === 200) {
      toast.success("Invitation to investors has been successfully sent.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      setLoading(false);
      setOpen(false);
      props.onClose();
    }
    else {
      toast.error("Unable to Invite Investors", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      setLoading(false);
      setOpen(false);
      props.onClose();
    }
  }

  //#region Service Calls
  const changeInvestorDrown = async () => {
    const data = await getnonSubscribedInvestors(selectedFund,InvestorTypeID);
    // debugger
    if (data?.responseCode === 200) {
      const InvestorOptions = data?.responseData?.map(fund => ({
        label: `${fund.firstName} ${fund.lastName}`,
        value: fund.id,
      }));
      setInvestorsDropdown(InvestorOptions);
      setinvestorDetails(data.responseData);
    } else {
      // Handle error if needed
    }
  }

  const getInvestorsType = async () => {
    const data = await getInvestorsTypes('InvestorType');
    if (data?.responseCode === 200) {
      setinvestorTypes(data?.responseData);
    } else {
      // Handle error if needed
    }
  }

  useEffect(() => {
    getInvestorsType()
  }, [])

  useEffect(() => {
    changeInvestorDrown();
  }, [InvestorTypeID,selectedFund])

  return (
    <div>
      <DialogContent sx={{ marginTop: '-18px' }}>
        <div>
          <TextInput
            type='select'
            name="fundname"
            defaultValue={""}
            options={props?.fundsDropdown
              ? props?.fundsDropdown?.map(option => ({ label: option.label, value: option.value, disabled: option?.disabled }))
              : []
            }
            label="Select Fund"
            required={true}
            onChange={(name, value) => { handleChange(name, value) }}
          />
        </div>
        <div className="margin-top-15">
          <TextInput
            type={'select'}
            name={'investortype'}
            label={"Investor Type"}
            defaultValue={""}
            options={investortypes ? investortypes?.map(item => ({ value: item.listItemID, label: item.listItemValue })) : []
            }
            required={true}
            onChange={(name, value) => { handleChange(name, value) }}
          />
        </div>
        <div className="margin-top-15">
          <InvestorDropDown
            name="investors"
            label="Investors"
            editable={selectedFund === null ? false : true}
            required={true}
            options={investorsDropdown?.map(fundName => ({ value: fundName.value, label: fundName.label })) || []}
            onChange={(name, value) => { handleChange(name, value) }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div className="space-between">
          <div>
            <ActionButton className="btn-primary"
              component="label"
              variant="outlined"
              onClick={handleCancel}
              icon={<CancelIcon />}
              label="Cancel"
            />
          </div>
          <div className="margin-left-10">
            <ActionButton className="btn-primary"
              icon={<TouchAppIcon />}
              label="Submit"
              loading={loading}
              startIconName=""
              disabled={submitbtn}
              onClick={() => handleSave()}
            />
          </div>
        </div>
      </DialogActions>
    </div>
  )
}

export default InviteExcistingInvestor