import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DataGrid, GridColumnMenu } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import { DataGridPro, GridPagination } from '@mui/x-data-grid-pro';
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material"
import './Stack.css'; // Path to your custom CSS file
import { useState } from 'react';

const FundGridView = (props) => {

  //#region props
  const { columns, rows, pagination, handleRowClick, onRowsSelectionHandler, norowsMessage, isHeight = true } = props;

  //#region variables
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  return (
    <DataGridPro
      rows={rows}
      key={rows?.length}
      columns={columns}
      rowHeight={100}
      disableColumnMenu={false}
      onRowClick={handleRowClick}
      classes={{
        row: 'custom-row',
        cell: 'custom-cell',
      }}
      slots={{
        columnSortedDescendingIcon: ArrowDropDown,
        columnSortedAscendingIcon: ArrowDropUp,
        columnMenu: CustomColumnMenu,
        pagination: GridPagination,
      }}
      localeText={{ noRowsLabel: norowsMessage || "no rows" }}
      getRowClassName={(params) => {
        return (params.row.fundTerminated || params.row.isFundMatured) ? "gray-out" : "";
      }}
      sx={{
        ".gray-out": {
          color: "#ff0000",
        },
        "& .MuiDataGrid-row:hover": {
          cursor: "pointer" // Clickable row with hand symbol
        },
      }}
      pagination={pagination}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      paginationMode="client"
      pageSizeOptions={[10, 25, 50]}
      slotProps={{
        pagination: {
          labelDisplayedRows: () => {
            const totalPageCount = Math.ceil(rows.length / paginationModel.pageSize);
            return `Current Page: ${paginationModel.page + 1} of ${totalPageCount}`;
          },
        },
      }}
    />
  )
}

const CustomColumnMenu = (props) => {
  return (
    <GridColumnMenu
      sx={{ marginTop: '30px' }}
      {...props}
      slots={{
        columnMenuColumnsItem: null,
        columnMenuSortItem: null,
      }}
    />
  );
};

export default FundGridView