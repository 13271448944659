import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { border } from '@mui/system';
import { ConverttoIST } from '../Functions/TimeConvestion';

export default function Accordian(props) {

  const [expanded, setExpanded] = React.useState();

  //console.log('props', props?.body.props);

  const handleChange = (isExpanded) => {
    setExpanded(isExpanded);

    if (props?.onChange) {
      props?.onChange(props?.body?.props)
    }; //
  };

  const accordianEndView = (timestamp) => {
    return (
        <div className='space-between'>
            <div className='mr4 reciepients-count'>
             {ConverttoIST(timestamp)}
            </div>
            <div>
            </div>
        </div>
    )
};

  return (
    <div>
      <Accordion style={{ border: '1px lightgray solid' }}
        expanded={expanded}
        defaultExpanded={props?.defaultExpanded}
        onChange={(event, isExpanded) => handleChange(isExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {props?.title}
          </Typography>
          <Typography sx={{ marginLeft: 'auto' }}>
            {props?.showtimestamp && 
            accordianEndView(props?.timestamp)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {props?.body}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}