import { useState, useEffect } from "react";
import FundNews from "../../common/FundNews/FundNews";
import { getAllFunds, getSuggestedArticlesByUserID } from "../Services/FundNewsService";
import { Grid } from '@mui/material';
import secureLocalStorage from "react-secure-storage";
import * as React from 'react';
import Box from '@mui/material/Box';
import FundPerformance from "../../common/FundPerformance/FundPerformance";
import { PerformancePages } from "../../common/FundPerformance/apiDataTemplate";
import MyFundsGrid from "./MyFundsGrid";
import { getKeyValuePairs } from "../../portfolio-companies/services/services";
import NewSubscribersGrid from "./NewSubscribersGrid";

const FundManagerDashboard = () => {

  //#region variables
  const userID = secureLocalStorage.getItem("userId")
  const [fundNewsData, setFundNewsData] = useState();
  const [fundTypes,setFundTypes] = useState([]);
  const [allFundsData, setAllFundsData] = useState(null); 

  //#region api get call
  const getFundTypes = async() => {
    const data = await getKeyValuePairs("FundType");
    if(data.responseCode === 200){
        const dropdownValues = data.responseData?.map(option => ({ label: option.listItemValue, value: option.listItemID })) || []
        setFundTypes(dropdownValues)
    }
    else{
        setFundTypes([])
    }
  }

  const getFundNews = async () => {
    const data = await getSuggestedArticlesByUserID(userID);
    if (data.responseCode === 200) {
      setFundNewsData(data.responseData);
    }
    else {
      setFundNewsData([]);
    }
  }

  const getFundDetails = async () => {
    const response = await getAllFunds();
    if (response?.responseCode == 200) {
        setAllFundsData(response?.responseData)
    }
    else {
      setAllFundsData([]);
    }
  }
    
  //#region useEffect
  useEffect(() => {
    getFundNews();
    getFundTypes();
    getFundDetails();
  }, []);

  //#region return
  return (
    <Box sx={{ flexGrow: 1 }} className='wrapper mt2-page'>
        <Grid xs={12} className=" font-size-xsmall font-size-color welcomeuser">
          Welcome back, {secureLocalStorage.getItem("fullName")}
        </Grid>
        <Grid xs={12} className="heading-dashboard font-size-colorblack">
          Fund Manager Dashboard
        </Grid>
        <Grid xs={12} className='margin-top-15 margin-bottom-20'>
          <div><MyFundsGrid fundTypes={fundTypes} /></div>
        </Grid>
        <Grid container spacing={2} className='margin-bottom-20'>
          <Grid item xs={12} md={8}>
              <NewSubscribersGrid fundTypes={fundTypes} />
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="white-card">
              <FundNews FundNewsData={fundNewsData} role={"FundManager"} FundNewsByUserID={true} />
            </div>
          </Grid>
        </Grid >
        <Grid xs={12} className='margin-top-15 white-card'>
            <FundPerformance
                     type={PerformancePages.FUND_FILTERS} 
                     allFundsDetails={allFundsData} />
        </Grid>
    </Box>
  );
};
export default FundManagerDashboard;
