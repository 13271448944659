import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';
import { checkFeaturePermissions } from '../../../utils/common';
import { FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import { ToastContainer, toast } from 'react-toastify';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { postRoleFeatures, resetingRoleFeautures } from '../Services/TabsData';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ResetConfirmation from '../popups/ResetConfirmation';

/**
 * RoleFeatureAccordion component
 * 
 * This component displays and manages role features for a specific role.
 * It allows the user to edit, save, and reset role feature permissions.
 */
const RoleFeatureAccordion = (props) => {

  //#region props
  const { gridRowsData, roleID, roleName, primaryroleID, getAllRoleFeatures, expanded, onAccordianClick, isEditEnabled, setExpanded, primaryroleName } = props;

  //#region varibales
  const [isEditing, setIsEditing] = useState(false);
  const [modifiedRows, setModifiedRows] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [resetPopup, setResetPopup] = useState(false);

  //#region change events
  const handleCheckboxChange = (rowId, field, newValue) => {

    const filteridlength = gridRowsData.indexOf(gridRowsData.find((item) => item.applicationFeatureRoleAccessId === rowId));

    const ItemID = filteridlength;

    gridRowsData[ItemID][field] = newValue;

    const rowIndex = modifiedRows.findIndex((row) => row.applicationFeatureRoleAccessId === rowId);

    if (rowIndex === -1) {
      // If the row is not in the list, add it
      const originalRow = originalData.find((row) => row.applicationFeatureRoleAccessId === rowId);
      if (originalRow) {
        let modifiedRow = { ...originalRow, [field]: newValue };

        if (newValue && field === "hasWriteAccess") {
          modifiedRow = {
            ...modifiedRow,
            hasReadAccess: gridRowsData[ItemID]["hasReadEnable"] === true ? newValue : false,
            hasUpdateAccess: newValue,
            // Add more fields as needed
          };
          gridRowsData[ItemID]["hasReadAccess"] = gridRowsData[ItemID]["hasReadEnable"] === true ? newValue : false;
          gridRowsData[ItemID]["hasUpdateAccess"] = gridRowsData[ItemID]["hasUpdateEnable"] === true ? newValue : false;
        }

        if (newValue && field === "hasUpdateAccess") {
          modifiedRow = {
            ...modifiedRow,
            hasReadAccess: gridRowsData[ItemID]["hasReadEnable"] === true ? newValue : false,
            // Add more fields as needed
          };
          /*   */
          gridRowsData[ItemID]["hasReadAccess"] = gridRowsData[ItemID]["hasReadEnable"] === true ? newValue : false;
        }

        if (newValue && field === "hasDeleteAccess") {
          modifiedRow = {
            ...modifiedRow,
            hasReadAccess: gridRowsData[ItemID]["hasReadEnable"] === true ? newValue : false,
            // Add more fields as needed
          };
          gridRowsData[ItemID]["hasReadAccess"] = gridRowsData[ItemID]["hasReadEnable"] === true ? newValue : false;
        }

        if (newValue === false && field === "hasReadAccess") {

          modifiedRow = {
            ...modifiedRow,
            hasReadAccess: false,
            hasWriteAccess: false,
            hasUpdateAccess: false,
            hasDeleteAccess: false,
            // Add more fields as needed
          };

          // Add more fields as needed
          gridRowsData[ItemID]["hasWriteAccess"] = false;
          gridRowsData[ItemID]["hasReadAccess"] = false;
          gridRowsData[ItemID]["hasUpdateAccess"] = false;
          gridRowsData[ItemID]["hasDeleteAccess"] = false;

        }

        setModifiedRows((prevModifiedRows) => [...prevModifiedRows, modifiedRow]);

      }
    } else {
      setModifiedRows((prevModifiedRows) => {
        const updatedModifiedRows = [...prevModifiedRows];
        const updatedRow = {
          ...updatedModifiedRows[rowIndex],
          [field]: newValue,
        };

        // Conditionally update other fields
        if (newValue && field === "hasWriteAccess") {
          updatedRow.hasUpdateAccess = gridRowsData[ItemID]["hasReadEnable"] === true ? newValue : false;
          updatedRow.hasReadAccess = gridRowsData[ItemID]["hasUpdateEnable"] === true ? newValue : false;
          // Add more fields as needed
          gridRowsData[ItemID]["hasReadAccess"] = gridRowsData[ItemID]["hasReadEnable"] === true ? newValue : false;
          gridRowsData[ItemID]["hasUpdateAccess"] = gridRowsData[ItemID]["hasUpdateEnable"] === true ? newValue : false;
        }

        if (newValue && field === "hasUpdateAccess") {
          updatedRow.hasReadAccess = gridRowsData[ItemID]["hasReadEnable"] === true ? newValue : false;
          // Add more fields as needed
          gridRowsData[ItemID]["hasReadAccess"] = gridRowsData[ItemID]["hasReadEnable"] === true ? newValue : false;
        }

        if (newValue && field === "hasDeleteAccess") {
          updatedRow.hasReadAccess = gridRowsData[ItemID]["hasReadEnable"] === true ? newValue : false;
          // Add more fields as needed
          gridRowsData[ItemID]["hasReadAccess"] = gridRowsData[ItemID]["hasReadEnable"] === true ? newValue : false;
        }

        if (newValue === false && field === "hasReadAccess") {

          updatedRow.hasWriteAccess = false;
          updatedRow.hasReadAccess = false;
          updatedRow.hasUpdateAccess = false;
          updatedRow.hasDeleteAccess = false;
          // Add more fields as needed
          gridRowsData[ItemID]["hasWriteAccess"] = false;
          gridRowsData[ItemID]["hasReadAccess"] = false;
          gridRowsData[ItemID]["hasUpdateAccess"] = false;
          gridRowsData[ItemID]["hasDeleteAccess"] = false;

        }
        updatedModifiedRows[rowIndex] = updatedRow;
        return updatedModifiedRows;
      });
    }
  };

  //#region click events
  const handleEditClick = () => {
    setIsEditing(true);
    setModifiedRows([]); // Clear the list of modified rows when editing starts
  };

  const handleCancelClick = () => {
    getAllRoleFeatures();
    setExpanded({});
    setIsEditing(false);
    setModifiedRows([]);
  };

  const handleSaveClick = () => {
    updateRoleFeautrures();
  };

  const openResetPopup = () => {
    setResetPopup(true);
  };

  const closeResetPopup = () => {
    setResetPopup(false);
  };


  //#region api post calls
  const updateRoleFeautrures = async () => {
    setLoading(true);
    if (modifiedRows.length > 0) {
      const data = await postRoleFeatures(modifiedRows);
      if (data.responseCode === 200) {
        setOriginalData(data.responseData);
        toast.success("Permissions updated successfully",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        setLoading(false);
        handleCancelClick();
      }
      else {
        toast.error("Unable to update features. please try again",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        /* handleCancelClick(); */
        setIsEditing(false);
        setLoading(false);
      }
    }
    else {
      setIsEditing(false);
      setLoading(false);
    }
  }

  //#region get calls
  const resetRoleFeatures = async () => {
    closeResetPopup();
    setResetLoading(true);
    const data = await resetingRoleFeautures(roleID, primaryroleID);
    if (data.responseCode === 200) {
      toast.success("Role Features have been reset to their initial configuration",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setResetLoading(false);
      handleCancelClick();
    }
    else {
      toast.error("Unable to reset role features. please try again",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setResetLoading(false);
    }
  }

  //#region useeffect
  useEffect(() => {
    setOriginalData(gridRowsData);
  }, [gridRowsData]);

  //#region grid columns
  const columns = [
    { field: 'applicationFeatureName', headerName: 'Feature Name', flex: 1 },
    {
      field: 'hasWriteAccess',
      headerName: 'Create',
      flex: 1,
      renderCell: (params) => {
        return (
          params.row.hasCreateEnable && (<Checkbox
            checked={params.value}
            disabled={!isEditing} // Use disabled instead of readOnly
            onChange={(event) => handleCheckboxChange(params.row.applicationFeatureRoleAccessId, 'hasWriteAccess', event.target.checked)}
          />)
        );
      },
    },
    {
      field: 'hasReadAccess',
      headerName: 'Read',
      flex: 1,
      renderCell: (params) => {
        return (
          params.row.hasReadEnable && (<Checkbox
            checked={params.value}
            disabled={!isEditing} // Use disabled instead of readOnly
            onChange={(event) => handleCheckboxChange(params.row.applicationFeatureRoleAccessId, 'hasReadAccess', event.target.checked)}
          />)
        );
      },
    },
    {
      field: 'hasUpdateAccess',
      headerName: 'Update',
      flex: 1,
      renderCell: (params) => {
        return (
          params.row.hasUpdateEnable && (<Checkbox
            checked={params.value}
            disabled={!isEditing} // Use disabled instead of readOnly
            onChange={(event) => handleCheckboxChange(params.row.applicationFeatureRoleAccessId, 'hasUpdateAccess', event.target.checked)}
          />)
        );
      },
    },
    {
      field: 'hasDeleteAccess',
      headerName: 'Delete',
      flex: 1,
      renderCell: (params) => {
        return (
          params.row.hasDeleteEnable && (<Checkbox
            checked={params.value}
            disabled={!isEditing} // Use disabled instead of readOnly
            onChange={(event) => handleCheckboxChange(params.row.applicationFeatureRoleAccessId, 'hasDeleteAccess', event.target.checked)}
          />)
        );
      },
    },
  ];

  //#region grid rows
  return (
    <div className='clsAccordionContainer' >
      <Accordion key={roleID}
        style={{ border: '1px lightgray solid' }}
        expanded={expanded || false}
        onChange={onAccordianClick(roleID)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${roleID}-content`}
          id={`panel-${roleID}-header`}>
          <Typography>{roleName}</Typography>
          <br />
        </AccordionSummary>
        <AccordionDetails>
          {
            checkFeaturePermissions(RolesAndPermissions.SITE_ADMINISTRATION, FeatureAccess.UPDATE) && isEditEnabled &&
            <div className='space-between'>
              <div>Parent Role : {primaryroleName}</div>
              <div className='child-row-margin-5'>
                {
                  isEditing ?
                    <>
                      <div>
                        <LoadingButton
                          variant='outlined'
                          size="large"
                          onClick={handleCancelClick}
                          startIcon={<CancelIcon />}
                          loadingPosition="start">
                          <p className={`actionButtonLabel`}>Cancel</p>
                        </LoadingButton>
                      </div>
                      <div>
                        <LoadingButton
                          variant='contained'
                          size="large"
                          loading={loading}
                          startIcon={<SaveIcon />}
                          onClick={handleSaveClick}
                          loadingPosition="start">
                          <p className={`actionButtonLabel`}>SAVE</p>
                        </LoadingButton>
                      </div>
                    </> :
                    <>
                      <div>
                        <Tooltip title={'Reset means returning features to its original/default features settings'}>
                          <LoadingButton
                            variant='outlined'
                            size="large"
                            loading={resetLoading}
                            startIcon={<RotateLeftIcon />}
                            onClick={() => openResetPopup()}
                            className='margin-right-5'
                            loadingPosition="start">
                            <p className={`actionButtonLabel`}>RESET</p>
                          </LoadingButton>
                        </Tooltip>
                      </div>
                      <div>
                        <LoadingButton
                          variant='contained'
                          size="large"
                          startIcon={<EditIcon />}
                          onClick={handleEditClick}
                          loadingPosition="start">
                          <p className={`actionButtonLabel`}>EDIT</p>
                        </LoadingButton>
                      </div></>
                }
              </div>
            </div>
          }
          {
            expanded && gridRowsData.length > 0 ?
              <DataGridPro
                columns={columns}
                rows={gridRowsData ?
                  gridRowsData?.map((item, index) => {
                    return {
                      id: index, ...item
                    }
                  }) : []}
                hideFooter={true} /> : "Loading..."
          }
        </AccordionDetails>
      </Accordion>
      {
        resetPopup &&
        <ResetConfirmation
          open={resetPopup}
          onClose={closeResetPopup}
          onAccept={resetRoleFeatures}
          content={'Are you sure you want to reset?'} />
      }
      <br />
    </div>

  );
};

export default RoleFeatureAccordion;