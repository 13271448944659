import * as yup from 'yup';

// US Phone Number Format: (###) ###-####
export const phoneSchema = yup.string()
  .matches(/^\(\d{3}\)\s?\d{3}-\d{4}$/, 'Phone number must be in the format (###) ###-####')

// Alphabetic Input: Only letters and spaces allowed
export const alphabeticSchema = yup.string()
  .matches(/^[A-Za-z\s]*$/, 'Only alphabetic characters and spaces are allowed')

// Numeric Input: Only numeric values allowed
export const numericSchema = yup.string()
  .matches(/^\d*$/, 'Only numeric values are allowed')

// US Currency Format: Numeric with optional thousand separators
export const usCurrencySchema = yup.string()
  .matches(/^\d{1,3}(,\d{3})*(\.\d{2})?$/, 'Please enter a valid currency format')

// ZIP Code Format: 5 digits or 5+4 digits (##### or #####-####)
export const zipCodeSchema = yup.string()
  .matches(/^\d{5}(-\d{4})?$/, 'Please enter a valid ZIP code')

// Percentage Input: Allows values between 0 and 100 with up to 2 decimals
export const percentageSchema = yup.number()
  .min(0, 'Percentage cannot be negative')
  .max(100, 'Percentage cannot exceed 100')

// Alphanumeric Input: Letters, numbers, and spaces allowed
export const alphanumericSchema = yup.string()
  .matches(/^[A-Za-z0-9\s]*$/, 'Only alphanumeric characters and spaces are allowed')

// All Characters Input: Any input allowed
export const allCharactersSchema = yup.string()

// SSN Format: AAA-AA-0000
export const ssnSchema = yup.string()
  .matches(/^[A-Za-z0-9]{3}-[A-Za-z0-9]{2}-\d{4}$/, 'SSN must be in the format AAA-AA-0000')
// Alpha-Hyphen-Period Format: Only letters, spaces, hyphens, and periods
export const alphaHyphenPeriodSchema = yup.string()
  .matches(/^[A-Za-z\s.-]*$/, 'Only letters, hyphens, periods, and spaces are allowed')

export const emailAddressSchema = yup.string()
  .email('Please enter a valid email address')


// Export all schemas as a single object
const CustomValidationSchema = {
    phoneSchema,
    alphabeticSchema,
    numericSchema,
    usCurrencySchema,
    zipCodeSchema,
    percentageSchema,
    alphanumericSchema,
    allCharactersSchema,
    ssnSchema,
    alphaHyphenPeriodSchema,
    emailAddressSchema
  };
  
  export default CustomValidationSchema;
