import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types';
import { Checkbox, FormControl, FormHelperText, InputLabel, ListItemIcon, ListItemText, MenuItem, Select } from '@mui/material';
import { AllOptions, OptionsEnum } from './fieldsEnum';

const MultiSelectField = (props) => {

  //#region props
  const {
    name,
    label,
    value,
    onChange,
    options,
    onBlur,
    required,
    disabled,
    error,
    errorMessage,
    helperText,
    allOption,
    readOnly
  } = props;

  //#region variabels
  const isAllSelected = options.length > 0 && value.length === options.length;

  //#region change events
  const handleChange = (event) => {
    const { value: selectedValues } = event.target;
    if (selectedValues.includes('all')) {
      const finalValues = isAllSelected ? [] : options.map(option => option.value);
      onChange(name, finalValues);
    } else {
      onChange(name, selectedValues);
    }
  };

  useEffect(()=>{
       if(isAllSelected){
        const finalValues = options?.map(option => option.value);
        onChange(name, finalValues);
       }
  },[isAllSelected])

  //#region return
  return (
    <FormControl variant="standard" sx={{ maxWidth: '100%' }} fullWidth>
      <InputLabel id={name} shrink focused={!disabled} error={error} required={required}>
        {label}
      </InputLabel>
      <Select
        id={name}
        labelId={name}
        name={name}
        multiple
        value={value}
        onChange={handleChange}
        sx={{lineHeight:'1.5em !important'}}
        error={error}
        label={label}
        onBlur={!readOnly && onBlur}
        disabled={disabled}
        renderValue={(selected) =>
          options.filter(option => selected.includes(option.value)).map(option => option.label).join(', ')
        }
        inputProps={{ readOnly: readOnly }}>
        {allOption && (
          <MenuItem key="-1" value="all">
            <ListItemIcon>
              <Checkbox checked={isAllSelected} indeterminate={!isAllSelected && value?.length > 0} />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={value?.includes(option.value)} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error}>
        {error ? errorMessage : (disabled && helperText ? helperText : '')}
      </FormHelperText>
    </FormControl>
  );
};

MultiSelectField.propTypes = {
    type: propTypes.string,
    onChange: propTypes.func.isRequired,
    variant: propTypes.oneOf(['standard', 'filled', 'outlined']),
    required: propTypes.bool,
    value: propTypes.string,
    placeholder: propTypes.string,
    label: propTypes.string,
    disabled: propTypes.bool,
    options: propTypes.array,
    name: propTypes.string,
    size: propTypes.string,
    errorMessage: propTypes.string,
    helperText: propTypes.string,
    allOption: propTypes.bool,
    readOnly: propTypes.bool
};

MultiSelectField.defaultProps = {
    type: 'select',
    variant: 'standard',
    size: 'small',
    required: false,
    value: '',
    placeholder: '',
    label: '',
    options: [],
    disabled: false,
    name: '',
    errorMessage: '',
    helperText: '',
    allOption: false,
    readOnly: false
};

export default MultiSelectField;