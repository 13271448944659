import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCompareFunds } from '../../../../state/slices/MarketPlaceSlice';
import FundBoxView from './FundBoxView';
import { getHeaderColorConfig, getrootBackgroundColorConfig } from '../../../../state/slices/colorConfigSlice';
import { getAllFunds } from '../../../../state/slices/MarketPlaceSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { FundAddTypes, MarketPlacePagesEnum } from '../../mpenum';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { getFundSubscriptions } from '../../services/mpservices';
import secureLocalStorage from 'react-secure-storage';
import CircleIcon from '../../../chatbot/Icon';
import { HeaderTemplates, PageHeader } from '../../../common/PageHeader/PageHeader';
import { ToastContainer, toast } from 'react-toastify';
import { Tooltip } from '@mui/material';
import FundCardView from '../FundCardView';

const CompareFunds = (props) => {

 //#region props
 const { handlePageChange } = props;

 //#region redux variables
 let comparefunds = useSelector(getCompareFunds);
 const location = useLocation();
 const { state } = location;

  //#region click events
  const handleAddClick = () =>{
    if(comparefunds?.length >= 3){
     /*  toast.warning("Please deselect a fund to add a new one. You can compare a maximum of 3 funds", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      }); */
      return;
    }
    handlePageChange(MarketPlacePagesEnum.MAIN)
  }

  const handleBackClick = () =>{
    handlePageChange(MarketPlacePagesEnum.MAIN)
  }


  return (
    <div>
      <PageHeader
          title="Compare Funds"
          template={HeaderTemplates.NORMAL_VIEW}
          isNavigate={handleBackClick} />
  
    <div className='wrapper mt2'>
        <div className='mp-wrapper'>
            {
              comparefunds?.length > 0 && comparefunds?.map((item,index)=>(
                  <div key={index}>
                      <FundCardView fundDetails={item} type={FundAddTypes.COMPARE} {...props} />
                  </div>
              ))
            }
            <Tooltip title={comparefunds?.length >= 3 ? 
                 <div className=''>
                  You've reached the limit of 3 funds. To<br/>
                  compare a different fund, remove one from
                  <br /> the current selection 
                 </div>
                 : ""}>
              <div className='add-box cursor-pointer' onClick={handleAddClick}>
                <div className='add-box2 cursor-pointer'>
                    <div className='add-icon-center'>
                        <ControlPointIcon color="action"/>
                    </div>
                </div>
              </div>
            </Tooltip>
            
       </div>
       <CircleIcon/>
    </div>
    <ToastContainer />
    </div>
  )
}

export default CompareFunds