import { useEffect, useState } from 'react';
import { getHeaderColorConfig } from '../../../state/slices/colorConfigSlice';
import { useSelector } from 'react-redux';
import styles from "./styles.css";
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import { checkFeaturePermissions } from '../../../utils/common';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';

export const HeaderTemplates = {
	BASIC: 'basic',
	Standard: 'standard',
	CLASSIC: 'classic',
	USER: 'user',
	CUSTOM: 'custom',
	NORMAL_VIEW: 'normal'
}

export const PageHeader = (props) => {

	//#region props
	const { fundType, template, hideBackButton, primaryTitle, description, onAddClick, onAddClickToolTip } = props;

	const [isExpanded, setExpendState] = useState(false);
	const navigate = useNavigate();
	const [backToolTip, setBackToolTip] = useState('Click to go Back')
	const headerColorConfig = useSelector(getHeaderColorConfig);

	const onBackClick = () => {
		if (props.isNavigate) {
			props.isNavigate();
		}
		else {
			navigate(-1);
		}
	};

	useEffect(() => {
		if (props.isNavigate) {
			setBackToolTip(props.isNavigateToolTip);
		}
	}, []);

	return (
		<div className={`${props.hideBackButton ? '' : ''}`}>
			<div
				style={{
					background: props.customBackgroundImage
						? `url('${props.customBackgroundImage}')`
						: `radial-gradient( 50% 50%at 50% 50%, ${headerColorConfig.gradientSecondaryColor}, ${headerColorConfig.gradientPrimaryColor} ) ${props.template === HeaderTemplates.Standard || props.template === HeaderTemplates.CLASSIC ? ",url('/DefaultBackgroundImage.png')" : ''
						}`,
					backgroundSize: 'cover',
					//   marginTop: '50px',
				}}
				className={
					isExpanded
						? "product-banner"
						: 'product-banner'
				}>{props.template == HeaderTemplates.BASIC ? <div className='bgColor-banner-content'>
					<div
						style={{
							fontFamily: headerColorConfig.fontFamily,
							fontSize: headerColorConfig.fontSize,
							color: headerColorConfig.color
						}}
						className='investmentMarketplace'>
						{props.primaryTitle}
					</div>
					<div
						style={{ color: headerColorConfig.fontColor }}
						className='banner-desp'>
						{props.description}
					</div>
				</div> : <></>
				}
				{
					props.template == HeaderTemplates.Standard ?
						<>
							<div className="productbanner-content">
								<div className="banner-left-content">
									{!props.hideBackButton && (
										<IconButton onClick={onBackClick} title={backToolTip}>
											<ArrowBackIcon className='backIcon' fontSize='large' />
										</IconButton>
									)}
									<div className='investor-brand-logo'>
										<img
											alt=""
											src={props?.headerImage ? props?.headerImage : "/DefaultLogo.png"}
										/>
									</div>
									<div style={{ display: "flex", flexDirection: "column" }}>
										<div style={{ color: headerColorConfig.color }}>{props?.fundName}</div>
										<div style={{ color: headerColorConfig.color }}>{props?.companyName}</div>
										<div style={{ color: "#a3b8c6", fontSize: "16px", marginLeft: '3px' }}>
											{ fundType ? fundType :
											   ( secureLocalStorage.getItem('FundTypeName') ? (secureLocalStorage.getItem('FundTypeName')) : <></>)}
										</div>
									</div>

								</div>
								{
									(props.button ? (
										<Button variant='contained' className='btn-primary'
											style={{ width: props.btnLabel === "Add Company" ? 'auto' : '', height: "38px" }}
											onClick={props.onBtnClick}
											startIcon={props.btnIcon ? props.btnIcon : <AddIcon />}>
											{props.btnLabel}
										</Button>
									) : null)
								}

							</div>
						</> : <></>
				}
				{
					props.template == HeaderTemplates.CLASSIC ?
						<>
							<div className="productbanner-content" >
								<IconButton onClick={onBackClick} title="Click to Back" className='investorbackicon'>
									<ArrowBackIcon className='backIcon' fontSize='large' />
								</IconButton>
								<div className='investor-fund-banner'>
									<div className="classicImageContainer">
										<span className='investorbannerLogo'>
											<img
												alt=""
												src={props.primaryImage ? props.primaryImage : "/DefaultLogo.png"}
											/>
										</span>
										<div style={{ color: headerColorConfig.color }}>{props.primaryTitle}</div>
										{/* <>{props.primaryTitle}</> */}
									</div>
									<div className='classicImageContainer margin-top-10'>
										{props.secondaryImage ? <span className='investorPicbanner'><img alt="" src={props.secondaryImage} /></span> : <></>}
										<div style={{ color: headerColorConfig.color }}>{props.secondaryTitle}</div>
										{/* <>{props.secondaryTitle}</> */}
									</div>
								</div>

								{
									checkFeaturePermissions(RolesAndPermissions.FUND, FeatureAccess.UPDATE, FeatureAccess.READ, FeatureAccess.CREATE) &&
									(props.button ? (
										<Button className={styles.editFundButton} startIcon={props.btnIcon}
											onClick={props.onBtnClick}
										>
											{props.btnLabel}
										</Button>
									) : null)
								}

							</div>
						</> : <></>
				}
				{
					props.template === HeaderTemplates.USER ?
						<>
							<div className="productbanner-content">
								<IconButton onClick={onBackClick} title="Click to Back">
									<ArrowBackIcon className='backIcon' fontSize='large' />
								</IconButton>
								<div className="userImageContainer">
									<img
										alt=""
										src={props.userIcon}
									/>
									<div style={{ color: headerColorConfig.color }}>{secureLocalStorage.getItem("InvestorName")}</div>
									{/* <>{secureLocalStorage.getItem("InvestorName")}</> */}
								</div>
							</div>
						</> : <></>
				}
				{
					props.template === HeaderTemplates.NORMAL_VIEW ?
						<>
							<div className="normalview-conatiner productbanner-content space-between">
								<div className='child-row-margin-5'>
									{
										props.isHideBackbtn || props?.hideBackButton ? "" :
											<IconButton onClick={onBackClick} title="Click to Back">
												<ArrowBackIcon className='backIcon' fontSize='large' />
											</IconButton>
									}
									<div
										style={{
											fontFamily: headerColorConfig.fontFamily,
											fontSize: headerColorConfig.fontSize,
											color: headerColorConfig.color
										}}
										className='title'>
										{props.title}
									</div>
								</div>
								<div>
									{
										props.isShowButton &&
										<Button
											startIcon={props.btnIcon}
											onClick={props.onBtnClick}
											variant='contained'>
											{props.btnLabel}
										</Button>
									}
								</div>
							</div>
						</> : <></>
				}
			</div>
		</div>
	)
}