import React, { useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../AppAuthentication/authConfig";
import secureLocalStorage from 'react-secure-storage';
import Modal from '../Modal/Modal';
import { WhiteLabeling } from '../../WhiteLabeling/WhiteLabeling';
import { useSelector } from 'react-redux';
import { getMainNavColorConfig, getTopNavColorConfig } from '../../../state/slices/colorConfigSlice';
import { HomeOutlined, StorefrontOutlined, NotificationsOutlined, ChecklistOutlined, NewspaperOutlined, TextSnippetOutlined, ManageAccountsOutlined, SettingsOutlined, LogoutOutlined, MenuOpenOutlined, AccountCircleOutlined, KeyboardArrowDownOutlined, MenuOutlined } from '@mui/icons-material';
import { checkFeaturePermissions } from '../../../utils/common';
import { RolesAndPermissions, FeatureAccess, AppDashboards, AppRoles } from '../../../utils/enum';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Tooltip } from '@mui/material';
import { borderLeft } from '@mui/system';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AppMessenger from '../../appMessenger/components/AppMessenger';
import PageviewIcon from '@mui/icons-material/Pageview';
import AzureChatBot from '../../chatbot/AzureChatBot';
import MessengerModel from '../../appMessenger/components/MessengerModel';
import { getUserDetailsbyEmail } from './navServices';
import CancelIcon from '@mui/icons-material/Cancel';
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  overflowX: 'hidden',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer({ children }) {
  const theme = useTheme();
  const [navItems,setnavItems] = useState(null)
  const [open, setOpen] = React.useState(false);
  const [openLabelingModal, setLabelingModal] = React.useState(false);
  const mainNavColorConfig = useSelector(getMainNavColorConfig);
  const [investorProfile, setInvestorProfile] = useState({});
  const [fullName, setFullName] = useState('');
  const [messengerPopup,setMessengerPopup] = useState(false)
  const suggestedReadingsPermission = checkFeaturePermissions(RolesAndPermissions.SUGGESTED_READINGS, FeatureAccess.CREATE);
  const currentUrl = window.location.href;
  const containsMarketplace = currentUrl.includes("marketplace");

  const navigate = useNavigate();

  const handleDrawer = () => {
    setOpen(!open);
  };

  const msalInstance = new PublicClientApplication(msalConfig);

  const [showPopup, setShowPopup] = useState(false);

  const onLogoutClick = () => {
    setShowPopup(true);
  }
  const handleConfirmRemove = () => {
    secureLocalStorage.setItem("fullName", "");
    secureLocalStorage.clear();
    msalInstance.logoutRedirect({ postLogoutRedirectUri: "/?login_page=true" });
    setShowPopup(false);
  };

  const handleCancelRemove = () => {
    // Close the popup without removing the item
    setShowPopup(false);
  };

  const [menuItems, setMenuItems] = useState([]);
  const NavBarItems = [
    {
      id: 1,
      text: "Home",
      icon: <HomeOutlined title='Home' sx={{ color: mainNavColorConfig.iconColor, width: '24px', height: '24px' }} />,
      hovericon: "mainNavColorConfig.primaryColor",
      selectedIcon : <HomeOutlined title='Home' sx={{ color: mainNavColorConfig.activeIconColor, width: '24px', height: '24px' }} />,
      FeatureName: "Home",
      AccessLevel: "Read",
      selected: containsMarketplace ? false : secureLocalStorage.getItem("currentpage") ? (secureLocalStorage.getItem("currentpage") === 1 ? true : false) : true
    },
    {
      id: 2,
      text: "Marketplace",
      icon: <StorefrontOutlined sx={{ color: mainNavColorConfig.iconColor, width: '24px', height: '24px' }} />,
      selectedIcon : <StorefrontOutlined title='Home' sx={{ color: mainNavColorConfig.activeIconColor, width: '24px', height: '24px' }} />,
      // hovericon: "icons/sidebar/marketplace-hover.svg",
      FeatureName: "Marketplace",//seshu for navigation
      AccessLevel: "Read",
      selected: containsMarketplace ? true : secureLocalStorage.getItem("currentpage") === 2 ? true : false
    },
    // {
    //   id: 3,
    //   text: "Notifications",
    //   icon: <NotificationsOutlined sx={{ color: mainNavColorConfig.iconColor, width: '24px', height: '24px' }} />,
    //   selectedIcon : <NotificationsOutlined title='Home' sx={{ color: mainNavColorConfig.activeIconColor, width: '24px', height: '24px' }} />,
    //   // hovericon: "icons/sidebar/notifications-icon-hover.svg",
    //   FeatureName: "Home",
    //   AccessLevel: "Read",
    //   selected: secureLocalStorage.getItem("currentpage") === 3 ? true : false
    // },
    {
      id: 4,
      text: "Calendar",
      icon: <CalendarMonthIcon sx={{ color: mainNavColorConfig.iconColor, width: '24px', height: '24px' }} />,
      selectedIcon: <CalendarMonthIcon sx={{ color: mainNavColorConfig.activeIconColor, width: '24px', height: '24px' }} />,
      // hovericon: "icons/sidebar/task-icon-hover.svg",
      FeatureName: "Home",
      AccessLevel: "Read",
      selected: containsMarketplace ? false : secureLocalStorage.getItem("currentpage") === 4 ? true : false
    },
    {
      id: 5,
      text: "Suggested Readings",
      icon: <NewspaperOutlined sx={{ color: mainNavColorConfig.iconColor, width: '24px', height: '24px' }} />,
      selectedIcon: <NewspaperOutlined sx={{ color: mainNavColorConfig.activeIconColor, width: '24px', height: '24px' }} />,
      // hovericon: "icons/sidebar/news-icon-hover.png",
      FeatureName: "Home",
      AccessLevel: "Read",
      selected: containsMarketplace ? false : secureLocalStorage.getItem("currentpage") === 5 ? true : false
    },
    {
      id: 6,
      text: "Investor Reporting",
      icon: <TextSnippetOutlined sx={{ color: mainNavColorConfig.iconColor, width: '24px', height: '24px' }} />,
      selectedIcon: <TextSnippetOutlined sx={{ color: mainNavColorConfig.activeIconColor, width: '24px', height: '24px' }} />,
      // hovericon: "icons/sidebar/documents-icon-hover.svg",
      FeatureName: "Home",
      AccessLevel: "Read",
      selected: containsMarketplace ? false : secureLocalStorage.getItem("currentpage") === 6 ? true : false
    },
    {
      id: 7,
      text: "KYC & AML",
      icon: <ManageAccountsOutlined sx={{ color: mainNavColorConfig.iconColor, width: '24px', height: '24px' }} />,
      selectedIcon: <ManageAccountsOutlined sx={{ color: mainNavColorConfig.activeIconColor, width: '24px', height: '24px' }} />,
      // hovericon: "icons/sidebar/account-icon-hover.svg",
      FeatureName: "Home",
      AccessLevel: "Read",
      selected: containsMarketplace ? false : secureLocalStorage.getItem("currentpage") === 7 ? true : false
    },
    {
      id: 10,
      text: "Investor Relations",
      icon: <PeopleAltIcon sx={{ color: mainNavColorConfig.iconColor, width: '24px', height: '24px' }} />,
      selectedIcon: <PeopleAltIcon sx={{ color: mainNavColorConfig.activeIconColor, width: '24px', height: '24px' }} />,
      // hovericon: "icons/sidebar/settings-icon-hover.svg",
      FeatureName: "Home",
      AccessLevel: "Read",
      selected: containsMarketplace ? false : secureLocalStorage.getItem("currentpage") === 10 ? true : false
    },
    {
      id: 12,
      text: "Auditing",
      icon: <PageviewIcon sx={{ color: mainNavColorConfig.iconColor, width: '24px', height: '24px' }} />,
      selectedIcon: <PageviewIcon sx={{ color: mainNavColorConfig.activeIconColor, width: '24px', height: '24px' }} />,
      // hovericon: "icons/sidebar/settings-icon-hover.svg",
      FeatureName: "auditing",
      AccessLevel: "Read",
      selected: containsMarketplace ? false : secureLocalStorage.getItem("currentpage") === 12 ? true : false
    },
    {
      id: 11,
      isBottomIcon : true,
      text: "Instant Messenger",
      icon: <AppMessenger styles={{ color: mainNavColorConfig.iconColor, width: '24px', height: '24px' }} 
      // messengerPopup={messengerPopup} setMessengerPopup={setMessengerPopup} 
      />,
      selectedIcon: <AppMessenger styles={{ color: mainNavColorConfig.activeIconColor, width: '24px', height: '24px' }} 
      // messengerPopup={messengerPopup}  setMessengerPopup={setMessengerPopup}
      />,
      // hovericon: "icons/sidebar/settings-icon-hover.svg",
      FeatureName: "Home",
      AccessLevel: "Read",
      selected: containsMarketplace ? false :  secureLocalStorage.getItem("currentpage") === 11 ? true : false
    },
    {
      id: 9,
      isBottomIcon : true,
      text: "Settings",
      icon: <SettingsOutlined sx={{ color: mainNavColorConfig.iconColor, width: '24px', height: '24px' }} />,
      // hovericon: "icons/sidebar/settings-icon-hover.svg",
      selectedIcon: <SettingsOutlined sx={{ color: mainNavColorConfig.activeIconColor, width: '24px', height: '24px' }} />,
      FeatureName: "Home",
      AccessLevel: "Read",
      selected: containsMarketplace ? false : secureLocalStorage.getItem("currentpage") === 9 ? true : false
    },
    {
      id: 8,
      text: "Logout",
      isBottomIcon : true,
      icon: <LogoutOutlined sx={{ color: mainNavColorConfig.iconColor, width: '24px', height: '24px' }} />,
      selectedIcon: <LogoutOutlined sx={{ color: mainNavColorConfig.activeIconColor, width: '24px', height: '24px' }} />,
      // hovericon: "icons/sidebar/logout-icon-hover.svg",
      FeatureName: "Home",
      AccessLevel: "Read",
      selected: containsMarketplace ? false : secureLocalStorage.getItem("currentpage") === 8 ? true : false
    },
    
  ];

  useEffect(()=>{
  setMenuItems(NavBarItems);
  setMenuItems(NavBarItems)
  },[mainNavColorConfig.activeIconColor , mainNavColorConfig.iconColor])

  useEffect(() => {
    let UpdatedItems = NavBarItems;
    if (secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD) {
      UpdatedItems = UpdatedItems.filter(item => ![5,7,10,12].includes(item.id));
    }
    if (secureLocalStorage.getItem("userrole") === AppDashboards.PC_DASHBOARD) {
      UpdatedItems = UpdatedItems.filter(item => ![2,4,5,6,7,10,12,11].includes(item.id));
    }
    if (secureLocalStorage.getItem("userrole") === AppDashboards.CO_DASHBOARD) {
      UpdatedItems = UpdatedItems.filter(item => ![5,10].includes(item.id));
    }
    if (secureLocalStorage.getItem("userrole") === AppDashboards.SUPERADMIN_DASHBOARD) {
      UpdatedItems = UpdatedItems.filter(item => item.id === 1);
    }
    if (secureLocalStorage.getItem("userrole") === AppDashboards.FIRM_DASHBOARD) {
      UpdatedItems = UpdatedItems.filter(item => item.id === 1 || item.id === 8 || item.id === 9);
    }
    if (!checkFeaturePermissions(RolesAndPermissions.MARKET_PLACE, FeatureAccess.READ)) {
      UpdatedItems = UpdatedItems.filter(item => item.id !== 2);
    }
    if (!checkFeaturePermissions(RolesAndPermissions.SUGGESTED_READINGS, FeatureAccess.READ,FeatureAccess.CREATE)) {
      UpdatedItems = UpdatedItems.filter(item => item.id !== 5);
    }
    if (!checkFeaturePermissions(RolesAndPermissions.KYC_AML_Verification, FeatureAccess.READ)) {
      UpdatedItems = UpdatedItems.filter(item => item.id !== 7);
    }
    setMenuItems(UpdatedItems);
  }, []);


  const [buttomMenuItems, setButtomMenuItems] = useState([
    {
      id: 11,
      text: "Instant Messenger",
      icon: <AppMessenger styles={{ color: mainNavColorConfig.iconColor, width: '24px', height: '24px' }} messengerPopup={messengerPopup} setMessengerPopup={setMessengerPopup}/>,
      selectedIcon: <AppMessenger styles={{ color: mainNavColorConfig.activeIconColor, width: '24px', height: '24px' }}  messengerPopup={messengerPopup} setMessengerPopup={setMessengerPopup}/>,
      // hovericon: "icons/sidebar/settings-icon-hover.svg",
      FeatureName: "Home",
      AccessLevel: "Read",
      selected: secureLocalStorage.getItem("currentpage") === 11 ? true : false
    },
    {
      id: 9,
      text: "Settings",
      icon: <SettingsOutlined sx={{ color: mainNavColorConfig.iconColor, width: '24px', height: '24px' }} />,
      // hovericon: "icons/sidebar/settings-icon-hover.svg",
      selectedIcon: <SettingsOutlined sx={{ color: mainNavColorConfig.activeIconColor, width: '24px', height: '24px' }} />,
      FeatureName: "Home",
      AccessLevel: "Read",
      selected: secureLocalStorage.getItem("currentpage") === 9 ? true : false
    },
    {
      id: 8,
      text: "Logout",
      icon: <LogoutOutlined sx={{ color: mainNavColorConfig.iconColor, width: '24px', height: '24px' }} />,
      selectedIcon: <LogoutOutlined sx={{ color: mainNavColorConfig.activeIconColor, width: '24px', height: '24px' }} />,
      // hovericon: "icons/sidebar/logout-icon-hover.svg",
      FeatureName: "Home",
      AccessLevel: "Read",
      selected: secureLocalStorage.getItem("currentpage") === 8 ? true : false
    },
  ]);

    useEffect(() => {
      let UpdatedItems = buttomMenuItems;
      if (secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD) {
        UpdatedItems = UpdatedItems.filter(item => item.id !== 10);
      }
      if (secureLocalStorage.getItem("userrole") === AppDashboards.CO_DASHBOARD) {
        UpdatedItems = UpdatedItems.filter(item => item.id !== 10);
      }
      if (secureLocalStorage.getItem("userrole") === AppDashboards.PC_DASHBOARD) {
        UpdatedItems = UpdatedItems.filter(item => ![11].includes(item.id));
      }
      setButtomMenuItems(UpdatedItems);
    }, [mainNavColorConfig.activeIcon]);


  const emailId = secureLocalStorage.getItem("userEmail");

  const getUserData = async () => {
     const data = await getUserDetailsbyEmail(emailId);
     if(data.responseCode === 200){
      setInvestorProfile(data.responseData);
      const firstName = (data.responseData?.personalInformation?.firstName ?? "").charAt(0).toUpperCase() + (data.responseData?.personalInformation?.firstName ?? "").slice(1);
      const lastName = (data.responseData?.personalInformation?.lastName ?? "").charAt(0).toUpperCase() + (data.responseData?.personalInformation?.lastName ?? "").slice(1);
      setFullName(firstName + " " + lastName);
     }
     else{

     }
  }

  useEffect(() => {
    getUserData();
  }, []);

  const navigateCODashboard = (isSamePage) => {
    navigate("/CoDashboard");
    if(isSamePage){
      window.location.reload();
    }
  }

  const navigateMarketPlace = (isSamePage) => {
    navigate("/marketplace");
    if(isSamePage){
      window.location.reload();
    }
  }


  const handleMenuItemClick = (id,isselected) => {
    setButtomMenuItems((prevState) =>
      prevState.map((item) => ({
        ...item,
        selected: id === item.id ? true : false,
      }))
    );
    setMenuItems((prevState) =>
      prevState.map((item) => ({
        ...item,
        selected: id === item.id ? true : false,
      }))
    );
    console.clear();
    secureLocalStorage.setItem("currentpage", id);

    if (id === 2) {
      navigateMarketPlace(isselected);
    }
    else if (id === 1) {
      console.clear();
      // TODO : This code needs to be removed and refactored
      if(secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD){
        secureLocalStorage.setItem('InvestorAndFMNotificationsRow',null)
      }
      secureLocalStorage.getItem("userrole") === AppDashboards.FIRM_DASHBOARD ?
        navigate("/firm-wide-dashboard") : (
          secureLocalStorage.getItem("userrole") === AppDashboards.CO_DASHBOARD ?
            navigateCODashboard(isselected) : secureLocalStorage.getItem("userrole") === AppDashboards.SUPERADMIN_DASHBOARD ?
              navigate("/administration") : (
                secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD ?
                  navigate("/Investor-Dashboard") : (
                    secureLocalStorage.getItem("userrole") === AppDashboards.PC_DASHBOARD ?
                      (investorProfile?.personalInformation?.currentOnboardingStep == 5
                        ? navigate("/PortfolioOnboarding")
                        : navigate("/portfolio-company-overview"))
                        : navigate("/FundManager-Dashboard"))))
    } 
    else if (id === 4) {
      navigate("/Calendar")
    }
    else if (id === 5) {
      secureLocalStorage.getItem("userrole") === AppDashboards.PC_DASHBOARD ?
        navigate("") : (
          secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD
            ? navigate("/")
            : navigate("/suggested-readings"))
    }
    else if (id === 6) {
      secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD
            ? navigate("/investor-reports-view")
            : navigate("/investor-reporting-dashboard")
    }
    else if (id === 7) {
      navigate("/kyc-aml-dashboard")
    }
    /* else if (id == 7) {
      secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD &&
      navigate("/investor-relations/investors");
    } */
    else if (id === 10) {
      if (secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD) {
        if (!checkFeaturePermissions(RolesAndPermissions.INVITE_INVESTORS, FeatureAccess.READ)) {
          navigate("/investor-relations/custom-email");
        } else {
          navigate("/investor-relations/investors");
        }
      }
    }
    else if(id === 12){
      if (secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD) {
        navigate('/auditing-dashboard')
      }
    }
    else {
      navigate("/under-construction")
    }
  };

  const handleBottonMenuItemClick = (id) => {
    setMenuItems((prevState) =>
      prevState.map((item) => ({
        ...item,
        selected: id === item.id ? true : false,
      }))
    );
    setButtomMenuItems((prevState) =>
      prevState.map((item) => ({
        ...item,
        selected: id === item.id ? true : false,
      }))
    );
    secureLocalStorage.setItem("currentpage", id);
    if (id === 8) {
      onLogoutClick();
    }
    else if (id === 11) {
      setMessengerPopup(true)
    }
    else if (id === 9) {
      if (secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD) {
        navigate("/administration");
      }
      else {
        // ****** Uncomment the below state setter method for white labeling modal *********
        // setLabelingModal(true);
        // ****** And comment below IF block to for the modal to persist ******* {
        navigate("/administration");
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', width: 'max-content', height: '100vh' }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open} sx={{ '& .MuiDrawer-paper': { backgroundColor: mainNavColorConfig.primaryColor, zIndex: 1202 } }}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div>
          <DrawerHeader>
            <IconButton onClick={handleDrawer}>
              {theme.direction === 'rtl' ? (
                <img src="icons/Closed-logo-Extended.svg" alt="Left Icon" />
              ) : (
                open ? (
                  <MenuOpenOutlined sx={{ color: mainNavColorConfig.iconColor, width: '28px', height: '28px' }} />
                ) : <MenuIcon sx={{ color: mainNavColorConfig.iconColor, width: '28px', height: '28px' }} />
              )}
            </IconButton>
          </DrawerHeader>

            <Divider />
            <List>
              {menuItems.map((item) => ( item.isBottomIcon !== true &&
                (item.id !== 5 || suggestedReadingsPermission) &&   // Check for suggested readings permission
                checkFeaturePermissions(item.FeatureName, FeatureAccess.READ) &&
                <Tooltip title={item.text}  placement="right">
                  <ListItem disablePadding className='left-nav-li'
                    sx={{
                      borderLeft: item.selected && "5px solid " + mainNavColorConfig.selectionIndicatorColor, 
                      color: item.selected && mainNavColorConfig.activeIcon
                    }}
                    onClick={() => {
                      secureLocalStorage.getItem('isProfilingCompleted') && 
                      handleMenuItemClick(item.id,item.selected);
                    }}>
                    <ListItemButton className='left-nav-li-button'
                         sx={{
                          backgroundColor: item.selected && mainNavColorConfig.secondaryColor,
                          color: item.selected && mainNavColorConfig.primaryColor,
                          "&:hover": {
                            backgroundColor: mainNavColorConfig.secondaryColor,
                            color: mainNavColorConfig.activeIcon,
                          }
                        }}
                    >
                      
                      <ListItemIcon className='left-nav-icon'
                        selected={item.selected}
                       >
                       {item.selected ? item.selectedIcon : item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.text}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: mainNavColorConfig.iconColor,
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
              ))}
            </List>
          </div>
          <div style={{ marginTop: 'auto' }}>
            <Divider />
            <List>
            {menuItems.map((item) => (
                (item.isBottomIcon === true ) && 
                <Tooltip title={item.text} placement="right">
                  <ListItem disablePadding className='left-nav-li'
                    sx={{
                      borderLeft: item.selected && "5px solid" + mainNavColorConfig.selectionIndicatorColor,
                    }}
                    onClick={() => 
                      secureLocalStorage.getItem('isProfilingCompleted') && 
                      handleBottonMenuItemClick(item.id)}>
                    <ListItemButton className='left-nav-li-button'
                     sx={{
                      backgroundColor: item.selected && mainNavColorConfig.secondaryColor,
                      color: item.selected && mainNavColorConfig.primaryColor,
                      "&:hover": {
                        backgroundColor: mainNavColorConfig.secondaryColor,
                        color: mainNavColorConfig.activeIcon,
                      },
                     }}
                    >
                      <ListItemIcon className='left-nav-icon'
                        selected={item.selected}
                        >
                        {item.selected ? item.selectedIcon : item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.text}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: mainNavColorConfig.iconColor,
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
              ))}
            </List>
          </div>
        </div>
      </Drawer>

      {showPopup ? (
        <Dialog open={showPopup} className="logout-pop">
          <DialogTitle>
           <h6> 
            <InfoIcon className='info-i' />
           Confirmation </h6>
          </DialogTitle>
          <DialogContent>
            Are you sure you want to logout?
          </DialogContent>
          <DialogActions>
            <div className="log-confirm-btns">

              <Button className='btn-primary'
                component="label"
                variant="outlined"
                onClick={handleCancelRemove}
              >
                <CancelIcon/>
                Cancel
              </Button>

              <Button className="btn-primary"
                component="label"
                variant="contained"
                onClick={handleConfirmRemove}
              >
                <LogoutIcon/>
                LOGOUT
              </Button>

            </div>
          </DialogActions>
        </Dialog>
      ) : null}
     
      <Modal open={openLabelingModal}>
        <WhiteLabeling onClose={() => setLabelingModal(false)} />
      </Modal>
    </Box>
  );
}
