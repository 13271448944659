import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mainNavigation:{
        primaryColor: '#0a1a27',
        secondaryColor: '#d2dae0',
        iconColor: '#858d93',
        selectionIndicatorColor: '#38b5e8',
        activeIcon : '#0a1a27'
    },
    topNavigation: {
        logoUrl:'/Alt360-Logo-Royal-SM.png',
        backgroundColor:'#fefefe',
        iconColor:'#828a91'
    },
    header: {
        gradientPrimaryColor:'#0a1a27',
        gradientSecondaryColor: '#5f6b76',
        fontFamily:'Cresta',
        fontColor:'#ffffff',
    },
    rootBackground: {
        color:'#eaf0f7',
        size: 1,
    }
}

const colorConfigSlice = createSlice({
  name: "colorConfig",
  initialState,
  reducers: {
    updateColorConfig: (state, action) => {
      const { mainNavigation, topNavigation, header, rootBackground} = action.payload;
      state.mainNavigation=mainNavigation;
      state.topNavigation=topNavigation;
      state.header=header;
      state.rootBackground=rootBackground;
    },
  },
});

export const getMainNavColorConfig = (state) => state.colorConfig.mainNavigation;
export const getTopNavColorConfig = (state) => state.colorConfig.topNavigation;
export const getHeaderColorConfig = (state) => state.colorConfig.header;
export const getrootBackgroundColorConfig = (state) => state.colorConfig.rootBackground;
export const getColorConfig = (state) => state.colorConfig;

export const { updateColorConfig } = colorConfigSlice.actions;
export default colorConfigSlice.reducer;
