import React, { useEffect } from 'react'
import TextInput from '@mui/material/TextField';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InputAdornment from '@mui/material/InputAdornment';
import ALTTextField from '../../common/input-fields/ALTTextField';

function AttachFile({ submittedContent, setSubmittedContent,selectedTab,errorMessages, onAttachClick, disabled, NoAttachementRequired, onChange }) {
    const [text, setText] = React.useState(submittedContent);

    /* useEffect(() => {
        onSave(submittedContent)
    }, [submittedContent]) */

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        console.log('Selected File:', file);
    };

    console.log('selcted',selectedTab)
    return (
        <TextInput
            // placeholder="Enter your discussion"
            multiline={true}
            label={'Content'}
            name="Enter your discussion"
            value={submittedContent}
            rows={3}
            disabled={disabled}
            error = {errorMessages.content ? true : false}
            helperText = {errorMessages.content}
            onChange={(e) => setSubmittedContent(e.target.value)}
            //inputProps={{ maxLength: selectedTab === 1 ? 140 : 5000 }} 
            InputProps={{
                endAdornment: (
                    !NoAttachementRequired && (
                        <InputAdornment position="end" style={{ position: 'absolute', top: 20, right: 10 }}>
                            <AttachFileIcon
                                onClick={onAttachClick}
                                sx={{ width: '25px', height: '25px', color: 'black', cursor: 'pointer !important' }}
                            />
                        </InputAdornment>
                    ),
                <InputAdornment position="bottom">{ `${submittedContent.length} / ${selectedTab === 1 ? 140 : 5000}` }</InputAdornment>
                ),
            }}
            sx={{ width: '100%' }}
        />

    )
}

export default AttachFile