// VideoPopup.js
import React from 'react';
import { Dialog,DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
 
const VideoPopup = ({ open, onClose, currVideo }) => {
  // debugger
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle className='space-between'>
         <h4>{currVideo?.title}</h4>
         <div>
            <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            >
            <CloseIcon />
            </IconButton>
         </div>
      </DialogTitle>
      <DialogContent>
        <iframe
          src={currVideo.url}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ width: '100%', height: '80vh' }}
        ></iframe>
      </DialogContent>
    </Dialog>
  );
};
 
export default VideoPopup;