import React from 'react';
import TextInput from '../TextInput/TextInput';
import secureLocalStorage from 'react-secure-storage';
import { useState } from 'react';
import { useEffect } from 'react';
import { Dialog, DialogActions, DialogTitle, DialogContent, Tooltip } from '@mui/material';
import { Button } from "@mui/material";
import { Grid } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import IconButton from '@mui/material/IconButton';
import Indicator from '../DataGrid/Indicator';
import AIPDataGrid from '../DataGrid/AIPDataGrid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from "react-router-dom";
import { AppDashboards } from '../../../utils/enum';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RuleIcon from '@mui/icons-material/Rule';
import { Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getAppNotifications } from '../../InvestorRelations/Services/IRServices';


const AppNotifications = (props) => {

  //#region props
  const { isInvestorNameRequired = false, crntFundID, crntInvestorid, disableSubscriptionButton } = props;

  //#region variables
  const navigate = useNavigate();
  const [fundNotifications, setFundNotifications] = useState(null);
  const fundId = secureLocalStorage.getItem("FundId");
  const SubscriptionStatus = {
    UNDER_REVIEW: "Under Review",
    UNDER_COMPLIANCE_REVIEW: "Under Compliance Review",
  };

  //#region functions
  const getSubscriptionStatus = (user) => {
    const userRole = secureLocalStorage.getItem('userrole');
    const userStatus = user.entityStatus;

    if (userRole === AppDashboards.INV_DASHBOARD) {
      return userStatus.includes("Review") ? SubscriptionStatus.UNDER_REVIEW : userStatus;
    }

    if (userRole === AppDashboards.FM_DASHBOARD) {
      return userStatus.includes("Under Compliance Review") ? SubscriptionStatus.UNDER_REVIEW : userStatus;
    }

    return user.entityStatus;
  };

  //#region click events
  const handleRowClick = (params) => {
    debugger
    secureLocalStorage.setItem("FundId", params.row.fundId);
    const requestedData = {
      id: params.row.notificationID,
      UserName: params.row.notifiedUserName,
      FundName: params.row.fundName,
      Status: getSubscriptionStatus(params.row),
      Type: params.row.entityType,
      entityAmount: params.row.entityAmount,
      committedCapital: params.row.committedCapital,
      fundSize: params.row.fundSize,
      currentfundId: params.row.fundId,
      NotificationType: params.row.notificationType,
      subscriptionsID: params.row.entityID,
      fundID: params.row.fundId,
      investorId: params.row.notifiedUserID,
      fundTypeName: params.row.fundTypeName,
    }
    secureLocalStorage.setItem('InvestorAndFMNotificationsRow', requestedData)
    if (params.row.entityStatus === 'Approved') {
      secureLocalStorage.setItem("investorId", requestedData.investorId)
      secureLocalStorage.setItem("InvestorName", requestedData.UserName)
      navigate('/Investor-Overview-Page', {
        state: {
          FundId: params.row.fundId,
          subscriptionId: params.row.entityID
        }
      });
      window.scrollTo(0, 0);
    }
    else {
      secureLocalStorage.setItem("investorId", requestedData.investorId)
      secureLocalStorage.setItem("fundID", requestedData.fundID)
      navigate('/subscription-details', {
        state: {
          FundId: params.row.fundId,
          subscriptionId: params.row.entityID,
          selectedRow: requestedData,
        }
      });
      window.scrollTo(0, 0);
    }
  };

  //#region api get calls
  const fetchDataForNotifications = async () => {
    const response = await getAppNotifications(crntFundID, crntInvestorid);
    if (response.responseCode === 200) {
      setFundNotifications(response.responseData);
    }
  };

  //#region cloumns
  const notificationsColumns = [
    ...(isInvestorNameRequired === true ? [{
      "id": 1,
      "field": "notifiedUserName",
      "headerName": "Investor Name",
      "width": 120,
      "renderCell": (params) => {
        return <Indicator params={params} />
      }
    }] : []),
    ...(isInvestorNameRequired === false ? [{
      "id": 2,
      "field": "fundName",
      "headerName": "Fund Name",
      "width": 170,
      "renderCell": (params) => {
        return <Indicator params={params} />
      }
    }] : []),
    ...(isInvestorNameRequired === false ? [{
      "id": 3,
      "field": "fundTypeName",
      "headerName": "Fund Type",
      "width": 140,
    }] : []),
    {
      "id": 4,
      "field": "notificationType",
      "headerName": "Notification Type",
      "width": 140,
      "renderCell": (params) => {
        return <Indicator params={params} />
      }
    },
    {
      "id": 5,
      "field": "status",
      "headerName": "Status",
      "width": 140,
      "renderCell": (params) => {
        return (
          <div style={{ marginLeft: "10px" }}>
            {getSubscriptionStatus(params.row)}
          </div>
        );
      }
    },
    {
      "id": 6,
      "field": "Actions",
      "headerName": "Actions",
      "width": 70,
      "renderCell": (params) => {
        return (
          <div style={{ marginLeft: "10px" }}>
            {<Tooltip title="View"><VisibilityIcon /></Tooltip>}
          </div>
        );
      }
    }
  ];

  //#region useeffect
  useEffect(() => {
    fetchDataForNotifications();
  }, []);

  //#region return
  return (
    <>
      <DataGridPro
        columns={notificationsColumns}
        rows={fundNotifications ?
          fundNotifications?.map((item, index) => {
            return {
              id: index,
              ...item
            }
          }).sort((a, b) => new Date(b.id) - new Date(a.id)) : []}
        onRowClick={handleRowClick}
        rowHeight={39}
        sx={{
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer" // Clickable row with hand symbol
          },
        }}
        onRowsSelectionHandler={() => { }}
        hideFooter
      />
    </>

  );
}

export default AppNotifications;