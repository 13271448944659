import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { TopNavBar } from "./components/common/navigation-bar/TopNavBar";
import MiniDrawer from "./components/common/navigation-bar/NavBarGrid";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails,updateProfileIcon } from "./state/slices/userDetailsSlice";
import { getrootBackgroundColorConfig } from "./state/slices/colorConfigSlice";
import { getUserDetailsbyEmail } from "./components/common/navigation-bar/navServices";


 
export const AuthApp = () => {

  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const userDetails = useSelector(getUserDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState('');
 
  const [ProfileInformation,setProfileInformation] = useState(null);
 
  const emailId = secureLocalStorage.getItem("userEmail");

  const getUserData = async () => {
    const data = await getUserDetailsbyEmail(emailId);
    if(data.responseCode === 200){
     const firstName = (data.responseData?.personalInformation?.firstName ?? "").charAt(0).toUpperCase() + (data.responseData?.personalInformation?.firstName ?? "").slice(1);
     const lastName = (data.responseData?.personalInformation?.lastName ?? "").charAt(0).toUpperCase() + (data.responseData?.personalInformation?.lastName ?? "").slice(1);
     secureLocalStorage.setItem("firstName", firstName);
     secureLocalStorage.setItem("lastName", lastName);
     secureLocalStorage.setItem("userName", data?.responseData.personalInformation?.userName);
     const fullName = `${firstName} ${lastName}`;
     secureLocalStorage.setItem("fullName", fullName);
     setFullName(firstName + " " + lastName);
     setProfileInformation(data.responseData);
        
      const profileIconValue = data.responseData?.personalInformation?.profileIcon ? data.responseData?.personalInformation?.profileIcon : "";
      dispatch(updateProfileIcon({ value: profileIconValue }));
    }
    else{

    }
 }

  useEffect(() => {
    if(!secureLocalStorage.getItem("userLoggedIn")){
      navigate("/", { replace: true });
      return;
    }
    getUserData();
  }, []);
 
  return (<div style={{ display: 'flex' }}>
    <div className="AppNavLeft">
    <MiniDrawer />
    </div>

    <div className="AppSpace" style={{ backgroundColor: rootBackColorConfig.color }}>
      <TopNavBar fullName={userDetails?.fullName} ProfileInformation={ProfileInformation}/>
     <div className="scrollRightSpace">
      <Outlet />
      </div>
    </div>
  </div>
  )
}