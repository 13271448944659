import { Box, Button, Card, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const VideosPage = (props) => {
  //#region props
  const { selectedVideo, setSelectedVideo, setActiveBodySection } = props;

  //#region variables
  const videoRefs = useRef([]);
  const [hasMounted, setHasMounted] = useState(false);
  const videos = [
    {
        title: 'Add Fund',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-AddFund.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/AddFund.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'Calendar',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-Calendar.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Calendar.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'Compare Funds',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-CompareFunds.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/CompareFunds.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'Compliance Officer Dashboard',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-ComplianceOfficerDashboard.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/ComplianceOfficer.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'Favorite Funds',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-FavouriteFunds.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/FavoriteFunds.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'FundManager Dashboard',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-FundManagerDashboard.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/FundManagerDashboard.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'Initiating CapitalCalls to Investors',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-InitiatingCapitalCallstoInvestors.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/CapitalCalls.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'Initiating Distributions to Investors',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-InitiatingDistributionstoInvestors.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/DistributionstoInvestors.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'Investor-MyInvestments',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-Investor-_MyInvestments.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/MyInvestments.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'Investor Dashboard',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-InvestorDashboard.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/InvestorDashboard.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'Investor Registration Process',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-InvestorRegistrationProcess.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/InvestorRegistrationProcess.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'Invite Investors',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-InviteInvestors.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/InviteInvestor.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'K-1s Reports',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-K-1sReports.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/K-1sReports.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'Marketplace',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-Marketplace.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Marketplace.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'New Subscriber',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-NewSubscriber.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/NewSubscriber.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'Onboarding and Profiling',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-OnboardingandProfiling.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/OnboardingandProfiling.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'Subscription Process',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-SubscriptionProcess.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/SubscriptionProcess.jpg',
        componentTxt: 'videos'
    },
    {
        title: 'Suggested Readings',
        url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-SuggestedReadings.mp4', 
        poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/SuggestedReadings.jpg',
        componentTxt: 'videos'
    },
  ];

  //#region functions
  const requestFullscreen = (element) => {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(); // Safari
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen(); // IE11
    } else {
        console.error("Fullscreen API is not supported.");
    }
};

  //#region click events
  const handleBackToList = (article) => {
    setActiveBodySection(null);
    setSelectedVideo({
      url: "",
      isFullScreen: false, 
    });
  };

  const handleVideoClick = (url) => {
    setSelectedVideo({
      url: url,
      isFullScreen: false, 
    });
  };

  //#region useeffect
  useEffect(() => {
    if (!hasMounted) {
        setHasMounted(true);
      }
    if (selectedVideo?.isFullScreen) {
      const videoElement = videoRefs.current.find(
        (videoRef,index) => videoRef && videoRefs.current[index].childNodes[0].src === selectedVideo.url
      );
      if (videoElement) {
        requestFullscreen(videoElement);
      }
    }
  }, [selectedVideo, hasMounted]);

  //#region return
  return (
    <div className='article-container'>
            <Box>
                <Button 
                 onClick={() => handleBackToList('article')}> 
                 <ArrowBackIcon  className='helpbackicon'/>
                  Go Back 
                </Button>
                <h2>Video Tutorials</h2>
                <Grid container spacing={4} marginTop={1}>
                    {videos?.map((video, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} key={index} className='articleVedio'>
                            <Card>
                                <Box className="videoSec">
                                    <video
                                        //autoPlay
                                        loop
                                        muted
                                        controls
                                        poster={video.poster}
                                        style={{ width: '100%' }}
                                    >
                                        <source
                                            src={video.url}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video tag.
                                    </video>
                                </Box>
                            </Card>
                            <h6 className='videotitle'>{video.title}</h6>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div>
  )
}

export default VideosPage;
