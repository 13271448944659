import React, { useEffect, useState } from 'react'
import FileUploadBtn from '../../../common/FileUploadButton/FileUploadBtn';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import EditIcon from '@mui/icons-material/Edit';
import ActionButton from '../../../common/ActionButton/ActionButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Grid, LinearProgress, Select } from '@mui/material';
import SelectField from '../../../common/input-fields/SelectField';
import { CreateBankingInformation, UpdateProfileBankInformation, UpdateProfileInformation } from '../../../user-profile/InvestorUserProfile/Services/Investorinfo';
import { getKeyValuePairs } from '../../../portfolio-companies/services/services';
import { InvestorTypesEnum, SpousehasIIAaccdropdown, dependentsOptions, employmentstatusdropdown, isemployesdropdown } from '../../services/apiDataTemplate';
import DiscussionBox from '../../../KYCVerification/components/DiscussionBox';
import secureLocalStorage from 'react-secure-storage';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VDRoom from '../../../common/VirtualDataRoom/VDRoom';
import { userProfileToastMessages, validationsConstants } from '../../../../utils/AppConstants';
import { updateUserProfile } from '../../services/services';
import { ToastContainer, toast } from 'react-toastify';
import { bankingInformationFields } from '../../services/fieldsData';
import InputField from '../../../common/input-fields/InputField';
import { textFormatTypeEnum, validationsTypeEnum } from '../../../common/TextInput/appInputenum';
import { uploadFileTypes } from '../../../common/FileUploadButton/uploadComponentenum';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { KycAmlStuatus, KycStatusvalues } from '../../../KYCVerification/enum';
import { updatekycamlstatus } from '../../../KYCVerification/services/services';
import { getStatesandCitieslist } from '../../../InvestorFundSubscription/Services/Statecountry';
import { FieldTypes } from '../../../common/input-fields/utlis/InputFieldsEnum';

const BankingInformation = (props) => {

  //#region props
  const { investorDetails,getInvestorInfo,investorTypesValues,isKycVerification,kycStatus,amlStatus,getInvestorKycAml,isViewOnly } = props; 

  //#region variables
  const [isSaveEnabled,setIsSaveEnabled] = useState(false);
  const [fieldsDetails, setFieldsDetails] = useState(bankingInformationFields);
  const [showComments, setshowComments] = useState(false);
  const [loading,setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [verifyBtnLoading,setVerifyBtnLoading] = useState(false);
  const [rejectBtnLoading,setRejectBtnLoading] = useState(false);

  //#region change events
  const handleChange = (name, value) => {
    bankingInformationDetails.setFieldValue(name, value);
  }

  //#region click events
  const onEditClick = () => {
    setIsSaveEnabled(true);
  }

  const onCancelClick = () => {
    setIsSaveEnabled(false);
    bankingInformationDetails.resetForm();
    getInvestorInfo();
  }

  const onVerifyClick = () => {
    setVerifyBtnLoading(true);
    updateKycStatus(true);
  }

  const onRejectClick = () => {
    setRejectBtnLoading(true);
    updateKycStatus(false);
  }

  const handleComments = () => {
    const details = {
      "id": bankingInformationDetails?.values?.userId
    }
    secureLocalStorage.setItem("KYCInvestorDetails", details);
    setshowComments(!showComments);
  }

  //#region api get calls
  const getDropDownDetails = async () => {
    const statesData = await getStatesandCitieslist();

    let stateOptions = []
    let countryOptions = []

    if(statesData.responseCode === 200){
      stateOptions = statesData.responseData
          ? statesData.responseData?.states?.map(option => ({ label: option.stateName, value: option.stateMasterId })) || []
          : []; 
    }

    if(statesData.responseCode === 200){
      countryOptions = statesData.responseData
          ? statesData.responseData?.countries?.map(option => ({ label: option.countryName, value: option.countryMasterID })) || []
          : []; 
    }
    const updatedFields = bankingInformationFields.map(field => {
      if (field.name === 'countryID') {
        return { ...field, options: countryOptions };
      } 
      else if (field.name === 'stateMasterId') {
        return { ...field, options: stateOptions };
      } 
      return field;
    });
    
    setFieldsDetails(updatedFields);

  }

  //#region api post calls
  const updateBankingInformation = async () => {
    setLoading(true);
    const requestData = {
      "personalInformation": investorDetails.personalInformation,
      "bankingInformation": bankingInformationDetails.values,
      "investmentProfile": null
    };
    requestData.userId = investorDetails.personalInformation.userId;
    const data = await UpdateProfileBankInformation(requestData,investorDetails.personalInformation.userId)
    if(data.responseCode === 200){
      setLoading(false);
      onCancelClick();
      toast.success(userProfileToastMessages.INFORMATION_SUCESSFUL, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
    }
    else{
      setLoading(false);
      onCancelClick();
      toast.error(userProfileToastMessages.INFORMATION_FAILED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
  }

  const createBankingInformation = async () => {
    setLoading(true);
    bankingInformationDetails.values.userId = investorDetails.personalInformation.userId;
    const data = await CreateBankingInformation(bankingInformationDetails.values,investorDetails.personalInformation.userId)
    if(data.responseCode === 200){
      setLoading(false);
      onCancelClick();
      toast.success(userProfileToastMessages.INFORMATION_SUCESSFUL, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
    }
    else{
      setLoading(false);
      onCancelClick();
      toast.error(userProfileToastMessages.INFORMATION_FAILED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
  }

  //#region update calls
  const updateKycStatus = async (isVerified) => {
    const requestBody = {
      "userId": investorDetails.personalInformation.userId,
      "kycaml": KycAmlStuatus.AML_STATUS,
      "kycStatus": kycStatus,
      "amlStatus": isVerified ? KycStatusvalues.VERIFIED : KycStatusvalues.REJECTED,
    }
    const data = await updatekycamlstatus(requestBody);
    if (data.responseCode === 200) {
      getInvestorKycAml();
      setVerifyBtnLoading(false);
      setRejectBtnLoading(false);
      toast.success(userProfileToastMessages.VERIFICATION_SUCCESS, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
    else {
      toast.error(userProfileToastMessages.VERIFICATION_FAILED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
  }

  //#region useeffect
  useEffect(()=>{
    getDropDownDetails();
  },[])

  useEffect(()=>{
    bankingInformationDetails.setValues(investorDetails?.bankingInformation);
  },[investorDetails])

  //#region formik validations
  const bankingValidationSchema = () => {
    const shape = {};
  
      fieldsDetails?.forEach((item) => {
        if (item?.required) {
          if(item?.validationType === validationsTypeEnum.ZIP_CODE){
            shape[item.name] = yup
                              .string()
                              .required(validationsConstants.REQUIRED)
                              .matches(/^\d{5}(?:-\d{4})?$/, 'Invalid ZIP code format')
          }
          else if(item?.validationType === validationsTypeEnum.ACCOUNT_NUMMBER){
            shape[item.name] = yup
                                .string()
                                .required(validationsConstants.REQUIRED)
                                .min(12, validationsConstants.ACCOUNT_NUMBER)
                                .max(12, validationsConstants.ACCOUNT_NUMBER);
          }
          else if(item?.type === FieldTypes.SELECT_FIELD){
            shape[item.name] = yup.lazy(value => {
              if (typeof value === 'number') {
                return yup.number()
                  .min(1, validationsConstants.REQUIRED)
                  .required(validationsConstants.REQUIRED);
              } else {
                return yup.string()
                  .required(validationsConstants.REQUIRED);
              }
            });
          }
          else {
            shape[item.name] = yup.string().trim().required(validationsConstants.REQUIRED);
          }
        }
      });
    return yup.object().shape(shape);
  };
  
  
  const bankingInformationDetails = useFormik({
    initialValues: {
      ...investorDetails
    },
    validationSchema: bankingValidationSchema(), 
    onSubmit: (values) => {
      bankingInformationDetails.values?.userBankDetailsID === 0 
      ? createBankingInformation() :
      updateBankingInformation();
    },
  });
  

  //#region return
  return (
    <div className='child-margin-15'>
      {
        investorDetails ? 
        <>
         {
          isKycVerification ?  
           isViewOnly ? "" :
          <div className='firm-verify-btns space-between'>
             <div></div>
             {
              amlStatus === KycStatusvalues.VERIFIED || amlStatus === KycStatusvalues.REJECTED ? 
              <div>
                 <ActionButton 
                      label={amlStatus}
                      variant='contained'
                      disabled={true}
                      onClick={()=>{}}/>
              </div> :
              <div className='child-row-margin-10'>
                 <div>
                    <ActionButton 
                        label="VERIFY"
                        icon={<CheckCircleIcon />}
                        disabled={amlStatus === KycStatusvalues.INCOMPLETE || rejectBtnLoading}
                        loading={verifyBtnLoading}
                        variant='contained'
                        onClick={onVerifyClick}/>
                 </div>
                 <div>
                  <ActionButton 
                        label="REJECT"
                        icon={<ThumbDownIcon />}
                        disabled={verifyBtnLoading}
                        loading={rejectBtnLoading}
                        variant='outlined'
                        onClick={onRejectClick}/>
                 </div>
              </div>
             }
          </div> :
          <div className='investor-save-cancel-btns space-between'>
          <div></div>
          <div className='child-row-margin-10'>
            {
              isSaveEnabled ? 
              <>
                <ActionButton 
                  label="CANCEL"
                  icon={<CancelIcon />}
                  disabled={loading}
                  variant='outlined'
                  onClick={onCancelClick}/>
                 <ActionButton
                    label="SAVE"
                    loading={loading}
                    icon={<SaveIcon />}
                    onClick={bankingInformationDetails.handleSubmit} />
              </> : 
              <>
                <ActionButton 
                  label="EDIT"
                  icon={<EditIcon />}
                  onClick={onEditClick}/>
              </>
            }
          </div>
          </div>
         }
         <form className='white-card child-margin-15' novalidate="novalidate">
            <Grid container spacing={2} alignItems="center">
              <Grid item lg='4' md='4' sm='12' xs='12'>
                  <FileUploadBtn 
                        name="selectedImage"
                        label="UPLOAD PROFILE ICON"
                        defaultFile={investorDetails?.personalInformation?.profileIcon ? investorDetails?.personalInformation?.profileIcon : '/icons/defaultpropic.jpg'}
                        fileType={uploadFileTypes.IMAGE}
                        readOnly={true}
                        fromWhiteLabeling={isSaveEnabled ? true : false}
                        isSize={true}
                        onChange={() => {}}/>
              </Grid>
              {
                investorTypesValues?.
                  find((item) => item?.listItemID === investorDetails?.personalInformation?.investorTypeID)?.listItemValue 
                     === InvestorTypesEnum.INDIVIDUAL_INVESTOR 
                 ? <>
                    <Grid item lg='4' md='4' sm='6' xs='12'>
                    <ALTTextField 
                      textFormatType={textFormatTypeEnum.ONLY_ALPHABETS}
                      name="firstName"
                      label="First Name"
                      value={investorDetails?.personalInformation?.firstName}
                      onChange={(name, value) => handleChange(name, value)}
                      disabled={true} />
                    </Grid>
                    <Grid item lg='4' md='4' sm='6' xs='12'>
                      <ALTTextField 
                        textFormatType={textFormatTypeEnum.ONLY_ALPHABETS}
                        name="lastName"
                        label="Last Name"
                        value={investorDetails?.personalInformation?.lastName}
                        onChange={(name, value) => handleChange(name, value)}
                        required={true}
                        disabled={true} />
                    </Grid>
                   </>
                 : <>
                    <Grid item lg='4' md='4' sm='6' xs='12'>
                      <ALTTextField 
                        name="firstName"
                        label="Legal Entity Name"
                        value={investorDetails?.personalInformation?.firstName}
                        onChange={() => {}}
                        disabled={true} />
                    </Grid>
                   </>
              }
            </Grid>
            <Grid container spacing={2} mt={2} justifyContent="flex-end">
              {
                fieldsDetails?.map((field)=>{
                    return(
                      <Grid item lg='4' md='4' sm='6' xs='12'>
                         <InputField 
                             textFormatType={field.formatType || ''}
                             type={field.type}
                             name={field.name}
                             label={field.label}
                             variant="standard"
                             rows={1}
                             required={field.required}
                             options={field?.options?.length > 0 ? field?.options : []}
                             value={bankingInformationDetails?.values?.[field.name] || ''}
                             onChange={(name, value) => handleChange(name, value)}
                             readOnly={!isSaveEnabled}
                             onBlur={bankingInformationDetails.handleBlur}
                             error={bankingInformationDetails.touched?.[field.name] && Boolean(bankingInformationDetails?.errors?.[field.name])}
                             errorMessage={bankingInformationDetails?.touched?.[field.name] && bankingInformationDetails?.errors?.[field.name]}/>
                      </Grid>
                    )
                })
              }
            </Grid>
            {/* <div>
                <Button
                  onClick={handleComments}
                  icon={<VisibilityIcon />}
                  variant="contained"
                  className="btn-primary">
                  {showComments ? 'Hide Comments' : 'Show Comments'}
                </Button>
            </div> */}
         </form> 
        {/*  {
            showComments &&
            <div item xs={12} className='whiteCard' sx={{ marginTop: '10px' }}>
              <DiscussionBox count={count} setCount={setCount} />
            </div>
          }
          <div className='white-card margin-top-10'>
              <VDRoom 
                 VDRType={'KYCAML'}
                 cnrtFundID={0}
                 crntSubscriptionID={0}
                 crntInvestorID={investorDetails?.personalInformation?.userId}
                 hideMenu={true} />
          </div> */}
        </> : 
        <div>
          <LinearProgress />
        </div>
      }
      <ToastContainer />
    </div>
  )
}

export default BankingInformation