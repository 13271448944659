import { Box, Button, Tooltip, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { addCompare, removeCompare, updateAllFunds } from '../../../state/slices/MarketPlaceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFunds } from '../../FundManagerDashboard/Services/FundNewsService';
import secureLocalStorage from 'react-secure-storage';
import { checkFeaturePermissions } from '../../../utils/common';
import { AppDashboards, FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import { useNavigate } from 'react-router-dom';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { addFavourite,removeFavourite } from '../../../state/slices/MarketPlaceSlice';
import { updateFavouriteFund } from '../services/mpservices';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FundGridView from '../../common/StackView/FundGridView';
import { ConvertToPercentage, ConvertToUSCurrency } from '../../common/Functions/CommonConvertions';
import { ArrowDropDown , ArrowDropUp} from "@mui/icons-material"
import { DataGridPro } from '@mui/x-data-grid-pro';
import { filter } from 'd3';
import { fundTypesEnum } from '../jsonData';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
const FundListView = (props) => {

  //#region props
  const { allFunds,allInvestors,comparefunds,selectedFundType,filterOptions,favoritefunds,getFunds } = props;

  //#region variables
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const privateEquityColumns = [
    {
      id: 1, field: "iconImage", headerName: "Fund Logo", width: 140,
      renderCell: (params) => <img className='list-icon-img-container' src={params.value !== "" ? params.value : "/DefaultLogo.png"} />,
    },
    { id: 2, field: "favourite", headerName: "", width: 20,
      renderCell: (params) => {
        const isFavorite = favoritefunds?.some((item) => item?.fundID === params.row.fundID);
        return isFavorite === true ? 
        <Tooltip title="Click to Remove">
          <FavoriteIcon title="Favorite" color="primary" sx={{ width: '24px', height: '24px' }} /> 
          </Tooltip> :
           <Tooltip title="Add to Favorite">
          <FavoriteBorderIcon title="Favorite" sx={{ color: '#D2DAE0', width: '24px', height: '24px' }} />
          </Tooltip>
      }
    },
    { id: 3, field: "compare", headerName: "", width: 20,
      renderCell: (params) => {
        const isCompared = comparefunds?.some((item) => item?.fundID === params.row.fundID);
        return isCompared ? 
        <Tooltip title="Click to Remove">
              <CompareArrowsIcon title="Add to Compare" color="primary" sx={{ width: '24px', height: '24px' }} />
        </Tooltip> :
        <Tooltip title={comparefunds?.length >= 3 ? 
          <div>
            Please deselect a fund to add a new one. 
            <br />You can compare a maximum of 3 funds
          </div> 
          : "Add to Compare"}>
          <CompareArrowsIcon title="Add to Compare" sx={{ color: '#D2DAE0', width: '24px', height: '24px' }} />
          </Tooltip>
      }
    },
    { id: 4, field: "fundName", headerName: "Fund Name", width: 190 },
    { id: 5, field: "fundTypeName", headerName: "Fund Type", width: 130 },
    { id: 6, field: "fundStatusName", headerName: "Fund Status", width: 130 },
    /* { id: 7, field: "targettedIRR", headerName: "Target Returns", width: 100,
      renderCell: (params) => ConvertToPercentage(params.value),
     }, */
    {
      id: 8, field: "minimumInvestmentAmount", headerName: "Minimum Investment", width: 120,
      renderCell: (params) => ConvertToUSCurrency(params.value),
    },
    { id: 9, field: "liquidityProvision", headerName: "Liquidity", width: 180 },
    {
      id: 10, field: "navigate", headerName: "", width: 30,
      renderCell: (params) => <img src="/chevron-right-fill0-wght400-grad0-opsz48.svg" />,
    },
  ];

  const privateCreditColumns = [
    {
      id: 1, field: "iconImage", headerName: "Fund Logo", width: 140,
      renderCell: (params) => <img className='list-icon-img-container' src={params.value !== "" ? params.value : "/DefaultLogo.png"} />,
    },
    { id: 2, field: "favourite", headerName: "", width: 20,
      renderCell: (params) => {
        const isFavorite = favoritefunds?.some((item) => item?.fundID === params.row.fundID);
        return isFavorite === true ? 
        <Tooltip title="Click to Remove">
          <FavoriteIcon title="Favorite" color="primary" sx={{ width: '24px', height: '24px' }} /> 
          </Tooltip>: 
          <Tooltip title="Add to Favorite">
          <FavoriteBorderIcon title="Favorite" sx={{ color: '#D2DAE0', width: '24px', height: '24px' }} />
          </Tooltip>
      }
    },
    { id: 3, field: "compare", headerName: "", width: 20,
      renderCell: (params) => {
        const isCompared = comparefunds?.some((item) => item?.fundID === params.row.fundID);
        return isCompared ? 
        <Tooltip title="Click to Remove">
          <CompareArrowsIcon title="Remove from Compare" color="primary" sx={{ width: '24px', height: '24px' }} /> : 
          </Tooltip> :
         <Tooltip title="Add to Compare">
          <CompareArrowsIcon title="Add to Compare" sx={{ color: '#D2DAE0', width: '24px', height: '24px' }} />;
          </Tooltip>
      }
    },
    { id: 4, field: "fundName", headerName: "Fund Name", width: 190 },
    { id: 5, field: "fundTypeName", headerName: "Fund Type", width: 130 },
    { id: 6, field: "fundStatusName", headerName: "Fund Status", width: 130 },
    {
      id: 8, field: "minimumInvestmentAmount", headerName: "Minimum Investment", width: 120,
      renderCell: (params) => ConvertToUSCurrency(params.value),
    },
    { id: 9, field: "liquidityProvision", headerName: "Liquidity", width: 180 },
    {
      id: 10, field: "navigate", headerName: "", width: 30,
      renderCell: (params) => <img src="/chevron-right-fill0-wght400-grad0-opsz48.svg" />,
    },
  ];

  //#region functions
  const renderColumns = () => {
    const selectedType = filterOptions?.fundType.find(option => option.value === selectedFundType)?.label;
    if (selectedType === fundTypesEnum.PRIVATE_EQUITY) {
      return privateEquityColumns;
    } else if (selectedType === fundTypesEnum.PRIVATE_CREDIT) {
      return privateCreditColumns;
    } else {
      return privateEquityColumns;
    }
  };
  
  //#region click events
  const onColumnClick = (params) => {
    const fundDetails = params?.row;
    if(params?.colDef?.field === "navigate"){
      handleViewDetail(fundDetails)
    }
    else if(params?.colDef?.field === "favourite"){
      const isFavorite = favoritefunds?.some((item) => item?.fundID === params.row.fundID);
      if(isFavorite){
        handleRemoveFavourite(fundDetails)
      }
      else{
        handleAddFavourite(fundDetails)
      }
    }
    else if(params?.colDef?.field === "compare"){
      if(comparefunds?.length >= 3){
        return
      }
      if(comparefunds?.find((item) => item?.fundID === fundDetails?.fundID)){
        handleRemoveCompare(fundDetails)
      }
      else{
        handleAddCompare(fundDetails);
      }
    }
  }

  const handleAddCompare = (fund) => {
    const updatedFunds = allFunds.map(item => {
      if (item.fundID === fund.fundID) {
          return {...item, compare: true}; 
      }
      return item;
    });
    const compareFund = updatedFunds.find((item)=> item.fundID === fund.fundID)
    dispatch(updateAllFunds({ value : updatedFunds }));
    dispatch(addCompare({ fund : compareFund }));
  }

  const handleRemoveCompare = (fund) => {
    const updatedFunds = allFunds.map(item => {
      if (item.fundID === fund.fundID) {
          return {...item, compare: false}; 
      }
      return item;
    });
    const fundID = fund.fundID;
    dispatch(updateAllFunds({ value : updatedFunds }));
    dispatch(removeCompare({ fundID : fundID }));
  }

  const handleAddFavourite = async(fund) => {
    const data = await updateFavouriteFund(fund.fundID,true);
    const updatedFunds = allFunds.map(item => {
      if (item.fundID === fund.fundID) {
          return {...item, favourite: true}; 
      }
      return item;
    });
    const favFund = updatedFunds.find((item)=> item.fundID === fund.fundID)
   /*  dispatch(updateAllFunds({ value : updatedFunds })); */
    if(data.responseCode === 200){
      getFunds();
      dispatch(addFavourite({ fund : favFund }));
    }
    else{
      /* console.log("Unable to add Fund") */
    }
  }

  const handleRemoveFavourite = async(fund) => {
    const data = await updateFavouriteFund(fund.fundID,false);
    if(data.responseCode === 200){
      const updatedFunds = allFunds.map(item => {
        if (item.fundID === fund.fundID) {
            return {...item, favourite: false}; 
        }
        return item;
      });
      const fundID = fund.fundID;
      getFunds();
      /* dispatch(updateAllFunds({ value : updatedFunds })); */
      dispatch(removeFavourite({ fundID : fundID }));
    }
    else{
      /* console.log("Unable to remove Fund") */
    }
  }

  const handleViewDetail = (fundDetails) => {

    secureLocalStorage.setItem("FundId", fundDetails?.fundID);
    secureLocalStorage.setItem("FundName", fundDetails?.fundName);
    const havepermissiontoread =  checkFeaturePermissions(RolesAndPermissions.FUND, FeatureAccess.READ);
    if (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD) {
      /* navigate('/fund-details', { parsedFundId }, { fundStatusName }); */
      navigate('/fund-details', {
        state: {
          FundID: fundDetails?.fundID,
          InvestorID: secureLocalStorage.getItem("userId"),
          SubscriptionID: 0,
          navigatefrommarketplace: true,
          disableSubscriptionButton: true
        }
      });
      
    } else if (havepermissiontoread) {
      
      const SelectedFund = {
        "FundMaturity": fundDetails.isFundMatured,
        "FundTermination": fundDetails.fundTerminated, 
        BGImage: fundDetails.bgImage,
        IconImage: fundDetails.iconImage,
      }
      secureLocalStorage.setItem("navigatingFromMarketPlace", true);
      secureLocalStorage.setItem("selectedFund", SelectedFund);
      navigate('/marketplace/OverviewPage', fundDetails?.fundID);
    }
    
    secureLocalStorage.setItem("FundId", JSON.stringify(fundDetails?.fundID));
    secureLocalStorage.setItem('fundStatusName',fundDetails?.fundStatusName);
    window.scrollTo(0, 0);
  };


  return (
    <Box sx={{  width: '100%',height: '500px' }}>
       <DataGridPro
            columns={renderColumns()}
            rows = {allFunds ? allFunds?.map((fund) => ({
                        id: fund.fundID,
                        ...fund
                      })) : []}
            rowHeight={100}
            onCellClick={onColumnClick}
            classes={{
              row: 'custom-row', 
              cell: 'custom-cell',
            }}
            hideFooterPagination={true}
            disableColumnMenu={false}
            hideFooter={true}
            slots={{
              disableColumnMenu: true,
            }}
            localeText={{ noRowsLabel: "No Funds"}}
            disableMultipleColumnsFiltering
            disableColumnFilter
            disableMultipleColumnsSorting
        />
    </Box>
  )
}

export default FundListView