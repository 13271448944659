import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import ActionButton from '../../../common/ActionButton/ActionButton';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import RichTextInput from '../../../common/rich-text-input/RichTextInput';

const NotificationDetailPopup = (props) => {

    const { open,notificationDetails, onClose ,setOpen} = props;
    const [loading, setLoading] = useState(false);
    debugger

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle> 
                 <IconButton onClick={()=> setOpen(false)} style={{ float: 'right' }} title="Close">
                    < CloseIcon />
                 </IconButton>
            </DialogTitle>
            <DialogContent>
                <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{notificationDetails.notificationSubject || notificationDetails.label}</p>
                {/* <p style={{ wordWrap: 'break-word', }}>{notificationDetails.notificationBody.replace(/<[^>]*>/g, '')}</p> */}
                <div className='disablediv'>
                      <RichTextInput
                            variant="outlined"
                            name="body"
                            defaultValue={notificationDetails.notificationBody || notificationDetails.relatedText}
                            hideTextControllers={true}
                            onChange={()=>{}}
                            disabled={true}
                            validateField={false} />
                </div>
                <div>
                            {notificationDetails?.fileNames?.split(',')?.map((file, index) => (
                                <React.Fragment key={index}>
                                    <a
                                        href={notificationDetails??notificationDetails.containerPath + "/" + notificationDetails?.notificationDetailsID + "/" + file.trim()}
                                        target="_blank"
                                    >
                                        {file}
                                    </a>
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>
            </DialogContent>
        </Dialog>
    )
}
export default NotificationDetailPopup;