import React, { useState } from 'react'
import { submitOfflineSubscription } from '../../../InvestorFundSubscription/Services/Services'
import { Button, Grid } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';
import secureLocalStorage from 'react-secure-storage';
import EditIcon from '@mui/icons-material/Edit';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ToastContainer, toast } from 'react-toastify';
import ActionButton from '../../../common/ActionButton/ActionButton';
import FileUploadButton from '../../../common/FileUploadButton/FileUploadButton';
import { useNavigate } from 'react-router-dom';
import { downloadSubscriptionAgreement, downloadsubscriptiontemplate } from '../../services/services';
import axios from 'axios';
import Configurations from '../../../../Configurations';
import { is } from 'date-fns/locale';

const OfflineSubscriptionPage = (props) => {

  //#region props
  const { investorSubscriptionDetails } = props;

  //#region variables
  const investorId = secureLocalStorage.getItem("userId");
  const [loading, setLoading] = useState(false);
  const FundName = secureLocalStorage.getItem("FundName")
  const [file, setFile] = useState();
  const navigate = useNavigate();
  const [preBtnLoading, setPreBtnLoading] = useState(false);
  const [blankBtnLoading, setBlankBtnLoading] = useState(false);

  //#region change events
  const handleRqFileUpload = (name, file) => {
    if (file instanceof File) {
      setFile(file);
    } else {
      setFile(null)
    }
  };

  //#region click events
  const onPrefilledTemplateClick = () => {
    getOfflineDocuments(true);
  }

  const onBlankTemplateClick = () => {
    getOfflineDocuments(false);
  }

  const BackToMarketPlace = () => {
    navigate("/marketplace");
    setFile();
  };

  const onClearClick = () => {
    setPreBtnLoading(false);
    setBlankBtnLoading(false);
  }

  //#region api get calls
  const getOfflineDocuments = async (isPreFilled) => {
    if(isPreFilled){
      setPreBtnLoading(true);
    }
    else{
      setBlankBtnLoading(true);
    }
    try {
      const subscriptionDetails = {
        fundID: investorSubscriptionDetails?.fundID,
        fundTypeID: investorSubscriptionDetails?.fundBasicDetails?.fundTypeId,
        fundName: investorSubscriptionDetails?.fundBasicDetails?.fundName,
        investorID: investorSubscriptionDetails?.investorID,
        investorTypeID: investorSubscriptionDetails?.investorTypeID,
        InvestorName: secureLocalStorage.getItem("fullName")
      }
      const response = await downloadSubscriptionAgreement(subscriptionDetails,isPreFilled)
      if (response.responseCode === 200) {
        const contentDisposition = response.headers["content-disposition"];
        if (contentDisposition && contentDisposition.includes("attachment")) {
          console.warn("Server is forcing file download. Content-Disposition:", contentDisposition);
        }
        const fileBlob = new Blob([response.responseData], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = objectUrl;
        link.download = `${isPreFilled ? "PreFilled_Form" : "Blank_Form"}.docx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(objectUrl);
        onClearClick();
      } else {
        onClearClick();
        console.warn("File download failed with response code:", response.status);
      }
    } catch (error) {
      onClearClick();
      console.error("An error occurred while downloading the file:", error);
    }
  };  

  //#region api post calls
  const handleFormofflineSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('fileList', file);
    const requestedData = props?.investorSubscriptionDetails.questionBankAndSteps.userQuestionAndAnswers
      /* ?.filter((question) =>
        (question.userProvidedAnswer !== null && question.userProvidedAnswer !== "") ||
        (question.multiChoiceQuestionAnswerIDs !== null && question.multiChoiceQuestionAnswerIDs.length > 0) ||
        (question.questionAnswerID !== null && question.questionAnswerID !== 0)) */
      ?.map((item) => ({
        "UserProfileID": item?.subscriptionID === 0 ? 0 : item?.userProfileID,
        "QuestionBankID": item?.questionBankID,
        "QuestionName": item?.questionName,
        "UserID": investorId,
        "fundID": 0,
        "SubscriptionID": item?.subscriptionID || 0,
        "QuestionPatternTypeID": item?.questionPatternTypeID,
        "QuestionPatternType": item?.questionPatternType,
        "QuestionAnswerID": item?.questionAnswerID || 0,
        "UserProvidedAnswerinText": item?.userProvidedAnswer || "",
        "multiChoiceQuestionAnswerIDs": item?.multiChoiceQuestionAnswerIDs || [],
        "bookMarks" : item?.bookMarks
        /* "UploadedFile": item?.uploadedFile || '', */
      })) || [];

    formData.append('userProvidedAnswers', JSON.stringify(requestedData))
    const response = await submitOfflineSubscription(secureLocalStorage.getItem("FundId"), investorId, 0, true, formData);
    if (response.responseCode === 200) {
      toast.success("Offline Subscription successful.",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" })
      //setLoading(false);
      //props.getSubscriptionDetails()
      navigate("/marketplace");
    } else {
      toast.warning("Something Went Wrong , Please Try Again",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" })
      setLoading(false);
    }
  }

  //#region toast messages
  const PDFWarningMessage = () => {
    toast.warning("Invalid file type. Please upload a PDF file.", { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" })
  }

  //#region return
  return (
    <div>
      <Grid>
        <Grid container xs={12} md={12} spacing={2} marginTop="20px" >
          <Grid item xs={12} md={3.5}>
            <ActionButton
              component="label"
              variant="contained"
              className='btn-primary'
              label="Download prefilled form"
              disabled={blankBtnLoading}
              loading={preBtnLoading}
              onClick={onPrefilledTemplateClick}
              icon={<DownloadIcon />}>
            </ActionButton>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <ActionButton
              component="label"
              variant="contained"
              className='btn-primary'
              label="Download blank form"
              disabled={preBtnLoading}
              loading={blankBtnLoading}
              onClick={onBlankTemplateClick}
              icon={<DownloadIcon />}>
            </ActionButton>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <div>
              <FileUploadButton
                name="uploadSignedForm"
                label="Upload Signed Form"
                defaultFile={file}
                pdffileonly={true}
                PDFWarningMessage={() => PDFWarningMessage()}
                onChange={(name, value) => handleRqFileUpload(name, value)}
              />
            </div>

          </Grid>
        </Grid>
        <Grid container marginTop="20px">
          <div>
            <h1>Offline Subscription Instructions</h1>
            <p>The prefilled form will contain your personal, investment, and accreditation details as provided in the Subscription section of your investor profile.</p>

            <p>Before uploading your subscription agreement form, please ensure:</p>

            <ul>
              <li>The form is fully completed, and all required fields are signed and dated.</li>
              <li>Any additional supporting documentation is included, such as identity verification, accreditation evidence, etc. as required by the fund.</li>
              <li>You only upload the final PDF version of the signed form, not a blank template.</li>
            </ul>

            <p>The signed form and documents can be uploaded once completed. Your subscription will then be reviewed by the compliance team before the <br />investment process is finalized.</p>

            <p>Please reach out if you need any assistance with the offline subscription process. We are happy to help guide you through the requirements.</p>
          </div>
        </Grid>
        <Grid container marginTop="20px" justifyContent="flex-end">
          <ActionButton className='btn-primary' marginBottom="20px"
            component="label"
            variant="outlined"
            // style={{ width: '200px', backgroundColor: 'white', color: 'gray', marginRight: '10px' }}
            onClick={BackToMarketPlace}
            disabled={loading}
            icon={<KeyboardBackspaceIcon />}
            label="Back To MarketPlace"
          >
          </ActionButton>
          <div className='ml2 resp'>
            <ActionButton
              icon={<TouchAppIcon />}
              label='Submit'
              variant="contained"
              className='btn-primary'
              loading={loading}
              onClick={handleFormofflineSubmit}
              disabled={file != null ? false : true}
            />
          </div>
        </Grid>
      </Grid>
      <Grid>
        {/*  <Grid container sx={{ justifyContent: "flex-end" }} >
                      <a href="#" download >
                        <Button className='download-btn'
                          component="label"
                          variant="contained"
                        >
                           <DownloadIcon />
                              Download File
                        </Button>
                      </a>
                    </Grid> */}
      </Grid>
      <ToastContainer />
    </div>
  )
}

export default OfflineSubscriptionPage