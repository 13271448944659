import React, { useState , useEffect} from 'react'
import './NewOnboardingques/styles.css'
import { useNavigate } from 'react-router-dom';
import TopBarObq from './NewOnboardingques/TopBarObq';
import { checkUserNameExcists, updateUserNamewithInvestorTypes } from '../services/RegistrationProfilingService';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import ActionButton from '../../common/ActionButton/ActionButton';
import { getInvestorsTypes } from '../../InvestorRelations/Services/IRServices';
import { getSubInvestorsTypes } from '../../InvestorRelations/Services/IRServices';
import { InvestorTypes } from '../../../utils/enum';
import { InvestorTypesEnum } from '../../user-profiles/services/apiDataTemplate';
import { getrootBackgroundColorConfig } from '../../../state/slices/colorConfigSlice';
import { useSelector } from 'react-redux';
import SaveIcon from '@mui/icons-material/Save';
import { addCustomYupMethods } from '../../common/input-fields/utlis/CustomYupValidations';
import SelectField from '../../common/input-fields/SelectField';
import ALTTextField from '../../common/input-fields/ALTTextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { validationsConstants } from '../../../utils/AppConstants';

const UserName = () => {

  //#region imported values
  const navigate = useNavigate();

  const isinitailInvestorType = secureLocalStorage.getItem('investorTypeID')?? 0;
  const userNameFromADB2C = secureLocalStorage.getItem("userName") ?? '';
  const initialSubType = secureLocalStorage.getItem("investorSubType") ?? 0;
  const investorTypeID = secureLocalStorage.getItem("investorTypeID") ?? 0;
  const firstName = secureLocalStorage.getItem("firstName") ?? "";
  const lastName = secureLocalStorage.getItem("lastName") ?? "";
 /*  const currInvestorType = secureLocalStorage.getItem('investorType'); */

  //#region initialData
  const initialData = {
    userName : userNameFromADB2C,
    investortypeID: investorTypeID,
    subInvestortypeID: initialSubType,
    firstName: firstName,
    legalEnityName: firstName,
    lastName: lastName
  }

  //#region variables
  const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
  const [nonsubTypeDetails,setNonsubTypeDetails] = useState(null);
  const [isnosubType,setNoSubTypeRequired] = useState(true);
  const [btnloading, setLoading] = useState(false);
  const [investorTypes,setInvestorTypes] = useState(null);
  const [subInvestorTypes,setSubInvestorTypes] = useState(null);

  //#region functions
  const currentInvestorType = () => {
    return investorTypes
            ?.find(item => item?.listItemID === userData.values.investortypeID)?.listItemValue
  }

  const resetIndiviudalValues = () => {
    userData.setFieldValue("firstName", "");
    userData.setFieldValue("lastName", "");
    userData.setFieldTouched("firstName", false);
    userData.setFieldTouched("lastName", false);
    userData.setErrors((prevErrors) => ({
      ...prevErrors,
      firstName: undefined,
      lastName: undefined,
    }));
  }

  const resetNonIndividualValues = () => {
    userData.setFieldValue("legalEnityName", "");
    userData.setFieldValue("subInvestortypeID", 0);
    userData.setFieldTouched("legalEnityName", false);
    userData.setFieldTouched("subInvestortypeID", false);
    userData.setErrors((prevErrors) => ({
      ...prevErrors,
      legalEnityName: undefined,
      subInvestortypeID: undefined,
    }));
  }

  //#region change events
  const handleChange = (field, value) => {
    if(field == 'investortypeID')
    {
      const investorType = investorTypes?.find(item => item.listItemID == value)?.listItemValue;
      secureLocalStorage.setItem('investorType', investorType);
      if(investorType === InvestorTypesEnum.INDIVIDUAL_INVESTOR){
        resetNonIndividualValues();
      }
      else{
        resetIndiviudalValues();
      }
      //for subtype
      if(nonsubTypeDetails?.find(item => item.listItemID === value)){
        setNoSubTypeRequired(true);
        userData.setFieldValue(field, value);
        userData.setFieldValue("subInvestortypeID", "");
      }
      else{
        setNoSubTypeRequired(false);
        userData.setFieldValue(field, value);
      }
      getSubInvestorsType(value);
    }
    else{
      userData.setFieldValue(field, value);
    }
  }

  //#region click events
  const onSaveClick = () => {
    checkUserName();
  }

  //#region api get calls
  const getInvestorsType = async () => {
    const data = await getInvestorsTypes('InvestorType');
    if (data?.responseCode === 200) {
      setInvestorTypes(data?.responseData);
      const nonsubInvetsortypes = data?.responseData?.filter(item => item.listItemValue === InvestorTypes.INDIVIDUAL_INVESTOR);
      setNonsubTypeDetails(nonsubInvetsortypes);
      if(isinitailInvestorType)
      {
        userData["investortypeID"] = isinitailInvestorType;
        //filter for individual investor
         
        if(nonsubInvetsortypes.find(item => item.listItemID === isinitailInvestorType))
        {
          setNoSubTypeRequired(true);
        }
        else{
          setNoSubTypeRequired(false);
        }
        getSubInvestorsType(isinitailInvestorType);
      }
      else{
        setNoSubTypeRequired(false);
      }
    } else {
      // Handle error if needed
    }
  }

  const getSubInvestorsType = async (typeID) => {
    const data = await getSubInvestorsTypes(typeID);
    if (data?.responseCode === 200) {
      setSubInvestorTypes(data?.responseData);
      userData.setFieldValue("subInvestortypeID", 0)
    } else {
      // Handle error if needed
    }
  }

  //#region api update calls
  const updateUserNameWithInvestor = async () => {
    debugger
    const isbulkUploadUser = secureLocalStorage.getItem('IsBulkUploadUser'); 
    const requestedBody = {
      "userId": secureLocalStorage.getItem("userId"),
      "userFirstName": currentInvestorType() === InvestorTypesEnum.INDIVIDUAL_INVESTOR ? userData.values.firstName : userData.values.legalEnityName,
      "userLastName": userData.values.lastName,
      "userEmailAddress": secureLocalStorage.getItem("userEmail"),
      "userName": userData.values.userName,
      "investorTypeID": userData.values.investortypeID,
      "investorSubTypeID": userData.values.subInvestortypeID || 0
    }
    const data = await updateUserNamewithInvestorTypes(requestedBody);
    if (data?.responseCode == 200) {
        if(isbulkUploadUser)
        {
          navigate('/bulk-upload-invite');
        }
        else{
          const investorTypeName = investorTypes?.find(item => item.listItemID == userData.values.investortypeID)?.listItemValue;
          secureLocalStorage.setItem('investorType',investorTypeName);
          secureLocalStorage.setItem('investorTypeID',userData.values.investortypeID)
          secureLocalStorage.setItem('investorSubType',userData.values.subInvestortypeID)
          secureLocalStorage.setItem("userName",userData.values.userName)
          navigate('/onboarding-process');
        }
        setLoading(false);
    } else {
      toast.warning("Username already exists",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setLoading(false);
    }
  }

  const checkUserName = async () => {
    setLoading(true);
    if(userNameFromADB2C){
      updateUserNameWithInvestor();
    }
    else{
      const userCheck = await checkUserNameExcists(userData.values.userName);
      if (userCheck?.responseCode == 200) {
        toast.warning("Username already exists",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        setLoading(false);
      } 
      else {
        updateUserNameWithInvestor();
      }
    }
  }
  
  //#region formik validations
  addCustomYupMethods()

  const investorValidationSchema = yup.object().shape({
    userName: yup.string()
        .required(validationsConstants.REQUIRED)
        .customUserName(validationsConstants.USER_NAME),
    firstName: yup.string() 
        .when([], (investortypeID, schema) => {
            if (currentInvestorType() === InvestorTypesEnum.INDIVIDUAL_INVESTOR) {
                return schema.required(validationsConstants.REQUIRED)
                             .matches(/^[A-Za-z\s]+$/, validationsConstants.ONLY_ALPHABETS);
            }
            return schema;
        }),
    lastName: yup.string() 
        .when([], (investortypeID, schema) => {
            if (currentInvestorType() === InvestorTypesEnum.INDIVIDUAL_INVESTOR) {
                return schema.required(validationsConstants.REQUIRED)
                             .matches(/^[A-Za-z\s]+$/, validationsConstants.ONLY_ALPHABETS);
            }
            return schema;
        }),
    legalEnityName: yup.string() 
        .when([], (investortypeID, schema) => {
            if (currentInvestorType() !== InvestorTypesEnum.INDIVIDUAL_INVESTOR) {
                return schema.required(validationsConstants.REQUIRED);
            }
            return schema;
        }),
    investortypeID: yup
        .number()
        .min(1, validationsConstants.REQUIRED)
        .required(validationsConstants.REQUIRED),
    subInvestortypeID: yup.mixed()
        .when([], (investortypeID, schema) => {
            if (currentInvestorType() !== InvestorTypesEnum.INDIVIDUAL_INVESTOR) {
                return yup.number()
                    .min(1, validationsConstants.REQUIRED)
                    .required(validationsConstants.REQUIRED);
            }
            return schema;
        }),
   });

 

  const userData = useFormik({
    initialValues: initialData,
    validationSchema: investorValidationSchema,
    onSubmit: (values) => {
      onSaveClick()
    },
  });

  //#region useEffect
  useEffect(() => {
    getInvestorsType();
  },[]);
  
  //#region return
  return (
       <div style={{ backgroundColor: rootBackColorConfig.color }} className='investor-onboardbg'>
          <TopBarObq position="sticky" hideMenuButton={true}/>
          <div  className='centerFlex'>
              <div className='obqquestionName'>
                <h1 className='welcome-msg'>
                    Welcome. Lets get started !
                </h1>
                <div className='welcome-desc'>
                We just need a little information, so that We can customize your experience.
                </div>
              </div>
              <div className='obqoptions child-margin-15'>
                 <div>
                    <ALTTextField
                        name="userName"
                        label="User Name"
                        value={userData.values.userName}
                        onChange={(name, value) => handleChange(name, value)}
                        required={true}
                        onBlur={userData.handleBlur}
                        error={userData.touched.userName && Boolean(userData.errors.userName)}
                        errorMessage={userData.touched.userName && userData.errors.userName} />
                 </div>
                 <div>
                    <SelectField
                        name="investortypeID"
                        label="Investor Type"
                        value={userData.values.investortypeID}
                        onChange={(name, value) => handleChange(name, value)}
                        options={investorTypes?.map(option => ({ label: option?.listItemValue, value: option?.listItemID }))}
                        required={true}
                        onBlur={userData.handleBlur}
                        error={userData.touched.investortypeID && Boolean(userData.errors.investortypeID)}
                        errorMessage={userData.touched.investortypeID && userData.errors.investortypeID} />
                 </div>
                 {
                  userData.values.investortypeID > 0 &&
                  currentInvestorType() === InvestorTypesEnum.INDIVIDUAL_INVESTOR ? 
                    <>
                      <div>
                        <ALTTextField
                            name="firstName"
                            label="First Name"
                            value={userData.values.firstName}
                            onChange={(name, value) => handleChange(name, value)}
                            required={true}
                            onBlur={userData.handleBlur}
                            error={userData.touched.firstName && Boolean(userData.errors.firstName)}
                            errorMessage={userData.touched.firstName && userData.errors.firstName} />
                      </div>
                      <div>
                      <ALTTextField
                          name="lastName"
                          label="Last Name"
                          value={userData.values.lastName}
                          onChange={(name, value) => handleChange(name, value)}
                          required={true}
                          onBlur={userData.handleBlur}
                          error={userData.touched.lastName && Boolean(userData.errors.lastName)}
                          errorMessage={userData.touched.lastName && userData.errors.lastName} />
                      </div>
                    </> : 
                    <>
                      <div>
                      <SelectField
                          name="subInvestortypeID"
                          label="Sub Investor Type"
                          value={userData.values.subInvestortypeID}
                          onChange={(name, value) => handleChange(name, value)}
                          options={subInvestorTypes?.map(option => ({ label: option?.listItemValue, value: option?.listItemID }))}
                          required={true}
                          onBlur={userData.handleBlur}
                          error={userData.touched.subInvestortypeID && Boolean(userData.errors.subInvestortypeID)}
                          errorMessage={userData.touched.subInvestortypeID && userData.errors.subInvestortypeID} />
                      </div>
                      <div>
                          <ALTTextField
                              name="legalEnityName"
                              label="Legal Entity Name"
                              value={userData.values.legalEnityName}
                              onChange={(name, value) => handleChange(name, value)}
                              required={true}
                              onBlur={userData.handleBlur}
                              error={userData.touched.legalEnityName && Boolean(userData.errors.legalEnityName)}
                              errorMessage={userData.touched.legalEnityName && userData.errors.legalEnityName} />
                      </div>
                    </>
                 }
                 
              </div>
              <div className="obqquestionName">
                      <ActionButton
                        component="label"
                        variant="contained"
                        label="Save"
                        icon={<SaveIcon />}
                        styleProps={{ 
                          padding: '8px 36px' 
                        }} 
                        loading={btnloading}
                        onClick={userData.handleSubmit}/>
              </div>
          </div>  
          <ToastContainer />
        </div>
  )
}

export default UserName