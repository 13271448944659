import { useState } from "react";
import FundPerformance from "../../common/FundPerformance/FundPerformance";

const PerformanceTab = (props) => {

  //#region props
  const { fundDetails } = props;
  
  //#region return
  return (
    <>
    <div className=" mt3">
      <h4>Performance</h4>
      <div className="whiteCard mt2">
        <FundPerformance fundTypeName={fundDetails?.fundTypeName} />
      </div>
    </div>
    </>
  );
};

export default PerformanceTab;
