import React from 'react'
import '../../../Generalize.css';
import { useState } from 'react';
import { useEffect } from 'react';
import '../../../common/NestedList/styles.css'
import 'react-toastify/dist/ReactToastify.css';
import secureLocalStorage from 'react-secure-storage';
import ActionButton from '../../../common/ActionButton/ActionButton';
import { AppDashboards } from '../../../../utils/enum';
import Accordian from './Accordian';

const AccordiansModal = (props) => {

  //#region variables
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [arraydata, setArrayData] = useState(props.data);
  const isFundMatured = secureLocalStorage.getItem("selectedFund")?.FundMaturity;
  const isFundTerminated = secureLocalStorage.getItem("selectedFund")?.FundTermination;

  //#region click events
  const handleCheckboxChange = (index) => {
    setSelectedIndex(index); // Update the selected index for the checkbox
  }

  const addMember = (arraydata) => {
    if (props.name == 'managementteams') {
      if (arraydata === null || arraydata === undefined || arraydata.length === 0) {

        setArrayData([
          {
            "fundManagementTeamID": 0,
            "fundID": 0,
            "firstName": "",
            "lastName": "",
            "description": "",
            "title": "",
            "emailAddress": "",
            "addressLine1": "",
            "addressLine2": "",
            "phoneNumber": "",
            "city": "",
            "state": 0,
            "country": 0,
            "zipCode": "",
            "managementTeamStaff": 0,
            "authorizedSignatory": false
          }
        ]);
      } else if (arraydata.length > 0) {
        setArrayData(prevTeamMembers => [
          ...prevTeamMembers,
          {
            "fundManagementTeamID": 0,
            "fundID": 0,
            "firstName": "",
            "lastName": "",
            "description": "",
            "title": "",
            "emailAddress": "",
            "addressLine1": "",
            "addressLine2": "",
            "phoneNumber": "",
            "city": "",
            "state": 0,
            "country": 0,
            "zipCode": "",
            "managementTeamStaff": 0,
            "authorizedSignatory": false
          }
        ]);
      }

    }
    else if (props.name == 'feesandexpenses') {
      if (arraydata === null || arraydata.length === 0) {
        setArrayData([
          {
            "id": 0,
            "fundID": 0,
            "managementFees": '',
            "performanceFeesOrCarriedInterest": '',
            "additionalFeesAndExpenses": ''
          }
        ]);
      }
      else if (arraydata.length > 0) {
        setArrayData(prevTeamMembers => [
          ...prevTeamMembers,
          {
            "id": 0,
            "fundID": 0,
            "managementFees": '',
            "performanceFeesOrCarriedInterest": '',
            "additionalFeesAndExpenses": ''
          }
        ]);
      }
    }
  }

  //#region useeffect
  useEffect(() => {
    return () => {
      props.updateData(arraydata);
    }
  }, [arraydata]);

  //#region return
  return (
    <div>
      <div className="fundRow no-margin" style={{ marginBottom: '10px', marginRight: '10px' }}>
        <div>

        </div>
        <div>
          {!isFundTerminated && !isFundMatured ?

            (<ActionButton
              variant="outlined"
              label={props.ButtoonLabel}
              startIconName="ControlPointOutlined"
              onClick={() => addMember(arraydata)}
              styleProps={{
                borderColor: '#4287f5 !important',
                color: '#4287f5 !important',
                fontSize: '14px',
              }} />) :

            (props.name == 'managementteams' ?
              <div>
                <div>

                </div>
                <div>
                  <ActionButton
                    variant="outlined"
                    label={props.ButtoonLabel}
                    startIconName="ControlPointOutlined"
                    onClick={() => addMember(arraydata)}
                    styleProps={{
                      borderColor: '#4287f5 !important',
                      color: '#4287f5 !important',
                      fontSize: '14px',
                    }} />
                </div>
              </div> :
              null)
          }

        </div>
      </div>
      {
        (arraydata && arraydata.length > 0) &&
        <Accordian
          funddata={props.fundData}
          arraydata={arraydata}
          updateData={props.updateData}
          componentName={props.name}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          handleCheckboxChange={handleCheckboxChange}
          Component={props.Component}
          getFundDetails={props.getFundDetails}
          setArrayData={setArrayData}
          managementStaffData={props.managementStaffData?.filter(a => a.userRoleName !== AppDashboards.INV_DASHBOARD)}
          {...props}
        />
      }
    </div>
  )
}

export default AccordiansModal