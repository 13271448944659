import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Configurations from '../../../Configurations';
import mammoth from 'mammoth';
import { questionPatterns } from '../services/apiDataTemplate';
import { convertArrayToString } from '../../common/Functions/ConvertStringtoArray';
import secureLocalStorage from 'react-secure-storage';

const PreviewFilePopup = (props) => {

  //#region props
  const { questionsDetails, investorId, investorSubscriptionDetails } = props;

  //#rgeion variables
  const [htmlContent, setHtmlContent] = useState(null);

  //#region api post calls
  const getSubscriptionAgreement = async () => {

    const requestedData = questionsDetails
      ?.map((item) => ({
        "UserProfileID": item?.subscriptionID === 0 ? 0 : item?.userProfileID,
        "QuestionBankID": item?.questionBankID,
        "QuestionName": item?.questionName,
        "UserID": investorId,
        "fundID": 0,
        "SubscriptionID": item?.subscriptionID || 0,
        "QuestionPatternTypeID": item?.questionPatternTypeID,
        "QuestionPatternType": item?.questionPatternType,
        "QuestionAnswerID": item?.questionAnswerID || 0,
        "UserProvidedAnswerinText": item.questionPatternType === questionPatterns.FILE_UPLOAD
          ? item?.userProvidedAnswer instanceof Blob || item?.userProvidedAnswer instanceof File
            ? item?.userProvidedAnswer?.name : item?.userProvidedAnswer
          : item.questionPatternType === questionPatterns.MULTI_CHOICE_QUESTION ? convertArrayToString(item?.multiChoiceQuestionAnswerIDs)
            : item.questionPatternType === questionPatterns.GRID
              ? (JSON.stringify(item?.userProvidedAnswer) || "")
              : item?.userProvidedAnswer || "",
        "multiChoiceQuestionAnswerIDs": item?.multiChoiceQuestionAnswerIDs || [],
        "bookmarks": item?.bookmarks,
        "showInInvestProfile": item?.showInInvestProfile,
      })) || [];

    const subscriptionDetails = {
      fundID: investorSubscriptionDetails?.fundID,
      fundTypeID: investorSubscriptionDetails?.fundBasicDetails?.fundTypeId,
      fundName: investorSubscriptionDetails?.fundBasicDetails?.fundName,
      investorID: investorSubscriptionDetails?.investorID,
      investorTypeID: investorSubscriptionDetails?.investorTypeID,
      investorName: secureLocalStorage.getItem("fullName")
    }
    const response = await axios.post(`${Configurations.apiBaseUri}/v1/FundSubscription/PreviewSubscriptionAgreement?fundID=${subscriptionDetails?.fundID}&fundTypeID=${subscriptionDetails?.fundTypeID}&fundName=${subscriptionDetails?.fundName}&investorID=${investorId}&investorTypeID=${subscriptionDetails?.investorTypeID}&InvestorName=${subscriptionDetails?.investorName}`,
      requestedData, {
      responseType: 'blob', // Receive the file as a Blob
    });

    if (response.status === 200) {
      const arrayBuffer = await response.data;
      mammoth.convertToHtml({ arrayBuffer })
        .then((result) => {

          var htmlString
            = result.value
              .replace("/" + subscriptionDetails.fundName + "/", subscriptionDetails.fundName)
              .replace("/" + subscriptionDetails.investorName + "/", subscriptionDetails.investorName)
              .replace("/bm-lp-subscriptionaccepteddate/", new Date("MM-dd-yyyy"))
              .replace("/bm-gp-subscriptionaccepteddate/", new Date("MM-dd-yyyy"))
              .replace("/InvestorSign/", "")
              .replace("/FMSign/", "")
              .replace("/bmFundManagerName/", "");

          requestedData?.forEach((question) => {
            if (question?.bookmark !== "") {
              htmlString = htmlString.replace(question?.bookmarks, "");
            }
          });

          setHtmlContent(htmlString); // The generated HTML
        })
        .catch((err) => {
          setHtmlContent('');
          console.error("Error converting docx to HTML:", err);
        });
    } else {
      setHtmlContent('');
      console.warn('File download failed with response code:', response.status);
    }
  };

  //#region useeffect
  useEffect(() => {
    getSubscriptionAgreement();
  }, []);

  //#region return
  return (
    <div>
      {
        htmlContent === null ? <div>Please wait while we are generating the preview...</div> :
          htmlContent === "" ? <div>unable to load the document</div> :
            <iframe
              srcDoc={htmlContent}
              style={{ width: '100%', height: '350px' }}
            />
      }
    </div>
  );
};

export default PreviewFilePopup;
