import React, { useState } from 'react';
import { Box, Grid, Card, CardContent, CardActionArea, Typography } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import './HelpPage.css'
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import VideoPopup from './VideoPopup'; 
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
const HelpAndSupportBody = (props) => {

    //#region props
    const { setActiveBodySection } = props

    //#region variables
    const [currVideo,setCurrVideo] = useState({
        url: '',
        title: '' 
    });
    const [openPopup, setOpenPopup] = useState(false);

    //#region styles
    const iconStyle = {
        height: "24px",
        width: "24px"
    }

    //#region variables
    const informationCards = [
        {
            title: 'FAQs',
            url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/FAQsHelp.jpg',
            // poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail1.png',
            componentTxt: 'faq'
        },
        {
            title: 'Video tutorials',
            /* url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Video2.mp4', */
            url: '/Alt360Learning.jpg',
            componentTxt: 'videos'
        },
        {
            title: 'Articles',
            url: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Article.png',
            // poster: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Thumbnails/Thumbnail3.png',
            isVideo: false,
            componentTxt: 'articles'
        },
    ];
    
    const flowcharts = [
        {
            id: 1,
            icon: <HowToRegIcon sx={iconStyle} />,
            title: 'How to register as an Investor',
            description: [
                "To register as an investor on Alt360, follow these steps:",
                "Navigate to Alt360: Visit Alt360's website or application.",
                "Click on Signup: Look for the Signup option and click on it.",
                "Fill in Information: Enter your details.",
                "Click on Signup: Complete the registration process by clicking on Signup with email.",
                "Receive Email: You will receive a confirmation email with Account Activation link."
            ],
            linkText: 'Sign Up Now ➔',
            videoUrl: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-InvestorRegistrationProcess.mp4'
        },
        {
            id: 2,
            icon: <MonetizationOnIcon sx={iconStyle} />,
            title: 'How to create a fund',
            description: [
                "Creating a fund is simple and quick. Just follow these steps:",
                "Log in to your account.",
                'Go to the "My Funds" section and click the "Add a Fund" button.',
                'Fill in the necessary details and submit your fund.',
                "Click here to create a fund now."
            ],
            linkText: 'Create a fund ➔',
            videoUrl: 'https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-AddFund.mp4'
        },
        {
            id: 3,
            icon: <HourglassEmptyIcon sx={iconStyle} />,
            title: 'How to Subscribe a fund',
            description: [
                "To subscribe to a fund as an investor on Alt360, follow these steps:",
                "Login: Log in to your Alt360 investor account.",
                'Navigate to Marketplace: Go to the Marketplace or similar section where available funds are listed.',
                'Click on Subscribe: Find the fund you\'re interested in and click on the "Subscribe" button.',
                'Fill in Information: Enter the required information such as the amount you wish to invest.',
                'Submit: Review your subscription details and click "Submit".',
                'E-Sign: Complete the subscription process by electronically signing if required.'
            ],
            linkText: 'Subscribe a Fund ➔',
            videoUrl: "https://alt360beta.blob.core.windows.net/fusionfoundryassets/Videos/Alt360-SubscriptionProcess.mp4"
        },
    ];

    //#region click events
    const handleClick = (navigate) => {
        setActiveBodySection(navigate)
    }

    const handleVideoClick = (flowchart) => {
        setCurrVideo({
            url: flowchart.videoUrl,
            title: flowchart.title 
        });
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
        setCurrVideo({
            url: '',
            title: '' 
        });
      };
    
    

    //#region return
    return (
        <Box className="helpSupportbody">
            <h4>New to Alt360? Start here. </h4>
            <Grid container spacing={3}>
                {informationCards.map((video, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={index} className='articleVedio'>
                        <Card>
                            <CardActionArea
                                onClick={() => handleClick(video.componentTxt)}
                            >
                                <Box className="videoSec">
                                <img
                                    alt=""
                                    src={video.url}
                                   />
                                </Box>
                            </CardActionArea>
                        </Card>
                        <h6 className='videotitle' onClick={() => handleClick(video.componentTxt)}>{video.title}</h6>
                    </Grid>
                ))}
            </Grid>
            <Box className="howitwork-sec">
                <h4>Here’s how it works</h4>
                <Grid container spacing={3}>
                    {flowcharts.map((flowchart) => (
                        <Grid item xs={12} sm={4} md={4} key={flowchart.id}>
                            <Card className='hiw-bg-color'>
                                <CardContent className='hiw-content'>
                                    <Box className="hiw-box">
                                        {/* <Box className='hiw-icon'>{flowchart.icon}</Box> */}
                                        <Box xs={12} sm={12} md={4} lg={4}>
                                            <h6 className='child-row-margin-5 align-item-center'>
                                                <div className='help-support-icon'>{flowchart.icon}</div>
                                                <b>{flowchart.title}</b> 
                                            </h6>
                                            <ul className='hiw-ul'>
                                                {flowchart.description.map((step, index) => (
                                                    <li key={index}>
                                                       <ArrowRightAltIcon/>  <Typography variant="body1">{step}</Typography>
                                                    </li>
                                                ))}
                                            </ul>
                                            <Typography variant="body2">
                                            <span 
                                                onClick={() => handleVideoClick(flowchart)} 
                                                className='getstartlink'>
                                                {flowchart.linkText}
                                                </span>

                                            </Typography>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
             {
                openPopup &&
                <VideoPopup 
                    open={openPopup} 
                    onClose={handleClosePopup} 
                    currVideo={currVideo} 
                />
            } 
        </Box>
    );
}

export default HelpAndSupportBody;
