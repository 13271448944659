import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';

const OfflineConfirmationDialog = ({
  open,
  onClose,
  steps,
  setDisableStepsFrom,
  questionDetais,
  popupopen,
  setCurrStepDetails,
  submitSubscriptionAnswers
}) => {

  const openFirstStepWithEmptyAnswer = () => {
    let firstStepToShow = 0;
    const firstIncompleteStep = questionDetais
      ?.filter(step => step.isActive && step.setValidations && step.userProvidedAnswer === '' && step.showIfParentAnswerIDIsIn === '' && step.questionAnswer.length === 0)
      .sort((a, b) => a.step - b.step);

    if (firstIncompleteStep !== null && firstIncompleteStep.length > 0) {
      firstStepToShow = firstIncompleteStep[0].step;
      if (firstStepToShow > 0) {
        onClose();
        setCurrStepDetails(steps.find(u => u.stepNumber === firstStepToShow));
      }
    }
    popupopen(true);
  };

  const onConfirm = () => {
    submitSubscriptionAnswers(questionDetais)
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: { borderRadius: 12, padding: '20px 24px' },
      }}
    >
      <DialogTitle id="confirmation-dialog-title" style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '8px' }}>
       <h6>  <InfoIcon className='info-i' /> Confirmation </h6>
       
       
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          style={{ padding: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography id="confirmation-dialog-description">
          Incomplete form fields, Do you want to proceed?
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between', }}>
        <Button
        className='btn-primary'
          variant="outlined"
          onClick={openFirstStepWithEmptyAnswer}
        >
         <VisibilityIcon/> VIEW DETAILS
        </Button>
        <div>
          <Button
            className='btn-primary'
            variant="outlined"
            onClick={onClose}
            style={{ fontWeight: 'bold', textTransform: 'none', marginRight: '8px' }}
          >
           <RotateLeftIcon/> NO
          </Button>
          <Button
            className='btn-primary'
            variant="contained"
            onClick={onConfirm}
         
          //style={{ fontWeight: 'bold', textTransform: 'none', minWidth: '80px' }}
          >
           <CheckCircleIcon/> YES
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default OfflineConfirmationDialog;
