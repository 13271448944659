import React from 'react'
import "../Leftnav.css"
import FilterBox from './FilterBox';
import { useState } from 'react';
import ListView from './ListView';
// import NavigationBar from '../../common/navigation-bar/NavigationBar'
import { useNavigate } from "react-router-dom";
import FundView from './FundView';
import CircleIcon from '../../chatbot/Icon';
import { getHeaderColorConfig } from '../../../state/slices/colorConfigSlice';
import { useSelector } from 'react-redux';
import styles from "../../user-profile/FundManagerUserProfile.module.css"
import MiniDrawer from '../../common/navigation-bar/NavBarGrid'
import { HeaderTemplates, PageHeader } from '../../common/PageHeader/PageHeader';
import { getrootBackgroundColorConfig } from '../../../state/slices/colorConfigSlice';


const MarketplaceDescription="Connecting investors with a wide range of non-traditional investment opportunities, while providing a transparent and accessible environment for investors to discover, evaluate, and participate in alternative investment options beyond the traditional stock and bond markets." 

const InvestmentPlace = () => {
	const [isExpanded, setExpendState] = useState(false);
	const [Menu, setMenu] = useState(true);
	const [searchText, setSearchText] = useState('');
	const navigate = useNavigate();
	const headerColorConfig = useSelector(getHeaderColorConfig);

	const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);

	const [filters, setFilters] = useState({
		fundTypes: [],
		industries: [],
		strategies: [],
		investmentAmount: { min: "", max: "" },
		termDuration: { min: "", max: "" },
		fundManagers: [],
		sorting: "",
	});
	const navigateto = useNavigate();
	const InvestorOverview = (itemId) => {
	  navigateto('/Investor-Overview-Page', { state: { FundId: { itemId } } });
	  window.scrollTo(0, 0);
	};

	const handleFilter = (filters) => {
		setFilters(filters);
	};

	const onTextChange = (data) => {
		if (data.length >= 5) {
			setMenu(false);
		}
		setSearchText(data);
	}
	return (
		<div className='mt-page'>
			
				
			{/* {console.log(secureLocalStorage.getItem("currentpage"))} */}
		
			{/* <NavigationBar isExpanded={isExpanded} setExpendState={setExpendState} onTextChange={onTextChange} currentpage={2}/> */}
			<CircleIcon/>
			{/*Below top button where it displayes perecentage investment marketplace*/}
			<PageHeader
			primaryTitle=" Investment Marketplace"
			description={MarketplaceDescription}
			 template={HeaderTemplates.BASIC}/>
			<FilterBox isExpanded={isExpanded} Menu={Menu} setMenu={setMenu}
				onFilterChange={handleFilter} />
            <div style={{backgroundColor: rootBackColorConfig.color}}>

                  {/* <div className={styles.fundStatusHeaderWrapper}>
            
                    </div> */}
                    <div style={{position: "relative"}}>

				{Menu ? (
					<FundView isExpanded={isExpanded} filters={filters} searchData={searchText} />
				) : (
					<ListView isExpanded={isExpanded} setExpendState={setExpendState} filters={filters} searchData={searchText} navigateto={"/Investor-Overview-Page"}/>
				)}
			</div>
		</div>
		</div>
		

	);
};

export default InvestmentPlace;