import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFavouriteFunds } from '../../../../state/slices/MarketPlaceSlice';
import FundBoxView from './FundBoxView';
import { getHeaderColorConfig, getrootBackgroundColorConfig } from '../../../../state/slices/colorConfigSlice';
import { getAllFunds } from '../../../../state/slices/MarketPlaceSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { FundAddTypes, MarketPlacePagesEnum } from '../../mpenum';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { getFavoriteFunds, getFundSubscriptions } from '../../services/mpservices';
import secureLocalStorage from 'react-secure-storage';
import CircleIcon from '../../../chatbot/Icon';
import { HeaderTemplates, PageHeader } from '../../../common/PageHeader/PageHeader';
import FundCardView from '../FundCardView';

const FavouriteFunds = (props) => {

  //#region props
  const { handlePageChange } = props;

  //#region redux variables
  const navigate = useNavigate();
  // const favouriteFunds = useSelector(getFavouriteFunds);
  const [favouriteFunds, setfavouritefunds] = useState(null);
  const location = useLocation();
  const { state } = location;

  //#region click events
  const handleAddClick = () =>{
      navigate('/marketplace')
  }

  const handleBackClick = () =>{
    handlePageChange(MarketPlacePagesEnum.MAIN)
  }

  const fetchDataforfavfunds = async () => {
    const data = await getFavoriteFunds();
    if(data.responseCode === 200){
      const favouriteFundsData = data.responseData.map(fund => ({
        ...fund,
        favourite: true
      }));

      setfavouritefunds(favouriteFundsData);
    }
    else{

    }
  };

  useEffect(()=>{
      fetchDataforfavfunds();
    },[])

  return (
    <div>
        <PageHeader
          title="Favorite Funds"
          template={HeaderTemplates.NORMAL_VIEW}
          isNavigate={handleBackClick} />
  
    <div className='wrapper mt2'>
       
        <div className='mp-wrapper'>
            {
              favouriteFunds?.length > 0 && favouriteFunds?.map((item,index)=>(
                <div key={index}>
                    <FundCardView fundDetails={item} type={FundAddTypes.FAVOURITE} fetchDataforfavfunds={fetchDataforfavfunds} {...props} />
                </div>
              ))
            }
            <div className='add-box cursor-pointer' onClick={handleBackClick}>
               <div className='add-box2 cursor-pointer'>
                  <div className='add-icon-center'>
                       <ControlPointIcon color="action"/>
                  </div>
               </div>
            </div>
       </div>
       <CircleIcon/>
    </div>
    </div>
  )
}

export default FavouriteFunds