import axiosApiCall from "../../Services/axiosApiCall";
import { getAPIResponse } from "../../../utils/common";
import secureLocalStorage from "react-secure-storage";

export async function getFundbyId(fundID) {
    return await axiosApiCall.get(`/v1/Fund/GetFundById?fundID=${fundID}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        })
};

export async function getUserQuestionBank(investorId, questionType) {
    if(investorId != undefined){
    return await axiosApiCall.get(`/v1/FundSubscription/getUserQuestionBank?investorID=${investorId}&questionType=${questionType}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        })
    }
};

export async function getQuestioinBank(investorId,questionsTypeId,investorTypeId) {
    return await axiosApiCall.get(`/v1/QuestionBank/GetQuestioinBank?investorTypeID=${investorTypeId}&fundTypeID=0&questionTypeID=${questionsTypeId}&fundID=0&investorID=${investorId}&subscriptionID=0`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        })
};

export async function getFundSubscriptionDetails(fundID, investorId, subscriptionId) {
    return await axiosApiCall.get(`/v1/FundSubscription/Get?fundid=${fundID}&investorID=${investorId}&subscriptionID=${subscriptionId}`)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        })
};

export async function saveSubscriptionAnswers(formBody) {
    return await axiosApiCall.post(`/v1/FundSubscription/SaveSubscriptionAnswers`, formBody)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        })
};

export async function SubscribeFund(fundID, fundTypeId, investorID, subscriptionID, capitalCommitted, isOffListSubscription, submitAndESign,investorTypeID, formBody) {
    return await axiosApiCall.post(`/v1/FundSubscription/Subscribe?fundid=${fundID}&fundTypeID=${fundTypeId}&investorID=${investorID}&subscriptionID=${subscriptionID}&capitalCommitted=${capitalCommitted}&isOffLineSubscription=${isOffListSubscription}&submitSubscription=${submitAndESign}&investorTypeID=${investorTypeID}`, formBody)
        .then(res => {
            return getAPIResponse(res);
        }).catch(error => {
            return getAPIResponse(error);
        })
};

export async function downloadOfflineSubscriptionForm(subscriptionID) {
    return await axiosApiCall.get(`/v1/FundSubscription/DownloadOfflineSubscription?subscriptionID=${subscriptionID}`, {
        responseType: 'blob',
    })
    .then(res => {
        return getAPIResponse(res)
    }).catch(error => {
        return getAPIResponse(error)
    })
}

export async function downloadsubscriptiontemplate(fundid,investorid,isprefilledform) {
    return await axiosApiCall.get(`/v1/FundSubscription/DownloadSubscriptionTemplate?FundID=${fundid}&InvestorID=${investorid}&IsPrefilledDocument=${isprefilledform}`,{
        responseType: 'blob' 
      })
    .then(res => {
        return getAPIResponse(res)
    }).catch(error => {
        return getAPIResponse(error)
    })
}

export async function downloadSubscriptionAgreement(subscriptionDetails,isPreFilled) {
    return await axiosApiCall.get(`/v1/FundSubscription/DownloadSubscriptionAgreement?fundID=${subscriptionDetails?.fundID}&fundTypeID=${subscriptionDetails?.fundTypeID}&fundName=${subscriptionDetails?.fundName}&investorID=${subscriptionDetails?.investorID}&investorTypeID=${subscriptionDetails?.investorTypeID}&InvestorName=${subscriptionDetails?.InvestorName}&withData=${isPreFilled}`,{
        responseType: 'blob' 
      })
    .then(res => {
        return getAPIResponse(res)
    }).catch(error => {
        return getAPIResponse(error)
    })
}


