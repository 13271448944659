import * as React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment/moment';
import '../TextInput/styles.css'


const FirstComponent = ({ fieldName, fieldLabel, defaultValue, onhandleChange, isSaveEnabled, variant, width, required, errorMessage, applyFutureDateValidation = false,
  applyYesterdayConstraint = false,
  applyMinDateConstraint = false,
  applyMaxDateConstraint = false, }) => {
  const handleChange = (fieldName, selectedDate) => {
    onhandleChange(fieldName, selectedDate);
  };
  const yesterday = applyYesterdayConstraint ? dayjs().subtract(0, 'day') : null;
  const minDate = applyMinDateConstraint ? dayjs().subtract(100, 'year') : null;
  const selectedDate = dayjs(moment(defaultValue).format('YYYY-MM-DD'));
  const isFutureDate = applyFutureDateValidation && selectedDate.isAfter(yesterday);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} >

      <DatePicker

        label={fieldLabel}
        slotProps={{
          textField: {
            variant: variant,
            fullWidth: true,
            InputLabelProps: {
              shrink: true,
              focused: true,
            },
            style: { width: width },
            required: required,
            error: applyFutureDateValidation ? isFutureDate || !!errorMessage : false,
            helperText: applyFutureDateValidation && isFutureDate ? 'Future dates are not allowed' : <span style={{ color: 'rgb(211, 47, 47)' }}>{errorMessage}</span>,
          },
        }}
        defaultValue={dayjs(moment(defaultValue).format("YYYY-MM-DD"))}

        onChange={(newValue) => {
          handleChange(fieldName, moment(newValue.$d).format("YYYY-MM-DD"))

        }}

        disableOpenPicker={!isSaveEnabled}
        disabled={!isSaveEnabled}
        maxDate={applyMaxDateConstraint ? yesterday : null}
        minDate={applyMinDateConstraint ? minDate : null}


      />
    </LocalizationProvider>
  );

}

export default FirstComponent;
