import React, { useEffect, useState } from 'react';
import { LineChart,XAxis, YAxis, Tooltip, Legend, Line, ResponsiveContainer, Label } from 'recharts';

//#this component will display j-curve chart
function JCurveChart(props){

  const { selectedYear,selectedFund } = props;

  const [jsonData, setJsonData] = useState([]);

  const generateRandomY = () => Math.floor(Math.random() * 1000) - 500; 

  const generateRows = (numberOfRows, selectedYear) => {
    const currentYear = new Date().getFullYear();
    const startYear = selectedYear || currentYear;
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];

    let rows = [];
    for (let i = 0; i < numberOfRows; i++) {
      const year = startYear + Math.floor(i / 4);
      const quarter = quarters[i % 4];
      const dataPoint = { x: `${quarter} ${year}`, y: generateRandomY() };
      rows.push(dataPoint);
    }

    return rows;
  };

  useEffect(() => {
    const numberOfRows = 10; // Define how many rows you want to generate
    setJsonData(generateRows(numberOfRows, selectedYear));
  }, [selectedYear,selectedFund]);

  
  return (
    <div style={{ width: '100%', height: '600px', padding: '16px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={jsonData}margin={{ top: 20, right: 30, left: 0, bottom: 140 }}>
        <XAxis dataKey="x" />
          <YAxis>
            <Label 
                value="Net cashflow ($)" 
                angle={-90} 
                position="insideLeft" 
                style={{ textAnchor: 'middle' }}
            />
          </YAxis>
          <Tooltip />
         {/*  <Legend
            iconType="circle"
            iconSize={10}
            wrapperStyle={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              borderRadius: '10px',
              padding: '10px',
            }}
          /> */}
          <Line type="monotone" dataKey="y" stroke="#1F61C5" name="" strokeWidth={5} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default JCurveChart;
